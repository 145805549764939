import { useState } from 'react'

type GenericAction<K, V> = (state: K, action: V) => K

function useGenericReducer<T extends GenericAction<K, V>, K, V>(
  reducer: T,
  initialState: K
) {
  const [state, setState] = useState(initialState)

  function dispatch(action: V) {
    const nextState = reducer(state, action)
    setState(nextState)
  }
  return [state, dispatch] as const
}

export { useGenericReducer }
export type { GenericAction }
