import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from '../../store/kopiliot-api/customer'
import Spinner from '../../components/spinner/Spinner'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import Flex from '../../components/common/Flex'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { CustomerId } from '../../shared/models/id/customer-id'
import CustomerForm from '../../features/customers/form/CustomerForm'
import { useForm } from 'react-hook-form'
import Customer from '../../shared/models/customer'
import { toast } from 'react-toastify'
import { EntityType } from '../../shared/models/entity-type.models'
import RelationListing from '../../features/relation/RelationListing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomerEditMenu from './CustomerEditMenu'
import AuditLogHome from '../audit-log/AuditLogHome'
import AttributesList from '../../features/attributes/AttributesList'

type IdRouteParam = {
  customerID: string
}

const CustomerEdit = () => {
  const [key, setKey] = React.useState('main')
  // Manage navigation
  const [show, setShow] = useState(false)
  let navigate = useNavigate()
  let { customerID } = useParams<IdRouteParam>() as IdRouteParam
  // Fetch tenant data
  const {
    data,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
  } = useGetCustomerQuery(customerID)
  const [updateCustomer, { isLoading: isLoadingUpdate }] =
    useUpdateCustomerMutation()
  const customerForm = useForm<Customer>()
  const onSubmit = async (data: Customer) => {
    try {
      const res = await updateCustomer(data).unwrap()
      toast.success(`${res.name} updated successfully`)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {(isLoadingQuery || isFetchingQuery) && <Spinner />}
      <ContentBlock>
        <ContentBlock.Header title={data?.name}>
          <Flex justifyContent={'between'}>
            <Button
              variant={'outline-primary'}
              className={'me-2'}
              onClick={() => setShow(true)}
            >
              <FontAwesomeIcon icon={'bars'} />
            </Button>
            <CustomerEditMenu
              id={customerID}
              show={show}
              handleShow={() => setShow(true)}
              handleClose={() => setShow(false)}
            />
          </Flex>
        </ContentBlock.Header>
        <ContentBlock.Body className={'ps-0 pe-0'}>
          <Tabs
            transition={true}
            unmountOnExit={true}
            fill
            activeKey={key}
            onSelect={(k) => setKey(k ? k : 'main')}
          >
            <Tab eventKey="main" title="Main" className="p-3">
              <CustomerForm
                isLoading={isLoadingQuery}
                initialValues={data}
                form={customerForm}
                onSubmit={onSubmit}
                hideFields={['tenantId']} // TenantId is not editable
              />
              <Button
                onClick={customerForm.handleSubmit(onSubmit)}
                disabled={isLoadingUpdate}
                type={'submit'}
              >
                Valider
              </Button>
            </Tab>
            <Tab
              eventKey="attribute"
              title="Attributes"
              className="p-3 pe-0 ps-0"
            >
              {data?.id && <AttributesList entityID={data.id} />}
            </Tab>
            <Tab eventKey="relations" title="Relations" className="p-1">
              <RelationListing
                entityID={customerID}
                entityType={EntityType.CUSTOMER}
              />
            </Tab>
            <Tab
              eventKey="audit_logs"
              title="Audit Logs"
              className="p-3 ps-0 pe-0"
            >
              {customerID && (
                <AuditLogHome entityID={new CustomerId(customerID)} />
              )}
            </Tab>
          </Tabs>
        </ContentBlock.Body>
      </ContentBlock>
    </>
  )
}

export default CustomerEdit
