import React from 'react'
import { Accordion } from 'react-bootstrap'
import ContentBlock from '../../../../components/common/component-card/ContentBlock'
import SideBarItem from './SideBarItem'
import { NodeTypes } from '../../../../shared/models/rule-node'

const SideBar = () => {
  return (
    <>
      <ContentBlock className={''}>
        <ContentBlock.Body className={'p-0'}>
          <Accordion alwaysOpen>
            <Accordion.Item eventKey="filter">
              <Accordion.Header>Filters</Accordion.Header>
              <Accordion.Body className={'p-1'}>
                <SideBarItem
                  nodeType={NodeTypes.FILTER_ASSET_PROFILE_SWITCH}
                  icon={'filter'}
                  title={'Asset profile switch'}
                />
                <SideBarItem
                  nodeType={NodeTypes.FILTER_CHECK_ALARM_STATUS}
                  icon={'filter'}
                  title={'Check Alarm Status'}
                />
                <SideBarItem
                  nodeType={NodeTypes.FILTER_CHECK_FIELD_EXIST}
                  icon={'filter'}
                  title={'Rechercher champs existants'}
                />
                <SideBarItem
                  nodeType={NodeTypes.FILTER_ENTITY_TYPE}
                  icon={'filter'}
                  title={'Entity Type'}
                />
                <SideBarItem
                  nodeType={NodeTypes.FILTER_ENTITY_TYPE_SWITCH}
                  icon={'filter'}
                  title={'Entity Type Switch'}
                />
                <SideBarItem
                  nodeType={NodeTypes.FILTER_MESSAGE_TYPE}
                  icon={'filter'}
                  title={'Message Type'}
                />
                <SideBarItem
                  nodeType={NodeTypes.FILTER_MESSAGE_TYPE_SWITCH}
                  icon={'filter'}
                  title={'Message Type Switch'}
                />
                <SideBarItem
                  nodeType={NodeTypes.FILTER_SCRIPT}
                  icon={'filter'}
                  title={'Script'}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="enrichment">
              <Accordion.Header>Enrichment</Accordion.Header>
              <Accordion.Body>
                <SideBarItem
                  nodeType={NodeTypes.ENRICHMENT_CUSTOMER_ATTRIBUTES}
                  icon={'puzzle-piece'}
                  title={'Attributs du client'}
                />
                <SideBarItem
                  nodeType={NodeTypes.ENRICHMENT_ORIGINATOR_ATTRIBUTES}
                  icon={'puzzle-piece'}
                  title={"Attributs de l'origine"}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="transform">
              <Accordion.Header>Transform</Accordion.Header>
              <Accordion.Body>
                <SideBarItem
                  nodeType={NodeTypes.TRANSFORM_RENAME_KEYS}
                  icon={'magnifying-glass-arrow-right'}
                  title={'Rename Keys'}
                />
                <SideBarItem
                  nodeType={NodeTypes.TRANSFORM_SCRIPT}
                  icon={'magnifying-glass-arrow-right'}
                  title={'Script'}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="action">
              <Accordion.Header>Action</Accordion.Header>
              <Accordion.Body>
                <SideBarItem
                  nodeType={NodeTypes.ACTION_SAVE_TELEMETRY}
                  icon={'database'}
                  title={'Save Timeseries'}
                />
                <SideBarItem
                  nodeType={NodeTypes.ACTION_CREATE_ALARM}
                  icon={'bell'}
                  title={'Créer Alarme'}
                />
                <SideBarItem
                  nodeType={NodeTypes.ACTION_CLEAR_ALARM}
                  icon={'bell-slash'}
                  title={'Supprimer Alarme'}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="external">
              <Accordion.Header>External</Accordion.Header>
              <Accordion.Body>
                <p>Body</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="flow">
              <Accordion.Header>Flow</Accordion.Header>
              <Accordion.Body>
                <p>Body</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ContentBlock.Body>
      </ContentBlock>
    </>
  )
}

export default SideBar
