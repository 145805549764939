import React, { PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
import { Col, Card, Row } from 'react-bootstrap'
import classNames from 'classnames'

interface TitleProps extends PropsWithChildren {
  titleTag: keyof JSX.IntrinsicElements
  className: string
  breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

const Title = ({
  titleTag: TitleTag = 'h5',
  className,
  breakPoint,
  children,
}: TitleProps) => (
  <TitleTag
    className={classNames(
      {
        'mb-0': !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint,
      },
      className
    )}
  >
    {children}
  </TitleTag>
)

interface CardHeaderProps extends PropsWithChildren {
  title: string
  light?: boolean
  titleTag?: keyof JSX.IntrinsicElements
  titleClass?: string
  className: string
  breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  endEl: any
}

const CardHeader = ({
  title,
  light,
  titleTag,
  titleClass,
  className,
  breakPoint,
  endEl,
  children,
}: CardHeaderProps) => (
  <Card.Header className={classNames(className, { 'bg-light': light })}>
    {endEl ? (
      <Row className="align-items-center">
        <Col>
          <Title
            breakPoint={breakPoint}
            titleTag={titleTag}
            className={titleClass ? titleClass : ''}
          >
            {title}
          </Title>
          {children}
        </Col>
        <Col
          {...{ [breakPoint ? breakPoint : 'xs']: 'auto' }}
          className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
        >
          {endEl}
        </Col>
      </Row>
    ) : (
      <Title
        breakPoint={breakPoint}
        titleTag={titleTag}
        className={titleClass ? titleClass : ''}
      >
        {title}
      </Title>
    )}
  </Card.Header>
)

Title.propTypes = {
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.node,
}

Title.defaultProps = { titleTag: 'h5' }

export default CardHeader
