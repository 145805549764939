import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import NavbarVertical from '../../components/navbar/vertical/NavbarVertical'
import NavbarTop from '../../components/navbar/top/NavbarTop'
import Footer from '../../components/footer/Footer'
import Spinner from '../../components/spinner/Spinner'
import { useMeQuery } from '../../store/kopiliot-api/base-api'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useLocation } from 'react-router'
import { setBreadcrumb } from '../../store/slices/breadcrumb'
import BreadcrumbKopiliot from '../../components/breadcrumb/BreadcrumbKopiliot'
import { websocketConnect } from '../../store/slices/websocket'
import { toast } from 'react-toastify'

const PrivateRoute = () => {
  const toastId = React.useRef<string | null>(null)
  const { data, error, isLoading, isFetching } = useMeQuery(null)
  const {
    isConnected,
    isConnecting,
    isReconnecting,
    error: wsError,
  } = useAppSelector((state) => state.websocket)
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    //dispatch(websocketConnect())
  }, [data])
  React.useEffect(() => {
    if (wsError) {
      toast.error('Error fetching user data')
    }
    if (isConnecting) {
      toastId.current = 'toast-connecting-ws'
      toast.info('Connecting to WebSocket', {
        toastId: toastId.current,
        isLoading: true,
      })
    }
    if (isReconnecting) {
      toast.info('Reconnecting to WebSocket')
    }
    if (isConnected && toastId.current) {
      toast.update(toastId.current, {
        render: 'Connected to WebSocket',
        isLoading: false,
        type: 'success',
        autoClose: 3000,
        className: 'rotateY animated',
      })
    }
  }, [isConnected, isConnecting, isReconnecting, wsError])
  if (isFetching || isLoading) {
    return <Spinner />
  } else if (data) {
    return (
      <>
        <NavbarVertical />
        <div className={'content'}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
          <Footer />
        </div>
      </>
    )
  } else {
    return <Navigate to={'auth/login'} />
  }
}

export default PrivateRoute
