import React from 'react'
import TooltipBadge, { TooltipBadgeProps } from './TooltipBadge'
import { ProgressBar, ProgressBarProps } from 'react-bootstrap'

interface ProgressBarWithTooltipProps {}

const ProgressBarWithTooltip = ({
  ...rest
}: ProgressBarWithTooltipProps & TooltipBadgeProps & ProgressBarProps) => {
  return (
    <TooltipBadge {...rest}>
      <ProgressBar {...rest} />
    </TooltipBadge>
  )
}

export default ProgressBarWithTooltip
