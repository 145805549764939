import React from 'react'
import { MultiSelectProps, SelectBaseProps, SelectCommonProps } from './types'
import SelectBase from './SelectBase'

const Select = <T,>(props: SelectBaseProps<T>) => {
  return <SelectBase {...props} />
}

const MultiSelect = <T,>(props: MultiSelectProps<T>) => {
  return <SelectBase {...props} isMulti />
}

export { Select, MultiSelect }
