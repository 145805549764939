import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  label?: string
  help?: string
  labelFor?: string
  className?: string
}

const LabelHelp = ({ label, help, labelFor, className }: Props) => {
  return (
    <>
      <Form.Label className={`me-1 ${className}`} htmlFor={labelFor}>
        {label}
      </Form.Label>
      <OverlayTrigger placement={'top'} overlay={<Tooltip>{help}</Tooltip>}>
        <FontAwesomeIcon icon={'circle-question'} />
      </OverlayTrigger>
    </>
  )
}

export default LabelHelp
