import React from 'react'
import Profile from './profile/Profile'
import { useMeQuery } from '../../store/kopiliot-api/base-api'

const Me = () => {
  const { data, error, isLoading } = useMeQuery(null)
  return <>{isLoading ? <div>Loading</div> : <Profile user={data!} />}</>
}

export default Me
