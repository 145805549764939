import React, { useRef, useState } from 'react'
import { Node } from 'reactflow'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  Col,
  Form,
  Overlay,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-golang'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface TransformScriptConfiguration {
  script: string
}

interface TransformScriptFormProps {
  onSubmit: (
    data: Node<
      BaseNodeData<TransformScriptConfiguration>,
      NodeTypes.FILTER_SCRIPT
    >
  ) => void
  defaultValues?: Node<
    BaseNodeData<TransformScriptConfiguration>,
    NodeTypes.FILTER_SCRIPT
  >
}

const PopoverHelp = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Transform Script</Popover.Header>
    <Popover.Body className={'text-700'}>
      Votre code sera exécuté dans une fonction recevant trois paramètres :
      <br />
      <ul>
        <li>
          <strong>msg</strong> : le message qui est une map[string]any
        </li>
        <li>
          <strong>metadata</strong> : les metadata sous la forme d'une
          map[string]string. Attention ! les metadata sont des string. Il vous
          faudra les convertir si besoin. Par exemple, si vous avez une metadata
          de type booléen, vous devrez la convertir en booléen.
        </li>
        <li>
          <strong>msgType</strong> : le type de message
        </li>
      </ul>
      <br />
      Votre code doit retourner une map avec potentiellement des metadata (clé
      metadata), le message (clé msg) et le type de message (clé msgType).{' '}
      <br />
      <br />
      Si une des clés est absente, elle ne sera pas modifiée dans le message de
      sortie.
    </Popover.Body>
  </Popover>
)

const TransformScriptForm = ({
  onSubmit,
  defaultValues,
}: TransformScriptFormProps) => {
  const [show, setShow] = useState(false)
  const target = useRef(null)
  const form = useForm<
    Node<BaseNodeData<TransformScriptConfiguration>, NodeTypes.FILTER_SCRIPT>
  >({
    defaultValues,
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={errors?.data?.name}
            />
          </Form.Group>
        </Row>
        <Row className={''}>
          <Form.Group as={Col} lg={12}>
            <Form.Label ref={target}>Script </Form.Label>
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={PopoverHelp}
            >
              <FontAwesomeIcon
                icon={'circle-question'}
                className={'cursor-pointer'}
              />
            </OverlayTrigger>
            <Controller
              name={'data.configuration.script'}
              control={form.control}
              rules={{ required: 'Script is required' }}
              render={({ field }) => {
                return (
                  <AceEditor
                    width={'100%'}
                    height={'300px'}
                    placeholder="Write some code"
                    value={field.value}
                    mode="golang"
                    theme="monokai"
                    //onLoad={this.onLoad}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    onChange={(value) => {
                      field.onChange(value)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row className={''}>
          <Form.Group as={Col} lg={12}>
            <Input
              register={register('data.description')}
              label={'Description'}
              type={'textarea'}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default TransformScriptForm
