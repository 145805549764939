import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useGetTenantProfileQuery } from '../../store/kopiliot-api/tenant-profile'

interface TenantProfileNameProps {
  tenantProfileId: string
}

const TenantName = ({ tenantProfileId }: TenantProfileNameProps) => {
  const { data, isLoading, isFetching, error } =
    useGetTenantProfileQuery(tenantProfileId)
  if (error) {
    return <span>Error while getting the tenant profile name</span>
  }
  return isLoading && isFetching ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <span>{data?.name}</span>
  )
}

export default TenantName
