import React, { useEffect, useState } from 'react'
import { EntityType } from '../../shared/models/entity-type.models'
import Select, { MultiValue, SingleValue } from 'react-select'
import _ from 'lodash'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import Flex from '../common/Flex'

interface Props<T extends FieldValues> {
  labelEntityType?: string
  labelEntityID?: string
  isMultiple?: boolean
  allowClear?: boolean
  placeholder?: string
  allowedEntityTypes?: EntityType[]
  options?: { value: any; label: string }[]
  value?: any
  entityTypeField: Path<T>
  entityIdField: Path<T>
  onChangeSelectID?: (value: { value: any; label: string }) => void
  onChangeSearchTerm?: (value: string) => void
}

const SelectEntity = <T extends FieldValues>({
  labelEntityType,
  labelEntityID,
  isMultiple,
  allowedEntityTypes,
  entityTypeField,
  entityIdField,
  value,
  onChangeSelectID,
  onChangeSearchTerm,
  options = [],
}: Props<T>) => {
  const form = useFormContext<T>()
  const [searchTerm, setSearchTerm] = useState('')
  const {
    register,
    formState: { defaultValues },
  } = useFormContext<T>()

  useEffect(() => {
    const search = setTimeout(() => {
      if (searchTerm && searchTerm.length > 3 && onChangeSearchTerm) {
        onChangeSearchTerm(searchTerm)
      }
    }, 1000)
    return () => clearTimeout(search)
  }, [searchTerm])

  const getOptions = () => {
    if (allowedEntityTypes && allowedEntityTypes.length > 0) {
      return allowedEntityTypes.map(
        (value): { label: string; value: string } => {
          return {
            label: value,
            value: value,
          }
        }
      )
    }
    return Object.keys(EntityType).map(
      (key): { label: string; value: string } => {
        return {
          label: key,
          value: EntityType[key as keyof typeof EntityType],
        }
      }
    )
  }
  const getValue = (fieldValue: any) => {
    if (isMultiple) {
      const find = _.filter(options, (value) => {
        return _.includes(fieldValue, value.value)
      })
      return find ? find : null
    } else {
      const find = _.find(options, (value) => {
        return value.value === fieldValue
      })
      return find ? find : null
    }
  }

  const isMultiValue = <Options extends unknown>(
    arg: MultiValue<Options> | SingleValue<Options>
  ): arg is MultiValue<Options> => {
    return Array.isArray(arg)
  }

  return (
    <>
      <Flex direction={'row'}>
        <div className={'w-25'}>
          <Form.Label htmlFor={entityTypeField}>
            {labelEntityType ? labelEntityType : "Type d'entité"}
          </Form.Label>
          <Controller
            name={entityTypeField}
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  classNamePrefix={'react-select'}
                  isMulti={false}
                  options={getOptions()}
                  value={value ? ({ value: value, label: value } as any) : null}
                  onChange={(
                    value: SingleValue<{ value: string; label: string }>,
                    actionMeta
                  ) => {
                    onChange(value?.value)
                  }}
                  {..._.omit(register(entityTypeField), ['onChange'])}
                />
              )
            }}
          />
        </div>
        <div className={'w-50'}>
          <Form.Label htmlFor={entityIdField}>
            {labelEntityID ? labelEntityID : 'ID'}
          </Form.Label>
          <Controller
            name={entityIdField}
            shouldUnregister={true}
            render={({ field, formState, fieldState }) => {
              return (
                <Select
                  isSearchable={true}
                  classNamePrefix={'react-select'}
                  isMulti={isMultiple}
                  options={options}
                  onChange={(value) => {
                    if (isMultiValue(value)) {
                      const updValues = _.map(value, 'value')
                      field.onChange(updValues)
                    } else {
                      field.onChange(value?.value)
                    }
                  }}
                  onInputChange={setSearchTerm}
                  value={getValue(field.value)}
                  ref={field.ref}
                />
              )
            }}
          />
        </div>
      </Flex>
    </>
  )
}

export default SelectEntity
