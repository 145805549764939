import React from 'react'
import { Alias, AliasFilterType } from '../../../../shared/models/alias.model'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../components/form/Input'
import { camelCaseToWords } from '../../../../utils/utils'
import SingleEntity from './SingleEntity'
import Select from 'react-select'
import EntityList from './EntityList'

interface Props {
  form: UseFormReturn<Alias>
  onSubmit: (data: Alias) => void
}
const AliasForm = ({ form, onSubmit }: Props) => {
  const watchType = form.watch('config.type')

  const renderDynamicForm = () => {
    switch (watchType) {
      case AliasFilterType.singleEntity:
        return <SingleEntity />
      case AliasFilterType.entityList:
        return <EntityList />
      default:
        return <div>Cannot retrieve the appropriate form</div>
    }
  }

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={6}>
            <Input
              label={'Nom'}
              register={form.register('name', {
                required: 'Le nom est obligatoire',
                shouldUnregister: true,
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Input
              register={form.register('description', {
                shouldUnregister: true,
              })}
              label={'Description'}
              type={'textarea'}
            />
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} md={4}>
            <Form.Label htmlFor={'config.type'}>Type de filtre</Form.Label>
            <Controller
              name={'config.type'}
              shouldUnregister={false}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    classNamePrefix={'react-select'}
                    isMulti={false}
                    options={Object.keys(AliasFilterType).map((key) => ({
                      label: camelCaseToWords(key),
                      value: key,
                    }))}
                    value={
                      value
                        ? { value: value, label: camelCaseToWords(value) }
                        : null
                    }
                    onChange={(value, actionMeta) => {
                      if (!value) return
                      onChange(value.value)
                    }}
                    //{..._.omit(form.register('config.type'), ['onChange'])}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        {watchType && renderDynamicForm()}
      </Form>
    </FormProvider>
  )
}

export default AliasForm
