import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import {
  TimeWindow,
  TimeWindowType,
} from '../../../../shared/models/time.model'
import React from 'react'
import { Button, Form, Tab, Tabs } from 'react-bootstrap'
import Divider from '../../../../components/common/Divider'
import TimeWindowRealTime from './TimeWindowRealTime'
import TimeWindowHistory from './TimeWindowHistory'
import Flex from '../../../../components/common/Flex'

interface Props {
  onSubmit: SubmitHandler<TimeWindow>
  className?: string
  isEditing?: boolean
  defaultTimeWindow: TimeWindow
}

const TimeWindowForm = ({
  onSubmit,
  className,
  isEditing,
  defaultTimeWindow,
}: Props) => {
  const form = useForm<TimeWindow>({ defaultValues: defaultTimeWindow })
  const [activeTab, setActiveTab] = React.useState(
    defaultTimeWindow.timeWindowType
  )
  React.useEffect(() => {
    form.setValue('timeWindowType', activeTab)
  }, [activeTab])
  return (
    <div className={className}>
      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Tabs
            defaultActiveKey={activeTab}
            onSelect={(k) => setActiveTab(k as TimeWindowType)}
            fill
            unmountOnExit={true}
          >
            <Tab
              title={'Temps réel'}
              eventKey={TimeWindowType.REAL_TIME}
              unmountOnExit={true}
            >
              <TimeWindowRealTime isEditing={isEditing} />
            </Tab>
            <Tab
              title={'Historique'}
              eventKey={TimeWindowType.HISTORY}
              unmountOnExit={true}
            >
              <TimeWindowHistory isEditing={isEditing} />
            </Tab>
          </Tabs>
          <Divider />
          <Flex direction={'row'} justifyContent={'start'} className={'px-2'}>
            <Button variant={'outline-primary'} type={'submit'}>
              Valider
            </Button>
          </Flex>
        </Form>
      </FormProvider>
    </div>
  )
}

export default TimeWindowForm
