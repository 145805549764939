import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import { Node } from 'reactflow'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'
import { AlarmSeverity } from '../../../../../../shared/models/alarm.model'
import AceEditor from 'react-ace'
import { Select } from '../../../../../../components/page-components/selects-entities/generic-select/Select'
import _ from 'lodash'
import { SelectableValue } from '../../../../../../components/page-components/selects-entities/generic-select/selectable-value'

export interface CreateAlarmConfiguration {
  useMessageAlarmData?: boolean
  script?: string
  alarmType: string
  alarmSeverity: AlarmSeverity
  propagateAlarmToRelatedEntities?: boolean
  propagateAlarmToOwner?: boolean
  propagateAlarmToOwnersHierarchy?: boolean
  propagateAlarmToTenant?: boolean
}

interface CreateAlarmFormProps {
  onSubmit: (
    data: Node<
      BaseNodeData<CreateAlarmConfiguration>,
      NodeTypes.ACTION_CREATE_ALARM
    >
  ) => void
  defaultValues: Node<
    BaseNodeData<CreateAlarmConfiguration>,
    NodeTypes.ACTION_CREATE_ALARM
  >
}

const CreateAlarmForm = ({ onSubmit, defaultValues }: CreateAlarmFormProps) => {
  const form = useForm({ defaultValues })
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = form

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={errors.data?.name ? errors.data?.name : undefined}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Check
              type="switch"
              label="Debug Mode ?"
              {...register('data.isDebugMode')}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Check
              type={'checkbox'}
              {...register('data.configuration.useMessageAlarmData')}
              label={'Use message alarm data'}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <Form.Label>Script</Form.Label>
            <Controller
              name={'data.configuration.script'}
              control={form.control}
              rules={{ required: 'Script is required' }}
              render={({ field }) => {
                return (
                  <AceEditor
                    width={'100%'}
                    height={'300px'}
                    placeholder="Write some code"
                    value={field.value}
                    mode="golang"
                    theme="monokai"
                    //onLoad={this.onLoad}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    onChange={(value) => {
                      field.onChange(value)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.configuration.alarmType', {
                required: "Le type d'alarme est obligatoire",
              })}
              label={"Type d'alarme"}
              error={
                errors.data?.configuration?.alarmType
                  ? errors.data?.configuration?.alarmType
                  : undefined
              }
            />
          </Form.Group>
        </Row>
        <Row className={'mt-2'}>
          <Form.Label>Sévérité</Form.Label>
          <Controller
            name={'data.configuration.alarmSeverity'}
            control={form.control}
            rules={{ required: 'Alarm severity is required' }}
            render={({ field }) => {
              return (
                <Select
                  errorMessage={errors.data?.configuration?.alarmSeverity?.toString()}
                  invalid={
                    !!errors.data?.configuration?.alarmSeverity?.toString()
                  }
                  onChange={(value: SelectableValue<AlarmSeverity>) => {
                    field.onChange(value.value)
                  }}
                  value={field.value}
                  options={_.map(Object.keys(AlarmSeverity), (key) => {
                    return {
                      label: key,
                      value: key as AlarmSeverity,
                    }
                  })}
                />
              )
            }}
          />
        </Row>
        <Row className={'mt-2'}>
          <Form.Group as={Col} md={12}>
            <Form.Check
              type={'checkbox'}
              {...register(
                'data.configuration.propagateAlarmToRelatedEntities'
              )}
              label={"Propager l'alarme aux entités liées"}
            />
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Check
              type={'checkbox'}
              {...register('data.configuration.propagateAlarmToOwner')}
              label={"Propager l'alarme au propriétaire"}
            />
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Check
              type={'checkbox'}
              {...register(
                'data.configuration.propagateAlarmToOwnersHierarchy'
              )}
              label={"Propager l'alarme à la hiérarchie des propriétaires"}
            />
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Check
              type={'checkbox'}
              {...register('data.configuration.propagateAlarmToTenant')}
              label={"Propager l'alarme au tenant"}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default CreateAlarmForm
