import { TsValue } from '../../shared/models/telemetry/telemetry.model'
import { Chart, Filler } from 'chart.js'
import { Line } from 'react-chartjs-2'
import React from 'react'

Chart.register(Filler)

interface GraphProps {
  label?: string
  metric?: Array<TsValue> | null
}
const MetricsChart = ({ label, metric }: GraphProps) => {
  return (
    <>
      <Line
        options={{
          elements: {
            line: {
              borderWidth: 1,
            },
          },
          parsing: {
            xAxisKey: 'ts',
            yAxisKey: 'value',
          },
          scales: {
            x: {
              type: 'timeseries',
            },
          },
        }}
        data={{
          datasets: [
            {
              label: label,
              data: metric,
              fill: true,
              borderColor: 'rgb(29, 112, 183)',
              backgroundColor: 'rgb(29, 112, 183, 0.5)',
            },
          ],
        }}
      />
    </>
  )
}

export default MetricsChart
