import { JsonTableDataKeySpecificProps } from '../../../../../../shared/models/panel'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { TimeSeriesTableType } from '../time-series-table/columns'
import _ from 'lodash'

export interface JsonTableType {
  ts: number
  [key: string]: number
}

const getColumns = (
  cols: JsonTableDataKeySpecificProps
): ColumnDef<JsonTableType, any>[] => {
  const columnHelper = createColumnHelper<JsonTableType>()
  const columns: ColumnDef<JsonTableType, any>[] = [
    columnHelper.accessor((row) => row.ts, {
      id: 'ts',
      cell: (info) =>
        info.getValue()
          ? dayjs(info.getValue()).format('DD-MM-YYYY HH:mm:ss')
          : '',
      header: () => 'Timestamp',
      enableSorting: true,
    }),
  ]
  _.forEach(cols.columns, (col) => {
    columns.push(
      columnHelper.accessor((row) => row[col.key], {
        id: col.key,
        cell: (info) => info.getValue(),
        header: () => col.label,
        enableSorting: true,
      })
    )
  })
  return columns
}

export default getColumns
