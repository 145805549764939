import React from 'react'
import { useNavigate } from 'react-router-dom'
import AddRuleChainModal from '../../features/rule-chain/components/list/AddRuleChainModal'
import { useAppSelector } from '../../store/store'
import RuleChain from '../../shared/models/rule-chain.model'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import { RuleChainQuery } from '../../store/kopiliot-api/query-type'
import { useLazyGetRuleChainsQuery } from '../../store/kopiliot-api/rule-chain'
import useTenantContext from '../context/tenant.context'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import {
  defaultRuleChainActionsRow,
  getColumnsForTableRuleChain,
} from '../../features/rule-chain/components/list/utils'
import { useLazyGetTenantRuleChainsQuery } from '../../store/kopiliot-api/tenant'
import { EntityType } from '../../shared/models/entity-type.models'

const RuleChainList = () => {
  const { authority, tenantID: userTenantID } = useAppSelector(
    (state) => state.currentUser
  )
  const { tenantIDParam } = useTenantContext()
  const navigate = useNavigate()
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [selectedRuleChain, setSelectedRuleChain] = React.useState<
    string | undefined
  >(undefined)
  // Pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    RuleChainQuery,
    PaginationActions
  >(reducerPage, {
    limit: 20,
    offset: 0,
    textSearch: '',
    tenantID: undefined,
  })
  // Queries
  const [getRuleChains, resultGetRuleChain] = useLazyGetRuleChainsQuery()
  const [getRuleChainsByTenant, resultGetRuleChainByTenant] =
    useLazyGetTenantRuleChainsQuery()
  React.useEffect(() => {
    fetchRuleChains()
  }, [paginationState.offset, paginationState.limit, tenantIDParam])

  // Utility functions
  const fetchRuleChains = () => {
    if (tenantIDParam) {
      getRuleChainsByTenant({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch: paginationState.textSearch,
        tenantID: { id: tenantIDParam, entityType: EntityType.TENANT },
      })
    } else {
      getRuleChains({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch: paginationState.textSearch,
        tenantID: paginationState.tenantID,
      })
    }
  }
  const getData = () => {
    if (tenantIDParam) {
      return resultGetRuleChainByTenant.data
    } else {
      return resultGetRuleChain.data
    }
  }
  const handleEdit = (id: string) => {
    navigate(`/rule-chain/${id}`)
  }
  const handleDelete = (id: string) => {
    setSelectedRuleChain(id)
    setShowModalDelete(true)
  }
  const handleCreate = () => {
    setShowModalCreate(true)
  }
  const isLoadingData = resultGetRuleChain.isLoading
  const getDisabledField = (): Array<keyof RuleChain> => {
    if (authority === 'TENANT_ADMIN') {
      return ['tenantId']
    }
    return []
  }
  const actionsRow = defaultRuleChainActionsRow(handleDelete, handleEdit)
  return (
    <>
      <KopiliotList
        title={'Rule Chains'}
        icon={'code-branch'}
        isLoading={isLoadingData}
        data={getData()}
        actionsProps={{
          onRefresh: () => {
            fetchRuleChains()
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          hiddenColumns: ['id'],
          rowClassName: 'default__table-row align-middle fs-10',
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableRuleChain(actionsRow),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: RuleChain) => item.id!.id,
          titleAccessor: (item: RuleChain) => item.name,
          descriptionAccessor: (item: RuleChain) => item.description,
          linkAccessor: (item: RuleChain) => `${item.id!.id}`,
          actionsRow: actionsRow,
        }}
      />
      <AddRuleChainModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        defaultValues={
          authority === 'TENANT_ADMIN' ? { tenantId: userTenantID } : {}
        }
        disabledField={getDisabledField()}
      />
    </>
  )
}

export default RuleChainList
