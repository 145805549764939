import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import team3 from '../../../assets/img/team/3.jpg'
import Avatar from '../../common/Avatar'
import { useLogoutMutation } from '../../../store/kopiliot-api/auth'
import { toast } from 'react-toastify'
import { useGetUserQuery } from '../../../store/kopiliot-api/user'
import { useMeQuery } from '../../../store/kopiliot-api/base-api'

const ProfileDropdown = () => {
  const [mutation, { isLoading, isSuccess, error, isError }] =
    useLogoutMutation()
  const { data, isLoading: isLoadingUser } = useMeQuery(null)
  useEffect(() => {
    if (isSuccess) {
      window.location.href = '/auth/login'
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        ;(error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          })
        )
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])
  const onLogoutHandler = async () => {
    mutation()
  }
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar name={`${data?.firstname} ${data?.lastname}`} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/me">
            Profile
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/me/settings">
            Paramètres
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={onLogoutHandler}>Déconnexion</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
