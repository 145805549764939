import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Input from '../../../../components/form/Input'
import { Panel } from '../../../../shared/models/panel'
import { Form } from 'react-bootstrap'
import _ from 'lodash'

export const LegendForm = () => {
  const {
    register,
    unregister,
    watch,
    control,
    formState,
    getValues,
    setValue,
  } = useFormContext<Panel>()
  const watchLegend = watch('config.settings.plugins.legend.display')
  return (
    <>
      <Input
        register={register('config.settings.plugins.legend.display', {
          shouldUnregister: true,
        })}
        type={'switch'}
        label={'Visibility'}
      />

      {watchLegend && (
        <>
          <Input
            register={register('config.settings.plugins.legend.align', {
              shouldUnregister: true,
            })}
            type={'select'}
            label={'Align'}
            options={[
              { value: 'start', text: 'Start' },
              { value: 'center', text: 'Center' },
              { value: 'end', text: 'End' },
            ]}
          />
          <Input
            register={register('config.settings.plugins.legend.position', {
              shouldUnregister: true,
            })}
            type={'select'}
            label={'Position'}
            isDisabled={!watchLegend}
            options={[
              { value: 'top', text: 'Top' },
              { value: 'bottom', text: 'Bottom' },
              { value: 'left', text: 'Left' },
              { value: 'right', text: 'Right' },
            ]}
          />
          <Input
            register={register('config.settings.plugins.legend.fullWidth', {
              shouldUnregister: true,
            })}
            type={'switch'}
            label={'Full width'}
          />
          <Input
            register={register('config.settings.plugins.legend.reverse', {
              shouldUnregister: true,
            })}
            type={'switch'}
            label={'Reverse'}
          />
          <Input
            register={register(
              'config.settings.plugins.legend.label.usePointStyle',
              {
                shouldUnregister: true,
              }
            )}
            type={'switch'}
            label={'Point style'}
          />
        </>
      )}
    </>
  )
}

export default LegendForm
