import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Panel } from '../../../../../shared/models/panel'
import Input from '../../../../../components/form/Input'

const GaugeStyleForm = () => {
  const { register, watch, setValue } = useFormContext<Panel>()
  return (
    <>
      <Input
        register={register('config.settings.type', {
          shouldUnregister: true,
        })}
        type={'select'}
        label={'Easing'}
        options={[
          { value: 'grafana', text: 'Grafana' },
          { value: 'semicircle', text: 'Semicircle' },
          { value: 'radial', text: 'Radial' },
        ]}
      />
    </>
  )
}

export default GaugeStyleForm
