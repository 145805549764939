import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { RuleChainId } from '../../shared/models/id/rule-chain-id'
import { Connection, Node } from 'reactflow'

interface RuleChainEditSlice {
  ruleChainId?: RuleChainId
  ruleNodes: Node[]
  connections: Connection[]
}

const initialState: RuleChainEditSlice = {
  ruleNodes: [],
  connections: [],
}

const ruleChainEditSlice = createSlice({
  initialState: initialState,
  name: 'ruleChainEdit',
  reducers: {
    setRuleChainId(state, action: PayloadAction<RuleChainId>) {
      state.ruleChainId = action.payload
    },
  },
})

export const { setRuleChainId } = ruleChainEditSlice.actions

export default ruleChainEditSlice.reducer

export const selectCurrentRuleChain = (state: RootState) => state.ruleChainEdit
