import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal } from 'react-bootstrap'
import { Alias } from '../../../shared/models/alias.model'
import ButtonLoading from '../../../components/button/ButtonLoading'
import AliasForm from '../form/alias/AliasForm'
import {
  useAddAliasMutation,
  useUpdateAliasMutation,
} from '../../../store/kopiliot-api/dashboard'
import { DashboardId } from '../../../shared/models/id/dashboard-id'
import { toast } from 'react-toastify'

interface AddAliasModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  alias?: Alias
  dashboardID: string
}

const AliasModal = ({
  isOpen,
  setIsOpen,
  alias,
  dashboardID,
}: AddAliasModalProps) => {
  // DEFAULT
  const defaultAlias: Partial<Alias> = {
    name: '',
    description: '',
    config: {},
    dashboardID: new DashboardId(dashboardID),
  }
  // FORM
  const form = useForm<Alias>({ defaultValues: defaultAlias })

  const [addAlias] = useAddAliasMutation()
  const [updateAlias] = useUpdateAliasMutation()

  const handleClose = () => {
    form.reset(defaultAlias)
    setIsOpen(!isOpen)
  }
  React.useEffect(() => {
    if (isOpen) {
      if (alias) {
        form.reset(alias)
      } else {
        form.reset(defaultAlias)
      }
    }
  }, [isOpen])

  const onSubmit = async (data: Alias) => {
    try {
      let message = ''
      if (data.id && data.id.id) {
        await updateAlias(data).unwrap()
        message = 'Alias modifié avec succès'
      } else {
        await addAlias(data).unwrap()
        message = 'Alias ajouté avec succès'
      }
      toast.success(message)
      form.reset(defaultAlias)
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'xl'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Ajouter un alias</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <AliasForm form={form} onSubmit={onSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          variant={'outline-primary'}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AliasModal
