import React from 'react'
import { Panel } from '../../../../shared/models/panel'
import { useFormContext } from 'react-hook-form'
import Input from '../../../../components/form/Input'

const AnimationForm = () => {
  const { register, watch, setValue } = useFormContext<Panel>()

  return (
    <>
      <Input
        register={register('config.settings.animation.duration', {
          shouldUnregister: false,
        })}
        type={'number'}
        label={'Duration'}
      />
      <Input
        register={register('config.settings.animation.easing', {
          shouldUnregister: false,
        })}
        type={'select'}
        label={'Easing'}
        options={[
          { value: 'linear', text: 'Linear' },
          { value: 'easeInQuad', text: 'EaseInQuad' },
          { value: 'easeOutQuad', text: 'EaseOutQuad' },
          { value: 'easeInOutQuad', text: 'EaseInOutQuad' },
          { value: 'easeInCubic', text: 'EaseInCubic' },
          { value: 'easeOutCubic', text: 'EaseOutCubic' },
          { value: 'easeInOutCubic', text: 'EaseInOutCubic' },
          { value: 'easeInQuart', text: 'EaseInQuart' },
          { value: 'easeOutQuart', text: 'EaseOutQuart' },
          { value: 'easeInOutQuart', text: 'EaseInOutQuart' },
          { value: 'easeInQuint', text: 'EaseInQuint' },
          { value: 'easeOutQuint', text: 'EaseOutQuint' },
          { value: 'easeInOutQuint', text: 'EaseInOutQuint' },
          { value: 'easeInSine', text: 'EaseInSine' },
          { value: 'easeOutSine', text: 'EaseOutSine' },
          { value: 'easeInOutSine', text: 'EaseInOutSine' },
          { value: 'easeInExpo', text: 'EaseInExpo' },
          { value: 'easeOutExpo', text: 'EaseOutExpo' },
          { value: 'easeInOutExpo', text: 'EaseInOutExpo' },
          { value: 'easeInCirc', text: 'EaseInCirc' },
          { value: 'easeOutCirc', text: 'EaseOutCirc' },
          { value: 'easeInOutCirc', text: 'EaseInOutCirc' },
          { value: 'easeInElastic', text: 'EaseInElastic' },
          { value: 'easeOutElastic', text: 'EaseOutElastic' },
          { value: 'easeInOutElastic', text: 'EaseInOutElastic' },
          { value: 'easeInBack', text: 'EaseInBack' },
          { value: 'easeOutBack', text: 'EaseOutBack' },
          { value: 'easeInOutBack', text: 'EaseInOutBack' },
          { value: 'easeInBounce', text: 'EaseInBounce' },
          { value: 'easeOutBounce', text: 'EaseOutBounce' },
          { value: 'easeInOutBounce', text: 'EaseInOutBounce' },
        ]}
      />
      <Input
        register={register('config.settings.animation.loop', {
          shouldUnregister: false,
        })}
        type={'switch'}
        label={'Loop animation'}
      />
    </>
  )
}

export default AnimationForm
