import React from 'react'
import { Node } from 'reactflow'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../components/form/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BaseNodeData } from '../base/types'
import { NodeTypes } from '../../../../../shared/models/rule-node'

export interface RenameKeysConfiguration {
  messageTypeFilter: string[]
  location: 'metadata' | 'data'
  keys: { keyName?: string; newKeyName?: string }[]
}

interface Props {
  onSubmit: (
    data: Node<
      BaseNodeData<RenameKeysConfiguration>,
      NodeTypes.TRANSFORM_RENAME_KEYS
    >
  ) => void
  defaultValues?: Node<
    BaseNodeData<RenameKeysConfiguration>,
    NodeTypes.TRANSFORM_RENAME_KEYS
  >
}

const RenameKeysNodeForm = ({ onSubmit, defaultValues }: Props) => {
  const form = useForm<
    Node<BaseNodeData<RenameKeysConfiguration>, NodeTypes.TRANSFORM_RENAME_KEYS>
  >({
    defaultValues,
  })
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'data.configuration.keys',
  })
  return (
    <FormProvider {...form}>
      <Form
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
        id={'form_rule_node'}
      >
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={form.register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={form.formState.errors?.data?.name}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            lg={6}
            md={12}
            className={'d-flex align-items-center'}
          >
            <Form.Check
              className={'mt-auto'}
              type="switch"
              label="Debug Mode ?"
              {...form.register('data.isDebugMode')}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              type={'select'}
              label={'Message type filter'}
              options={[
                { text: 'Message Data', value: 'data' },
                { text: 'Message Metadata', value: 'metadata' },
              ]}
              register={form.register('data.configuration.location', {
                required: 'Message type filter is mandatory',
              })}
            />
          </Form.Group>
        </Row>

        {fields.map((field, index) => {
          return (
            <>
              <Row className="mb-3 g-3" key={field.id}>
                <Form.Group as={Col}>
                  <Input
                    label={'Old key name'}
                    register={form.register(
                      `data.configuration.keys.${index}.keyName`,
                      { required: 'Old key name is mandatory' }
                    )}
                    error={
                      form.formState.errors?.data?.configuration?.keys?.[index]
                        ?.keyName
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Input
                    label={'New key name'}
                    register={form.register(
                      `data.configuration.keys.${index}.newKeyName`,
                      { required: 'New key name is mandatory' }
                    )}
                    error={
                      form.formState.errors?.data?.configuration?.keys?.[index]
                        ?.newKeyName
                    }
                  />
                </Form.Group>
                <Col className={'d-flex align-items-center'} md={1}>
                  <Button
                    className={'mt-auto mb-2'}
                    variant={'outline-danger'}
                    onClick={() => remove(index)}
                  >
                    <FontAwesomeIcon icon={'trash'} />
                  </Button>
                </Col>
              </Row>
            </>
          )
        })}
        <Button variant="falcon-primary" onClick={() => append({})}>
          Add
        </Button>
        <Row className={'mt-2'}>
          <Form.Group as={Col} md={12}>
            <Input
              register={form.register('data.description', {
                shouldUnregister: true,
              })}
              label={'Description'}
              type={'textarea'}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default RenameKeysNodeForm
