import React, { useEffect } from 'react'
import TenantProfile from '../../shared/models/tenant-profile'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import ContentBlockBody from '../../components/common/component-card/ContentBlockBody'
import ContentBlockHeader from '../../components/common/component-card/ContentBlockHeader'
import Input from '../../components/form/Input'

interface TenantProfileFormProps {
  onSubmit: SubmitHandler<TenantProfile>
  initialValues?: TenantProfile
  isLoading?: boolean
  form: UseFormReturn<TenantProfile>
}

const TenantProfileForm = ({
  onSubmit,
  initialValues,
  isLoading,
  form,
}: TenantProfileFormProps) => {
  // Init Form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = form
  // Init value if needed
  useEffect(() => {
    reset(initialValues)
  }, [initialValues])
  // Return
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ContentBlock>
        <ContentBlock.Header
          title={'Informations générales'}
          light={false}
          className="border-bottom border-200"
        />
        <ContentBlockBody>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group as={Col} md={12} className="mb-2">
              <Input
                register={{
                  ...register('name', {
                    required: 'Name field is required',
                  }),
                }}
                type={'text'}
                label={'name'}
                error={errors.name}
              />
            </Form.Group>
            <Row className={'gx-3 gy-2 align-items-center'}>
              <Form.Group as={Col} md={12} className="mb-2">
                <Input
                  isDisabled={isLoading}
                  register={{
                    ...register('description', {
                      required: 'Description field is required',
                    }),
                  }}
                  type={'textarea'}
                  label={'Description'}
                  error={errors.description}
                />
              </Form.Group>
            </Row>
          </Row>
        </ContentBlockBody>
      </ContentBlock>
      <ContentBlock>
        <ContentBlockHeader
          title={'Metadata'}
          light={false}
          className="border-bottom border-200"
        ></ContentBlockHeader>
        <ContentBlockBody>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxDevices',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max Device'}
              />
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxAssets',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max Assets'}
              />
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxCustomers',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max Customers'}
              />
            </Form.Group>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxUsers',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max Users'}
              />
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxDashboards',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max Dashboards'}
              />
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxRuleChains',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max Rule-Chains'}
              />
            </Form.Group>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxResourcesInBytes',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max Resources (Bytes)'}
              />
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-2">
              <Input
                type={'numeric'}
                isDisabled={isLoading}
                register={register(
                  'tenantProfileMetadata.configuration.maxOtaPackagesInBytes',
                  {
                    valueAsNumber: true,
                  }
                )}
                label={'Max OTA packages (Bytes)'}
              />
            </Form.Group>
          </Row>
        </ContentBlockBody>
      </ContentBlock>
    </Form>
  )
}

export default TenantProfileForm
