import React from 'react'
import { EntityType } from '../../../../../../shared/models/entity-type.models'
import { Node } from 'reactflow'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'
import Divider from '../../../../../../components/common/Divider'
import Select from 'react-select'
import _ from 'lodash'

interface EntityTypeConfiguration {
  entityTypes?: EntityType[]
}

interface EntityTypeFormProps {
  onSubmit: (
    data: Node<
      BaseNodeData<EntityTypeConfiguration>,
      NodeTypes.FILTER_ENTITY_TYPE
    >
  ) => void
  defaultValues: Node<
    BaseNodeData<EntityTypeConfiguration>,
    NodeTypes.FILTER_ENTITY_TYPE
  >
}

const EntityTypeForm = ({ onSubmit, defaultValues }: EntityTypeFormProps) => {
  const form = useForm({ defaultValues })
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = form
  const getOptions = () => {
    return Object.keys(EntityType).map(
      (key): { label: string; value: string } => {
        return {
          label: key,
          value: EntityType[key as keyof typeof EntityType],
        }
      }
    )
  }
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={'align-items-center'}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              //error={errors.name ? errors.name : undefined}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.isDebugMode')}
              label={'Debug Mode ?'}
              type={'switch'}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}></Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12} className="mb-3">
            <Form.Label htmlFor={'data.configuration.entityTypes'}>
              Originator types filter
            </Form.Label>
            <Controller
              name={'data.configuration.entityTypes'}
              render={({ field: { onChange } }) => {
                const { configuration } = defaultValues.data
                return (
                  <Select
                    classNamePrefix={'react-select'}
                    isMulti
                    options={getOptions()}
                    defaultValue={configuration?.entityTypes?.map((value) => ({
                      value: value.toString(),
                      label: value.toString(),
                    }))}
                    onChange={(values, actionMeta) => {
                      const strValues = _.chain(values)
                        .map('value')
                        .uniq()
                        .value()
                      onChange(strValues)
                    }}
                    {..._.omit(register('data.configuration.entityTypes'), [
                      'onChange',
                    ])}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Divider />
        <Row>
          <Form.Group as={Col} lg={12}>
            <Input
              register={form.register('data.description', {
                shouldUnregister: true,
              })}
              label={'Description'}
              type={'textarea'}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default EntityTypeForm
