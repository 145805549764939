import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
  useDeleteCustomerMutation,
  useLazyGetCustomerQuery,
} from '../../../store/kopiliot-api/customer'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  customerID?: string
}

const DeleteCustomerModal = ({ isOpen, setIsOpen, customerID }: Props) => {
  const [triggerGetCustomer, resultGetCustomer] = useLazyGetCustomerQuery()
  useEffect(() => {
    if (customerID) {
      triggerGetCustomer(customerID, true)
    }
  }, [customerID])
  const [deleteCustomer, { isLoading: isDeleting }] =
    useDeleteCustomerMutation()
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Modal
        show={isOpen && customerID !== undefined}
        onHide={handleClose}
        size={'lg'}
        className={'border'}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title as="h5">
            Supprimer le client {`${resultGetCustomer?.data?.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'p-2'}>
          <p>
            {`Êtes - vous sûr de vouloir supprimer le client ${resultGetCustomer?.data?.name} ?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={'button'}
            variant={'secondary'}
            onClick={handleClose}
            disabled={isDeleting}
          >
            Annuler
          </Button>
          <Button
            children={'Supprimer'}
            variant={'danger'}
            disabled={isDeleting}
            onClick={() => {
              if (customerID) {
                deleteCustomer(customerID)
              }
              handleClose()
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteCustomerModal
