import React from 'react'
import AddAssetModal from '../../features/asset/components/list/AddAssetModal'
import {
  useDeleteAssetMutation,
  useLazyGetAssetQuery,
  useLazyGetAssetsQuery,
} from '../../store/kopiliot-api/asset'
import useTenantCustomerContext from '../context/tenant-customer.context'
import { useNavigate } from 'react-router-dom'
import { AssetQuery, DeviceQuery } from '../../store/kopiliot-api/query-type'
import { useForm, useWatch } from 'react-hook-form'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  changeTextSearch,
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import { Pagination } from '../../shared/models/others/pagination'
import Asset from '../../shared/models/asset'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import {
  defaultAssetActionsRow,
  getColumnsForTableAssets,
} from '../../features/asset/components/list/utils'
import { EntityType } from '../../shared/models/entity-type.models'
import { useLazyGetTenantAssetsQuery } from '../../store/kopiliot-api/tenant'
import DeleteAssetModal from '../../features/asset/components/list/DeleteAssetModal'
import { useLazyGetCustomerAssetsQuery } from '../../store/kopiliot-api/customer'
import Device from '../../shared/models/device.model'
import { Authority } from '../../shared/models/user'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { createEntityQueryReducer } from '../../hooks/create-entity-pagination-reducer'
import {
  ActionUpdateAssetProfileID,
  ActionUpdateCustomerID,
  ActionUpdateDeviceProfileID,
  ActionUpdateTenantID,
  CommonActionType,
} from '../../hooks/common-actions'

const AssetList = () => {
  const user = useSelector((state: RootState) => state.currentUser)
  // Get the context of the current tenant and customer
  const { tenantIDParam, customerIDParam } = useTenantCustomerContext()

  const navigate = useNavigate()
  // State
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  const [selectedAsset, setSelectedAsset] = React.useState<string | undefined>()
  const searchForm = useForm<AssetQuery>()
  const {
    control,
    formState: { errors },
  } = searchForm
  const textSearch = useWatch({
    control,
    name: 'textSearch',
    defaultValue: '',
  })
  const tenantID = useWatch({
    control,
    name: 'tenantID',
    defaultValue: undefined,
  })
  const customerID = useWatch({
    control,
    name: 'customerID',
    defaultValue: undefined,
  })
  const assetProfileID = useWatch({
    control,
    name: 'assetProfileID',
    defaultValue: undefined,
  })
  const reducer = React.useMemo(() => {
    return createEntityQueryReducer<
      AssetQuery,
      | PaginationActions
      | ActionUpdateTenantID
      | ActionUpdateCustomerID
      | ActionUpdateAssetProfileID
    >({
      handleSpecificActions: {
        [CommonActionType.UPDATE_TENANT_ID]: (state, action) => {
          return { ...state, tenantID: action.payload.tenantID }
        },
        [CommonActionType.UPDATE_CUSTOMER_ID]: (state, action) => {
          return { ...state, customerID: action.payload.customerID }
        },
        [CommonActionType.UPDATE_ASSET_PROFILE_ID]: (state, action) => {
          return { ...state, assetProfileID: action.payload.assetProfileID }
        },
      },
    })
  }, [])
  // Query
  const [getAssets, resultGetAssets] = useLazyGetAssetsQuery()
  const [getAssetsByTenant, resultGetAssetsByTenant] =
    useLazyGetTenantAssetsQuery()
  const [getAssetsByCustomer, resultGetAssetsByCustomer] =
    useLazyGetCustomerAssetsQuery()

  const [fetchAsset, resultFetchAsset] = useLazyGetAssetQuery()

  // Pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducer,
    AssetQuery,
    | PaginationActions
    | ActionUpdateTenantID
    | ActionUpdateCustomerID
    | ActionUpdateAssetProfileID
  >(reducer, {
    textSearch: '',
    assetProfileID: undefined,
    tenantID: undefined,
    customerID: undefined,
    limit: 10,
    offset: 0,
  })
  React.useEffect(() => {
    fetchAssets()
  }, [
    paginationState.offset,
    paginationState.limit,
    textSearch,
    assetProfileID,
    tenantID,
    customerID,
    tenantIDParam,
    customerIDParam,
  ])
  React.useEffect(() => {
    const search = setTimeout(() => {
      changeTextSearch(dispatchPaginationAction, textSearch)
    }, 1000)
    return () => clearTimeout(search)
  }, [textSearch])
  React.useEffect(() => {
    const currentTenantID = tenantIDParam
      ? { id: tenantIDParam, entityType: EntityType.TENANT }
      : tenantID
    dispatchPaginationAction({
      type: CommonActionType.UPDATE_TENANT_ID,
      payload: { tenantID: currentTenantID },
    })
  }, [tenantIDParam, tenantID])
  React.useEffect(() => {
    const currentCustomerID = customerIDParam
      ? { id: customerIDParam, entityType: EntityType.CUSTOMER }
      : customerID
    dispatchPaginationAction({
      type: CommonActionType.UPDATE_CUSTOMER_ID,
      payload: { customerID: currentCustomerID },
    })
  }, [customerIDParam, customerID])
  React.useEffect(() => {
    dispatchPaginationAction({
      type: CommonActionType.UPDATE_ASSET_PROFILE_ID,
      payload: { assetProfileID: assetProfileID },
    })
  }, [assetProfileID])

  // Utility functions
  const onDeleteAsset = (assetId: string) => {
    fetchAsset(assetId, false)
    setSelectedAsset(assetId)
    setShowModalDelete(true)
  }
  const onEditAsset = (assetId: string) => {
    navigate(`${assetId}`)
  }
  const getData = (): Pagination<Asset> | undefined => {
    if (tenantIDParam) {
      if (customerIDParam) {
        return resultGetAssetsByCustomer.data
      }
      return resultGetAssetsByTenant.data
    } else {
      return resultGetAssets.data
    }
  }
  const isLoadingData = resultGetAssets.isLoading || resultFetchAsset.isLoading

  function fetchAssets() {
    if (customerIDParam) {
      getAssetsByCustomer({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch,
        assetProfileID,
        customerID: { id: customerIDParam, entityType: EntityType.CUSTOMER },
      }) // Fetch assets for a specific customer
    } else if (tenantIDParam) {
      getAssetsByTenant({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch,
        assetProfileID,
        tenantID: { id: tenantIDParam, entityType: EntityType.TENANT },
      }) // Fetch assets for a specific tenant
    } else {
      // Fetch assets for all tenants
      getAssets({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch,
        assetProfileID,
        customerID,
        tenantID,
      })
    }
  }

  const getHiddenFields = () => {
    const hiddenFields: Array<keyof Asset> = []
    if (tenantIDParam) {
      hiddenFields.push('tenantID')
    }

    if (customerIDParam) {
      hiddenFields.push('customerID')
    }
    return hiddenFields
  }

  const getHiddenColumns = (): Array<keyof Asset> => {
    if (user.authority === Authority.TENANT_ADMIN || tenantIDParam) {
      return ['tenantID']
    } else if (user.authority === Authority.CUSTOMER_USER || customerIDParam) {
      return ['tenantID', 'customerID']
    } else {
      return []
    }
  }

  return (
    <>
      <KopiliotList
        title={'Assets'}
        icon={'box-open'}
        isLoading={isLoadingData}
        actionsProps={{
          onRefresh: () => {
            fetchAssets()
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id', ...getHiddenColumns()],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableAssets(
            defaultAssetActionsRow(onDeleteAsset, onEditAsset)
          ),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: Asset) => item.id!.id,
          titleAccessor: (item: Asset) => item.name,
          descriptionAccessor: (item: Asset) => item.description,
          linkAccessor: (item: Asset) => `${item.id!.id}`,
          actionsRow: defaultAssetActionsRow(onDeleteAsset, onEditAsset),
        }}
      />
      <DeleteAssetModal
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        assetID={selectedAsset}
      />
      <AddAssetModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        //defaultValues={defaultValues}
        hideFields={getHiddenFields()}
      />
    </>
  )
}

export default AssetList
