import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import { Dashboard } from '../../shared/models/dashboard.model'
import { Alias } from '../../shared/models/alias.model'
import {
  DashboardQuery,
  formatSortOrder,
  PaginationWithSearchTerm,
} from './query-type'
import { DashboardId } from '../../shared/models/id/dashboard-id'

export interface AliasListQuery extends PaginationWithSearchTerm {
  dashboardID: string
}

const dashboardApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getDashboards: build.query<Pagination<Dashboard>, DashboardQuery>({
      query: (args) => {
        return {
          url: 'dashboards',
          method: 'GET',
          params: {
            offset: args.offset,
            limit: args.limit,
            textSearch: args.textSearch,
            ...formatSortOrder(args.sortOrder),
          },
        }
      },
      providesTags: (result = new Pagination<Dashboard>(), error, arg) => [
        ...result.rows.map((item: Dashboard) => {
          return {
            type: 'Dashboard' as const,
            id: item.id?.id,
          }
        }),
        { type: 'Dashboard', id: 'LIST' },
      ],
    }),
    getDashboard: build.query<Dashboard, string>({
      query: (id) => {
        return {
          url: `dashboards/${id}`,
          method: 'GET',
        }
      },
      providesTags: (result, error, arg) => [{ type: 'Dashboard', id: arg }],
    }),
    addDashboard: build.mutation({
      query: (dashboard) => ({
        url: 'dashboards',
        method: 'POST',
        body: dashboard,
      }),
      invalidatesTags: [
        { type: 'Dashboard', id: 'LIST' },
        { type: 'DashboardByTenant', id: 'LIST' },
      ],
    }),
    updateDashboard: build.mutation<Dashboard, Dashboard>({
      query(arg) {
        return {
          url: `dashboards/${arg.id?.id}`,
          method: 'PUT',
          body: arg,
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Dashboard', id: arg.id?.id },
      ],
    }),
    deleteDashboard: build.mutation<Dashboard, DashboardId>({
      query: (id) => ({
        url: `dashboards/${id.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Dashboard', id: arg.id },
      ],
    }),
    getAliases: build.query<Pagination<Alias>, AliasListQuery>({
      query: (args) => {
        return {
          url: `dashboards/${args.dashboardID}/aliases`,
          method: 'GET',
          params: {
            offset: args.offset,
            limit: args.limit,
            textSearch: args.textSearch,
          },
        }
      },
      providesTags: (result = new Pagination<Alias>(), error, arg) => [
        ...result.rows.map((item: Alias) => {
          return {
            type: 'Alias' as const,
            id: item.dashboardID.id,
          }
        }),
        { type: 'Alias', id: 'LIST' },
      ],
    }),
    getAlias: build.query<Alias, { dashboardID: string; id: string }>({
      query: (args) => {
        return {
          url: `dashboards/${args.dashboardID}/aliases/${args.id}`,
          method: 'GET',
        }
      },
    }),
    addAlias: build.mutation<Alias, Alias>({
      query(arg: Alias) {
        return {
          url: `dashboards/${arg.dashboardID.id}/aliases`,
          method: 'POST',
          body: arg,
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Alias', id: 'LIST' },
        { type: 'Alias', id: arg.dashboardID.id },
        { type: 'Dashboard', id: arg.dashboardID.id },
      ],
    }),
    updateAlias: build.mutation<Alias, Alias>({
      query(arg: Alias) {
        return {
          url: `dashboards/${arg.dashboardID.id}/aliases/${arg.id?.id}`,
          method: 'PUT',
          body: arg,
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Alias', id: 'LIST' },
        { type: 'Alias', id: arg.dashboardID.id },
        { type: 'Dashboard', id: arg.dashboardID.id },
      ],
    }),
    deleteAlias: build.mutation<Alias, { dashboardID: string; id: string }>({
      query: (args) => {
        return {
          url: `dashboards/${args.dashboardID}/aliases/${args.id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Alias', id: 'LIST' },
        { type: 'Alias', id: arg.dashboardID },
        { type: 'Dashboard', id: arg.dashboardID },
      ],
    }),
  }),
})

export const {
  useGetDashboardsQuery,
  useGetDashboardQuery,
  useLazyGetDashboardQuery,
  useLazyGetDashboardsQuery,
  useAddDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
  useGetAliasesQuery,
  useLazyGetAliasesQuery,
  useGetAliasQuery,
  useLazyGetAliasQuery,
  useAddAliasMutation,
  useUpdateAliasMutation,
  useDeleteAliasMutation,
} = dashboardApi

export default dashboardApi
