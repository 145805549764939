import { useOutletContext } from 'react-router-dom'

export type TenantCustomerRouteParam = {
  tenantID?: string
  customerID?: string
}

export type TenantCustomerContext = {
  tenantIDParam?: string
  customerIDParam?: string
}

const useTenantCustomerContext = () => {
  return useOutletContext<TenantCustomerContext>()
}

export default useTenantCustomerContext
