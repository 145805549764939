import React, { useEffect } from 'react'
import { BaseFormProps } from '../utils/common'
import { EntityGroup } from '../../shared/models/entity-group.model'
import { FormProvider } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../components/form/Input'

const EntityGroupForm = ({
  form,
  isLoading,
  isEditing,
  initialValues,
  onSubmit,
  hideFields = [],
}: BaseFormProps<EntityGroup>) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form
  useEffect(() => {
    reset()
    reset(initialValues)
  }, [initialValues?.entityType])
  return (
    <>
      <FormProvider {...form}>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Col md={12}>
              <Input
                register={register('name', {
                  required: 'Name field is required',
                })}
                label={'Nom'}
                error={errors.name}
              />
            </Col>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group as={Col} md={12}>
              <Input
                register={register('description')}
                label={'Description'}
                type={'textarea'}
                textAreaRows={4}
                error={errors.description}
              />
            </Form.Group>
          </Row>
        </Form>
      </FormProvider>
    </>
  )
}

export default EntityGroupForm
