import React, { ForwardedRef, PropsWithChildren, useEffect } from 'react'
import './panelContainer.css'
import { EntityId } from '../../../shared/models/id/entity-id'

interface Props extends PropsWithChildren {
  width?: number
  style?: React.CSSProperties
  entityID: EntityId
  title?: string
  className?: string
  onMouseDown?: () => void
  onMouseUp?: () => void
  onTouchEnd?: () => void
}

interface ChildProps {
  isMouseIn?: boolean
}

const PanelContainer = React.forwardRef(
  (
    {
      entityID,
      title,
      children,
      width,
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [isMouseIn, setIsMouseIn] = React.useState(false)

    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement<ChildProps>(child)) {
        return React.cloneElement(child, { isMouseIn })
      }
      return child
    })
    return (
      <div
        className={'react-grid-item' + className}
        ref={ref}
        style={{
          ...style,
        }}
        onMouseEnter={() => setIsMouseIn(true)}
        onMouseLeave={() => setIsMouseIn(false)}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      >
        {childrenWithProps}
      </div>
    )
  }
)
export default PanelContainer
