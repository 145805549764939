import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { EntityGroup } from '../../shared/models/entity-group.model'
import Tenant from '../../shared/models/tenant'
import { Link } from 'react-router-dom'
import Flex from '../../components/common/Flex'
import TenantProfileName from '../helper/TenantProfileName'
import HubStatus from '../tenants/list/HubStatus'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import TenantName from '../helper/TenantName'
import { EntityType } from '../../shared/models/entity-type.models'
import CustomerName from '../helper/CustomerName'
import { ActionRowProps } from '../../components/kopiliot-list/type'

const getColumnsForTableEntityGroup = (actionRows: ActionRowProps[]) => {
  const columnHelper = createColumnHelper<EntityGroup>()
  const columns: ColumnDef<EntityGroup, string>[] = [
    columnHelper.accessor((row) => row.id?.id, {
      id: 'id',
      cell: (info) => info.getValue(),
      header: () => 'Id',
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'name',
      header: () => 'Nom',
      enableSorting: true,
      meta: {
        className: 'align-middle',
      },
      cell: (props) => {
        const { id, name } = props.row.original

        return (
          <h5 className={'mb-0 fs-10'}>
            <Link to={id ? `${id.id}` : '#'} className={'fw-semibold'}>
              {name}
            </Link>
          </h5>
        )
      },
    }),
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: () => 'Description',
      enableSorting: true,
    }),
    columnHelper.accessor((row) => row.ownerID.entityType.toString(), {
      id: 'ownerType',
      cell: (info) => info.getValue(),
      header: () => 'Owner Type',
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'owner',
      cell: (props) => {
        if (props.row.original.ownerID.entityType === EntityType.TENANT) {
          return (
            <>
              <TenantName tenantId={props.row.original.ownerID.id} />
            </>
          )
        }
        return (
          <>
            <CustomerName customerId={props.row.original.ownerID.id} />
          </>
        )
      },
      header: () => 'Onwer',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        const entityGroupID = props.row.original.id?.id
        return (
          <>
            {actionRows && entityGroupID && (
              <Dropdown
                className={'text-center btn-reveal-trigger'}
                onClick={(event) => event.stopPropagation()}
              >
                <Dropdown.Toggle
                  size={'sm'}
                  className={'btn-reveal'}
                  variant="falcon-default"
                  id="dropdown-assets-actions"
                >
                  <FontAwesomeIcon icon={'ellipsis-h'} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  popperConfig={{ strategy: 'fixed' }}
                  renderOnMount={true}
                >
                  {actionRows.map((item) => {
                    return (
                      <Dropdown.Item
                        className={`text-${item.color || 'primary'}`}
                        key={item.title}
                        onClick={() => {
                          if (item.onClick) {
                            item.onClick(entityGroupID)
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fs-0 me-2"
                        />
                        {item.title}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )
      },
      header: () => 'Actions',
    }),
  ]
  return columns
}

const defaultEntityGroupActionsRow = (
  onDeleteItem: (id: string) => void,
  onEditItem: (id: string) => void
) => {
  const actionsRow: ActionRowProps[] = [
    {
      title: 'Edit',
      color: 'primary',
      icon: 'edit',
      onClick: (id) => {
        onEditItem(id)
      },
    },
    {
      title: 'Delete',
      color: 'danger',
      icon: 'trash-alt',
      onClick: (id) => {
        onDeleteItem(id)
      },
    },
  ]
  return actionsRow
}

export { getColumnsForTableEntityGroup, defaultEntityGroupActionsRow }
