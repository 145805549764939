import React, { useMemo, useRef } from 'react'
import DashboardHeader from './header/DashboardHeader'
import { Container } from 'react-bootstrap'
import ReactGridLayout, { Layout } from 'react-grid-layout'
import {
  updateGridPosByID,
  updateLayout,
} from '../../store/slices/dashboardSlice'
import CanvasEditOrCreate from './header/CanvasEditOrCreate'
import CanvasAliasList from './header/CanvasAliasList'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useResizeObserver } from 'usehooks-ts'
import PanelContainer from './panel/PanelContainer'
import PanelBody from './panel/panel-body/PanelBody'
import _ from 'lodash'
import { useLazyGetDashboardQuery } from '../../store/kopiliot-api/dashboard'
import HandleError from '../../utils/HandleError'

interface DashboardViewProps {
  dashboardID: string
}

const DashboardView = ({ dashboardID }: DashboardViewProps) => {
  // Get width remaining
  const ref = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({
    ref,
    box: 'border-box',
  })
  const [getDashboard, { isLoading, isFetching, error }] =
    useLazyGetDashboardQuery()
  React.useEffect(() => {
    getDashboard(dashboardID)
  }, [dashboardID])
  // Get the dashboard data from the store (slice)
  const currentDashboard = useAppSelector((state) => state.dashboard)

  const currentLayout = useMemo(() => {
    if (!currentDashboard?.panels) return null
    return _.chain(currentDashboard.panels)
      .toArray()
      .filter((item) => !!item.id)
      .map((item) => {
        return {
          i: item.id!.id,
          x: item.position.positionX,
          y: item.position.positionY,
          w: width < 768 ? 24 : item.position.positionW,
          h: item.position.positionH,
        }
      })
      .value()
  }, [currentDashboard.panels, width])

  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (!currentDashboard.id?.id) return
    return () => {
      //console.log('cleanup')
    }
  }, [currentDashboard.id?.id])
  const memoizedRenderPanel = useMemo(() => {
    if (!currentDashboard?.panels) {
      return null
    }
    return Object.entries(currentDashboard?.panels).map(([key, value]) => {
      if (value.id === undefined || !width) return null
      return (
        <PanelContainer
          key={key}
          width={width}
          entityID={value.id}
          title={value.title}
        >
          <PanelBody panel={value} />
        </PanelContainer>
      )
    })
  }, [currentDashboard, width, currentLayout]) // Ajoutez currentLayout comme dépendance

  if (error) {
    return <HandleError error={error} />
  }
  return (
    <>
      <DashboardHeader title={currentDashboard?.name} />
      <Container
        fluid
        ref={ref}
        style={{ minHeight: 'calc(100vh - 8rem)', width: '100%' }}
        className={'p-0'}
      >
        {isLoading && <div>Loading...</div>}
        {currentDashboard.id?.id && (
          <ReactGridLayout
            className={'layout border border-3'}
            isBounded={false}
            isDraggable={currentDashboard.isEditing}
            isResizable={currentDashboard.isEditing}
            preventCollision={false}
            draggableHandle={'.react-grid-dragHandler'}
            compactType={'vertical'}
            width={width}
            layout={currentLayout ? currentLayout : []}
            cols={24}
            autoSize={true}
            containerPadding={[0, 0]}
            margin={[3, 3]}
            useCSSTransforms={true}
            rowHeight={30}
            onLayoutChange={(allLayouts: Layout[]) => {
              //dispatch(setLayouts(allLayouts))
            }}
            onDragStop={(layout, oldItem, newItem, placeholder, e, element) => {
              dispatch(updateLayout({ layout: layout }))
            }}
            onResizeStop={(
              layout,
              oldItem,
              newItem,
              placeholder,
              e,
              element
            ) => {
              dispatch(
                updateGridPosByID({
                  id: newItem.i,
                  gridPos: {
                    positionX: newItem.x,
                    positionY: newItem.y,
                    positionW: newItem.w,
                    positionH: newItem.h,
                  },
                })
              )
            }}
          >
            {memoizedRenderPanel}
          </ReactGridLayout>
        )}
      </Container>
      <CanvasEditOrCreate />
      {currentDashboard.id?.id && (
        <CanvasAliasList
          open={currentDashboard.showAliasList}
          dashboardID={currentDashboard.id.id}
        />
      )}
    </>
  )
}

export default DashboardView
