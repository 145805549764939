import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../components/button/ButtonLoading'
import Customer from '../../../shared/models/customer'
import { useAddCustomerMutation } from '../../../store/kopiliot-api/customer'
import CustomerForm from '../form/CustomerForm'

interface AddCustomerModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  hiddenFields?: Array<keyof Customer>
  initialValues?: Partial<Customer>
}

const AddCustomerModal = ({
  isOpen,
  setIsOpen,
  hiddenFields,
  initialValues,
}: AddCustomerModalProps) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const form = useForm<Customer>()
  const [addCustomer, { isLoading, error }] = useAddCustomerMutation()
  const onSubmit = async (data: Customer) => {
    try {
      await addCustomer(data).unwrap()
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'xl'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Créer un Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <CustomerForm
          form={form}
          onSubmit={onSubmit}
          hideFields={hiddenFields}
          initialValues={initialValues}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} variant={'secondary'} onClick={handleClose}>
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddCustomerModal
