import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Nav, Row } from 'react-bootstrap'
import classNames from 'classnames'
import NavWizardItem from '../../../components/wizard/NavItem'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import IconButton from '../../../components/common/IconButton'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import Device from '../../../shared/models/device.model'
import DeviceFormDetails from './DeviceFormDetails'
import DeviceFormMessageFilter from './DeviceFormMessageFilter'
import {
  CreateDeviceCommand,
  useAddDeviceMutation,
} from '../../../store/kopiliot-api/device'
import DeviceFormSuccess from './DeviceFormSuccess'
import DeviceFormCustomer from './DeviceFormCustomer'

interface DeviceFormProps {
  variant?: 'pills' | 'regular'
  form: UseFormReturn<CreateDeviceCommand>
  closeModal?: () => void
  disabledFields?: Array<keyof CreateDeviceCommand>
}

export interface DeviceFormStepProps {
  disabledField: Array<keyof CreateDeviceCommand>
}

const DeviceForm = ({
  variant = 'regular',
  form,
  closeModal,
  disabledFields = [],
}: DeviceFormProps) => {
  const [step, setStep] = useState(1)
  const [device, setDevice] = useState<Partial<CreateDeviceCommand>>({})
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
  } = form
  const onSubmitData = (data: CreateDeviceCommand) => {
    setDevice({ ...device, ...data })
    setStep(step + 1)
  }
  const [addDevice, { data, isLoading: isLoadingMutation, error }] =
    useAddDeviceMutation()
  useEffect(() => {
    // If the step is equal to 4, we start submit
    try {
      if (step === 3) {
        addDevice(device as CreateDeviceCommand)
          .unwrap()
          .then()
          .catch((error) => {
            console.log(error)
            handleNavs(1)
          })
      }
    } catch (error) {
      console.log(error)
    }
  }, [step])

  const handleNavs = (targetStep: number) => {
    // If the step is final step, we disable the navigation
    if (step !== 3 || error != undefined) {
      if (targetStep < step) {
        setStep(targetStep)
      } else {
        handleSubmit(onSubmitData)()
      }
    } else {
      console.log('No access to previous step because it is the end')
    }
  }
  const navItems: { label: string; icon: IconProp }[] = [
    {
      icon: 'microchip',
      label: 'Device détails',
    },

    {
      icon: 'building-user',
      label: 'Client',
    },
    {
      icon: 'thumbs-up',
      label: 'Done',
    },
  ]
  return (
    <FormProvider {...form}>
      <Card
        as={Form}
        noValidate
        className={'theme-wizard'}
        onSubmit={handleSubmit(onSubmitData)}
      >
        <Card.Header
          className={classNames({
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant,
          })}
        >
          <Nav className={'justify-content-center'} variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavWizardItem.Pills
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavWizardItem.Regular
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {step === 1 && <DeviceFormDetails disabledField={disabledFields} />}
          {step === 2 && <DeviceFormCustomer />}
          {step === 3 && (
            <DeviceFormSuccess
              isLoading={isLoadingMutation}
              closeModal={closeModal}
              result={data}
            />
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6', {
            'd-none': step === 3,
            ' d-flex': step < 3,
          })}
        >
          <IconButton
            variant="link"
            iconClassName={'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1,
            })}
            onClick={() => {
              setStep(step - 1)
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="outline-primary"
            className="ms-auto px-5"
            type={'submit'}
            iconClassName={'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </FormProvider>
  )
}

export default DeviceForm
