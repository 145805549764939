import React from 'react'
import Profile from './profile/Profile'
import { Col, Row } from 'react-bootstrap'
import ProfileSettings from './profile/ProfileSettings'
import AccountSettings from './settings/AccountSettings'
import { useMeQuery } from '../../store/kopiliot-api/base-api'
import ChangePassword from './settings/ChangePassword'

const MeSettings = () => {
  const { data, error, isLoading } = useMeQuery(null)
  return (
    <>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        // TODO check better
        <>
          // TODO check better
          <Profile user={data!} />
          <Row className="g-3">
            <Col lg={8}>
              <ProfileSettings user={data} />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <AccountSettings />
                <ChangePassword />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default MeSettings
