import React from 'react'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import AddAssetProfileModal from '../../features/asset-profile/list/AddAssetProfileModal'
import { useLazyGetAssetProfilesQuery } from '../../store/kopiliot-api/asset-profile'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import {
  defaultAssetProfileActionsRow,
  getColumnsForTableAssetProfiles,
} from '../../features/asset-profile/list/util'
import { AssetProfile } from '../../shared/models/asset-profile'
import { useNavigate } from 'react-router-dom'
import { PaginationWithSearchTerm } from '../../store/kopiliot-api/query-type'

const AssetProfileList = () => {
  const navigate = useNavigate()
  // Modal State
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [selectedAssetProfile, setSelectedAssetProfile] = React.useState<
    string | undefined
  >(undefined)
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  // Query
  const [fetchAssetProfiles, { data, isLoading, error }] =
    useLazyGetAssetProfilesQuery()

  //Pagination state
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    PaginationWithSearchTerm,
    PaginationActions
  >(reducerPage, {
    limit: 20,
    offset: 0,
    textSearch: '',
  })
  const filterAssetProfile = (filter?: string) => {
    dispatchPaginationAction({
      type: PaginationActionType.PAGE_FILTER,
      payload: { filter: filter },
    })
  }
  const onPageSizeChange = (pageSize: number) => {
    dispatchPaginationAction({
      type: PaginationActionType.PAGE_SIZE,
      payload: { pageSize: pageSize },
    })
  }
  const onPageChange = (page: {
    currentPage: number
    totalPages: number
    pageLimit: number
  }) => {
    dispatchPaginationAction({
      type: PaginationActionType.PAGE_CHANGE,
      payload: { ...page },
    })
  }
  React.useEffect(() => {
    fetchAssetProfiles(paginationState, false)
  }, [paginationState])
  // Actions
  const onDelete = (id: string) => {
    setSelectedAssetProfile(id)
    setShowModalDelete(true)
  }
  const onEdit = (id: string) => {
    navigate(`${id}`)
  }
  return (
    <>
      <KopiliotList
        title={"Profils d'assets"}
        icon={'address-card'}
        isLoading={isLoading}
        actionsProps={{
          onRefresh: () => {
            fetchAssetProfiles(paginationState, false)
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={data}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: data?.totalRows || 0,
          onPageChanged: onPageChange,
          onChangePageSize: onPageSizeChange,
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableAssetProfiles(
            defaultAssetProfileActionsRow(onDelete, onEdit)
          ),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: AssetProfile) => item.id!.id,
          titleAccessor: (item: AssetProfile) => item.name,
          descriptionAccessor: (item: AssetProfile) => item.description,
          linkAccessor: (item: AssetProfile) => `${item.id!.id}`,
          actionsRow: defaultAssetProfileActionsRow(onDelete, onEdit),
        }}
      />
      <AddAssetProfileModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
      />
    </>
  )
}

export default AssetProfileList
