import React from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'
import {
  Attribute,
  AttributeScope,
  DataType,
} from '../../shared/models/attribute'
import { Col, Form, Row } from 'react-bootstrap'
import { Select } from '../../components/page-components/selects-entities/generic-select/Select'
import { SelectableValue } from '../../components/page-components/selects-entities/generic-select/selectable-value'
import AttributeValue from './AttributeValue'

interface AttributeFormProps {
  onSubmit: SubmitHandler<Attribute>
  form: UseFormReturn<Attribute>
  isLoading?: boolean
  id?: string
}

const AttributeForm = ({
  onSubmit,
  form,
  isLoading,
  id,
}: AttributeFormProps) => {
  const { register, handleSubmit, control, formState, reset } = form
  const [dataType, setDataType] = React.useState<DataType>(DataType.STRING)
  React.useEffect(() => {
    const defaultValues = formState.defaultValues
    if (dataType) {
      let value: string | number | boolean | object = ''
      switch (dataType) {
        case DataType.LONG:
          value = defaultValues?.value || 0
          break
        case DataType.DOUBLE:
          value = defaultValues?.value || 0.0
          break
        case DataType.BOOLEAN:
          value = defaultValues?.value || false
          break
        case DataType.STRING:
          value = defaultValues?.value || ''
          break
        case DataType.JSON:
          value = defaultValues?.value || {}
          break
      }
      form.setValue('value', value, { shouldValidate: true })
    }
  }, [dataType, formState.defaultValues?.value])
  React.useEffect(() => {
    const defaultValues = formState.defaultValues
    if (defaultValues) {
      const type = typeof defaultValues.value
      switch (type) {
        case 'number':
          if (Number.isInteger(defaultValues.value)) {
            setDataType(DataType.LONG)
          } else {
            setDataType(DataType.DOUBLE)
          }
          break
        case 'boolean':
          setDataType(DataType.BOOLEAN)
          break
        case 'string':
          setDataType(DataType.STRING)
          break
        case 'object':
          setDataType(DataType.JSON)
          break
      }
    }
  }, [formState.defaultValues])
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={id}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Form.Label htmlFor="key">Key</Form.Label>
            <Form.Control
              className="mb-2"
              disabled={isLoading}
              type="text"
              placeholder="Enter the key"
              isInvalid={!!formState.errors.key}
              {...register('key', { required: 'Key field is required' })}
            />
            <Form.Control.Feedback type="invalid">
              {formState.errors.key && formState.errors.key.message}
            </Form.Control.Feedback>
          </Col>
          <Col md={'6'} className="mb-2">
            <Form.Label htmlFor="scope">Scope</Form.Label>
            <Controller
              control={control}
              name={'scope'}
              rules={{ required: 'Scope field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    onChange={(e: SelectableValue<AttributeScope>) => {
                      e.value && field.onChange(e.value)
                    }}
                    value={field.value}
                    options={[
                      { value: AttributeScope.SERVER_SCOPE, label: 'Serveur' },
                      { value: AttributeScope.SHARED_SCOPE, label: 'Partagé' },
                      { value: AttributeScope.CLIENT_SCOPE, label: 'Client' },
                    ]}
                  />
                )
              }}
            />
            <Form.Control.Feedback type="invalid">
              {formState.errors.scope && formState.errors.scope.message}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'12'}>
            <Form.Label htmlFor="dataType">Type de données</Form.Label>
            <Select
              onChange={(e: SelectableValue<DataType>) => {
                e.value && setDataType(e.value)
              }}
              value={dataType}
              options={[
                { value: DataType.BOOLEAN, label: 'Booléen' },
                { value: DataType.LONG, label: 'Entier' },
                { value: DataType.DOUBLE, label: 'Virgule flotante' },
                { value: DataType.STRING, label: 'Chaine de caractère' },
                { value: DataType.JSON, label: 'Valeur JSON' },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={'12'}>
            <Form.Label htmlFor="value">Valeur</Form.Label>
            <AttributeValue dataType={dataType} />
          </Col>
        </Row>
      </Form>
      {JSON.stringify(form.getValues())}
    </FormProvider>
  )
}

export default AttributeForm
