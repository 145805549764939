import { Mutex } from 'async-mutex'
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { tokenService } from '../../services/auth/token-service'

// Constants
const TOKEN_EXPIRED_MESSAGE = 'The token has expired'
const TOKEN_CANNOT_BE_REFRESHED = 'Cannot refresh token'

// Create a new mutex
export const tokenMutex = new Mutex()

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}/v1/`,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem('access_token')
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api: BaseQueryApi, extraOptions) => {
  // wait until the mutex is available without locking it
  await tokenMutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  // IN case the token expired
  if ((result.error?.data as any)?.message === TOKEN_EXPIRED_MESSAGE) {
    if (!tokenMutex.isLocked()) {
      const release = await tokenMutex.acquire()
      try {
        const refreshResult = await baseQuery(
          {
            credentials: 'include',
            url: 'auth/refresh-token',
            method: 'POST',
          },
          api,
          extraOptions
        )
        if (
          refreshResult.data &&
          (result.error?.data as any)?.message !== TOKEN_CANNOT_BE_REFRESHED
        ) {
          const { accessToken }: any = refreshResult.data
          localStorage.setItem('access_token', accessToken)
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          //api.dispatch(useLogoutMutation)
          //window.location.href = '/auth/login'
        }
      } catch (e) {
        console.log(e)
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await tokenMutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export default customFetchBase
