import { baseArkhaleAPI } from './base-api'
import {
  Notification,
  NotificationTarget,
} from '../../shared/models/notification.model'
import { Pagination } from '../../shared/models/others/pagination'
import { NotificationTargetQuery } from './query-type'
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

const notificationApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => {
    return {
      subscribeNotifications: build.query<Notification[], void>({
        query: () => ({
          url: '/notifications',
          method: 'GET',
        }),
      }),
      getNotificationTargets: build.query<
        Pagination<NotificationTarget>,
        NotificationTargetQuery
      >({
        query: (args) => ({
          url: '/notifications/targets',
          method: 'GET',
          params: {
            offset: args.offset,
            limit: args.limit,
            textSearch: args.textSearch,
            tenantID: args.tenantID,
          },
        }),
      }),
      addNotificationTarget: build.mutation<
        NotificationTarget,
        NotificationTarget
      >({
        query: (arg) => ({
          url: '/notifications/targets',
          method: 'POST',
          body: arg,
        }),
      }),
    }
  },
})

export const {
  useSubscribeNotificationsQuery,
  useLazySubscribeNotificationsQuery,
  useGetNotificationTargetsQuery,
  useLazyGetNotificationTargetsQuery,
  useAddNotificationTargetMutation,
} = notificationApi
