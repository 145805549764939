import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface MenuItem {
  name: string
  icon?: IconProp
  children?: MenuItem[]
  active: boolean
  exact?: boolean
  allowedAuthorities?: string[]
  to?: string
  badge?: {
    type:
      | 'primary'
      | 'secondary'
      | 'success'
      | 'danger'
      | 'warning'
      | 'info'
      | 'dark'
      | 'light'
    text: string
  }
}

export interface LeftMenuSection {
  label: string
  labelDisabled: boolean
  children: MenuItem[]
}

const homeSection: LeftMenuSection = {
  label: 'home',
  labelDisabled: true,
  children: [
    {
      name: 'Home',
      to: '/',
      icon: 'house',
      exact: true,
      active: true,
    },
  ],
}

const appSection: LeftMenuSection = {
  label: 'dashboard',
  labelDisabled: false,
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-line',
      to: '/dashboards/all',
      active: true,
      exact: true,
    },
    {
      name: 'Alarmes',
      icon: 'bell',
      to: '/alarms',
      active: true,
      exact: true,
      badge: {
        type: 'warning',
        text: 'Beta',
      },
    },
  ],
}

const entitiesSection: LeftMenuSection = {
  label: 'entities',
  labelDisabled: false,
  children: [
    {
      name: 'Assets',
      icon: 'box-open',
      to: '/assets/all',
      active: true,
      exact: false,
    },
    {
      name: 'Devices',
      icon: 'microchip',
      to: '/devices/all',
      active: true,
      exact: false,
    },
    {
      name: 'Rule Chain',
      icon: 'code-branch',
      to: '/rule-chains',
      allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
      active: true,
      exact: true,
    },
  ],
}

const profileSection: LeftMenuSection = {
  label: 'profile',
  labelDisabled: false,
  children: [
    {
      name: 'Tenant Profiles',
      to: '/profiles/tenant-profiles',
      icon: 'address-card',
      active: true,
      exact: false,
      allowedAuthorities: ['MAIN_TENANT'],
    },
    {
      name: 'Asset Profiles',
      to: '/profiles/asset-profiles',
      active: true,
      icon: 'address-card',
      exact: false,
      allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
    },
    {
      name: 'Device Profiles',
      to: '/profiles/device-profiles',
      active: true,
      icon: 'address-card',
      exact: false,
      allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
    },
  ],
}

const adminSection: LeftMenuSection = {
  label: 'admin',
  labelDisabled: false,
  children: [
    {
      name: 'API Usage',
      icon: 'chart-pie',
      allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
      to: '/api-usage',
      active: true,
      exact: true,
    },
    {
      name: 'Security',
      icon: 'shield-halved',
      allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
      to: '/security',
      active: true,
      exact: false,
      children: [
        {
          name: 'Roles',
          icon: 'shield-halved',
          allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
          to: '/security/roles',
          active: true,
          exact: false,
        },
        {
          name: 'Audit logs',
          icon: 'terminal',
          to: '/security/audit-logs',
          allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
          active: true,
          exact: true,
        },
      ],
    },
  ],
}

const managementSection: LeftMenuSection = {
  label: 'management',
  labelDisabled: false,
  children: [
    {
      name: 'Tenants',
      icon: 'city',
      to: '/tenants',
      active: true,
      exact: false,
      allowedAuthorities: ['MAIN_TENANT'],
    },
    {
      name: 'Clients',
      icon: 'building-user',
      to: '/customers/all',
      active: true,
      exact: false,
      allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
    },
    {
      name: 'Utilisateurs',
      icon: 'users',
      to: '/users/all',
      active: true,
      exact: false,
      allowedAuthorities: ['TENANT_ADMIN', 'MAIN_TENANT'],
    },
  ],
}

export default [
  homeSection,
  appSection,
  entitiesSection,
  managementSection,
  profileSection,
  adminSection,
]
