import { baseArkhaleAPI } from './base-api'
import Tenant from '../../shared/models/tenant'
import { Pagination } from '../../shared/models/others/pagination'
import Customer from '../../shared/models/customer'
import {
  AssetQuery,
  CustomerQuery,
  DeviceQuery,
  PaginationByTenantID,
  PaginationWithSearchTerm,
  RuleChainQuery,
  UserQuery,
} from './query-type'
import Asset from '../../shared/models/asset'
import Device from '../../shared/models/device.model'
import { HubInfoModel } from '../../shared/models/hub-info.model'
import User from '../../shared/models/user'
import { TimeseriesData } from '../../shared/models/telemetry/telemetry.model'
import { EntityType } from '../../shared/models/entity-type.models'
import { Dashboard } from '../../shared/models/dashboard.model'
import RuleChain from '../../shared/models/rule-chain.model'

const tenant = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getTenants: build.query<Pagination<Tenant>, PaginationWithSearchTerm>({
      query: (args) => ({
        url: 'tenants',
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
        },
      }),
      providesTags: (result = new Pagination<Tenant>(), error, arg) => [
        ...result.rows.map((item: Tenant) => {
          return {
            type: 'Tenant' as const,
            id: item.id?.id,
          }
        }),
        { type: 'Tenant', id: 'LIST' },
      ],
    }),
    getTenant: build.query<Tenant, string>({
      query: (tenantId) => `tenants/${tenantId}`,
      providesTags: (result, error, arg) => [{ type: 'Tenant', id: arg }],
    }),
    getHubInfoByTenantID: build.query<HubInfoModel, string>({
      query: (id) => `tenants/${id}/hub-infos`,
    }),
    getHubMetricsByTenantID: build.query<
      TimeseriesData,
      { tenantID: string; startDate: string }
    >({
      query: (args) => {
        const { tenantID, startDate } = args
        return `tenants/${tenantID}/hub-metrics?start_date=${startDate}`
      },
    }),
    getTenantCustomers: build.query<
      Pagination<Customer>,
      CustomerQuery & {
        tenantID: { id: string; entityType: EntityType.TENANT }
      }
    >({
      query: (args) => ({
        url: `tenants/${args.tenantID.id}/customers`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
        },
      }),
      providesTags: (result = new Pagination<Customer>(), error, arg) => [
        ...result.rows.map((item: Customer) => {
          return {
            type: 'CustomerByTenant' as const,
            id: item.id?.id,
          }
        }),
        { type: 'Customer', id: 'LIST' },
      ],
    }),
    getTenantAssets: build.query<
      Pagination<Asset>,
      AssetQuery & { tenantID: { id: string; entityType: EntityType.TENANT } }
    >({
      query: (args) => ({
        url: `tenants/${args.tenantID.id}/assets`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
          customerID: args.customerID?.id,
        },
      }),
      providesTags: (result = new Pagination<Asset>(), error, arg) => {
        return [
          { type: 'Asset', id: 'LIST' },
          { type: 'AssetByCustomer', id: arg.customerID?.id },
          { type: 'AssetByTenant', id: arg.tenantID?.id },
        ]
      },
    }),
    getTenantDevices: build.query<
      Pagination<Device>,
      DeviceQuery & { tenantID: { id: string; entityType: EntityType.TENANT } }
    >({
      query: (args) => ({
        url: `tenants/${args.tenantID.id}/devices`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
          customerID: args.customerID?.id,
          deviceProfileID: args.deviceProfileID?.id,
        },
      }),
    }),
    getTenantRuleChains: build.query<
      Pagination<RuleChain>,
      RuleChainQuery & {
        tenantID: { id: string; entityType: EntityType.TENANT }
      }
    >({
      query: (args) => ({
        url: `tenants/${args.tenantID.id}/rule-chains`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
        },
      }),
      providesTags: (result = new Pagination<RuleChain>(), error, arg) => [
        ...result.rows.map((item: RuleChain) => {
          return {
            type: 'RuleChain' as const,
            id: item.id?.id,
          }
        }),
        { type: 'RuleChain', id: 'LIST' },
      ],
    }),
    getTenantDashboards: build.query<
      Pagination<Dashboard>,
      PaginationByTenantID
    >({
      query: (args) => ({
        url: `tenants/${args.tenantID}/dashboards`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
        },
      }),
    }),
    getTenantUsers: build.query<
      Pagination<User>,
      UserQuery & { tenantID: { id: string; entityType: EntityType.TENANT } }
    >({
      query: (args) => ({
        url: `tenants/${args.tenantID.id}/users`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
          customerID: args.customerID?.id,
        },
      }),
    }),
    addNewTenant: build.mutation<Tenant, Tenant>({
      query: (tenant) => ({
        url: 'tenants',
        method: 'POST',
        body: tenant,
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }],
    }),
    updateTenant: build.mutation<Tenant, Tenant>({
      query: (tenant) => ({
        url: `tenants/${tenant.id?.id}`,
        method: 'PUT',
        body: tenant,
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }],
    }),
    deleteTenant: build.mutation<Tenant, string>({
      query: (tenantId) => ({
        url: `tenants/${tenantId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTenantsQuery,
  useLazyGetTenantsQuery,
  useLazyGetTenantRuleChainsQuery,
  useGetTenantQuery,
  useGetHubInfoByTenantIDQuery,
  useLazyGetHubInfoByTenantIDQuery,
  useGetHubMetricsByTenantIDQuery,
  useLazyGetHubMetricsByTenantIDQuery,
  useLazyGetTenantQuery,
  useAddNewTenantMutation,
  useUpdateTenantMutation,
  useLazyGetTenantCustomersQuery,
  useGetTenantAssetsQuery,
  useLazyGetTenantAssetsQuery,
  useGetTenantDevicesQuery,
  useLazyGetTenantDevicesQuery,
  useGetTenantUsersQuery,
  useLazyGetTenantUsersQuery,
  useDeleteTenantMutation,
} = tenant
