import classNames from 'classnames'
import React, { useContext } from 'react'
import { CloseButton } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useMeQuery } from '../../store/kopiliot-api/base-api'

interface ArkhaleCloseButtonProps {
  size?: string
  onClick?: React.MouseEventHandler
  noOutline: boolean
  className?: string
  variant?: string
}

const ArkhaleCloseButton = ({
  size,
  onClick,
  noOutline,
  variant,
  className = '',
  ...rest
}: ArkhaleCloseButtonProps) => {
  const { theme } = useSelector((state: RootState) => state.settings)
  let isDark = theme === 'dark'
  return (
    <CloseButton
      variant={variant ? variant : isDark ? 'white' : undefined}
      className={classNames('btn', {
        [`btn-${size}`]: size,
        'outline-none': noOutline,
        [className]: className,
      })}
      onClick={onClick && onClick}
      {...rest}
    />
  )
}

export default ArkhaleCloseButton
