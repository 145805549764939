import React from 'react'
import LoginForm from './LoginForm'
import SplitPageLayout from '../_layout/SplitPageLayout'
import bgImg from 'assets/img/generic/14.jpg'
import Flex from '../../components/common/Flex'
import { SubmitHandler } from 'react-hook-form'
import { LoginInput } from '../../shared/models/others/login-input'
import { useLoginMutation } from '../../store/kopiliot-api/auth'
import { Navigate } from 'react-router-dom'
import { useMeQuery } from '../../store/kopiliot-api/base-api'

const Login = () => {
  const [mutation] = useLoginMutation()
  const { data, error, isLoading, isFetching } = useMeQuery(null)

  const onSubmit: SubmitHandler<LoginInput> = async (data) => {
    try {
      await mutation(data).unwrap()
    } catch (e) {}
  }
  if (isFetching || isLoading) {
    return <div>Loading</div>
  } else if (!data || error) {
    return (
      <SplitPageLayout bgProps={{ image: bgImg }}>
        <Flex alignItems="center" justifyContent="between">
          <h3>Login</h3>
        </Flex>
        <LoginForm onSubmit={onSubmit} />
      </SplitPageLayout>
    )
  } else {
    return <Navigate to={'/'} />
  }
}

export default Login
