export interface TsValue {
  value: any
  ts: number
  count?: number
}

export const isTsValue = (value: any): value is TsValue => {
  return value && value.value !== undefined && value.ts !== undefined
}

export interface ComparisonTsValue {
  current: TsValue | null
  previous?: TsValue
}

export const isComparisonTsValue = (value: any): value is ComparisonTsValue => {
  return value && value.current !== undefined && isTsValue(value.current)
}

export interface TimeseriesData {
  [key: string]: Array<TsValue>
}

export interface LatestTimeseriesData {
  [key: string]: TsValue
}

export interface AggregatedTimeseriesData {
  [key: string]: ComparisonTsValue
}

export type LatestTsValueAsArray = TsValue & { key: string }
