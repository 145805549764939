import React, { useEffect } from 'react'
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { UserUpdate } from '../../../shared/models/user'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../components/form/Input'

interface UserFormProps {
  form: UseFormReturn<UserUpdate>
  onSubmit: SubmitHandler<UserUpdate>
  initialValues?: Partial<UserUpdate>
  isLoading?: boolean
  disabledField: Array<keyof UserUpdate>
}

const UserForm = ({
  form,
  onSubmit,
  initialValues,
  isLoading,
}: UserFormProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form

  // Init value if needed
  useEffect(() => {
    reset(initialValues)
  }, [initialValues])
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              register={register('firstname', {
                required: 'Firstname is required',
              })}
              label={'Firstname'}
              error={errors.firstname}
              isDisabled={isLoading}
            />
          </Col>
          <Col md={'6'}>
            <Input
              register={register('lastname', {
                required: 'Lastname is required',
              })}
              label={'Lastname'}
              error={errors.lastname}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              register={register('jobTitle')}
              label={'Job title'}
              error={errors.jobTitle}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              register={register('phone')}
              type={'phone'}
              label={'Phone'}
              error={errors.email}
              isDisabled={isLoading}
            />
          </Col>
          <Col md={'6'}>
            <Input
              register={register('email', { required: 'Email is required' })}
              type={'email'}
              label={'Email'}
              error={errors.email}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default UserForm
