import React from 'react'
import { SingleValuePanelProps } from '../../../panel/chart-helpers/single-value/SingleValue'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  PieChartDataKeySpecificProps,
  SingleValueDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import { Button, ButtonGroup, Col, Form, FormGroup, Row } from 'react-bootstrap'
import Input from '../../../../../components/form/Input'
import IconButton from '../../../../../components/common/IconButton'

interface PieChartPanelSpecificFormProps {
  initialValues?: PieChartDataKeySpecificProps
  onSubmitted?: (data: any) => void
}

const PieChartDataKeySpecificForm = ({
  initialValues,
  onSubmitted,
}: PieChartPanelSpecificFormProps) => {
  const form = useFormContext<PieChartDataKeySpecificProps>()
  const {
    reset,
    watch,
    register,
    trigger,
    formState: { errors },
  } = form
  React.useEffect(() => {
    if (initialValues) {
      form.reset(initialValues)
    }
  }, [initialValues])
  return (
    <Form onSubmit={onSubmitted ? form.handleSubmit(onSubmitted) : undefined}>
      <Form.Group>
        <Form.Label>Offset au passage de la souris:</Form.Label>
        <Input register={register('hoverOffset')} />
      </Form.Group>
    </Form>
  )
}

export default PieChartDataKeySpecificForm
