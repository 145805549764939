import { Relation } from '../../shared/models/entity-relation'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

const getColumnsForRelationTable = () => {
  const columnHelper = createColumnHelper<Relation>()
  const columns: ColumnDef<Relation, any>[] = [
    columnHelper.accessor((row) => row.relationType, {
      id: 'relationType',
      cell: (info) => info.getValue(),
      header: () => 'Type de relation',
      enableSorting: true,
    }),
    columnHelper.accessor((row) => row.from.entityType, {
      id: 'fromID',
      cell: (info) => info.getValue(),
      header: () => 'Type entité source',
      enableSorting: true,
    }),
    columnHelper.accessor((row) => row.from.id, {
      id: 'fromName',
      cell: (info) => info.getValue(),
      header: () => "Nom de l'entité source",
      enableSorting: true,
    }),
    columnHelper.accessor((row) => row.to.entityType, {
      id: 'toID',
      cell: (info) => info.getValue(),
      header: () => 'Type entité de destination',
      enableSorting: true,
    }),
    columnHelper.accessor((row) => row.to.id, {
      id: 'toName',
      cell: (info) => info.getValue(),
      header: () => "Nom de l'entité de destination",
      enableSorting: true,
    }),
  ]
  return columns
}

export default getColumnsForRelationTable
