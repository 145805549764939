import { useOutletContext } from 'react-router-dom'

export type TenantRouteParam = {
  tenantID?: string
}

export type TenantContext = {
  tenantIDParam?: string
}

const useTenantContext = () => {
  return useOutletContext<TenantContext>()
}

export default useTenantContext
