import { AlarmSeverity, AlarmStatus } from '../../shared/models/alarm.model'

const getColorForAlarmSeverity = (type: AlarmSeverity) => {
  switch (type) {
    case AlarmSeverity.CRITICAL:
      return 'danger'
    case AlarmSeverity.MAJOR:
      return 'warning'
    case AlarmSeverity.MINOR:
      return 'info'
    case AlarmSeverity.WARNING:
      return 'warning'
    case AlarmSeverity.INDETERMINATE:
      return 'info'
    default:
      return 'info'
  }
}

const getColorForActive = (status: AlarmStatus) => {
  switch (status) {
    case AlarmStatus.ACTIVE_UNACK:
    case AlarmStatus.ACTIVE_ACK:
      return 'danger'
    case AlarmStatus.CLEARED_ACK:
    case AlarmStatus.CLEARED_UNACK:
      return 'success'
    default:
      return 'info'
  }
}

const getColorForAcknowledge = (status: AlarmStatus) => {
  switch (status) {
    case AlarmStatus.ACTIVE_UNACK:
    case AlarmStatus.CLEARED_UNACK:
      return 'danger'
    case AlarmStatus.ACTIVE_ACK:
    case AlarmStatus.CLEARED_ACK:
      return 'success'
    default:
      return 'info'
  }
}

export { getColorForAlarmSeverity, getColorForActive, getColorForAcknowledge }
