import React from 'react'
import { AliasID } from '../../../shared/models/id/alias-id'
import { Alias } from '../../../shared/models/alias.model'
import { Button, Modal } from 'react-bootstrap'
import { useAppSelector } from '../../../store/store'
import _ from 'lodash'
import { useDeleteAliasMutation } from '../../../store/kopiliot-api/dashboard'

interface Props {
  isOpen: boolean
  onHidden: () => void
  alias?: Alias
}

const DeleteAliasModal = ({ isOpen, onHidden, alias }: Props) => {
  const dash = useAppSelector((state) => state.dashboard)
  const [deleteAlias, data] = useDeleteAliasMutation()
  const [isAliasInUse, setIsAliasInUse] = React.useState(false)
  React.useEffect(() => {
    if (alias?.id?.id) {
      const res = _.chain(dash.panels)
        .map((panel) => {
          return panel.config.dataSources
        })
        .flatten()
        .map((ds) => {
          return ds.entityAliasID
        })
        .filter((id) => {
          return id?.id === alias?.id?.id
        })
        .value()
      setIsAliasInUse(res.length > 0)
    }
  }, [alias?.id?.id])
  const onDelete = async () => {
    try {
      if (alias?.id?.id && !isAliasInUse) {
        await deleteAlias({
          id: alias.id.id,
          dashboardID: alias.dashboardID.id,
        }).unwrap()
      }
    } catch (error) {
      console.error(error)
    } finally {
      onHidden()
    }
  }
  return (
    <Modal show={isOpen} onHide={onHidden} centered>
      <Modal.Header closeButton>
        <Modal.Title>Supprimer l'alias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isAliasInUse ? (
          <p>Êtes-vous sûr de vouloir supprimer cet alias?</p>
        ) : (
          <p>
            Cet alias est utilisé dans un ou plusieurs panel. Suppression
            impossible
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHidden}>
          Annuler
        </Button>
        {!isAliasInUse ? (
          <Button variant="danger" onClick={onDelete}>
            Supprimer
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteAliasModal
