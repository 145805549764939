import classNames from 'classnames'
import React, { Fragment, useState } from 'react'
import { Collapse, Nav } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem'
import { MenuItem } from '../../../routes/routes'
import { RootState } from '../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { collapseBurgerMenu } from '../../../store/slices/menus'

const CollapseItems = ({ route }: { route: MenuItem }) => {
  const { pathname } = useLocation()
  const openCollapse = (children?: MenuItem[]) => {
    const checkLink = (children?: MenuItem) => {
      if (children?.to === pathname) {
        return true
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children?.children?.some(checkLink)
      )
    }
    return children?.some(checkLink)
  }
  const [open, setOpen] = useState(openCollapse(route.children))
  return (
    <Nav.Item as={'li'}>
      <Nav.Link
        onClick={() => setOpen(!open)}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500 active': !route.active,
        })}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children!} />
        </Nav>
      </Collapse>
    </Nav.Item>
  )
}

const NavbarVerticalMenu = ({ routes }: { routes: MenuItem[] }) => {
  const appDispatch = useDispatch()
  const { isBurgerMenuCollapsed } = useSelector(
    (state: RootState) => state.menus
  )
  const handleNavItemClick = () => {
    if (!isBurgerMenuCollapsed) {
      appDispatch(collapseBurgerMenu())
    }
  }
  const { authority } = useSelector((state: RootState) => state.currentUser)

  return (
    <Fragment>
      {routes.map((route) => {
        if (
          route.allowedAuthorities &&
          !route.allowedAuthorities.includes(authority)
        ) {
          return null
        }
        if (!route.children) {
          return (
            <Nav.Item as={'li'} key={route.name} onClick={handleNavItemClick}>
              <NavLink
                end={route.exact}
                to={route.to ? route.to : '#!'}
                state={{ open: route.to === '/authentication-modal' }}
                className={({ isActive }) =>
                  isActive && route.to !== '#!' ? 'active nav-link' : 'nav-link'
                }
              >
                <NavbarVerticalMenuItem route={route} />
              </NavLink>
            </Nav.Item>
          )
        }
        return <CollapseItems route={route} key={route.name} />
      })}
    </Fragment>
  )
}

export default NavbarVerticalMenu
