export function isDefinedAndNotNull(value: any): boolean {
  return typeof value !== 'undefined' && value !== null
}

export function isNumericValue(value: any): boolean {
  return value - parseFloat(value) + 1 >= 0
}

export const isFiniteOrStringRepresentation = (value: any): boolean => {
  return (
    Number.isFinite(value) ||
    (Number.isFinite(Number(value)) && typeof value === 'string')
  )
}

export const capitalizeFirstLetter = (str?: string) => {
  if (!str) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const camelCaseToWords = (str?: string) => {
  if (!str) {
    return ''
  }
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
}

export const isISO8601Format = (input: string): boolean => {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z$/
  return iso8601Regex.test(input)
}

export const isISO8601FormatWithTimezone = (input: string): boolean => {
  const iso8601Regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}\+\d{2}:\d{2}$/
  return iso8601Regex.test(input)
}

export const isValidDateString = (input: string): boolean => {
  const rst = Date.parse(input.replaceAll(' ', ''))
  return !isNaN(rst)
}

export const formatValue = (
  value: any,
  dec?: number,
  units?: string,
  showZeroDecimals?: boolean
): string | undefined => {
  if (
    isDefinedAndNotNull(value) &&
    isNumericValue(value) &&
    (isDefinedAndNotNull(dec) ||
      isDefinedAndNotNull(units) ||
      Number(value).toString() === value)
  ) {
    let formatted: string | number = Number(value)
    if (isDefinedAndNotNull(dec)) {
      formatted = formatted.toFixed(dec)
    }
    if (!showZeroDecimals) {
      formatted = Number(formatted).toLocaleString()
    }
    formatted = formatted.toString()
    if (isDefinedAndNotNull(units) && units!.length > 0) {
      formatted += ' ' + units
    }
    return formatted
  } else {
    return value !== null ? value : ''
  }
}
