import React from 'react'
import classNames from 'classnames'

export interface FlexProps {
  children: any
  justifyContent?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'evenly'
    | 'between'
    | 'end'
  alignItems?: 'stretch' | 'center' | 'start' | 'end'
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch'
  inline?: boolean
  wrap?: string
  className?: string
  tag?: keyof JSX.IntrinsicElements
  breakpoint?: string
  direction?: string
}

const Flex: React.FunctionComponent<
  FlexProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({
  tag: Wrapper = 'div',
  justifyContent,
  alignContent,
  alignItems,
  inline,
  wrap,
  className,
  breakpoint,
  direction,
  children,
  ...rest
}) => {
  return (
    <Wrapper
      className={classNames(
        {
          [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
          [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
          [`flex-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`align-content-${alignContent}`]: alignContent,
          [`flex-${wrap}`]: wrap,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}

export default Flex
