import React from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'

interface AdvanceTableRowPerPageProps {
  onChange: (size: number) => void
  rowsPerPageOptions?: number[]
  pageSize: number
  className?: string
}

const AdvanceTableRowPerPage = ({
  onChange,
  pageSize,
  rowsPerPageOptions = [10, 20, 30, 40, 50],
  className,
}: AdvanceTableRowPerPageProps) => {
  const setPageSize = (value: string) => {
    onChange(parseInt(value))
  }
  return (
    <>
      <p className={classNames('mb-0 mx-2', className)}>Lignes par page:</p>
      <Form.Select
        size="sm"
        className="w-auto"
        onChange={(e) => setPageSize(e.target.value)}
        defaultValue={pageSize}
      >
        {rowsPerPageOptions.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </Form.Select>
    </>
  )
}

export default AdvanceTableRowPerPage
