import { isPaginationActions, reducerPage } from './pagination-reducer'
import { PaginationWithSearchTerm } from '../store/kopiliot-api/query-type'

export interface EntityQueryReducerConfig<S> {
  handleSpecificActions?: {
    [actionType: string]: (
      state: S,
      action: { type: string; payload: any }
    ) => S
  }
}

export function createEntityQueryReducer<
  S extends PaginationWithSearchTerm,
  A extends { type: string; payload: any }
>(config: EntityQueryReducerConfig<S>) {
  const { handleSpecificActions } = config
  return function entityQueryReducer(state: S, action: A): S {
    if (isPaginationActions(action)) {
      return { ...state, ...reducerPage(state, action) }
    }
    if (handleSpecificActions && handleSpecificActions[action.type]) {
      return handleSpecificActions[action.type](state, action)
    }
    return state
  }
}
