import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Customer from '../../../shared/models/customer'
import TenantName from '../../helper/TenantName'
import { formatDate } from '../../../helpers/utls'
import { ActionRowProps } from '../../../components/kopiliot-list/type'

const getColumnsForTableCustomer = (actionRows: ActionRowProps[]) => {
  const columnHelper = createColumnHelper<Customer>()
  const columns: ColumnDef<Customer, string>[] = [
    columnHelper.accessor((row) => row.id?.id, {
      id: 'id',
      cell: (info) => info.getValue(),
      header: () => 'Id',
    }),
    columnHelper.display({
      id: 'name',
      header: () => 'Nom',
      enableSorting: true,
      meta: {
        className: 'align-middle',
      },
      cell: (props) => {
        const { id, name } = props.row.original

        return (
          <h5 className={'mb-0 fs-10'}>
            <Link to={id ? `${id.id}` : '#'} className={'fw-semibold'}>
              {name}
            </Link>
          </h5>
        )
      },
    }),
    columnHelper.accessor((row) => row.phone, {
      id: 'phone',
      cell: (info) => info.getValue(),
      header: () => 'Téléphone',
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => info.getValue(),
      header: () => 'Email',
    }),
    columnHelper.accessor((row) => row.city, {
      id: 'city',
      cell: (info) => info.getValue(),
      header: () => 'Ville',
    }),
    columnHelper.accessor((row) => row.country, {
      id: 'country',
      cell: (info) => info.getValue(),
      header: () => 'Pays',
    }),
    columnHelper.display({
      id: 'tenant',
      cell: (props) => {
        return (
          <>
            <TenantName tenantId={props.row.original.tenantId.id} />
          </>
        )
      },
      header: () => 'Tenant',
    }),
    columnHelper.display({
      id: 'time',
      cell: (props) => {
        const { createdAt, updatedAt } = props.row.original
        return createdAt && updatedAt ? (
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }}>
                Créé le {formatDate(createdAt, false)}
                <br></br>
                Mis à jour le {formatDate(updatedAt, false)}
              </Tooltip>
            }
          >
            <span>{formatDate(updatedAt)}</span>
          </OverlayTrigger>
        ) : (
          <span>Non défini</span>
        )
      },
      header: () => 'Time',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        const customerID = props.row.original.id?.id
        return (
          <>
            {actionRows && customerID && (
              <Dropdown
                className={'text-center btn-reveal-trigger'}
                onClick={(event) => event.stopPropagation()}
              >
                <Dropdown.Toggle
                  size={'sm'}
                  className={'btn-reveal'}
                  variant="falcon-default"
                  id="dropdown-assets-actions"
                >
                  <FontAwesomeIcon icon={'ellipsis-h'} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  popperConfig={{ strategy: 'fixed' }}
                  renderOnMount={true}
                >
                  {actionRows.map((item) => {
                    return (
                      <Dropdown.Item
                        className={`text-${item.color || 'primary'}`}
                        key={item.title}
                        onClick={() => {
                          if (item.onClick) {
                            item.onClick(customerID)
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fs-0 me-2"
                        />
                        {item.title}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )
      },
      header: () => 'Actions',
    }),
  ]
  return columns
}

const defaultCustomerActionsRow = (
  onDeleteItem: (id: string) => void,
  onEditItem: (id: string) => void
) => {
  const actionsRow: ActionRowProps[] = [
    {
      title: 'Edit',
      color: 'primary',
      icon: 'edit',
      onClick: (id) => {
        onEditItem(id)
      },
    },
    {
      title: 'Delete',
      color: 'danger',
      icon: 'trash-alt',
      onClick: (id) => {
        onDeleteItem(id)
      },
    },
  ]
  return actionsRow
}

export { getColumnsForTableCustomer, defaultCustomerActionsRow }
