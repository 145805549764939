import { BaseData } from './base-data'
import { HubInfoID } from './id/hub_info_id'
import { SubtleBadgeProps } from '../../components/common/SubtleBadge'

export enum HubInfoStatus {
  Unknown = 'unknown',
  Error = 'error',
  Enabling = 'enabling',
  Ready = 'ready',
  Disabling = 'disabling',
  Disabled = 'disabled',
}

export const isHubInfoStatus = (value: any): value is HubInfoStatus => {
  return Object.values(HubInfoStatus).includes(value)
}

export const getColorForHubInfoStatus = (
  status: HubInfoStatus
): SubtleBadgeProps['bg'] => {
  switch (status) {
    case HubInfoStatus.Error:
      return 'danger'
    case HubInfoStatus.Enabling:
      return 'warning'
    case HubInfoStatus.Ready:
      return 'success'
    case HubInfoStatus.Disabling:
      return 'warning'
    case HubInfoStatus.Disabled:
      return 'warning'
    default:
      return 'dark'
  }
}

export interface HubInfoModel extends BaseData<HubInfoID> {
  name: string
  status: HubInfoStatus
  enabled: boolean
  deviceCount: number
  connectedDeviceCount: number
  endpoint: string
}
