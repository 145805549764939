import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import AttributeForm from './AttributeForm'
import { Attribute, AttributeScope } from '../../shared/models/attribute'
import { useForm } from 'react-hook-form'
import { EntityId } from '../../shared/models/id/entity-id'
import { useSaveAttributeToEntityMutation } from '../../store/kopiliot-api/attribute'

interface Props {
  isOpen: boolean
  onClose: () => void
  entityID: EntityId
  defaultValues?: Attribute
}

const AddOrEditAttributeModal = ({
  entityID,
  isOpen,
  onClose,
  defaultValues,
}: Props) => {
  const form = useForm<Attribute>({
    defaultValues: {
      entityId: entityID.id,
      entityType: entityID.entityType,
    },
  })
  React.useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
        entityType: entityID.entityType,
        entityId: entityID.id,
      })
    }
  }, [defaultValues])
  const [saveAttribute, res] = useSaveAttributeToEntityMutation()
  const onSubmit = async (data: Attribute) => {
    try {
      if (typeof data.value === 'string') {
        data.value = data.value.trim()
        try {
          data.value = JSON.parse(data.value)
        } catch (e) {
          // Nothing to see here
        }
      }
      await saveAttribute(data).unwrap()
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un attribut</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AttributeForm onSubmit={onSubmit} form={form} />
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} onClick={onClose} variant={'outline-secondary'}>
          Annuler
        </Button>
        <Button
          type={'button'}
          onClick={form.handleSubmit(onSubmit)}
          variant={'outline-primary'}
        >
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddOrEditAttributeModal
