import React from 'react'
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  ComparisonResultType,
  processThresholdOperator,
  SingleValueDataKeySpecificProps,
  ThresholdWithColor,
} from '../../../../../shared/models/panel'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SingleValueValue from './SingleValueValue'
import SingleValueChart from './SingleValueChart'
import {
  ComparisonTsValue,
  isComparisonTsValue,
  isTsValue,
  TsValue,
} from '../../../../../shared/models/telemetry/telemetry.model'
import { formatValue } from '../../../../../utils/utils'

export interface SingleValuePanelProps {
  itemWidth?: number
  title?: string
  unit?: string
  value?: TsValue | ComparisonTsValue
  config?: SingleValueDataKeySpecificProps
}

const getColor = (
  value: TsValue | ComparisonTsValue | undefined,
  thresholds?: ThresholdWithColor[]
): string | undefined => {
  if (!thresholds || !value) {
    return undefined
  }
  let valueToCheck: any = 0
  if (isTsValue(value)) {
    valueToCheck = value.value
  } else if (isComparisonTsValue(value)) {
    valueToCheck = value.current?.value
  }

  for (const threshold of thresholds) {
    const is = processThresholdOperator(
      threshold.operator,
      valueToCheck,
      threshold.reference
    )
    if (is) {
      return threshold.color
    }
  }
  return undefined
}

const SingleValue = ({
  config,
  itemWidth,
  title,
  unit,
  value,
}: SingleValuePanelProps) => {
  const [concreteValue, setConcreteValue] = React.useState<any>()
  React.useEffect(() => {
    if (isTsValue(value)) {
      setConcreteValue(value.value)
    } else if (isComparisonTsValue(value)) {
      setConcreteValue(value.current?.value)
    }
  }, [value])
  const [colorState, setColorState] = React.useState<string | undefined>()
  const [sizeType, setSizeType] = React.useState<'small' | 'large'>('small')
  React.useEffect(() => {
    if (itemWidth) {
      if (itemWidth < 400) {
        setSizeType('small')
      } else {
        setSizeType('large')
      }
    }
  }, [itemWidth])
  const [fontSize, setFontSize] = React.useState<number>(0)
  React.useEffect(() => {
    const finalString = `${formatValue(concreteValue, 2, unit, false)}`

    let concreteWidth = itemWidth ? itemWidth : 0
    if (config?.showGraph) {
      concreteWidth = concreteWidth / 2
    }
    const finalFontSize = Math.min(
      Math.max(concreteWidth / finalString.length, 12),
      40
    )
    setFontSize(finalFontSize)
  }, [sizeType])

  //end test web socket
  React.useEffect(() => {
    setColorState(getColor(value, config?.thresholds))
  }, [concreteValue])
  /*const getDelta = (): SingleValueBadgeProps => {
    const badgeProps: SingleValueBadgeProps = {}
    if (!value?.delta?.value || !value?.value?.value) {
      return badgeProps
    }

    switch (value?.delta?.comparison) {
      case ComparisonResultType.PREVIOUS_VALUE:
        badgeProps.value = value.delta.value
        badgeProps.unit = value.delta.unit
        break
      case ComparisonResultType.DELTA_ABSOLUTE:
        badgeProps.value = value.value.value - value.delta.value
        badgeProps.unit = value.delta.unit
        break
      case ComparisonResultType.DELTA_PERCENT:
        if (
          isNumericValue(value.delta.value) &&
          isNumericValue(value.value.value)
        ) {
          badgeProps.value =
            ((value.value.value - value.delta.value) / value.delta.value) * 100
        } else {
          badgeProps.value = 0
        }
        badgeProps.unit = '%'
        break
      default:
        return badgeProps
    }
    if (badgeProps.value === 0) {
      badgeProps.icon = 'minus'
    } else if (badgeProps.value > 0) {
      badgeProps.icon = 'caret-up'
    } else {
      badgeProps.icon = 'caret-down'
    }
    return badgeProps
  }*/

  return (
    <Card
      className={'shadow-none border-2 h-100'}
      style={{
        borderColor: colorState,
      }}
    >
      <Card.Header className={'pb-0'}>
        <h6 className={'mb-0 mt-2'}>
          {title}
          {config?.helperText && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: 'fixed' }}>
                  {config?.helperText}
                </Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="ms-1 text-400"
                  id="weeklySalesTooltip"
                />
              </span>
            </OverlayTrigger>
          )}
        </h6>
      </Card.Header>
      <Card.Body
        as={'div'}
        className={classNames('d-flex flex-row justify-content-between', {})}
      >
        <SingleValueValue
          value={concreteValue}
          unit={unit}
          fontSize={fontSize}
          color={colorState}
        />
        {config?.showGraph && <SingleValueChart width={136} height={60} />}
      </Card.Body>
    </Card>
  )
}

export default SingleValue
