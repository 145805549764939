import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import User from '../../../shared/models/user'
import Flex from '../../../components/common/Flex'
import CustomerName from '../../helper/CustomerName'
import TenantName from '../../helper/TenantName'
import UserModule from './UserModule'
import { ActionRowProps } from '../../../components/kopiliot-list/type'

const getColumnsForTableUsers = (actionRows: ActionRowProps[]) => {
  const columnHelper = createColumnHelper<User>()

  const columns: ColumnDef<User, string>[] = [
    columnHelper.accessor((row) => row.id?.id, {
      id: 'id',
      cell: (info) => info.getValue(),
      header: () => 'Id',
    }),
    columnHelper.display({
      id: 'name',
      header: () => 'Nom',
      enableSorting: true,
      meta: {
        className: 'align-middle',
      },
      cell: (props) => {
        const { id, firstname, lastname } = props.row.original

        return (
          <h5 className={'mb-0 fs-10'}>
            <Link to={id ? `${id.id}` : '#'} className={'fw-semibold'}>
              {firstname} {lastname}
            </Link>
          </h5>
        )
      },
    }),

    columnHelper.accessor((row) => row.jobTitle, {
      id: 'jobTitle',
      cell: (info) => info.getValue(),
      header: () => 'Job Title',
    }),
    columnHelper.accessor((row) => row.phone, {
      id: 'phone',
      cell: (info) => info.getValue(),
      header: () => 'Phone',
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => info.getValue(),
      header: () => 'Email',
    }),
    columnHelper.display({
      id: 'enabled',
      cell: (props) => {
        return (
          <>
            {props.row.original.id && (
              <UserModule.UserActivated userID={props.row.original.id.id} />
            )}
          </>
        )
      },
      header: () => 'Enabled',
    }),
    columnHelper.display({
      id: 'customer',
      cell: (props) => {
        return (
          <>
            {props.row.original.customerId && (
              <CustomerName customerId={props.row.original.customerId.id} />
            )}
          </>
        )
      },
      header: () => 'Customer',
    }),
    columnHelper.display({
      id: 'tenant',
      cell: (props) => {
        return (
          <>
            <TenantName tenantId={props.row.original.tenantID.id} />
          </>
        )
      },
      header: () => 'Tenant',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        const userID = props.row.original.id?.id
        return (
          <>
            {actionRows && userID && (
              <Dropdown className={'text-center btn-reveal-trigger'}>
                <Dropdown.Toggle
                  size={'sm'}
                  className={'btn-reveal'}
                  variant="falcon-default"
                  id="dropdown-assets-actions"
                >
                  <FontAwesomeIcon icon={'ellipsis-h'} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  popperConfig={{ strategy: 'fixed' }}
                  renderOnMount={true}
                >
                  {actionRows.map((item) => {
                    return (
                      <Dropdown.Item
                        className={`text-${item.color || 'primary'}`}
                        key={item.title}
                        onClick={() => {
                          if (item.onClick) {
                            item.onClick(userID)
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fs-0 me-2"
                        />
                        {item.title}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )
      },
      header: () => 'Actions',
    }),
  ]
  return columns
}

const defaultUserActionsRow = (
  onDeleteItem: (id: string) => void,
  onEditItem: (id: string) => void
) => {
  const actionsRow: ActionRowProps[] = [
    {
      title: 'Edit',
      color: 'primary',
      icon: 'edit',
      onClick: (id) => {
        onEditItem(id)
      },
    },
    {
      title: 'Delete',
      color: 'danger',
      icon: 'trash-alt',
      onClick: (id) => {
        onDeleteItem(id)
      },
    },
  ]
  return actionsRow
}

export { getColumnsForTableUsers, defaultUserActionsRow }
