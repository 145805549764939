import React from 'react'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Accordion, Form } from 'react-bootstrap'
import Input from '../../../../components/form/Input'
import Select, { SingleValue } from 'react-select'
import _ from 'lodash'
import { Panel, PanelType } from '../../../../shared/models/panel'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../../../components/common/Flex'
import LegendForm from './LegendForm'
import TooltipForm from './TooltipForm'
import LineForm from './LineForm'
import PointStyleForm from './PointStyleForm'
import ResponsiveForm from './ResponsiveForm'
import AnimationForm from './AnimationForm'
import GaugeStyleForm from './gauges/GaugeStyleForm'

interface PanelOptionType {
  value: string
  label: string
  description: string
  icon: IconProp
}

interface Props {
  form: UseFormReturn<Panel>
  onSubmit: (data: Panel) => void
}

const GeneralForm = ({ form, onSubmit }: Props) => {
  const {
    formState: { errors, defaultValues },
  } = form

  const watchPanelType = form.watch('type')
  // TODO remove
  const options: PanelOptionType[] = [
    {
      value: PanelType.TIME_SERIES_LINE,
      label: 'Timeseries line chart',
      description: 'A line chart with time series data',
      icon: 'chart-line',
    },
    {
      value: PanelType.SINGLE_VALUE,
      label: 'Simple valeur',
      description: 'Une valeur simple avec ou sans mini-graphique',
      icon: 'table-cells-large',
    },
    {
      value: PanelType.SIMPLE_INDICATOR,
      label: 'Simple indicator',
      description: 'A simple indicator',
      icon: 'lightbulb',
    },
    {
      value: PanelType.TIME_SERIES_BAR,
      label: 'Bar Chart',
      description: 'A bar chart with time series data NOT IMPLEMENTED',
      icon: 'chart-column',
    },
    {
      value: PanelType.GAUGE,
      label: 'Gauge',
      description: 'One or multiple gauges representing a value BETA',
      icon: 'gauge-high',
    },
    {
      value: PanelType.TIME_SERIES_PIE,
      label: 'Pie Chart',
      description: 'One pie chart representing a value NOT IMPLEMENTED',
      icon: 'circle-half-stroke',
    },
    {
      value: PanelType.TIME_SERIES_RADAR,
      label: 'Radar Chart',
      description: 'One radar chart',
      icon: 'circle-half-stroke',
    },
    {
      value: PanelType.TIMESERIES_TABLE,
      label: 'Time series table',
      description: 'A time series table with time series data',
      icon: 'table-cells',
    },
    {
      value: PanelType.JSON_TABLE,
      label: 'JSON table',
      description: 'A JSON table. Use this for non-time series data',
      icon: 'table-cells',
    },
  ]
  const getSubForm = (panelType: string) => {
    let components: { title: string; elem: JSX.Element }[] = []
    switch (panelType) {
      case PanelType.TIME_SERIES_LINE:
        components.push({
          title: 'Responsive',
          elem: <ResponsiveForm />,
        })
        components.push({
          title: 'Legend config',
          elem: <LegendForm />,
        })
        components.push({
          title: 'Tooltip config',
          elem: <TooltipForm />,
        })
        components.push({ title: 'Line', elem: <LineForm /> })
        components.push({ title: 'Point', elem: <PointStyleForm /> })
        components.push({ title: 'Animation', elem: <AnimationForm /> })
        //components.push({ title: 'Annotation', elem: <ChartAnnotationForm /> })
        break
      case PanelType.GAUGE:
        components.push({ title: 'Gauge Style', elem: <GaugeStyleForm /> })
        break
      case PanelType.TIME_SERIES_BAR:
        components.push({
          title: 'Responsive',
          elem: <ResponsiveForm />,
        })
        components.push({ title: 'Tooltips', elem: <TooltipForm /> })
        components.push({ title: 'Legends', elem: <LegendForm /> })

        break
    }
    return components
  }
  const renderDynamicForm = () => {
    return _.map(getSubForm(watchPanelType), (subForm, index) => {
      return (
        <Accordion.Item
          className={'w-100'}
          eventKey={index.toString()}
          key={index}
        >
          <Accordion.Header>{subForm.title}</Accordion.Header>
          <Accordion.Body>{subForm.elem}</Accordion.Body>
        </Accordion.Item>
      )
    })
  }
  return (
    <>
      <FormProvider {...form}>
        <Form
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
          className={'h-100'}
        >
          <Controller
            control={form.control}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  classNamePrefix={'react-select'}
                  isSearchable={false}
                  options={options}
                  defaultValue={_.filter(options, (obj): boolean => {
                    return defaultValues?.type === obj.value
                  })}
                  formatOptionLabel={(option: PanelOptionType) => {
                    return (
                      <Flex
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-around'}
                      >
                        <div className={'me-2'}>
                          <FontAwesomeIcon size={'lg'} icon={option.icon} />
                        </div>
                        <Flex
                          direction={'column'}
                          className={'align-self-center'}
                        >
                          <span className={'text-900 fs--3 mb-0'}>
                            {option.label}
                          </span>
                          <span className={'text-600 fs--2 fw-light mb-0'}>
                            {option.description}
                          </span>
                        </Flex>
                      </Flex>
                    )
                  }}
                  onChange={(selectedOption: SingleValue<any>) => {
                    onChange(selectedOption.value)
                  }}
                  {..._.omit(
                    form.register('type', {
                      required: 'Dashboard Type is mandatory',
                      shouldUnregister: false,
                    }),
                    ['onChange']
                  )}
                />
              )
            }}
            name={'type'}
          />

          <Accordion defaultActiveKey="basic" className={'pt-2'} alwaysOpen>
            <Accordion.Item eventKey="basic">
              <Accordion.Header>Panel options</Accordion.Header>
              <Accordion.Body>
                <Input
                  register={form.register('title', {
                    required: 'Panel title is mandatory',
                    shouldUnregister: true,
                  })}
                  label={'Title'}
                  error={errors.title ? errors.title : undefined}
                />
                <Input
                  register={form.register('description', {
                    shouldUnregister: true,
                  })}
                  label={'Description'}
                  type={'textarea'}
                />
              </Accordion.Body>
            </Accordion.Item>
            {renderDynamicForm()}
          </Accordion>
        </Form>
      </FormProvider>
    </>
  )
}

export default GeneralForm
