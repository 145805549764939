import { Middleware } from '@reduxjs/toolkit'
import { WebSocketActionType, websocketSendQuery } from '../slices/websocket'
import _ from 'lodash'

export const webSocketMiddleware = (): Middleware => {
  let socket: WebSocket | null = null
  let pendingRequests: Record<string, { queryID: any; query: any }> = {} //

  return (store) => (next) => async (action) => {
    const { dispatch, getState } = store
    switch (action.type) {
      case WebSocketActionType.CONNECT:
        dispatch({ type: WebSocketActionType.CONNECTING })
        if (!socket) {
          const accessToken = localStorage.getItem('access_token')
          const { isLogged } = getState().currentUser

          if (!accessToken || !isLogged) {
            return
          }
          socket = new WebSocket(
            `${process.env.REACT_APP_WS_URL}/v1/ws?token=${accessToken}`
          )
          socket.onopen = () => {
            dispatch({ type: WebSocketActionType.CONNECTED })
          }
          socket.onclose = () => {
            dispatch({ type: WebSocketActionType.DISCONNECTED })
          }
          socket.onmessage = (event) => {
            //console.log('Message received:', event.data)
          }
        }
        break
      case WebSocketActionType.DISCONNECTED:
        console.log('Disconnected from WebSocket')
        if (socket) {
          socket.close()
          socket = null
        }
        break
      case WebSocketActionType.SEND_QUERY:
        const { isConnected, isConnecting, queries } = getState().websocket
        const { queryID, query } = action.payload
        if (socket) {
          socket.send(JSON.stringify({ queryID, query }))
        } else if (!queries[queryID]) {
          // Ajouter la requête en attente à l'état temporaire
          pendingRequests[queryID] = { queryID, query }
        }

        break
      case 'WEBSOCKET_CONNECTED':
        // Lorsque le WebSocket est connecté, déplacer les requêtes en attente vers le slice
        _.map(pendingRequests, (request) => {
          store.dispatch(
            websocketSendQuery({
              queryID: request.queryID,
              query: request.query,
            })
          )
        })
        pendingRequests = {} // Vider les requêtes en attente
        break
      default:
        break
    }
    return next(action)
  }
}
