import { EntityId } from './id/entity-id'
import { EntityType } from './entity-type.models'
import { BaseData } from './base-data'
import { AliasID } from './id/alias-id'
import { DashboardId } from './id/dashboard-id'

export enum AliasFilterType {
  singleEntity = 'singleEntity',
  entityList = 'entityList',
  entityName = 'entityName',
  /*entityType = 'entityType',
  stateEntity = 'stateEntity',
  assetType = 'assetType',
  deviceType = 'deviceType',
  edgeType = 'edgeType',
  entityViewType = 'entityViewType',
  apiUsageState = 'apiUsageState',
  relationsQuery = 'relationsQuery',
  assetSearchQuery = 'assetSearchQuery',
  deviceSearchQuery = 'deviceSearchQuery',
  edgeSearchQuery = 'edgeSearchQuery',
  entityViewSearchQuery = 'entityViewSearchQuery'*/
}

export interface SingleEntityFilter {
  singleEntity?: EntityId
}
export interface EntityListFilter {
  entityList?: {
    ids?: string[]
    entityType?: EntityType
  }
}

export type AliasFilter = SingleEntityFilter & EntityListFilter

export interface EntityAliasFilter extends AliasFilter {
  type?: AliasFilterType
  resolveMultiple?: boolean
}

export interface Alias extends BaseData<AliasID> {
  name: string
  description: string
  config: EntityAliasFilter
  //config: any
  dashboardID: DashboardId
}

export interface Aliases {
  [id: string]: Alias
}
