import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { DataSource, Panel, PanelType } from '../../../../shared/models/panel'
import DataKeyForm from './DataKeyForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  index: number
  panelType: PanelType
  type: 'dataKeys' | 'latestDataKeys'
}

const DataKeysForm = ({ index: datasourceIndex, panelType, type }: Props) => {
  const form = useFormContext<{ dataSources: Partial<DataSource>[] }>()
  const isInitialRender = React.useRef(true)
  const { fields, append, remove, insert } = useFieldArray({
    control: form.control,
    name: `dataSources.${datasourceIndex}.${type}`,
  })
  React.useEffect(() => {
    if (!fields.length && !isInitialRender.current) {
      form.trigger(`dataSources.${datasourceIndex}.${type}`)
    }
    if (isInitialRender.current) {
      isInitialRender.current = false
    }
  }, [fields, form.register, form.setValue, form.unregister, form.trigger])

  return (
    <>
      <Table responsive hover bordered size={'sm'}>
        <thead className={'fs-10'}>
          <tr>
            <th scope="col">Key</th>
            <th scope="col">Label</th>
            <th scope="col">Color</th>
            <th scope="col">Units</th>
            <th scope="col">Decimal</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => {
            return (
              <tr key={field.id}>
                <DataKeyForm
                  dataKeyIndex={index}
                  dataSourcesIndex={datasourceIndex}
                  remove={remove}
                  panelType={panelType}
                  type={type}
                />
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6} className={'text-center'}>
              <Button
                type={'button'}
                size={'sm'}
                className={'float-end'}
                variant={'outline-primary'}
                onClick={() => {
                  append({
                    key: '',
                    label: '',
                    color: '',
                    units: '',
                    decimal: 0,
                  })
                  if (form.formState.errors.dataSources) {
                    form.clearErrors(`dataSources.${datasourceIndex}.${type}`)
                  }
                }}
              >
                <FontAwesomeIcon icon={'plus'} />
              </Button>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  )
}

export default DataKeysForm
