import React from 'react'
import { useNavigate } from 'react-router-dom'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import TenantProfileForm from './TenantProfileForm'
import { SubmitHandler, useForm } from 'react-hook-form'
import TenantProfile from '../../shared/models/tenant-profile'
import {
  useGetTenantProfileQuery,
  useUpdateTenantProfileMutation,
} from '../../store/kopiliot-api/tenant-profile'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import SectionTitle from '../../components/common/widgets/SectionTitle'
import ButtonLoading from '../../components/button/ButtonLoading'

type IdRouteParam = {
  id: string
}

const TenantProfileUpdate = () => {
  let navigate = useNavigate()
  let { id } = useParams<IdRouteParam>() as IdRouteParam
  // Fetch data
  const {
    data,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
  } = useGetTenantProfileQuery(id)
  // Methods for update
  const [updateTenantProfile, { isLoading: isLoadingMutation }] =
    useUpdateTenantProfileMutation()
  const isLoading = isLoadingQuery || isLoadingMutation || isFetchingQuery
  const form = useForm<TenantProfile>({ defaultValues: data })
  const onSubmit: SubmitHandler<TenantProfile> = async (
    data: TenantProfile
  ) => {
    try {
      const res = await updateTenantProfile(data).unwrap()
      navigate(`/profiles/tenant-profiles`)
      toast.success(`Tenant profile ${res.name} mis à jour !`)
      form.reset()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <>
      <SectionTitle
        title={'Editer un tenant profile'}
        icon={'address-card'}
        subTitle={`Editer le tenant profile ${data?.name}`}
        transform="shrink-2"
        className="mb-4 mt-2"
      />
      <ContentBlock>
        <ContentBlock.Header>
          <ButtonLoading
            isLoading={isLoading}
            type={'submit'}
            text={'Valider'}
            onClick={form.handleSubmit(onSubmit)}
          />
        </ContentBlock.Header>
      </ContentBlock>
      <TenantProfileForm
        form={form}
        onSubmit={onSubmit}
        initialValues={data}
        isLoading={isLoading}
      />
    </>
  )
}

export default TenantProfileUpdate
