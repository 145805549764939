import React from 'react'
import './canvasEditOrCreate.css'
import { Offcanvas } from 'react-bootstrap'
import { v4 as UUIDv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { setCurrentAction } from '../../../store/slices/dashboardSlice'
import PanelForm from '../panel/PanelForm'
import { Panel, PanelType } from '../../../shared/models/panel'
import { EntityType } from '../../../shared/models/entity-type.models'
import { Dashboard } from '../../../shared/models/dashboard.model'
import { Simulate } from 'react-dom/test-utils'
import copy = Simulate.copy
import _ from 'lodash'
import { getDefaultSettings } from '../utils'

const CanvasEditOrCreate = () => {
  const currentDashboard = useAppSelector((state) => state.dashboard)
  const [panel, setPanel] = React.useState<Panel | null>()
  React.useEffect(() => {
    if (currentDashboard.currentAction === 'create' && currentDashboard.id) {
      setPanel({
        id: { id: UUIDv4(), entityType: EntityType.PANEL },
        type: PanelType.TIME_SERIES_LINE,
        isNew: true,
        dashboardID: currentDashboard.id,
        title: 'New Panel',
        position: {
          positionX: 0,
          positionY: 0,
          positionW: 6,
          positionH: 6,
        },
        config: {
          dataSources: [],
          settings: getDefaultSettings(PanelType.TIME_SERIES_LINE),
        },
      })
    } else if (currentDashboard.currentAction === 'edit') {
      const panel = _.find(currentDashboard.panels, (panel: Panel) => {
        return panel.id?.id === currentDashboard.currentPanelEditID?.id
      })

      if (panel) {
        setPanel(panel)
      }
    }
  }, [currentDashboard.currentAction, currentDashboard.currentPanelEditID])
  const dispatch = useAppDispatch()
  const isOpen =
    currentDashboard.currentAction === 'create' ||
    currentDashboard.currentAction === 'edit'
  return (
    <Offcanvas
      className={'canvas-wide'}
      show={isOpen}
      placement={'end'}
      onHide={() => dispatch(setCurrentAction({ action: undefined }))}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Séléctionnez un widget</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className={''}>
        {panel && <PanelForm defaultValues={_.cloneDeep(panel)} />}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default CanvasEditOrCreate
