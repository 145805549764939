import React from 'react'
import { DataSource } from '../../../../../shared/models/panel'
import { TimeseriesData } from '../../../../../shared/models/telemetry/telemetry.model'
import { TimeWindow } from '../../../../../shared/models/time.model'
import { Aliases } from '../../../../../shared/models/alias.model'
import { ChartProps, Pie } from 'react-chartjs-2'
import _ from 'lodash'
import { useAppSelector } from '../../../../../store/store'
import { ChartData, ChartDataset } from 'chart.js'

interface PieChartProps {
  timeSeriesData?: {
    [key: string]: { datasource: DataSource; data: TimeseriesData }
  }
  timeWindow?: TimeWindow
  aliases: Aliases
  options?: ChartProps<'pie'>['options']
  specificOptions?: any
}

function findLabel(dataSource: DataSource, key: string) {
  const dataKey = dataSource.dataKeys.find((d) => d.key === key)
  if (dataKey) {
    return dataKey?.label ? dataKey?.label : dataKey?.key
  }
}

const findColor = (dataSource: DataSource, key: string) => {
  const dataKey = dataSource.dataKeys.find((d) => d.key === key)
  if (dataKey) {
    return dataKey?.color ? dataKey?.color : 'red'
  }
}

const TimeSeriesPieChart = ({
  timeSeriesData,
  timeWindow,
  aliases,
  options,
  specificOptions,
}: PieChartProps) => {
  const { theme } = useAppSelector((state) => state.settings)
  const [chartData, setChartData] = React.useState<ChartData<'pie', number[]>>({
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
  })

  React.useEffect(() => {
    if (timeSeriesData) {
      let labels: string[] = []
      let dataTemp: number[] = []
      let colorTemp: string[] = []
      _.forEach(timeSeriesData, ({ datasource, data }) => {
        _.forEach(datasource.dataKeys, ({ label, key, color }) => {
          labels = [...labels, label]
          colorTemp = [...colorTemp, color]
          if (data[key] != null) {
            dataTemp = [...dataTemp, _.sumBy(data[key], (o) => o.value)]
          } else {
            dataTemp = [...dataTemp, 0]
          }
        })
      })
      setChartData({
        labels,
        datasets: [
          {
            data: dataTemp,
            backgroundColor: colorTemp,
            borderColor: colorTemp,
            hoverOffset: 4,
          },
        ],
      })
    }
  }, [timeSeriesData])
  return (
    <Pie
      data={chartData}
      //className={'my-0 mx-auto'}
      options={{ responsive: true, maintainAspectRatio: false }}
    />
  )
}

export default TimeSeriesPieChart
