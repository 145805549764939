import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { GeneralSearchForm } from './SearchBox'

const RecentSearch = () => {
  const form = useFormContext<GeneralSearchForm>()
  // Get search state
  const { recentSearchTerms } = useSelector(
    (state: RootState) => state.recentSearch
  )
  return (
    <>
      <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
        Cherché récemment
      </Dropdown.Header>
      {recentSearchTerms.map((term, index) => (
        <Dropdown.Item
          key={index}
          className="fs--1 px-x1 py-1 hover-primary "
          onClick={() => {
            form.setValue('searchInputValue', term)
            form.setFocus('searchInputValue')
          }}
        >
          {term}
        </Dropdown.Item>
      ))}
    </>
  )
}

export default RecentSearch
