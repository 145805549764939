import React from 'react'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import SelectBase from '../../../components/page-components/selects-entities/generic-select/SelectBase'
import { useLazyGetDeviceProfilesQuery } from '../../../store/kopiliot-api/device-profile'
import { SelectableValue } from '../../../components/page-components/selects-entities/generic-select/selectable-value'
import { DeviceProfileId } from '../../../shared/models/id/device-profile-id'
import { DeviceQuery } from '../../../store/kopiliot-api/query-type'
import { useAppSelector } from '../../../store/store'
import TenantId from '../../../shared/models/id/tenant-id'
import { useLazyGetTenantsQuery } from '../../../store/kopiliot-api/tenant'
import Divider from '../../../components/common/Divider'
import { useLazyGetCustomersQuery } from '../../../store/kopiliot-api/customer'
import { CustomerId } from '../../../shared/models/id/customer-id'

interface Props {
  form: UseFormReturn<Omit<DeviceQuery, 'offset' | 'limit'>, any>
  onSubmit: (data: any) => void
  disabledFields?: Array<keyof Omit<DeviceQuery, 'offset' | 'limit'>>
}

const DeviceSearchForm = ({ form, onSubmit, disabledFields }: Props) => {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = form
  const { authority } = useAppSelector((state) => state.currentUser)
  // DEVICE PROFILES SECTION
  const [getDeviceProfiles, deviceProfilesResult] =
    useLazyGetDeviceProfilesQuery()
  const [optionsDeviceProfiles, setOptionsDeviceProfiles] = React.useState<
    SelectableValue<DeviceProfileId>[]
  >([])

  React.useEffect(() => {
    if (deviceProfilesResult.isSuccess) {
      const values: SelectableValue<DeviceProfileId>[] = []
      deviceProfilesResult.data?.rows.forEach((value) => {
        values.push({
          label: value.name,
          value: value.id,
        })
      })
      setOptionsDeviceProfiles(values)
    }
  }, [deviceProfilesResult])

  const onInputDeviceProfileChange = (inputValue: string) => {
    if (authority === 'MAIN_TENANT') {
      getDeviceProfiles({
        limit: 20,
        offset: 0,
        textSearch: inputValue,
      })
    }
  }
  // Customer SECTION
  const [getCustomers, customersResult] = useLazyGetCustomersQuery()
  const [optionsCustomers, setOptionsCustomers] = React.useState<
    SelectableValue<CustomerId>[]
  >([])
  React.useEffect(() => {
    if (customersResult.isSuccess) {
      const values: SelectableValue<TenantId>[] = []
      customersResult.data?.rows.forEach((value) => {
        values.push({
          label: value.name,
          value: value.id,
        })
      })
      setOptionsCustomers(values)
    }
  }, [customersResult])
  const onInputCustomerChange = (inputValue: string) => {
    if (authority === 'MAIN_TENANT' || authority === 'TENANT_ADMIN') {
      getCustomers({
        limit: 20,
        offset: 0,
        textSearch: inputValue,
      })
    }
  }
  // TENANT SECTION
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [optionsTenants, setOptionsTenants] = React.useState<
    SelectableValue<TenantId>[]
  >([])
  React.useEffect(() => {
    if (tenantsResult.isSuccess) {
      const values: SelectableValue<TenantId>[] = []
      tenantsResult.data?.rows.forEach((value) => {
        values.push({
          label: value.name,
          value: value.id,
        })
      })
      setOptionsTenants(values)
    }
  }, [tenantsResult])
  const onInputTenantChange = (inputValue: string) => {
    if (authority === 'MAIN_TENANT') {
      getTenants({
        limit: 20,
        offset: 0,
        textSearch: inputValue,
      })
    }
  }
  return (
    <FormProvider {...form}>
      <Form className={'px-4 py-3'} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className={''}>
          <Input
            size={'sm'}
            label={'Search text'}
            register={register('textSearch')}
            className={'w-auto'}
          />
        </Form.Group>

        <Form.Label>Device Profile</Form.Label>
        <Controller
          name={'deviceProfileID'}
          control={form.control}
          render={({ field }) => {
            return (
              <SelectBase
                options={optionsDeviceProfiles}
                onChange={(value: SelectableValue<DeviceProfileId>) => {
                  field.onChange(value.value)
                }}
                onInputChange={(inputValue) => {
                  onInputDeviceProfileChange(inputValue)
                }}
                value={field.value}
                valueComparator={(a, b) => {
                  return a.value?.id === b.value?.id
                }}
              />
            )
          }}
        />
        {disabledFields?.includes('customerID') ? null : (
          <>
            <Form.Label>Customer</Form.Label>
            <Controller
              name={'customerID'}
              control={form.control}
              render={({ field }) => {
                return (
                  <SelectBase
                    options={optionsCustomers}
                    onChange={(value: SelectableValue<CustomerId>) => {
                      field.onChange(value.value)
                    }}
                    onInputChange={(inputValue) => {
                      onInputCustomerChange(inputValue)
                    }}
                    value={field.value}
                    valueComparator={(a, b) => {
                      return a.value?.id === b.value?.id
                    }}
                  />
                )
              }}
            />
          </>
        )}
        {disabledFields?.includes('tenantID') ? null : (
          <>
            <Form.Label>Tenant</Form.Label>
            <Controller
              name={'tenantID'}
              control={form.control}
              render={({ field }) => {
                return (
                  <SelectBase
                    options={optionsTenants}
                    onChange={(value: SelectableValue<TenantId>) => {
                      field.onChange(value.value)
                    }}
                    onInputChange={(inputValue) => {
                      onInputTenantChange(inputValue)
                    }}
                    value={field.value}
                    valueComparator={(a, b) => {
                      return a.value?.id === b.value?.id
                    }}
                  />
                )
              }}
            />
          </>
        )}
        <Divider />
        <Button
          variant={'outline-primary'}
          type={'button'}
          size={'sm'}
          onClick={() => reset()}
        >
          Reset
        </Button>
      </Form>
    </FormProvider>
  )
}

export default DeviceSearchForm
