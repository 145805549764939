import React, { useEffect } from 'react'
import { EntityGroup } from '../../shared/models/entity-group.model'
import { useAddNewAssetMutation } from '../../store/kopiliot-api/asset'
import { useForm } from 'react-hook-form'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../components/button/ButtonLoading'
import EntityGroupForm from './EntityGroupForm'
import { useAddEntityGroupMutation } from '../../store/kopiliot-api/entity-groups'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  defaultValues?: Partial<EntityGroup>
  hideFields?: Array<keyof EntityGroup>
}

const EntityGroupAddModal = ({
  isOpen,
  setIsOpen,
  defaultValues,
  hideFields,
}: Props) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const form = useForm<EntityGroup>({ defaultValues: defaultValues })
  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])
  const [addEntityGroup, { isLoading, error }] = useAddEntityGroupMutation()
  const onSubmit = async (data: EntityGroup) => {
    const cmd = {
      name: data.name,
      description: data.description,
      ownerID: data.ownerID.id,
      ownerType: data.ownerID.entityType,
      entityType: data.entityType,
    }
    try {
      await addEntityGroup(cmd).unwrap()
      form.reset()
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'sm'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Créer un groupe</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <EntityGroupForm
          form={form}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
        {JSON.stringify(form.formState.defaultValues)}
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} variant={'secondary'} onClick={handleClose}>
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default EntityGroupAddModal
