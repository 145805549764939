import React from 'react'
import { DeviceFormsProps } from './DeviceFormsProps'
import { Col, Form, Row } from 'react-bootstrap'
import GenericSelect from '../../../components/page-components/selects-entities/GenericSelect'
import {
  useLazyGetCustomerQuery,
  useLazyGetCustomersQuery,
} from '../../../store/kopiliot-api/customer'
import { CustomerId } from '../../../shared/models/id/customer-id'
import { Controller, useFormContext } from 'react-hook-form'
import { CreateDeviceCommand } from '../../../store/kopiliot-api/device'
import { useCustomerSearch } from '../../../components/page-components/selects-entities/generic-select/hooks'
import SelectBase from '../../../components/page-components/selects-entities/generic-select/SelectBase'
import { SelectableValue } from '../../../components/page-components/selects-entities/generic-select/selectable-value'

const DeviceFormCustomer = () => {
  const { control, watch, formState } = useFormContext<CreateDeviceCommand>()
  const customerID = watch('customerID')
  const { customerOptions, setSearch, isLoading } =
    useCustomerSearch(customerID)
  return (
    <>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Form.Group as={Col} lg={6} md={12}>
          <Form.Label htmlFor={'customerID'}>Customer</Form.Label>
          <Controller
            control={control}
            name={'customerID'}
            render={({ field }) => {
              return (
                <SelectBase
                  isLoading={isLoading}
                  placeholder={'Rechercher...'}
                  options={customerOptions}
                  onInputChange={(value) => {
                    setSearch(value)
                  }}
                  isClearable={true}
                  debounceDelay={1000}
                  minCharsForSearch={2}
                  value={field.value ? { value: field.value } : undefined}
                  onChange={(value: SelectableValue<CustomerId>) => {
                    if (value) {
                      field.onChange(value?.value)
                    }
                  }}
                  valueComparator={(a, b) => {
                    return a?.value?.id === b?.value?.id
                  }}
                  errorMessage={formState.errors.customerID?.message}
                />
              )
            }}
          />
        </Form.Group>
      </Row>
    </>
  )
}

export default DeviceFormCustomer
