import React from 'react'
import {
  AuditLogsQuery,
  DeviceQuery,
} from '../../../store/kopiliot-api/query-type'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import SelectBase from '../../../components/page-components/selects-entities/generic-select/SelectBase'
import { ActionStatus } from '../../../shared/models/audit-log'
import { SelectableValue } from '../../../components/page-components/selects-entities/generic-select/selectable-value'

interface Props {
  form: UseFormReturn<AuditLogsQuery, any>
  onSubmit: (data: any) => void
  disabledFields?: Array<keyof AuditLogsQuery>
}

const AuditLogSearchForm = ({ form, onSubmit, disabledFields }: Props) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = form
  const optionsStatus = Object.keys(ActionStatus).map((key) => {
    return {
      label: key,
      value: key as ActionStatus,
    }
  })
  return (
    <FormProvider {...form}>
      <Form className={'px-4 py-3'} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className={''}>
          <Input
            size={'sm'}
            label={'Search text'}
            register={register('textSearch')}
            className={'w-auto'}
          />
        </Form.Group>
        <Form.Group className={''}>
          <Form.Label>Status</Form.Label>
          <Controller
            name={'actionStatus'}
            control={form.control}
            render={({ field }) => {
              return (
                <SelectBase
                  options={optionsStatus}
                  onChange={(value: SelectableValue<ActionStatus>) => {
                    field.onChange(value.value)
                  }}
                  value={field}
                  valueComparator={(a, b) => {
                    return a.value === b.value
                  }}
                />
              )
            }}
          />
        </Form.Group>
      </Form>
    </FormProvider>
  )
}

export default AuditLogSearchForm
