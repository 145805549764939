import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Relation } from '../../../shared/models/entity-relation'
import { useForm } from 'react-hook-form'
import RelationForm from '../forms/RelationForm'
import ButtonLoading from '../../../components/button/ButtonLoading'
import { useAddRelationMutation } from '../../../store/kopiliot-api/relation'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  isEditing?: boolean
  defaultValues: Partial<Relation>
  direction: 'to' | 'from'
}

const AddOrEditRelationModal = ({
  isOpen,
  setIsOpen,
  defaultValues,
  direction,
}: Props) => {
  React.useEffect(() => {
    if (isOpen) {
      form.reset(defaultValues)
    }
  }, [isOpen])
  const handleClose = () => {
    form.reset()
    setIsOpen(!isOpen)
  }
  const form = useForm<Relation>({ defaultValues })
  const [addRelation] = useAddRelationMutation()
  const onSubmit = async (data: Relation) => {
    try {
      alert(JSON.stringify(data))
      await addRelation(data).unwrap()
      //await addTenant(data).unwrap()
      // handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      className={'border'}
      centered
      scrollable
      size={'lg'}
    >
      <Modal.Header
        closeButton
        //closeVariant={isDark ? 'white' : undefined}
        className="border-bottom-0"
      >
        <Modal.Title as="h5">Ajouter une relation</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <RelationForm form={form} onSubmit={onSubmit} direction={direction} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          isLoading={false}
          disabled={false}
          variant={'outline-primary'}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddOrEditRelationModal
