import React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import {
  ComparisonResultType,
  SingleValueDataKeySpecificProps,
  ThresholdOperator,
} from '../../../../../shared/models/panel'
import { Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap'
import Input from '../../../../../components/form/Input'
import IconButton from '../../../../../components/common/IconButton'
import { AggregationType } from '../../../../../shared/models/time.model'
import Divider from '../../../../../components/common/Divider'

interface SingleValuePanelSpecificFormProps {
  initialValues?: SingleValueDataKeySpecificProps
  onSubmitted?: (data: any) => void
}

const SingleValueDataKeySpecificForm = ({
  initialValues,
  onSubmitted,
}: SingleValuePanelSpecificFormProps) => {
  const form = useFormContext<SingleValueDataKeySpecificProps>()
  const {
    register,
    formState: { errors },
  } = form
  const watchAggregationType = useWatch({
    control: form.control,
    name: 'strategy',
  })
  const watchEnableDelta = useWatch({
    control: form.control,
    name: 'enableDelta',
  })
  const { fields, append, remove, swap } =
    useFieldArray<SingleValueDataKeySpecificProps>({
      control: form.control,
      name: `thresholds`,
    })
  React.useEffect(() => {
    if (initialValues) {
      form.reset(initialValues)
    }
  }, [initialValues])
  return (
    <Form onSubmit={onSubmitted ? form.handleSubmit(onSubmitted) : undefined}>
      <Form.Group as={Col} md={4}>
        <Input
          label={"Text d'aide"}
          register={register('helperText')}
          type={'text'}
        />
      </Form.Group>
      <Form.Group as={Col} md={4}>
        <Input
          label={'Stratégie'}
          register={register('strategy')}
          type={'select'}
          options={[
            { value: AggregationType.NONE, text: 'Aucune' },
            { value: AggregationType.SUM, text: 'Somme' },
            { value: AggregationType.AVG, text: 'Moyenne' },
            { value: AggregationType.MIN, text: 'Minimum' },
            { value: AggregationType.MAX, text: 'Maximum' },
            { value: AggregationType.COUNT, text: 'Compte' },
          ]}
        />
      </Form.Group>
      <Divider />
      {watchAggregationType != AggregationType.NONE && (
        <div>
          <h5>Comparaison</h5>
          <Container className={'border border-1 pt-2 pb-2'}>
            <Form.Group as={Col} md={12}>
              <Input
                label={'Activer Delta'}
                register={register('enableDelta')}
                type={'switch'}
              />
            </Form.Group>
            {watchEnableDelta && (
              <>
                <Form.Group as={Col} md={12}>
                  <Input
                    label={'Période de comparaison'}
                    register={register('comparisonPeriod', {
                      shouldUnregister: true,
                    })}
                    type={'select'}
                    options={[
                      { value: 'latestPeriod', text: 'Période précédente' },
                    ]}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12}>
                  <Input
                    label={'Calcul Delta'}
                    register={register('deltaCalculation')}
                    type={'select'}
                    options={[
                      {
                        value: ComparisonResultType.PREVIOUS_VALUE,
                        text: 'Valeur précédente',
                      },
                      {
                        value: ComparisonResultType.DELTA_ABSOLUTE,
                        text: 'Delta (Absolu)',
                      },
                      {
                        value: ComparisonResultType.DELTA_PERCENT,
                        text: 'Delta (pourcentage)',
                      },
                    ]}
                  />
                </Form.Group>
              </>
            )}
          </Container>
        </div>
      )}

      <Form.Group>
        <Form.Label>Couleurs</Form.Label>
        {fields.map((field, index) => {
          return (
            <Row key={field.id}>
              <Form.Group as={Col} md={2}>
                <Form.Label>Opérateur</Form.Label>
                <Input
                  type={'select'}
                  error={form.formState.errors?.thresholds?.[index]?.operator}
                  options={[
                    {
                      value: ThresholdOperator.GREATER_THAN,
                      text: 'Supérieur à',
                    },
                    {
                      value: ThresholdOperator.GREATER_THAN_OR_EQUAL,
                      text: 'Supérieur ou égal à',
                    },
                    { value: ThresholdOperator.LESS_THAN, text: 'Inférieur à' },
                    {
                      value: ThresholdOperator.LESS_THAN_OR_EQUAL,
                      text: 'Inférieur ou égal à',
                    },
                    { value: ThresholdOperator.EQUAL, text: 'Egal à' },
                    {
                      value: ThresholdOperator.NOT_EQUAL,
                      text: 'Différent de',
                    },
                    { value: ThresholdOperator.IN, text: 'Dans' },
                    { value: ThresholdOperator.NOT_IN, text: 'Pas dans' },
                    { value: ThresholdOperator.CONTAINS, text: 'Contiens' },
                    {
                      value: ThresholdOperator.NOT_CONTAINS,
                      text: 'Ne contiens pas',
                    },
                    { value: ThresholdOperator.MATCHES, text: 'Matches' },
                    {
                      value: ThresholdOperator.NOT_MATCHES,
                      text: 'Ne matches pas',
                    },
                  ]}
                  register={form.register(`thresholds.${index}.operator`, {
                    required: 'La limite est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={3}>
                <Form.Label>Référence</Form.Label>
                <Input
                  type={'numeric'}
                  error={form.formState.errors?.thresholds?.[index]?.reference}
                  register={form.register(`thresholds.${index}.reference`, {
                    required: 'La limite est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={1}>
                <Form.Label>Color</Form.Label>
                <Input
                  type={'color'}
                  register={form.register(`thresholds.${index}.color`, {
                    required: 'La couleur est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label></Form.Label>
                <div>
                  <ButtonGroup>
                    <IconButton
                      variant={'outline-secondary'}
                      iconClassName="chevron-up"
                      type="button"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0) {
                          swap(index - 1, index)
                        }
                      }}
                    />
                    <IconButton
                      variant={'outline-secondary'}
                      iconClassName="chevron-down"
                      type="button"
                      disabled={index === fields.length - 1}
                      onClick={() => {
                        if (index < fields.length - 1) {
                          swap(index, index + 1)
                        }
                      }}
                    />
                    <Button
                      variant={'outline-danger'}
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Remove
                    </Button>
                  </ButtonGroup>
                </div>
              </Form.Group>
            </Row>
          )
        })}
        <div>
          <Button
            type="button"
            variant={'outline-primary'}
            onClick={() =>
              append({
                color: 'black',
                limit: 0,
              })
            }
          >
            Ajouter
          </Button>
        </div>
      </Form.Group>
    </Form>
  )
}

export default SingleValueDataKeySpecificForm
