import React, { useState } from 'react'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  useGetTenantQuery,
  useUpdateTenantMutation,
} from '../../store/kopiliot-api/tenant'
import Spinner from '../../components/spinner/Spinner'
import TenantForm from './form/TenantForm'
import TenantId from '../../shared/models/id/tenant-id'
import Flex from '../../components/common/Flex'
import Tenant from '../../shared/models/tenant'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import HubInfo from '../../features/tenants/HubInfo'
import TenantEditMenu from './TenantEditMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AuditLogHome from '../audit-log/AuditLogHome'
import AttributesList from '../../features/attributes/AttributesList'
import { CustomerId } from '../../shared/models/id/customer-id'

type IdRouteParam = {
  tenantID: string
}

const TenantEdit = () => {
  // Manage the current tab (default to 'main')
  const [key, setKey] = useState('main')
  // Manage navigation
  const [show, setShow] = useState(false)
  let navigate = useNavigate()
  let { tenantID } = useParams<IdRouteParam>() as IdRouteParam
  // Fetch tenant data
  const {
    data,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
  } = useGetTenantQuery(tenantID)
  const [updateTenant, { isLoading: isLoadingUpdate }] =
    useUpdateTenantMutation()
  const onSubmit = async (data: Tenant) => {
    try {
      const res = await updateTenant(data).unwrap()
      toast.success(`${res.name} updated successfully`)
      navigate(`/tenants`)
    } catch (error) {
      console.log(error)
    }
  }
  const form = useForm<Tenant>()
  return (
    <>
      {(isLoadingQuery || isFetchingQuery) && <Spinner />}
      <ContentBlock>
        <ContentBlock.Header title={data?.name}>
          <Flex justifyContent={'between'}>
            <Button
              variant={'outline-primary'}
              className={'me-2'}
              onClick={() => setShow(true)}
            >
              <FontAwesomeIcon icon={'bars'} />
            </Button>
            <TenantEditMenu
              id={tenantID}
              show={show}
              handleShow={() => setShow(true)}
              handleClose={() => setShow(false)}
            />
          </Flex>
        </ContentBlock.Header>
        <ContentBlock.Body noLight className={'ps-0 pe-0'}>
          <Tabs
            className="mt-3"
            transition={true}
            unmountOnExit={true}
            fill={true}
            activeKey={key}
            onSelect={(k) => setKey(k ? k : 'main')}
          >
            <Tab eventKey="main" title="Main" className="p-3">
              <TenantForm
                form={form}
                onSubmit={onSubmit}
                isLoading={isLoadingQuery}
                initialValues={data}
              />
            </Tab>
            <Tab eventKey="hub_info" title="Hub Info" className="p-3">
              {data && data.id && <HubInfo tenantID={data.id} />}
            </Tab>
            <Tab
              eventKey="attribute"
              title="Attributes"
              className="p-3 pe-0 ps-0"
            >
              {data?.id && <AttributesList entityID={data.id} />}
            </Tab>
            <Tab eventKey="relations" title="Relations" className="p-3">
              Relations
            </Tab>
            <Tab
              eventKey="audit_logs"
              title="Audit Logs"
              className="p-3 ps-0 pe-0"
            >
              {tenantID && <AuditLogHome entityID={new TenantId(tenantID)} />}
            </Tab>
          </Tabs>
        </ContentBlock.Body>
      </ContentBlock>
    </>
  )
}

export default TenantEdit
