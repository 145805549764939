import React from 'react'
import { EntityId } from '../../shared/models/id/entity-id'
import { useLazyGetLatestTimeSeriesQuery } from '../../store/kopiliot-api/time-series'
import _ from 'lodash'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { getColumnsForTableLatestTelemetries } from './utils'
import { Pagination } from '../../shared/models/others/pagination'
import {
  LatestTsValueAsArray,
  TsValue,
} from '../../shared/models/telemetry/telemetry.model'

interface LatestTelemetriesProps {
  entityID: EntityId
}

const LatestTelemetries = ({ entityID }: LatestTelemetriesProps) => {
  const [fetchData, { data, isLoading, isFetching, error }] =
    useLazyGetLatestTimeSeriesQuery()
  React.useEffect(() => {
    if (entityID) {
      fetchData({
        queryID: 1,
        entityID: entityID.id,
        entityType: entityID.entityType,
        keys: [],
      })
    }
  }, [entityID])
  const getData = (): Pagination<LatestTsValueAsArray> => {
    const page: Pagination<LatestTsValueAsArray> = {
      rows: [],
      totalRows: 0,
      limit: 0,
      offset: 0,
    }
    if (data) {
      const values = _.map(data, (value, key) => {
        return { key, value: value.value, ts: value.ts }
      })
      page.totalRows = values.length
      page.rows = values
    }
    return page
  }
  return (
    <>
      <KopiliotList
        title={'Dernières valeurs'}
        icon={'database'}
        isLoading={isLoading || isFetching}
        data={getData()}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'fs-10 mb-0 overflow-hidden',
          },
          hiddenColumns: ['id', 'entityId', 'actionFailureDetails', 'userID'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableLatestTelemetries([]),
        }}
        cardProps={{
          idAccessor: (item: LatestTsValueAsArray) => item.key,
          titleAccessor: (item: LatestTsValueAsArray) => item.key,
          descriptionAccessor: (item: LatestTsValueAsArray) => item.value,
          linkAccessor: (item: LatestTsValueAsArray) => `${item.key}`,
          actionsRow: [],
        }}
        paginationProps={{
          pageLimit: getData().totalRows,
          totalRecords: getData().totalRows,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            console.log(page)
          },
          onChangePageSize: (size: number) => {
            console.log(size)
          },
        }}
      />
    </>
  )
}

export default LatestTelemetries
