import React, { useEffect } from 'react'
import {
  useAddNewTenantMutation,
  useDeleteTenantMutation,
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../store/kopiliot-api/tenant'
import Tenant from '../../shared/models/tenant'
import Spinner from '../../components/spinner/Spinner'
import ArkModal from '../../components/modal/ArkModal'
import { useForm } from 'react-hook-form'
import AddTenantModal from '../../features/tenants/modal/AddTenantModal'
import { toast } from 'react-toastify'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import { useGenericReducer } from '../../hooks/generic-hook'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { defaultTenantActionsRow, getColumnsForTableTenant } from './utils'
import { useNavigate } from 'react-router-dom'
import TenantSearchForm from '../../features/tenants/form/TenantSearchForm'
import { PaginationWithSearchTerm } from '../../store/kopiliot-api/query-type'

const TenantList = () => {
  const navigate = useNavigate()
  // SearchForm
  const searchForm = useForm<PaginationWithSearchTerm>()
  const { textSearch } = searchForm.watch()
  // Pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    PaginationWithSearchTerm,
    PaginationActions
  >(reducerPage, {
    limit: 20,
    offset: 0,
    textSearch: '',
  })
  // Modal Delete
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  // Modal Edit Or Create
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  // Tenant form
  const form = useForm<Tenant>()
  // Selected tenant
  const [selectedTenant, setSelectedTenant] = React.useState<
    string | undefined
  >(undefined)
  useEffect(() => {
    if (selectedTenant) {
      triggerGetTenant(selectedTenant, true)
    }
  }, [selectedTenant])
  React.useEffect(() => {
    fetchTenants()
  }, [paginationState, textSearch])

  const onDelete = (id: string) => {
    setSelectedTenant(id)
    setShowModalDelete(true)
  }
  const onEdit = (id: string) => {
    navigate(`${id}`)
  }
  const onSubmitForm = async (data: Tenant) => {
    try {
      const res = await addTenant(data).unwrap()
      form.reset()
      toast.success(`Tenant ${res.name} créé avec succès`)
      setShowModalCreate(false)
    } catch (e) {
      console.log(e)
    }
  }

  const [getTenants, resultGetTenants] = useLazyGetTenantsQuery()
  // Lazy query
  const [triggerGetTenant, resultGetTenant] = useLazyGetTenantQuery()
  // Mutations (delete, create, update)
  const [deleteTenant, { isLoading: isDeleting }] = useDeleteTenantMutation()
  const [addTenant, { isLoading: isAdding }] = useAddNewTenantMutation()
  // Loading check
  const loadingCheck = isDeleting || isAdding
  const isLoadingData = loadingCheck || resultGetTenant.isLoading
  // Fetch Tenants
  const fetchTenants = () => {
    console.log(textSearch)
    getTenants({
      ...paginationState,
      textSearch,
    })
  }
  const getData = () => {
    return resultGetTenants.data
  }
  const actionsRow = defaultTenantActionsRow(onDelete, onEdit)
  return (
    <>
      <KopiliotList
        title={'Tenants'}
        icon={'microchip'}
        isLoading={isLoadingData}
        actionsProps={{
          filterForm: (
            <TenantSearchForm form={searchForm} onSubmit={() => {}} />
          ),
          onRefresh: () => {
            fetchTenants()
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          hiddenColumns: ['id'],
          rowClassName: 'default__table-row align-middle fs-10',
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableTenant(actionsRow),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: Tenant) => item.id!.id,
          titleAccessor: (item: Tenant) => item.name,
          descriptionAccessor: (item: Tenant) => item.description,
          linkAccessor: (item: Tenant) => `${item.id!.id}`,
          actionsRow: actionsRow,
        }}
      />
      <ArkModal
        title={'Supprimer un Tenant'}
        handleClose={() => setShowModalDelete(false)}
        isOpen={showModalDelete}
        validButton={{
          children: 'Supprimer',
          onClick: () => {
            if (selectedTenant) {
              deleteTenant(selectedTenant)
            }
            setShowModalDelete(false)
          },
          variant: 'danger',
        }}
      >
        {resultGetTenant.data && resultGetTenant.isLoading && <Spinner />}
        <p>
          {`Voulez-vous vraiement supprimer le tenant ${resultGetTenant.data?.name}`}{' '}
          ?
        </p>
      </ArkModal>
      <AddTenantModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        onSubmit={onSubmitForm}
        form={form}
      />
    </>
  )
}

export default TenantList
