import React, { useRef } from 'react'
import VisualRepeater, {
  VisualRepeaterRenderValueProps,
  VizOrientation,
} from '../VisualRepeater'
import { useResizeObserver } from 'usehooks-ts'
import {
  DataSource,
  SingleValueDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import {
  AggregatedTimeseriesData,
  LatestTimeseriesData,
  TimeseriesData,
} from '../../../../../shared/models/telemetry/telemetry.model'
import { TimeWindow } from '../../../../../shared/models/time.model'
import { Aliases } from '../../../../../shared/models/alias.model'
import _ from 'lodash'
import SingleValue, { SingleValuePanelProps } from './SingleValue'
import { Row } from 'react-bootstrap'

interface SingleValueListProps {
  timeSeriesData?: {
    [key: string]: {
      datasource: DataSource<SingleValueDataKeySpecificProps>
      data: TimeseriesData
    }
  }
  aggregateTimeSeriesData?: {
    [key: string]: {
      datasource: DataSource<SingleValueDataKeySpecificProps>
      data: AggregatedTimeseriesData
    }
  }
  latestTimeSeriesData?: {
    [key: string]: {
      datasource: DataSource<SingleValueDataKeySpecificProps>
      data: LatestTimeseriesData
    }
  }
  timeWindow?: TimeWindow
  aliases: Aliases
  options?: any
}

const SingleValueList = ({
  timeSeriesData,
  latestTimeSeriesData,
  aggregateTimeSeriesData,
  timeWindow,
  aliases,
  options,
}: SingleValueListProps) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({
    ref: elementRef,
  })
  const getLength = (): number => {
    const timeSeriesDataCount = _.chain(timeSeriesData)
      .map((t) => t.data)
      .map((d) => _.size(d))
      .sum()
      .value()
    const latestTimeSeriesDataCount = _.chain(latestTimeSeriesData)
      .map((t) => t.data)
      .map((d) => _.size(d))
      .sum()
      .value()
    const aggregateTimeSeriesDataCount = _.chain(aggregateTimeSeriesData)
      .map((t) => t.data)
      .map((d) => _.size(d))
      .sum()
      .value()
    return (
      timeSeriesDataCount +
      latestTimeSeriesDataCount +
      aggregateTimeSeriesDataCount
    )
  }
  const renderValue = (
    props: VisualRepeaterRenderValueProps<SingleValuePanelProps>
  ): JSX.Element => {
    if (!props.value) {
      return <div>No value provided</div>
    }
    return (
      <SingleValue
        key={`${props.width}-${props.height}`}
        itemWidth={props.width}
        value={props.value.value}
        config={props.value.config}
        title={props.value.title}
        unit={props.value.unit}
      />
    )
  }

  function getValues() {
    const values: SingleValuePanelProps[] = []
    _.forEach(latestTimeSeriesData, (datasource) => {
      _.forEach(datasource.data, (value, key) => {
        const dataKey = datasource.datasource.latestDataKeys?.find(
          (d) => d.key === key
        )
        const singleValue: SingleValuePanelProps = {
          value: value,
          title: dataKey?.label,
          unit: dataKey?.units,
          config: dataKey?.specificProps,
        }
        values.push(singleValue)
      })
    })
    _.forEach(aggregateTimeSeriesData, (datasource) => {
      _.forEach(datasource.data, (value, key) => {
        const dataKey = datasource.datasource.dataKeys.find(
          (d) => d.key === key
        )
        const singleValue: SingleValuePanelProps = {
          value: value,
          title: dataKey?.label,
          unit: dataKey?.units,
          config: dataKey?.specificProps,
        }
        values.push(singleValue)
      })
    })
    return values
  }

  return (
    <Row className={'h-100 g-0 my-0 mx-3'} ref={elementRef}>
      <div ref={elementRef}>
        <VisualRepeater
          width={width}
          height={height}
          renderValue={renderValue}
          length={getLength()}
          getValues={getValues}
        />
      </div>
    </Row>
  )
}

export default SingleValueList
