import TenantId from '../shared/models/id/tenant-id'
import { CustomerId } from '../shared/models/id/customer-id'
import { DeviceProfileId } from '../shared/models/id/device-profile-id'
import { AssetId } from '../shared/models/id/asset-id'
import { AssetProfileId } from '../shared/models/id/asset-profile-id'

export enum CommonActionType {
  UPDATE_TENANT_ID = 'UPDATE_TENANT_ID',
  UPDATE_CUSTOMER_ID = 'UPDATE_CUSTOMER_ID',
  UPDATE_DASHBOARD_ID = 'UPDATE_DASHBOARD_ID',
  UPDATE_DEVICE_PROFILE_ID = 'UPDATE_DEVICE_PROFILE_ID',
  UPDATE_ASSET_PROFILE_ID = 'UPDATE_ASSET_PROFILE_ID',
}

interface PayloadUpdateTenantID {
  tenantID?: TenantId
}

export type ActionUpdateTenantID = {
  type: CommonActionType.UPDATE_TENANT_ID
  payload: PayloadUpdateTenantID
}

interface PayloadUpdateDashboardID {
  dashboardID?: string
}

export type ActionUpdateDashboardID = {
  type: CommonActionType.UPDATE_DASHBOARD_ID
  payload: PayloadUpdateDashboardID
}

interface PayloadUpdateCustomerID {
  customerID?: CustomerId
}

export type ActionUpdateCustomerID = {
  type: CommonActionType.UPDATE_CUSTOMER_ID
  payload: PayloadUpdateCustomerID
}

interface PayloadUpdateDeviceProfileID {
  deviceProfileID?: DeviceProfileId
}

export type ActionUpdateDeviceProfileID = {
  type: CommonActionType.UPDATE_DEVICE_PROFILE_ID
  payload: PayloadUpdateDeviceProfileID
}

interface PayloadUpdateAssetProfileID {
  assetProfileID?: AssetProfileId
}

export type ActionUpdateAssetProfileID = {
  type: CommonActionType.UPDATE_ASSET_PROFILE_ID
  payload: PayloadUpdateAssetProfileID
}
