import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  DataKey,
  GaugeDataKeySpecificProps,
  LineAnnotation,
  PanelType,
  PieChartDataKeySpecificProps,
  SimpleIndicatorDataKeySpecificProps,
  SingleValueDataKeySpecificProps,
  TimeSeriesLineDataKeySpecificProps,
} from '../../../../shared/models/panel'
import { Modal } from 'react-bootstrap'
import GaugeDataKeySpecificForm from './specific/GaugeDataKeySpecificForm'
import SingleValueDataKeySpecificForm from './specific/SingleValueDataKeySpecificForm'
import { SingleValuePanelProps } from '../../panel/chart-helpers/single-value/SingleValue'
import JsonTableDataKeySpecificForm from './specific/JsonTableDataKeySpecificForm'
import PieChartDataKeySpecificForm from './specific/PieChartDataKeySpecificForm'
import SimpleIndicatorDataKeySpecificForm from './specific/SimpleIndicatorDataKeySpecificForm'
import TimeSeriesLineChartDataKeySpecificForm from './specific/TimeSeriesLineChartDataKeySpecificForm'

interface SpecificDataKeySettingsProps {
  isOpen: boolean
  onClosed?: () => void
  onSubmitted?: (data: DataKey['specificProps']) => void
  initialValues?: DataKey['specificProps']
  panelType: PanelType
}

const SpecificDataKeySettings = ({
  isOpen,
  onClosed,
  onSubmitted,
  initialValues,
  panelType,
}: SpecificDataKeySettingsProps) => {
  const form = useForm<
    | GaugeDataKeySpecificProps
    | SingleValuePanelProps
    | TimeSeriesLineDataKeySpecificProps<LineAnnotation>
    | PieChartDataKeySpecificProps
    | SimpleIndicatorDataKeySpecificProps
    | SingleValueDataKeySpecificProps
  >({
    defaultValues: initialValues || {},
  })

  const { trigger } = form

  const onSubmit = async () => {
    const result = await trigger()
    if (onSubmitted && result) {
      await form.handleSubmit(onSubmitted)()
    }
  }

  const renderSpecificForm = () => {
    switch (panelType) {
      case PanelType.GAUGE:
        return (
          <GaugeDataKeySpecificForm
            initialValues={initialValues}
            onSubmitted={onSubmit}
          />
        )
      case PanelType.SINGLE_VALUE:
        return (
          <SingleValueDataKeySpecificForm
            initialValues={initialValues}
            onSubmitted={onSubmit}
          />
        )
      case PanelType.SIMPLE_INDICATOR:
        return (
          <SimpleIndicatorDataKeySpecificForm
            initialValues={initialValues}
            onSubmitted={onSubmit}
          />
        )
      case PanelType.TIME_SERIES_LINE:
        return <TimeSeriesLineChartDataKeySpecificForm />
      case PanelType.JSON_TABLE:
        return (
          <JsonTableDataKeySpecificForm
            initialValues={initialValues}
            onSubmitted={onSubmit}
          />
        )
      case PanelType.TIME_SERIES_PIE:
        return (
          <PieChartDataKeySpecificForm
            onSubmitted={onSubmit}
            initialValues={initialValues}
          />
        )
      default:
        return null
    }
  }

  return (
    <Modal show={isOpen} size={'xl'} centered onHide={onClosed}>
      <Modal.Header closeButton>
        <Modal.Title>Specific Data Key Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...form}>{renderSpecificForm()}</FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-secondary"
          data-bs-dismiss="modal"
          onClick={onClosed}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          data-bs-dismiss="modal"
          onClick={onSubmit}
        >
          Save changes
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SpecificDataKeySettings
