import React, { useEffect } from 'react'
import { CheckFieldExistConfiguration } from '../filter/check-field-exist/CheckFieldExistForm'
import { Handle, Position } from 'reactflow'
import NodeTemplate from '../base/NodeTemplate'
import { BaseNodeProps } from '../base/types'

const RenameKeysNode = React.memo(
  ({ data, isConnectable }: BaseNodeProps<CheckFieldExistConfiguration>) => {
    const title = 'Rename Keys'
    useEffect(() => {}, [])
    return (
      <>
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
        />
        <NodeTemplate icon={'filter'} title={title} name={data.name} />
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          isConnectable={isConnectable}
        />
      </>
    )
  }
)

export default RenameKeysNode
