import { Outlet } from 'react-router-dom'
import React from 'react'

const Alarm = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default Alarm
