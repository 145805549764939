import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { DataKey } from '../../../../../../shared/models/panel'
import _ from 'lodash'
import dayjs from 'dayjs'

export interface TimeSeriesTableType {
  ts: number
  [key: string]: number
}

const getColumns = (keys?: DataKey[]) => {
  const columnHelper = createColumnHelper<TimeSeriesTableType>()
  const columns: ColumnDef<TimeSeriesTableType, any>[] = [
    columnHelper.accessor((row) => row.ts, {
      id: 'ts',
      cell: (info) =>
        info.getValue()
          ? dayjs(info.getValue()).format('DD-MM-YYYY HH:mm:ss')
          : '',
      header: () => 'Timestamp',
      enableSorting: true,
    }),
  ]
  _.forEach(keys, (key) => {
    columns.push(
      columnHelper.accessor((row) => row[key.key], {
        id: key.key,
        cell: (info) => info.getValue(),
        header: () => key.label,
        enableSorting: true,
      })
    )
  })

  return columns
}

export default getColumns
