import React from 'react'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import SelectBase from '../../../components/page-components/selects-entities/generic-select/SelectBase'
import { useLazyGetDeviceProfilesQuery } from '../../../store/kopiliot-api/device-profile'
import { SelectableValue } from '../../../components/page-components/selects-entities/generic-select/selectable-value'
import { DeviceProfileId } from '../../../shared/models/id/device-profile-id'
import {
  DashboardQuery,
  DeviceQuery,
} from '../../../store/kopiliot-api/query-type'
import { useAppSelector } from '../../../store/store'
import TenantId from '../../../shared/models/id/tenant-id'
import { useLazyGetTenantsQuery } from '../../../store/kopiliot-api/tenant'
import Divider from '../../../components/common/Divider'
import { DashboardId } from '../../../shared/models/id/dashboard-id'

interface Props {
  form: UseFormReturn<DashboardQuery, any>
  onSubmit: (data: any) => void
  disabledFields?: Array<keyof DashboardQuery>
}

const DashboardSearchForm = ({ form, onSubmit, disabledFields }: Props) => {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = form
  const { authority } = useAppSelector((state) => state.currentUser)
  // DEVICE PROFILES SECTION

  // TENANT SECTION
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [optionsTenants, setOptionsTenants] = React.useState<
    SelectableValue<TenantId>[]
  >([])
  React.useEffect(() => {
    if (tenantsResult.isSuccess) {
      const values: SelectableValue<TenantId>[] = []
      tenantsResult.data?.rows.forEach((value) => {
        values.push({
          label: value.name,
          value: value.id,
        })
      })
      setOptionsTenants(values)
    }
  }, [tenantsResult])
  const onInputTenantChange = (inputValue: string) => {
    if (authority === 'MAIN_TENANT') {
      getTenants({
        limit: 20,
        offset: 0,
        textSearch: inputValue,
      })
    }
  }
  return (
    <FormProvider {...form}>
      <Form className={'px-4 py-3'} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className={''}>
          <Input
            size={'sm'}
            label={'Search text'}
            register={register('textSearch')}
            className={'w-auto'}
          />
        </Form.Group>

        {disabledFields?.includes('tenantID') ? null : (
          <>
            <Form.Label>Tenant</Form.Label>
            <Controller
              name={'tenantID'}
              control={form.control}
              render={({ field }) => {
                return (
                  <SelectBase
                    options={optionsTenants}
                    onChange={(value: SelectableValue<DashboardId>) => {
                      field.onChange(value.value)
                    }}
                    onInputChange={(inputValue) => {
                      onInputTenantChange(inputValue)
                    }}
                    value={field.value}
                    valueComparator={(a, b) => {
                      return a.value?.id === b.value?.id
                    }}
                  />
                )
              }}
            />
          </>
        )}

        <Divider />
        <Button
          variant={'outline-primary'}
          type={'button'}
          size={'sm'}
          onClick={() => reset()}
        >
          Reset
        </Button>
      </Form>
    </FormProvider>
  )
}

export default DashboardSearchForm
