import React from 'react'
import { Panel } from '../../../../shared/models/panel'
import { useFormContext } from 'react-hook-form'
import Input from '../../../../components/form/Input'

const PointStyleForm = () => {
  const { register, watch, setValue } = useFormContext<Panel>()

  return (
    <>
      <Input
        register={register('config.settings.elements.point.radius', {
          shouldUnregister: true,
        })}
        type={'numeric'}
        label={'Radius'}
      />
      <Input
        register={register('config.settings.elements.point.hoverRadius', {
          shouldUnregister: true,
        })}
        type={'numeric'}
        label={'Hover radius'}
      />
      <Input
        register={register('config.settings.elements.point.hitRadius', {
          shouldUnregister: true,
        })}
        type={'numeric'}
        label={'Hit radius'}
      />
      <Input
        register={register('config.settings.elements.point.pointStyle', {
          shouldUnregister: true,
        })}
        type={'select'}
        label={'Point style'}
        options={[
          { value: 'false', text: 'Caché' },
          { value: 'circle', text: 'Circle' },
          { value: 'cross', text: 'Cross' },
          { value: 'crossRot', text: 'CrossRot' },
          { value: 'dash', text: 'Dash' },
          { value: 'line', text: 'Line' },
          { value: 'rect', text: 'Rect' },
          { value: 'rectRounded', text: 'RectRounded' },
          { value: 'rectRot', text: 'RectRot' },
          { value: 'star', text: 'Star' },
          { value: 'triangle', text: 'Triangle' },
        ]}
      />
      <Input
        register={register('config.settings.elements.point.rotation', {
          shouldUnregister: true,
        })}
        type={'number'}
        label={'Rotation'}
      />
    </>
  )
}

export default PointStyleForm
