import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainLayout from './MainLayout'
import Dashboard from '../dashboard/Dashboard'
import AssetList from '../asset/AssetList'
import Asset from '../asset/Asset'
import Tenant from '../tenant/Tenant'
import TenantList from '../tenant/TenantList'
import NotFound from '../error/NotFound'
import Login from '../authentication/Login'
import PasswordReset from '../authentication/PasswordReset'
import TenantProfileList from '../tenant-profile/TenantProfileList'
import Me from '../user/Me'
import Home from '../home/Home'
import DashboardList from '../dashboard/DashboardList'
import PrivateRoute from './PrivateRoute'
import TenantProfileUpdate from '../tenant-profile/TenantProfileUpdate'
import MeSettings from '../user/MeSettings'
import Customer from '../customer/Customer'
import CustomerList from '../customer/CustomerList'
import TenantEdit from '../tenant/TenantEdit'
import DeviceProfileList from '../device-profile/DeviceProfileList'
import AssetProfileList from '../asset-profile/AssetProfileList'
import RuleChain from '../rule-chain/RuleChain'
import RuleChainList from '../rule-chain/RuleChainList'
import RuleChainEdit from '../rule-chain/RuleChainEdit'
import DeviceProfile from '../device-profile/DeviceProfile'
import DeviceProfileDetails from '../device-profile/DeviceProfileDetails'
import Device from '../device/Device'
import DeviceList from '../device/DeviceList'
import CustomerEdit from '../customer/CustomerEdit'
import AssetEdit from '../asset/AssetEdit'
import DeviceEdit from '../device/DeviceEdit'
import User from '../user/User'
import UserList from '../user/UserList'
import ActivateAccount from '../authentication/ActivateAccount'
import RequireUser from '../../components/auth/RequireUser'
import EntityGroup from '../entity-group/EntityGroup'
import EntityGroupList from '../entity-group/EntityGroupList'
import { EntityType } from '../../shared/models/entity-type.models'
import DeviceHome from '../device/DeviceHome'
import AssetHome from '../asset/AssetHome'
import CustomerHome from '../customer/CustomerHome'
import AuditLog from '../audit-log/AuditLog'
import AuditLogHome from '../audit-log/AuditLogHome'
import AssetProfile from '../asset-profile/AssetProfile'
import AssetProfileDetails from '../asset-profile/AssetProfileDetails'
import DashboardHome from '../dashboard/DashboardHome'
import DashboardViewMain from '../dashboard/DashboardViewMain'
import UserHome from '../user/UserHome'
import RuleChainHome from '../rule-chain/RuleChainHome'
import Alarm from '../alarm/Alarm'
import AlarmHome from '../alarm/AlarmHome'
import AlarmList from '../alarm/AlarmList'
import UserEdit from '../user/UserEdit'
import HomeWrapper from '../home/HomeWrapper'
import Notification from '../notification/Notification'
import NotificationHome from '../notification/NotificationHome'
import NotificationInbox from '../notification/NotificationInbox'
import NotificationSent from '../notification/NotificationSent'
import NotificationReciptients from '../notification/NotificationReciptients'
import NotificationTemplate from '../notification/NotificationTemplate'
import NotificationRule from '../notification/NotificationRule'

const Layout = () => {
  return (
    <>
      <Routes>
        <Route path={'/auth'} element={<MainLayout />}>
          <Route path={'login'} element={<Login />} />
          <Route path={'forgot-password'} element={<PasswordReset />} />
          <Route path={'activate'} element={<ActivateAccount />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route element={<PrivateRoute />}>
            <Route path={'/'} element={<HomeWrapper />}>
              <Route index element={<Home />} />
            </Route>
            <Route path={'me'} element={<Me />} />
            <Route path={'me/settings'} element={<MeSettings />} />
            <Route
              path="profiles"
              element={
                <RequireUser allowedRoles={['MAIN_TENANT', 'TENANT_ADMIN']} />
              }
            >
              <Route path="">
                <Route
                  path="tenant-profiles"
                  element={<RequireUser allowedRoles={['MAIN_TENANT']} />}
                >
                  <Route index element={<TenantProfileList />} />
                </Route>

                <Route
                  path="tenant-profiles/:id"
                  element={<TenantProfileUpdate />}
                />
                <Route path="asset-profiles" element={<AssetProfile />}>
                  <Route index element={<AssetProfileList />} />
                  <Route
                    path={':assetProfileID'}
                    element={<AssetProfileDetails />}
                  />
                </Route>
                <Route path={'device-profiles'} element={<DeviceProfile />}>
                  <Route index element={<DeviceProfileList />} />
                  <Route
                    path={':deviceProfileId'}
                    element={<DeviceProfileDetails />}
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="tenants"
              element={<RequireUser allowedRoles={['MAIN_TENANT']} />}
            >
              <Route path={''} element={<Tenant />}>
                <Route index element={<TenantList />} />
                <Route path={':tenantID'} element={<TenantEdit />} />
                <Route path={':tenantID/customers'} element={<Customer />}>
                  <Route path={''} element={<CustomerHome />}>
                    <Route path={'all'} element={<CustomerList />} />
                    <Route
                      path={'groups'}
                      element={
                        <EntityGroupList entityType={EntityType.CUSTOMER} />
                      }
                    />
                  </Route>
                </Route>
                <Route path={':tenantID/dashboards'} element={<Dashboard />}>
                  <Route path={''} element={<DashboardHome />}>
                    <Route path={'all'} element={<DashboardList />} />
                    <Route
                      path={'all/:dashboardID'}
                      element={<DashboardViewMain />}
                    />
                    <Route
                      path={'groups'}
                      element={
                        <EntityGroupList entityType={EntityType.DASHBOARD} />
                      }
                    />
                  </Route>
                </Route>
                <Route path={':tenantID/assets'} element={<Asset />}>
                  <Route path={''} element={<AssetHome />}>
                    <Route path={'all'} element={<AssetList />} />
                    <Route
                      path={'groups'}
                      element={
                        <EntityGroupList entityType={EntityType.ASSET} />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path={':tenantID/assets/all/:assetID'}
                  element={<AssetEdit />}
                />
                <Route path={':tenantID/devices'} element={<Device />}>
                  <Route path={''} element={<DeviceHome />}>
                    <Route path={'all'} element={<DeviceList />} />
                    <Route
                      path={'groups'}
                      element={
                        <EntityGroupList entityType={EntityType.DEVICE} />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path={':tenantID/devices/all/:deviceID'}
                  element={<DeviceEdit />}
                />
                {/*RULE CHAIN BY TENANT*/}
                <Route path={':tenantID/rule-chains'} element={<RuleChain />}>
                  <Route path={''} element={<RuleChainHome />}>
                    <Route index element={<RuleChainList />} />
                  </Route>
                </Route>
                <Route
                  path={':tenantID/rule-chains/:ruleChainID'}
                  element={<RuleChainEdit />}
                />
                {/*USER BY TENANT*/}
                <Route path={':tenantID/users'} element={<User />}>
                  <Route path={''} element={<UserHome />}>
                    <Route path={'all'} element={<UserList />} />
                    <Route
                      path={'groups'}
                      element={<EntityGroupList entityType={EntityType.USER} />}
                    />
                  </Route>
                </Route>
                {/* DASHBOARD BY TENANT */}
              </Route>
            </Route>
            <Route
              path="customers"
              element={
                <RequireUser allowedRoles={['MAIN_TENANT', 'TENANT_ADMIN']} />
              }
            >
              <Route path={''} element={<Customer />}>
                <Route path={''} element={<CustomerHome />}>
                  <Route path={'all'} element={<CustomerList />} />
                  <Route
                    path="groups"
                    key={'customers-groups'}
                    element={<EntityGroup />}
                  >
                    <Route
                      index
                      key={'customer-groups-list'}
                      element={
                        <EntityGroupList entityType={EntityType.CUSTOMER} />
                      }
                    />
                  </Route>
                </Route>
                <Route path={'all/:customerID'} element={<CustomerEdit />} />
                {/*Asset By Customer*/}
                <Route path={':customerID/assets'} element={<Asset />}>
                  <Route path={''} element={<AssetHome />}>
                    <Route path={'all'} element={<AssetList />} />
                    <Route
                      path={'groups'}
                      element={
                        <EntityGroupList entityType={EntityType.ASSET} />
                      }
                    />
                    <Route path={'all/:assetID'} element={<AssetEdit />} />
                  </Route>
                </Route>
                {/*Device By Customer*/}
                <Route path={':customerID/devices'} element={<Asset />}>
                  <Route path={''} element={<DeviceHome />}>
                    <Route path={'all'} element={<DeviceList />} />
                    <Route
                      path={'groups'}
                      element={
                        <EntityGroupList entityType={EntityType.DEVICE} />
                      }
                    />
                    <Route path={'all/:deviceID'} element={<DeviceEdit />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="users" element={<User />}>
              <Route path={''} element={<UserHome />}>
                <Route path={'all'} element={<UserList />} />
                <Route
                  path="groups"
                  key={'users-groups'}
                  element={<EntityGroup />}
                >
                  <Route
                    index
                    key={'users-groups-by-tenant-list'}
                    element={<EntityGroupList entityType={EntityType.USER} />}
                  />
                </Route>
              </Route>
              <Route path={'all/:userID'} element={<UserEdit />} />
            </Route>
            <Route path="assets" element={<Asset />}>
              <Route path={''} element={<AssetHome />}>
                <Route path={'all'} element={<AssetList />} />
                <Route
                  path="groups"
                  key={'assets-groups'}
                  element={<EntityGroup />}
                >
                  <Route
                    index
                    key={'asset-groups-by-tenant-list'}
                    element={<EntityGroupList entityType={EntityType.ASSET} />}
                  />
                </Route>
              </Route>
              <Route path={'all/:assetID'} element={<AssetEdit />} />
            </Route>
            <Route path="devices" element={<Device />}>
              <Route path={''} element={<DeviceHome />}>
                <Route path={'all'} element={<DeviceList />} />
                <Route
                  path="groups"
                  key={'device-groups'}
                  element={<EntityGroup />}
                >
                  <Route
                    index
                    key={'device-groups-by-tenant-list'}
                    element={<EntityGroupList entityType={EntityType.DEVICE} />}
                  />
                </Route>
              </Route>
              <Route path={'all/:deviceID'} element={<DeviceEdit />} />
            </Route>
            <Route path="rule-chains" element={<RuleChain />}>
              <Route path={''} element={<RuleChainHome />}>
                <Route index element={<RuleChainList />} />
              </Route>
              <Route path={':ruleChainID'} element={<RuleChainEdit />} />
            </Route>
            <Route path={'notification-center'} element={<Notification />}>
              <Route path={''} element={<NotificationHome />}>
                <Route path={'inbox'} element={<NotificationInbox />} />
                <Route path={'sent'} element={<NotificationSent />} />
                <Route
                  path={'recipients'}
                  element={<NotificationReciptients />}
                />
                <Route path={'templates'} element={<NotificationTemplate />} />
                <Route path={'rules'} element={<NotificationRule />} />
              </Route>
            </Route>
            <Route path="dashboards" element={<Dashboard />}>
              <Route path={''} element={<DashboardHome />}>
                <Route path={'all'} element={<DashboardList />} />
                <Route
                  path="groups"
                  key={'dashboard-groups'}
                  element={<EntityGroup />}
                >
                  <Route
                    index
                    key={'dashboard-groups-by-tenant-list'}
                    element={
                      <EntityGroupList entityType={EntityType.DASHBOARD} />
                    }
                  />
                </Route>
              </Route>
              <Route
                path={'all/:dashboardID'}
                element={<DashboardViewMain />}
              />
            </Route>
            <Route path={'alarms'} element={<Alarm />}>
              <Route path={''} element={<AlarmHome />}>
                <Route index element={<AlarmList />} />
              </Route>
            </Route>
            <Route path="security">
              <Route path={'audit-logs'} element={<AuditLog />}>
                <Route index element={<AuditLogHome />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default Layout
