import React from 'react'
import { Panel } from '../../../../shared/models/panel'
import { useFormContext } from 'react-hook-form'
import Input from '../../../../components/form/Input'

const ResponsiveForm = () => {
  const { register, getValues, watch, setValue } = useFormContext<Panel>()

  return (
    <>
      <Input
        register={register('config.settings.responsive', {
          shouldUnregister: true,
        })}
        type={'switch'}
        label={'Responsive'}
      />
      <Input
        register={register('config.settings.maintainAspectRatio', {
          shouldUnregister: true,
        })}
        type={'switch'}
        label={'Maintenir le ratio'}
      />
    </>
  )
}

export default ResponsiveForm
