import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Layout from './pages/_layout/Layout'
import useToggleStyle from './utils/useToggleStyle'
import { useSelector } from 'react-redux'
import { RootState } from './store/store'
// CSS
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { toast, ToastContainer } from 'react-toastify'
import _ from 'lodash'

const matchForHtmlClass: { [key: string]: string } = {
  Win: 'windows',
  Chrome: 'chrome',
  Firefox: 'firefox',
  Safari: 'safari',
}

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  useEffect(() => {
    const { userAgent } = window.navigator
    _.map(matchForHtmlClass, (classToAdd, check) => {
      if (userAgent.indexOf(check) > -1) {
        HTMLClassList.add(classToAdd)
      }
    })
  }, [HTMLClassList])
  const { theme } = useSelector((state: RootState) => state.settings)
  let isDark = theme === 'dark'
  useToggleStyle(isDark)

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Layout />
      <ToastContainer theme={'colored'} stacked position={'bottom-right'} />
    </BrowserRouter>
  )
}

export default App
