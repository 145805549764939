import React from 'react'
import { Handle, Position } from 'reactflow'
import NodeTemplate from '../base/NodeTemplate'
import { BaseNodeProps } from '../base/types'

const InputNode = React.memo(({ data, isConnectable }: BaseNodeProps<any>) => {
  return (
    <>
      <NodeTemplate icon={'arrow-right-to-bracket'} title={'Input'} />
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
      />
    </>
  )
})

export default InputNode
