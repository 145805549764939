import React, { useEffect } from 'react'
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'
import User, { UserCreate } from '../../../shared/models/user'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import GenericSelect from '../../../components/page-components/selects-entities/GenericSelect'
import {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useLazyGetCustomerQuery,
  useLazyGetCustomersQuery,
} from '../../../store/kopiliot-api/customer'
import TenantId from '../../../shared/models/id/tenant-id'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../../store/kopiliot-api/tenant'

interface UserFormProps {
  form: UseFormReturn<UserCreate>
  onSubmit: SubmitHandler<UserCreate>
  initialValues?: Partial<UserCreate>
  isLoading?: boolean
  disabledField: Array<keyof UserCreate>
}

const UserForm = ({
  form,
  onSubmit,
  initialValues,
  isLoading,
  disabledField,
}: UserFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = form
  const isDisabled = (field: keyof UserCreate) => {
    return disabledField.includes(field)
  }
  const currentUser = useSelector((state: RootState) => state.currentUser)
  // Init value if needed
  useEffect(() => {
    reset(initialValues)
  }, [initialValues])
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              register={register('firstname', {
                required: 'Firstname is required',
              })}
              label={'Firstname'}
              error={errors.firstname}
              isDisabled={isLoading}
            />
          </Col>
          <Col md={'6'}>
            <Input
              register={register('lastname', {
                required: 'Lastname is required',
              })}
              label={'Lastname'}
              error={errors.lastname}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              register={register('jobTitle')}
              label={'Job title'}
              error={errors.jobTitle}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              register={register('phone')}
              type={'phone'}
              label={'Phone'}
              error={errors.email}
              isDisabled={isLoading}
            />
          </Col>
          <Col md={'6'}>
            <Input
              register={register('email', { required: 'Email is required' })}
              type={'email'}
              label={'Email'}
              error={errors.email}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            {currentUser.authority === 'MAIN_TENANT' && (
              <GenericSelect
                label={'Customer'}
                listQuery={useLazyGetCustomersQuery}
                getQuery={useLazyGetCustomerQuery}
                register={register('customerId')}
                error={errors.customerId ? errors.customerId.id : undefined}
                valueConstructor={(value) => new TenantId(value)}
              />
            )}
          </Col>
          <Col md={'6'}>
            {currentUser.authority === 'MAIN_TENANT' && (
              <GenericSelect
                label={'Customer'}
                listQuery={useLazyGetTenantsQuery}
                getQuery={useLazyGetTenantQuery}
                register={register('tenantID')}
                error={errors.tenantID ? errors.tenantID.id : undefined}
                valueConstructor={(value) => new TenantId(value)}
              />
            )}
          </Col>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default UserForm
