import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useParams } from 'react-router-dom'
import {
  useGetUserCredentialsInfoQuery,
  useGetUserQuery,
} from '../../store/kopiliot-api/user'
import Spinner from '../../components/spinner/Spinner'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import { Tab, Tabs } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Authority, UserUpdate } from '../../shared/models/user'
import UserUpdateForm from '../../features/users/form/UserUpdateForm'
import UserSessions from './profile/UserSessions'
import Credentials from '../../features/users/Credentials'
import AuditLogHome from '../audit-log/AuditLogHome'

type IdRouteParam = {
  userID: string
}

const UserEdit = () => {
  const currentUser = useSelector((state: RootState) => state.currentUser)
  let { userID } = useParams<IdRouteParam>() as IdRouteParam
  const user = useGetUserQuery(userID)
  const userCredentials = useGetUserCredentialsInfoQuery(userID)
  const [key, setKey] = React.useState('main')
  const form = useForm<UserUpdate>()
  React.useEffect(() => {
    if (user.data) {
      form.reset(user.data)
    }
  }, [user.data])

  function onSubmit(data: UserUpdate) {
    console.log('submit')
  }

  return (
    <>
      {(user.isLoading ||
        user.isFetching ||
        userCredentials.isLoading ||
        userCredentials.isFetching) && <Spinner />}
      <ContentBlock>
        <ContentBlock.Header
          title={`${user.data?.firstname} ${user.data?.lastname}`}
          icon={'users'}
        />
        <ContentBlock.Body className={'p-0'} noLight={false}>
          <Tabs
            transition={true}
            unmountOnExit={true}
            fill={false}
            activeKey={key}
            onSelect={(k) => setKey(k ? k : 'main')}
          >
            <Tab eventKey="main" title="Main" className="p-3">
              <UserUpdateForm
                form={form}
                onSubmit={onSubmit}
                disabledField={[]}
              />
            </Tab>
            <Tab eventKey="sessions" title="Sessions" className="p-3">
              {user.data && <UserSessions user={user.data} />}
            </Tab>
            {currentUser.authority === Authority.MAIN_TENANT && (
              <Tab
                title={'Credentials'}
                eventKey={'credentials'}
                className="p-3"
              >
                <Credentials userID={userID} />
              </Tab>
            )}
            <Tab eventKey="audit-logs" title="Audit logs" className="p-3">
              {user.data?.id && <AuditLogHome entityID={user.data.id} />}
            </Tab>
          </Tabs>
        </ContentBlock.Body>
      </ContentBlock>
    </>
  )
}

export default UserEdit
