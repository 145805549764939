import { ApiUsage } from '../../shared/models/api-usage'
import { baseArkhaleAPI } from './base-api'

const apiUsageApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getApiUsage: build.query<ApiUsage, void>({
      query: () => ({
        url: 'api-usage',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetApiUsageQuery, useLazyGetApiUsageQuery } = apiUsageApi
