import React, { useRef } from 'react'
import {
  DataSource,
  SimpleIndicatorDataKeySpecificProps,
  SingleValueDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import {
  AggregatedTimeseriesData,
  LatestTimeseriesData,
} from '../../../../../shared/models/telemetry/telemetry.model'
import { TimeWindow } from '../../../../../shared/models/time.model'
import { Aliases } from '../../../../../shared/models/alias.model'
import _ from 'lodash'
import VisualRepeater, {
  VisualRepeaterRenderValueProps,
} from '../VisualRepeater'
import { Row } from 'react-bootstrap'
import { useResizeObserver } from 'usehooks-ts'
import SimpleIndicator, { CustomSimpleIndicatorProps } from './SimpleIndicator'

interface SimpleIndicatorListProps {
  aggregateTimeSeriesData?: {
    [key: string]: {
      datasource: DataSource<SimpleIndicatorDataKeySpecificProps>
      data: AggregatedTimeseriesData
    }
  }
  latestTimeSeriesData?: {
    [key: string]: {
      datasource: DataSource<SimpleIndicatorDataKeySpecificProps>
      data: LatestTimeseriesData
    }
  }
  timeWindow?: TimeWindow
  aliases: Aliases
  options?: any
}

const SimpleIndicatorList = ({
  latestTimeSeriesData,
  aggregateTimeSeriesData,
  timeWindow,
  aliases,
  options,
}: SimpleIndicatorListProps) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({
    ref: elementRef,
  })
  const getLength = (): number => {
    const latestTimeSeriesDataCount = _.chain(latestTimeSeriesData)
      .map((t) => t.data)
      .map((d) => _.size(d))
      .sum()
      .value()
    const aggregateTimeSeriesDataCount = _.chain(aggregateTimeSeriesData)
      .map((t) => t.data)
      .map((d) => _.size(d))
      .sum()
      .value()
    return latestTimeSeriesDataCount + aggregateTimeSeriesDataCount
  }
  const getValues = (): CustomSimpleIndicatorProps[] => {
    const values: CustomSimpleIndicatorProps[] = []
    _.forEach(latestTimeSeriesData, (data) => {
      _.forEach(data.data, (v, key) => {
        const ds = data.datasource.latestDataKeys?.find((d) => d.key === key)
        const simpleIndicatorValue: CustomSimpleIndicatorProps = {
          value: v,
          config: ds?.specificProps,
          label: ds?.label,
        }
        values.push(simpleIndicatorValue)
      })
    })
    _.forEach(aggregateTimeSeriesData, (data) => {
      _.forEach(data.data, (value, key) => {
        //values.push({ value: value })
      })
    })
    return values
  }
  const renderValue = (
    props: VisualRepeaterRenderValueProps<CustomSimpleIndicatorProps>
  ): JSX.Element => {
    if (!props.value) {
      return <div>No value provided</div>
    }
    return (
      <SimpleIndicator
        key={`${props.width}-${props.height}`}
        value={props.value.value}
        config={props.value.config}
        label={props.value.label}
      />
    )
  }
  return (
    <Row className={'h-100 g-0 my-0 mx-3'} ref={elementRef}>
      <div ref={elementRef}>
        <VisualRepeater
          width={width}
          height={height}
          renderValue={renderValue}
          length={getLength()}
          getValues={getValues}
        />
      </div>
    </Row>
  )
}

export default SimpleIndicatorList
