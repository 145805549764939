import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import { DeviceProfile } from '../../shared/models/device-profile'
import { PaginationWithSearchTerm } from './query-type'

const deviceProfileApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getDeviceProfiles: build.query<
      Pagination<DeviceProfile>,
      PaginationWithSearchTerm
    >({
      query: (args) =>
        `device-profiles?textSearch=${args.textSearch}&limit=${args.limit}&offset=${args.offset}`,
      providesTags: (result = new Pagination<DeviceProfile>(), error, arg) => [
        ...result.rows.map((item: DeviceProfile) => {
          return {
            type: 'DeviceProfile' as const,
            id: item.id?.id,
          }
        }),
        { type: 'DeviceProfile', id: 'LIST' },
      ],
    }),
    getDeviceProfile: build.query<DeviceProfile, string>({
      query: (deviceProfileId) => `device-profiles/${deviceProfileId}`,
      providesTags: (result, error, arg) => [
        { type: 'DeviceProfile', id: arg },
      ],
    }),
    addDeviceProfile: build.mutation<DeviceProfile, DeviceProfile>({
      query: (deviceProfile) => ({
        url: 'device-profiles',
        method: 'POST',
        body: deviceProfile,
      }),
    }),
    updateDeviceProfile: build.mutation<DeviceProfile, DeviceProfile>({
      query: (deviceProfile) => ({
        url: `device-profiles/${deviceProfile.id?.id}`,
        method: 'PUT',
        body: deviceProfile,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'DeviceProfile', id: arg.id?.id },
      ],
    }),
    deleteDeviceProfile: build.mutation<DeviceProfile, string>({
      query: (deviceProfileId) => ({
        url: `device-profiles/${deviceProfileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'DeviceProfile', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetDeviceProfilesQuery,
  useLazyGetDeviceProfilesQuery,
  useGetDeviceProfileQuery,
  useLazyGetDeviceProfileQuery,
  useAddDeviceProfileMutation,
  useUpdateDeviceProfileMutation,
  useDeleteDeviceProfileMutation,
} = deviceProfileApi
