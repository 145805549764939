import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit'
import { TypedActionCreator } from '@reduxjs/toolkit/dist/mapBuilders'
import { v4 as uuidv4 } from 'uuid'

const WEBSOCKET_SLICE_NAME = 'websocket'

export enum WebSocketActionType {
  CONNECT = 'WEBSOCKET_CONNECT',
  CONNECTED = 'WEBSOCKET_CONNECTED',
  DISCONNECTED = 'WEBSOCKET_DISCONNECTED',
  CONNECTING = 'WEBSOCKET_CONNECTING',
  RECONNECTING = 'WEBSOCKET_RECONNECTING',
  SEND_QUERY = 'WEBSOCKET_SEND_QUERY',
}

export const websocketConnect = createAction(WebSocketActionType.CONNECT)
export const websocketConnected = createAction(WebSocketActionType.CONNECTED)
export const websocketDisconnected = createAction(
  WebSocketActionType.DISCONNECTED
)
export const websocketConnecting = createAction(WebSocketActionType.CONNECTING)
export const websocketReconnecting = createAction(
  WebSocketActionType.RECONNECTING
)
export interface SendQueryPayload {
  queryID: string
  query: string
}
export const websocketSendQuery = createAction<PrepareAction<SendQueryPayload>>(
  WebSocketActionType.SEND_QUERY,
  (payload) => ({
    payload: {
      queryID: payload.queryID,
      query: payload.query,
    },
  })
)

interface WebsocketState {
  isConnected: boolean
  isConnecting: boolean
  isReconnecting: boolean
  error: string | null
  queries: Record<string, string>
}

const initialState: WebsocketState = {
  isConnected: false,
  isConnecting: false,
  isReconnecting: false,
  error: null,
  queries: {},
}

const websocketSlice = createSlice({
  name: WEBSOCKET_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(websocketConnected, (state) => {
        state.isConnected = true
        state.isConnecting = false
        state.isReconnecting = false
      })
      .addCase(websocketDisconnected, (state) => {
        state.isConnected = false
        state.isConnecting = false
        state.isReconnecting = false
      })
      .addCase(websocketConnecting, (state) => {
        state.isConnecting = true
        state.isReconnecting = false
      })
      .addCase(websocketReconnecting, (state) => {
        state.isConnecting = false
        state.isReconnecting = true
      })
      .addCase(websocketSendQuery, (state, action) => {
        state.queries[action.payload.queryID] = action.payload.query
      })
  },
})

export const websocketActions = websocketSlice.actions

export const websocketReducer = websocketSlice.reducer
