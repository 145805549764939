import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import Spinner from '../../components/spinner/Spinner'
import { Tab, Tabs } from 'react-bootstrap'
import { DeviceProfileId } from '../../shared/models/id/device-profile-id'
import { useForm } from 'react-hook-form'
import { DeviceProfile } from '../../shared/models/device-profile'
import DeviceProfileForm from '../../features/device-profile/form/DeviceProfileForm'
import ButtonLoading from '../../components/button/ButtonLoading'
import { toast } from 'react-toastify'
import Divider from '../../components/common/Divider'
import {
  useGetAssetProfileQuery,
  useUpdateAssetProfileMutation,
} from '../../store/kopiliot-api/asset-profile'
import { AssetProfile } from '../../shared/models/asset-profile'
import AssetProfileForm from '../../features/asset-profile/form/AssetProfileForm'
import AuditLogHome from '../audit-log/AuditLogHome'

// Define Request type
type RequestAssetProfileDetails = {
  assetProfileID: string
}

const AssetProfileDetails = () => {
  const { assetProfileID } =
    useParams<RequestAssetProfileDetails>() as RequestAssetProfileDetails
  const { data, isLoading, isFetching, error } =
    useGetAssetProfileQuery(assetProfileID)
  const [updateDeviceProfile, { isLoading: isUpdating }] =
    useUpdateAssetProfileMutation()
  const [key, setKey] = useState('main')
  const assetProfileForm = useForm<AssetProfile>({ defaultValues: data })
  useEffect(() => {
    assetProfileForm.reset(data)
  }, [data])
  const onSubmit = async (data: AssetProfile) => {
    try {
      const res = await updateDeviceProfile(data).unwrap()
      assetProfileForm.reset()
      toast.success(`Asset profile ${res.name} mis à jour avec succès !`)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {(isLoading || isFetching) && <Spinner />}
      <ContentBlock>
        <ContentBlock.Header title={data?.name} />
        <ContentBlock.Body noLight={false} className={'pe-0 ps-0'}>
          <Tabs
            transition={true}
            unmountOnExit={true}
            fill={false}
            activeKey={key}
            onSelect={(k) => setKey(k ? k : 'main')}
          >
            <Tab eventKey={'main'} title={'Details'} className="p-3">
              <AssetProfileForm
                form={assetProfileForm}
                onSubmit={onSubmit}
                isEditing
                hideFields={['tenantId']}
              />
              <Divider />
              <ButtonLoading
                text={'Enregistrer'}
                variant={'outline-primary'}
                isLoading={assetProfileForm.formState.isSubmitting}
                onClick={assetProfileForm.handleSubmit(onSubmit)}
              />
            </Tab>

            <Tab
              eventKey="audit_logs"
              title="Audit Logs"
              className="p-0 pe-0 ps-0"
            >
              <AuditLogHome
                entityID={new DeviceProfileId(assetProfileID)}
                hiddenColumns={['id', 'entityId']}
              />
            </Tab>
          </Tabs>
        </ContentBlock.Body>
      </ContentBlock>
    </>
  )
}

export default AssetProfileDetails
