import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import GeneralForm from './forms/GeneralForm'
import { useForm, useWatch } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import {
  addPanel,
  setCurrentAction,
  updatePanel,
} from '../../../store/slices/dashboardSlice'
import PanelBody from './panel-body/PanelBody'
import Flex from '../../../components/common/Flex'
import PanelFormHeader from './PanelFormHeader'
import Divider from '../../../components/common/Divider'
import DataSourceForms from '../form/datasource/DataSourceForms'
import { DataSource, Panel, PanelType } from '../../../shared/models/panel'
import PanelTimeWindowForm from './forms/PanelTimeWindowForm'
import Input from '../../../components/form/Input'
import {
  defaultTimeWindow,
  TimeWindow,
} from '../../../shared/models/time.model'
import { TimeseriesData } from '../../../shared/models/telemetry/telemetry.model'

interface Props {
  defaultValues?: Panel
}

const PanelForm = ({ defaultValues }: Props) => {
  const form = useForm<Panel>({ defaultValues })
  const { currentAction } = useAppSelector((state) => state.dashboard)
  const watchAll = form.watch()
  const useCustomTimeWindow = useWatch({
    control: form.control,
    name: 'config.useCustomTimeWindow',
    defaultValue: false,
  })
  const dispatch = useAppDispatch()
  const onSubmit = (data: any) => {
    if (currentAction === 'edit') {
      dispatch(updatePanel({ panel: data }))
    } else {
      dispatch(addPanel({ panel: data }))
    }
    dispatch(setCurrentAction({ action: undefined, panelID: undefined }))
  }
  const onSubmitDataSources = (dataSources: DataSource[]) => {
    form.setValue('config.dataSources', dataSources)
  }
  React.useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  return (
    <>
      <Container fluid className={'h-100 d-flex flex-column p-0'}>
        <Row className={'flex-shrink-0'}>
          <div className={'d-flex justify-content-between'}>
            <div className={'d-flex flex-row'}>
              <Input
                label={'Utiliser une plage définit'}
                register={form.register('config.useCustomTimeWindow')}
                type={'switch'}
                className={'me-2'}
              />
              {useCustomTimeWindow && (
                <PanelTimeWindowForm
                  onSubmit={(data: TimeWindow) => {
                    form.setValue('config.timeWindow', data)
                  }}
                  timeWindow={
                    watchAll.config.timeWindow
                      ? watchAll.config.timeWindow
                      : defaultTimeWindow()
                  }
                />
              )}
            </div>

            <PanelFormHeader onClickSubmit={form.handleSubmit(onSubmit)} />
          </div>
          <Divider />
        </Row>
        <Row className={'flex-grow-1'} style={{ minHeight: 0 }}>
          <Col md={8} className={'mh-100 overflow-scroll'}>
            <Flex direction={'column'}>
              <div style={{ height: '400px' }}>
                {watchAll && <PanelBody panel={watchAll} />}
              </div>
              <div>
                <DataSourceForms
                  panelType={watchAll.type}
                  onSubmitDataSources={onSubmitDataSources}
                  initialValues={form.getValues('config.dataSources')}
                />
              </div>
            </Flex>
          </Col>
          <Col md={4} className={'mh-100 overflow-scroll'}>
            <GeneralForm form={form} onSubmit={onSubmit} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PanelForm
