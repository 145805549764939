import React from 'react'
import { Nav } from 'react-bootstrap'
//css
import './listGroupNav.css'
import { Link, NavLink } from 'react-router-dom'
import Flex from '../../common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {}

const ListGroupNav = ({}: Props) => {
  return (
    <Nav
      variant={'underline'}
      defaultActiveKey={'home'}
      className={'list-group-nav fw-semi-bold'}
    >
      <Nav.Item>
        <NavLink to={'all'} className={'nav-link px-4 py-3'}>
          <Flex alignItems="center" justifyContent={'center'}>
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={'microchip'} />
            </span>
            <span className="nav-link-text ps-1">Tous</span>
          </Flex>
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className={`nav-link px-4 py-3`} to={'groups'}>
          <Flex alignItems="center" justifyContent={'center'}>
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={'object-ungroup'} />
            </span>
            <span className="nav-link-text ps-1">Groupes</span>
          </Flex>
        </NavLink>
      </Nav.Item>
    </Nav>
  )
}

export default ListGroupNav
