import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Alias } from '../../../../shared/models/alias.model'
import { Col, FormGroup, Row } from 'react-bootstrap'
import SelectEntity from '../../../../components/form/SelectEntity'
import { EntityType } from '../../../../shared/models/entity-type.models'
import {
  useLazyGetCustomerQuery,
  useLazyGetCustomersQuery,
} from '../../../../store/kopiliot-api/customer'
import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../../../store/kopiliot-api/tenant'
import { useLazyGetUsersQuery } from '../../../../store/kopiliot-api/user'
import {
  useLazyGetDeviceQuery,
  useLazyGetDevicesQuery,
} from '../../../../store/kopiliot-api/device'
import {
  useLazyGetAssetQuery,
  useLazyGetAssetsQuery,
} from '../../../../store/kopiliot-api/asset'
import _, { values } from 'lodash'

const SingleEntity = () => {
  const form = useFormContext<Alias>()

  const watchEntityType = form.watch('config.singleEntity.entityType')

  const [selectedValue, setSelectedValue] = React.useState<string | null>(
    form.getValues('config.singleEntity.id')
      ? form.getValues('config.singleEntity.id')
      : null
  )
  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([])
  // All Lazy Queries List
  const [getCustomers, customersResult] = useLazyGetCustomersQuery()
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [getAssets, assetsResult] = useLazyGetAssetsQuery()
  const [getUsers, usersResult] = useLazyGetUsersQuery()
  const [getDevices, devicesResult] = useLazyGetDevicesQuery()

  // All single Lazy Queries
  const [getCustomer, customerResult] = useLazyGetCustomerQuery()
  const [getTenant, tenantResult] = useLazyGetTenantQuery()
  const [getAsset, assetResult] = useLazyGetAssetQuery()
  //const [getUser, userResult] = useLazyGetUserQuery()
  const [getDevice, deviceResult] = useLazyGetDeviceQuery()
  // HOOKS
  React.useEffect(() => {
    if (selectedValue) {
      const query = getSingleQuery()
      if (selectedValue && query) {
        query(selectedValue, false)
      }
    }
  }, [selectedValue])

  React.useEffect(() => {
    setSelectedValue(
      form.getValues('config.singleEntity.id')
        ? form.getValues('config.singleEntity.id')
        : null
    )
  }, [form])

  React.useEffect(() => {
    if (watchEntityType) {
      switch (watchEntityType) {
        case EntityType.CUSTOMER:
          if (customerResult && customerResult.data?.id) {
            //form.setValue('config.singleEntity.id', customerResult.data?.id?.id)
            const addCurrentOption = _.find(options, (option) => {
              return option.value === customerResult.data?.id?.id
            })
            if (!addCurrentOption) {
              setOptions([
                ...options,
                {
                  label: customerResult.data.name,
                  value: customerResult.data.id.id,
                },
              ])
            }
          }
          break
        case EntityType.TENANT:
          if (tenantResult && tenantResult.data?.id) {
            const addCurrentOption = _.find(options, (option) => {
              return option.value === tenantResult.data?.id?.id
            })
            if (!addCurrentOption) {
              setOptions([
                ...options,
                {
                  label: tenantResult.data.name,
                  value: tenantResult.data.id.id,
                },
              ])
            }
          }
          break
        case EntityType.ASSET:
          if (assetResult && assetResult.data?.id) {
            const addCurrentOption = _.find(options, (option) => {
              return option.value === assetResult.data?.id?.id
            })
            if (!addCurrentOption) {
              setOptions([
                ...options,
                {
                  label: assetResult.data.name,
                  value: assetResult.data.id.id,
                },
              ])
            }
          }
          break
        case EntityType.DEVICE:
          if (deviceResult && deviceResult.data?.id) {
            const addCurrentOption = _.find(options, (option) => {
              return option.value === deviceResult.data?.id?.id
            })
            if (!addCurrentOption) {
              setOptions([
                ...options,
                {
                  label: deviceResult.data.name,
                  value: deviceResult.data.id.id,
                },
              ])
            }
          }
          break
      }
    }
  }, [customerResult, tenantResult, assetResult, deviceResult])

  React.useEffect(() => {
    setOptions([...getOptions()])
  }, [customersResult, tenantsResult, assetsResult, devicesResult])

  const getListQuery = () => {
    switch (watchEntityType) {
      case EntityType.CUSTOMER:
        return getCustomers
      case EntityType.TENANT:
        return getTenants
      case EntityType.ASSET:
        return getAssets
      case EntityType.USER:
        return getUsers
      case EntityType.DEVICE:
        return getDevices
    }
    return null
  }
  const getOptions = () => {
    let options: any[] = []
    switch (watchEntityType) {
      case EntityType.CUSTOMER:
        const customersOptions = _.map(
          customersResult.data?.rows,
          (customer) => {
            return {
              label: customer.name,
              value: customer.id?.id,
            }
          }
        )
        options.push(...customersOptions)
        break
      case EntityType.TENANT:
        const tenantsOptions = _.map(tenantsResult.data?.rows, (tenant) => {
          return {
            label: tenant.name,
            value: tenant.id?.id,
          }
        })
        options.push(...tenantsOptions)
        break
      case EntityType.ASSET:
        const assetsOptions = _.map(assetsResult.data?.rows, (asset) => {
          return {
            label: asset.name,
            value: asset.id?.id,
          }
        })
        options.push(...assetsOptions)
        break
      case EntityType.DEVICE:
        const devicesOptions = _.map(devicesResult.data?.rows, (device) => {
          return {
            label: device.name,
            value: device.id?.id,
          }
        }).values()
        options.push(...devicesOptions)
        break
    }
    return options
  }
  const getSingleQuery = () => {
    switch (watchEntityType) {
      case EntityType.CUSTOMER:
        return getCustomer
      case EntityType.TENANT:
        return getTenant
      case EntityType.ASSET:
        return getAsset
      case EntityType.USER:
      //return getUser
      case EntityType.DEVICE:
        return getDevice
    }
    return null
  }

  const getSingleResult = () => {
    switch (watchEntityType) {
      case EntityType.CUSTOMER:
        if (customerResult && customerResult.data?.id) {
          return {
            label: customerResult.data?.name,
            value: customerResult.data?.id?.id,
          }
        }
        return null
      case EntityType.TENANT:
        if (tenantResult && tenantResult.data?.id) {
          return {
            label: tenantResult.data?.name,
            value: tenantResult.data?.id?.id,
          }
        }
        return null
      case EntityType.ASSET:
        if (assetResult && assetResult.data?.id) {
          return {
            label: assetResult.data?.name,
            value: assetResult.data?.id?.id,
          }
        }
        return null
      case EntityType.DEVICE:
        if (deviceResult && deviceResult.data?.id) {
          return {
            label: deviceResult.data?.name,
            value: deviceResult.data?.id?.id,
          }
        }
        return null
    }
    return null
  }

  return (
    <>
      <Row>
        <FormGroup as={Col} lg={12}>
          <SelectEntity
            options={options}
            labelEntityType={'Entity'}
            entityTypeField={'config.singleEntity.entityType'}
            entityIdField={'config.singleEntity.id'}
            allowClear={true}
            isMultiple={false}
            allowedEntityTypes={[
              EntityType.DEVICE,
              EntityType.ASSET,
              EntityType.TENANT,
              EntityType.CUSTOMER,
              EntityType.USER,
              EntityType.DASHBOARD,
            ]}
            onChangeSelectID={(value) => {
              setSelectedValue(value.value)
            }}
            onChangeSearchTerm={(searchTerm) => {
              const query = getListQuery()
              if (query) {
                query(
                  { page: 0, pageSize: 40, textSearch: searchTerm } as any,
                  false
                )
              }
            }}
          />
        </FormGroup>
      </Row>
    </>
  )
}

export default SingleEntity
