export enum AttributeScope {
  SHARED_SCOPE = 'SHARED_SCOPE',
  SERVER_SCOPE = 'SERVER_SCOPE',
  CLIENT_SCOPE = 'CLIENT_SCOPE',
}

export enum DataType {
  STRING = 'STRING',
  LONG = 'LONG',
  DOUBLE = 'DOUBLE',
  BOOLEAN = 'BOOLEAN',
  JSON = 'JSON',
}

export interface Attribute {
  // PK
  entityId: string
  entityType: string
  scope: AttributeScope
  key: string
  // Value
  value: string | number | boolean | object
  lastUpdateDate: number
}
