import React, { CSSProperties } from 'react'
import { calculateGridDimensions } from './utils'

export enum VizOrientation {
  Auto,
  Horizontal,
  Vertical,
}
export interface VisualRepeaterRenderValueProps<V, D = {}> {
  value?: V
  width: number
  height: number
  orientation: VizOrientation
  /**
   * Total number of values being shown in repeater
   */
  count: number
}

interface VisualRepeaterProps<VALUE> {
  width: number
  height: number
  itemSpacing?: number
  length?: number
  orientation?: VizOrientation
  renderValue: (
    props: VisualRepeaterRenderValueProps<VALUE, any>
  ) => JSX.Element
  getValues: () => VALUE[]
}

const VisualRepeater = <VALUE,>({
  width,
  height,
  renderValue,
  orientation = VizOrientation.Auto,
  itemSpacing = 8,
  length = 4,
  getValues,
}: VisualRepeaterProps<VALUE>) => {
  const [values, setValues] = React.useState<VALUE[]>([])
  React.useEffect(() => {
    setValues(getValues())
  }, [getValues])
  const getOrientation = (): VizOrientation => {
    if (orientation === VizOrientation.Auto) {
      if (width > height) {
        return VizOrientation.Vertical
      } else {
        return VizOrientation.Horizontal
      }
    }

    return orientation
  }

  function renderGrid() {
    // Calculate grid dimensions
    const grid = calculateGridDimensions(width, height, itemSpacing, length)
    let xGrid = 0
    let yGrid = 0
    let items: JSX.Element[] = []
    for (let i = 0; i < length; i++) {
      //const value = values[i]
      const isLastRow = yGrid === grid.yCount - 1

      const itemWidth = isLastRow ? grid.widthOnLastRow : grid.width
      const itemHeight = grid.height

      const xPos = xGrid * itemWidth + itemSpacing * xGrid
      const yPos = yGrid * itemHeight + itemSpacing * yGrid

      const itemStyles: CSSProperties = {
        position: 'absolute',
        left: xPos,
        top: yPos,
        width: `${itemWidth}px`,
        height: `${itemHeight}px`,
      }

      items.push(
        <div key={i} style={itemStyles}>
          {renderValue({
            width: itemWidth,
            height: itemHeight,
            orientation,
            count: length,
            value: values[i],
          })}
        </div>
      )

      xGrid++

      if (xGrid === grid.xCount) {
        xGrid = 0
        yGrid++
      }
    }

    return <div style={{ position: 'relative' }}>{items}</div>
  }

  if (orientation === VizOrientation.Auto) {
    return renderGrid()
  }
  return null
}

export default VisualRepeater
