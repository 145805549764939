import React from 'react'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Relation, RelationType } from '../../../shared/models/entity-relation'
import { Col, Form, Row } from 'react-bootstrap'
import Select, { SingleValue } from 'react-select'
import _ from 'lodash'
import { EntityType } from '../../../shared/models/entity-type.models'
import SelectEntity from '../../../components/form/SelectEntity'
import {
  useLazyGetAssetQuery,
  useLazyGetAssetsQuery,
} from '../../../store/kopiliot-api/asset'
import {
  useLazyGetDeviceQuery,
  useLazyGetDevicesQuery,
} from '../../../store/kopiliot-api/device'

interface Props {
  form: UseFormReturn<Relation>
  onSubmit: (data: Relation) => void
  direction: 'to' | 'from'
}

const RelationForm = ({ direction, form, onSubmit }: Props) => {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(
    form.getValues(direction === 'to' ? 'from.id' : 'to.id')
      ? form.getValues(direction === 'to' ? 'from.id' : 'to.id')
      : null
  )
  // All Lazy Queries List
  const [getAssets, assetsResult] = useLazyGetAssetsQuery()
  const [getDevices, devicesResult] = useLazyGetDevicesQuery()
  // All single Lazy Queries
  const [getAsset, assetResult] = useLazyGetAssetQuery()
  const [getDevice, deviceResult] = useLazyGetDeviceQuery()
  const { handleSubmit } = form
  // Watch Entity Type
  const watchEntityType = form.watch(
    direction === 'to' ? `from.entityType` : `to.entityType`
  )
  const optionsType = [
    { label: 'Contiens', value: RelationType.CONTAIN },
    { label: 'Gère', value: RelationType.MANAGE },
  ]
  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([])
  // HOOKS
  React.useEffect(() => {
    if (selectedValue) {
      const query = getSingleQuery()
      if (selectedValue && query) {
        query(selectedValue, false)
      }
    }
  }, [selectedValue])

  React.useEffect(() => {
    console.log('options', getOptions())
    setOptions([...getOptions()])
  }, [assetsResult, devicesResult])

  const getOptions = () => {
    let options: any[] = []
    switch (watchEntityType) {
      case EntityType.ASSET:
        const assetsOptions = _.map(assetsResult.data?.rows, (asset) => {
          return {
            label: asset.name,
            value: asset.id?.id,
          }
        })
        options.push(...assetsOptions)
        break
      case EntityType.DEVICE:
        const devicesOptions = _.map(devicesResult.data?.rows, (device) => {
          return {
            label: device.name,
            value: device.id?.id,
          }
        }).values()
        options.push(...devicesOptions)
        break
    }
    return options
  }
  const getListQuery = () => {
    switch (watchEntityType) {
      case EntityType.ASSET:
        return getAssets
      case EntityType.DEVICE:
        return getDevices
    }
    return null
  }
  const getSingleQuery = () => {
    switch (watchEntityType) {
      case EntityType.ASSET:
        return getAsset
      case EntityType.DEVICE:
        return getDevice
    }
    return null
  }
  const renderSelectRelatedEntity = () => {
    if (direction === 'from') {
      if (!form.getValues().from?.entityType) return null

      return (
        <SelectEntity
          entityTypeField={'to.entityType'}
          entityIdField={'to.id'}
          options={options}
          isMultiple={false}
          allowedEntityTypes={[EntityType.DEVICE, EntityType.ASSET]}
          onChangeSelectID={(value) => {
            setSelectedValue(value.value)
          }}
          onChangeSearchTerm={(searchTerm) => {
            const query = getListQuery()
            if (query) {
              query(
                { page: 0, pageSize: 40, textSearch: searchTerm } as any,
                false
              )
            }
          }}
        />
      )
    } else {
      if (!form.getValues().to?.entityType) return null

      return (
        <SelectEntity
          options={options}
          entityTypeField={'from.entityType'}
          entityIdField={'from.id'}
          isMultiple={false}
          allowedEntityTypes={[EntityType.DEVICE, EntityType.ASSET]}
          onChangeSelectID={(value) => {
            setSelectedValue(value.value)
          }}
          onChangeSearchTerm={(searchTerm) => {
            const query = getListQuery()
            if (query) {
              query(
                { page: 0, pageSize: 40, textSearch: searchTerm } as any,
                false
              )
            }
          }}
        />
      )
    }
  }
  return (
    <FormProvider {...form}>
      <Form
        noValidate
        onSubmit={() => {
          handleSubmit((data) => onSubmit(data))
        }}
      >
        <Row>
          <Form.Group as={Col} md={12} className="mb-3">
            <Form.Label htmlFor={'relationType'}>Type de relation</Form.Label>
            <Controller
              control={form.control}
              name={'relationType'}
              render={({ field: { onChange } }) => {
                return (
                  <Select
                    classNamePrefix={'react-select'}
                    isSearchable={false}
                    defaultValue={_.filter(options, (obj): boolean => {
                      return form.getValues().relationType === obj.value
                    })}
                    onChange={(
                      selectedOption: SingleValue<typeof options[0]>
                    ) => {
                      if (!selectedOption) return
                      onChange(selectedOption.value)
                    }}
                    options={optionsType}
                    {..._.omit(form.register('relationType'), 'onChange')}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12} className="mb-3">
            <Form.Label htmlFor={'relationType'}>Entité</Form.Label>
            {renderSelectRelatedEntity()}
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default RelationForm
