import React from 'react'
import ContentBlock from '../../../components/common/component-card/ContentBlock'
import { Button, Form } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import { FormProvider, useForm } from 'react-hook-form'
import { ChangePasswordCurrentUser } from '../../../shared/models/others/change-password'
import { toast } from 'react-toastify'
import { useUpdatePasswordMutation } from '../../../store/kopiliot-api/me'

const ChangePassword = () => {
  const form = useForm<ChangePasswordCurrentUser>({})
  const [changePassword, {}] = useUpdatePasswordMutation()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = form
  const submitPasswordChange = async (data: ChangePasswordCurrentUser) => {
    try {
      const result = await changePassword(data).unwrap()
      toast.success(result.message)
      form.reset()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <ContentBlock>
      <ContentBlock.Header title={'Changer le mot de passe'} />
      <ContentBlock.Body>
        <FormProvider {...form}>
          <Form noValidate onSubmit={handleSubmit(submitPasswordChange)}>
            <Form.Group className="mb-3">
              <Input
                register={register('currentPassword', {
                  required: 'Please provide your current password',
                })}
                error={errors['currentPassword']}
                type={'password'}
                label={'Current password'}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input
                register={register('newPassword', {
                  required: 'Please provide your new password',
                  validate: (value) => {
                    const regex =
                      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[\W_]).{12,}$/gm
                    if (!regex.test(value))
                      return 'Your password does not satisfy the policy '
                  },
                })}
                error={errors['newPassword']}
                type={'password'}
                label={'New password'}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input
                register={register('newPasswordConfirm', {
                  required: 'Please confirm your new password',
                  validate: (value) => {
                    if (watch('newPassword') !== value) {
                      return 'Your passwords do not match'
                    }
                  },
                })}
                type={'password'}
                error={errors['newPasswordConfirm']}
                label={'Confirm New Password'}
              />
            </Form.Group>
            <Button className="w-100" type="submit">
              Update Password
            </Button>
          </Form>
        </FormProvider>
      </ContentBlock.Body>
    </ContentBlock>
  )
}

export default ChangePassword
