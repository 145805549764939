import React from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../components/button/ButtonLoading'
import Tenant from '../../../shared/models/tenant'
import TenantForm from '../form/TenantForm'

interface AddTenantModalProps {
  size?: 'sm' | 'lg' | 'xl'
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  onSubmit: SubmitHandler<Tenant>
  form: UseFormReturn<Tenant>
  isLoading?: boolean
}

const AddTenantModal = ({
  size = 'xl',
  isOpen,
  setIsOpen,
  onSubmit,
  form,
  isLoading,
}: AddTenantModalProps) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={size}
      className={'border'}
      centered
      scrollable
    >
      <Modal.Header
        closeButton
        //closeVariant={isDark ? 'white' : undefined}
        className="border-bottom-0"
      >
        <Modal.Title as="h5">Créer un Tenant</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-3'}>
        <TenantForm form={form} onSubmit={onSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          variant={'outline-primary'}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddTenantModal
