import React from 'react'
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  MarkerType,
} from 'reactflow'
import _ from 'lodash'
import { useAppSelector } from '../../../store/store'

const KopiliotDefault = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  selected,
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const { theme } = useAppSelector((state) => state.settings)
  const getColor = () => {
    if (theme === 'dark') {
      return selected ? 'rgb(230, 55, 87)' : '#f1efef'
    }
    return selected ? 'rgb(230, 55, 87)' : '#212121'
  }
  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          stroke: getColor(),
          strokeWidth: 2,
        }}
      />
      <EdgeLabelRenderer>
        <div
          onMouseOver={() => {
            console.log('hover')
          }}
          onMouseLeave={() => {
            console.log('leave')
          }}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            border: selected
              ? '2px solid rgb(29, 112, 183)'
              : '1px solid rgb(29, 112, 183)',
            padding: 7,
            backgroundColor: 'white',
            color: 'rgb(29, 112, 183)',
            borderRadius: 5,
            fontSize: 10,
            fontWeight: 600,
          }}
          className="nodrag nopan"
        >
          {data.conditions.join(' & ') || ''}
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export default KopiliotDefault
