import React from 'react'
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
} from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../components/common/Flex'
import TileLink from '../../components/link/TileLink'

interface Props {
  show: boolean
  handleClose: () => void
  handleShow: () => void
  id: string
}
const CustomerEditMenu = ({ show, handleShow, handleClose, id }: Props) => {
  const expand = false
  return (
    <Offcanvas show={show} onHide={handleClose} placement={'end'}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className={'text-primary'}>
          Gérer les ressources
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container fluid className={'px-0'}>
          <Row className={'mb-3'}>
            <Col md={6} className={''}>
              <TileLink
                icon={'box-open'}
                link={`/customers/${id}/assets/all`}
                text={'Assets'}
              />
            </Col>
            <Col md={6} className={''}>
              <TileLink
                icon={'microchip'}
                link={`/customers/${id}/devices/all`}
                text={'Devices'}
              />
            </Col>
          </Row>
          <Row className={'mb-3'}>
            <Col md={6} className={''}>
              <TileLink
                icon={'users'}
                link={`/customers/${id}/users/all`}
                text={'Utilisateurs'}
              />
            </Col>
          </Row>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default CustomerEditMenu
