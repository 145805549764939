import React, { useState } from 'react'

import Select, { InputActionMeta } from 'react-select'
import { default as ReactAsyncSelect } from 'react-select/async'
import { default as AsyncCreatable } from 'react-select/async-creatable'
import { SelectBaseProps, SelectValue } from './types'
import _ from 'lodash'
import { SelectableValue } from './selectable-value'
import { Form } from 'react-bootstrap'
import { FieldErrors } from 'react-hook-form'
import classNames from 'classnames'

const SelectBase = <T,>(
  {
    fieldName,
    allowCustomValue,
    classNamePrefix = 'react-select',
    options,
    onInputChange,
    debounceDelay = 0,
    minCharsForSearch = 0,
    isMulti,
    onKeyDown,
    onOpenMenu,
    loadOptions,
    cacheOptions,
    defaultOptions,
    onChange,
    placeholder,
    isClearable,
    isLoading,
    value,
    valueComparator,
    errorMessage,
    invalid,
  }: SelectBaseProps<T>,
  ref: React.ForwardedRef<any>
) => {
  // Async options
  let asyncSelectProps: any = {}
  const handleDebounceFn = (inputValue: string, action: InputActionMeta) => {
    if (inputValue && inputValue.length >= minCharsForSearch) {
      onInputChange && onInputChange(inputValue, action)
    }
  }
  const onInputChangedWithDebounce = React.useMemo(
    () => _.debounce(handleDebounceFn, debounceDelay),
    []
  )
  const getValueField = (value: SelectValue<T>) => {
    if (allowCustomValue) {
      return value
    }
    if (valueComparator) {
      if (Array.isArray(value)) {
        const values = value.map((v) => {
          return options?.find((option) => {
            return valueComparator(v as SelectableValue<T>, option)
          })
        })
        return values
      }
      return options?.find((option) => {
        return valueComparator(value as SelectableValue<T>, option)
      })
    }
    return options?.find((option) => option.value === value)
  }
  // common options
  const commonOptions = {
    placeholder,
    classNames: `${!!errorMessage ? 'is-invalid' : ''}`,
    onInputChange:
      debounceDelay > 0 ? onInputChangedWithDebounce : onInputChange,
    onKeyDown,
    isLoading,
    isMulti,
    onOpenMenu,
    options,
    onChange,
    isClearable,
    isInvalid: !!errorMessage,
    value: value ? getValueField(value) : null,
  }
  // Define component
  let ReactSelectComponent = Select
  // loading ?
  if (loadOptions) {
    ReactSelectComponent = (
      allowCustomValue ? AsyncCreatable : ReactAsyncSelect
    ) as any
    asyncSelectProps = {
      loadOptions,
      cacheOptions,
      defaultOptions,
    }
  }

  return (
    <>
      <ReactSelectComponent
        classNamePrefix={classNames(classNamePrefix, {
          'is-invalid': !!errorMessage,
        })}
        {...commonOptions}
        {...asyncSelectProps}
      />
      {errorMessage && (
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export default SelectBase
