import { NodeTypes, RuleNodeFmt } from '../../shared/models/rule-node'
import { Edge, Node } from 'reactflow'
import { BaseNodeData } from './components/nodes/base/types'
import { RuleNodeEdge } from '../../shared/models/rule-node-edge'
import _ from 'lodash'
import { RuleNodeId } from '../../shared/models/id/rule-node-id'

export interface CustomEdge {
  id: string
  source: string
  target: string
  data: EdgeData
}

/**
 * @description Convert the edges from the format that is stored in the database to the format that is used in the ReactFlow graph.
 * @param edgeFmt
 */
export const edgeLibsFromEdgesFmts = (
  edgeFmt: RuleNodeEdge[]
): Edge<EdgeData>[] => {
  return _.chain(edgeFmt)
    .groupBy(({ fromId, toId }) => `${fromId.id}-${toId.id}`)
    .map((edges, key) => {
      return {
        id: key,
        source: edges[0].fromId.id,
        target: edges[0].toId.id,
        label: _.map(edges, 'condition').join(' && ') || '',
        type: 'kopiliotDefault',
        data: {
          conditions: _.map(edges, 'condition'),
        },
      }
    })
    .value()
}

export const edgesFmtsFromEdgeLibs = (
  edges: Edge<EdgeData>[]
): RuleNodeEdge[] => {
  return _.chain(edges)
    .filter((edge) => edge.source != 'main_input')
    .flatMap((edge) =>
      _.map(edge.data?.conditions, (condition) => ({
        fromId: new RuleNodeId(edge.source),
        toId: new RuleNodeId(edge.target),
        condition,
      }))
    )
    .value()
}

/*
  EdgeData is the data that is stored in the ReactFlow graph. In the data field of the Edge.
 */
export interface EdgeData {
  conditions: string[]
}

export const nodeLibFromNodeFmt = (
  nodeFmt: RuleNodeFmt
): Node<BaseNodeData<any>, string | undefined> => {
  return {
    id: nodeFmt.id?.id ? nodeFmt.id.id : '',
    type: nodeFmt.type,
    position: {
      x: nodeFmt.positionX,
      y: nodeFmt.positionY,
    },
    data: {
      id: nodeFmt.id,
      added: nodeFmt.added,
      name: nodeFmt.name,
      //description: nodeFmt.description,
      isDebugMode: nodeFmt.debugMode,
      configuration: nodeFmt.configuration,
    },
    draggable: true,
    deletable: true,
    selectable: true,
  }
}

export const nodesLibsFromNodesFmts = (
  nodeFmt: RuleNodeFmt[]
): Node<BaseNodeData<any>, string | undefined>[] => {
  return nodeFmt.map((node) => nodeLibFromNodeFmt(node))
}

export const ruleNodeFmtFromNodeLib = (
  nodeLib: Node<BaseNodeData<any>, string | undefined>
): RuleNodeFmt => {
  return {
    id: nodeLib.data.id,
    added: nodeLib.data.added,
    name: nodeLib.data.name,
    type: nodeLib.type as NodeTypes,
    debugMode: nodeLib.data.isDebugMode ? nodeLib.data.isDebugMode : false,
    positionX: nodeLib.position ? nodeLib.position.x : 0,
    positionY: nodeLib.position ? nodeLib.position.y : 0,
    configuration: nodeLib.data.configuration ? nodeLib.data.configuration : {},
  }
}
