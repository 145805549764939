import { baseArkhaleAPI } from './base-api'
import { ChangePasswordCurrentUser } from '../../shared/models/others/change-password'
import { ChangeSettings } from '../../shared/models/others/change-settings'
import Tenant from '../../shared/models/tenant'
import Customer from '../../shared/models/customer'

const meApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    updatePassword: build.mutation<
      { message: string },
      ChangePasswordCurrentUser
    >({
      query(data) {
        return {
          url: 'me/update-password',
          method: 'PATCH',
          body: data,
        }
      },
    }),
    updateSettings: build.mutation<ChangeSettings, ChangeSettings>({
      query(data) {
        return { url: 'me/update-settings', method: 'PATCH', body: data }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          await dispatch(
            baseArkhaleAPI.endpoints.me.initiate(null, {
              forceRefetch: true,
            })
          )
        } catch (error) {}
      },
    }),
    myTenant: build.query<Tenant, void>({
      query: () => ({ url: 'me/my-tenant' }),
    }),
    myCustomer: build.query<Customer, void>({
      query: () => ({ url: 'me/my-customer' }),
    }),
  }),
})

export const {
  useUpdatePasswordMutation,
  useMyTenantQuery,
  useMyCustomerQuery,
  useLazyMyTenantQuery,
  useLazyMyCustomerQuery,
  useUpdateSettingsMutation,
} = meApi
