import { baseArkhaleAPI } from './base-api'
import { Attribute, AttributeScope } from '../../shared/models/attribute'
import { Pagination } from '../../shared/models/others/pagination'
import { PaginationWithSearchTerm } from './query-type'

export interface AttributePaginationForEntity extends PaginationWithSearchTerm {
  entityType: string
  entityId: string
  scope: AttributeScope
}

const attributeApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    // GET /attributes/{entityType}/{entityId}/value/{scope}?offset=0&textSearch=&limit=10
    getAttributesByEntityId: build.query<
      Pagination<Attribute>,
      AttributePaginationForEntity
    >({
      query: ({ entityType, entityId, scope, offset, textSearch, limit }) => ({
        url: `attributes/${entityType}/${entityId}/value/${scope.toString()}`,
        method: 'GET',
        params: {
          offset,
          textSearch,
          limit,
        },
      }),
      providesTags: (result, error, { entityType, entityId, scope }) => {
        if (error) return [{ type: 'Attribute', id: 'LIST' }]
        return [{ type: 'Attribute', id: `${entityType}-${entityId}-${scope}` }]
      },
    }),
    saveAttributeToEntity: build.mutation<Attribute, Attribute>({
      query: ({ entityId, entityType, scope, key, value }) => ({
        url: `attributes/${entityType}/${entityId}/${scope.toString()}`,
        method: 'POST',
        body: {
          [key]: value,
        },
      }),
      invalidatesTags: (result, error, { entityId, entityType, scope }) => [
        { type: 'Attribute', id: `${entityType}-${entityId}-${scope}` },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAttributesByEntityIdQuery,
  useLazyGetAttributesByEntityIdQuery,
  useSaveAttributeToEntityMutation,
} = attributeApi
