import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {
  FieldError,
  UseFormRegisterReturn,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'
import { toast } from 'react-toastify'
import HoverBox from '../common/Hoverbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface InputFileProps {
  register: UseFormRegisterReturn<any>
  setValue: UseFormSetValue<any>
  setError: UseFormSetError<any>
  label?: string
  isLoading?: boolean
  error?: FieldError
}

const InputFileImageAsBase64 = ({
  label,
  isLoading,
  register,
  error,
  setError,
  setValue,
}: InputFileProps) => {
  const [preview, setPreview] = useState<string | undefined>()
  const [selectedImage, setSelectedImage] = useState<Blob | undefined>()
  useEffect(() => {
    if (!selectedImage) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedImage)
    formatImage(selectedImage)
      .then((base64) => {
        setValue(register.name, base64)
        setPreview(base64)
      })
      .catch((error) => {
        toast(error.message, { type: 'error' })
      })
      .finally(() => {
        URL.revokeObjectURL(objectUrl)
      })

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedImage])

  const formatImage = (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (fileReaderEvent) => {
        if (typeof fileReaderEvent.target?.result !== 'string') {
          reject(new Error('FileReader result is not a string'))
          return
        }
        resolve(reader.result as string)
      }
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }
  const onChangeImage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    if ((e.target as HTMLInputElement).files === null) {
      alert('File is null!')
    } else {
      const target = e.target as HTMLInputElement
      if (target.files && target.files[0]) {
        const file = target.files[0]
        if (file.size > 200000) {
          setError(name, { type: 'manual', message: 'File size is too large' })
          return
        }
        setSelectedImage(file)
      }
    }
  }

  const onClickRemoveImage = () => {
    setSelectedImage(undefined)
    setValue(register.name, undefined)
  }

  return (
    <>
      <Row>
        <Col md={'8'}>
          <Form.Label htmlFor={register.name}>{label}</Form.Label>
          <Form.Control
            className="mb-2"
            disabled={isLoading}
            type={'file'}
            placeholder={`Enter the ${label}`}
            isInvalid={!!error}
            onChange={(e) => {
              onChangeImage(e, register.name)
            }}
          />

          <Form.Control
            className="mb-2"
            disabled={isLoading}
            hidden={true}
            type={'text'}
            placeholder={`Enter the ${label}`}
            isInvalid={!!error}
            {...register}
          />
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </Col>
        <Col md={'4'}>
          <HoverBox className={'text-center'}>
            <img className={'img-fluid'} src={preview} />
            <HoverBox.Content className="light bg-dark p-5 flex-center">
              <div onClick={onClickRemoveImage}>
                <FontAwesomeIcon icon={'circle-xmark'} size={'2xl'} />
              </div>
            </HoverBox.Content>
          </HoverBox>
        </Col>
      </Row>
    </>
  )
}

export default InputFileImageAsBase64
