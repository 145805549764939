import Flex from '../../components/common/Flex'
import { Button, Dropdown } from 'react-bootstrap'
import classNames from 'classnames'
import React from 'react'

export enum MetricRange {
  LastHour = 'last-hour',
  LastDay = 'last-day',
  LastWeek = 'last-week',
  LastMonth = 'last-month',
  LastYear = 'last-year',
}

export enum MetricType {
  MessageCount = 'messages_count',
  DeviceConnected = 'device_connected',
}

export interface MetricSelectType {
  value: MetricType
  label: string
}

export interface MetricRangeSelectProps {
  metricRange?: MetricRange
  setMetricRange?: (metricRange: MetricRange) => void
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

const MetricRangeSelect = ({
  metricRange,
  setMetricRange,
  breakpoint = 'md',
}: MetricRangeSelectProps) => {
  const selectMetricRange: { value: MetricRange; label: string }[] = [
    { value: MetricRange.LastHour, label: 'Last Hour' },
    { value: MetricRange.LastDay, label: 'Last Day' },
    { value: MetricRange.LastWeek, label: 'Last Week' },
    { value: MetricRange.LastMonth, label: 'Last Month' },
    { value: MetricRange.LastYear, label: 'Last Year' },
  ]
  return (
    <>
      <Flex direction={`row d-none d-${breakpoint}-block`}>
        {selectMetricRange.map((item) => (
          <Button
            key={item.value}
            value={item.value}
            size={'sm'}
            variant={'outline-primary'}
            onClick={
              setMetricRange ? () => setMetricRange(item.value) : () => {}
            }
            className={classNames('me-2', {
              active: item.value === metricRange,
            })}
          >
            {item.label}
          </Button>
        ))}
      </Flex>
      <Dropdown className={`d-block d-${breakpoint}-none`}>
        <Dropdown.Toggle
          variant={'outline-primary'}
          size={'sm'}
          id={'dropdown-basic'}
        >
          {metricRange}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {selectMetricRange.map((item) => (
            <Dropdown.Item
              key={item.value}
              onClick={
                setMetricRange ? () => setMetricRange(item.value) : () => {}
              }
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default MetricRangeSelect
