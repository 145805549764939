import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import DeviceForm from '../form/DeviceForm'
import {
  CreateDeviceCommand,
  useAddDeviceMutation,
} from '../../../store/kopiliot-api/device'
import TenantId from '../../../shared/models/id/tenant-id'

interface AddDeviceModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  disabledFields?: Array<keyof CreateDeviceCommand>
  defaultTenantID?: TenantId
}

const AddDeviceModal = ({
  isOpen,
  setIsOpen,
  defaultTenantID,
  disabledFields = [],
}: AddDeviceModalProps) => {
  const form = useForm<CreateDeviceCommand>()
  useEffect(() => {
    if (defaultTenantID) {
      form.setValue('tenantID', defaultTenantID)
    }
  }, [defaultTenantID])
  const [addDevice, { isLoading, error }] = useAddDeviceMutation()
  const handleClose = () => {
    form.reset()
    setIsOpen(!isOpen)
  }
  const onSubmit = async (data: CreateDeviceCommand) => {
    try {
      await addDevice(data).unwrap()
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'lg'}
      className={'border'}
      centered
    >
      <Modal.Header
        closeButton
        //closeVariant={isDark ? 'white' : undefined}
        className="border-bottom-0"
      >
        <Modal.Title as="h5">Créer un Device</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-0'}>
        <DeviceForm form={form} disabledFields={disabledFields} />
      </Modal.Body>
    </Modal>
  )
}

export default AddDeviceModal
