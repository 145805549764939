import React, { useState } from 'react'
import AddDeviceModal from '../../features/device/modal/AddDeviceModal'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  changeTextSearch,
  PaginationActions,
  PaginationActionType,
} from '../../hooks/pagination-reducer'
import DeleteDeviceModal from '../../features/device/modal/DeleteDeviceModal'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { useForm, useWatch } from 'react-hook-form'
import { CustomerQuery, DeviceQuery } from '../../store/kopiliot-api/query-type'
import {
  defaultDeviceActionsRow,
  getColumnsForTableDevice,
} from '../../features/device/list/utils'
import Device from '../../shared/models/device.model'
import { useNavigate } from 'react-router-dom'
import DeviceSearchForm from '../../features/device/form/DeviceSearchForm'
import { EntityType } from '../../shared/models/entity-type.models'
import useTenantCustomerContext from '../context/tenant-customer.context'
import { createEntityQueryReducer } from '../../hooks/create-entity-pagination-reducer'
import {
  ActionUpdateCustomerID,
  ActionUpdateDeviceProfileID,
  ActionUpdateTenantID,
  CommonActionType,
} from '../../hooks/common-actions'
import { useFetchDevices } from './device.utils'
import { Authority } from '../../shared/models/user'
import { Row, SortingState } from '@tanstack/react-table'

const DeviceList = () => {
  // Current user
  const user = useSelector((state: RootState) => state.currentUser)
  // If a parameter is present in the URL, it can be accessed via useParams()
  const { tenantIDParam, customerIDParam } = useTenantCustomerContext()
  const searchForm = useForm<Omit<DeviceQuery, 'offset' | 'limit'>>()
  const {
    control,
    formState: { errors },
  } = searchForm
  const textSearch = useWatch({
    control,
    name: 'textSearch',
    defaultValue: '',
  })
  const tenantID = useWatch({
    control,
    name: 'tenantID',
    defaultValue: undefined,
  })
  const customerID = useWatch({
    control,
    name: 'customerID',
    defaultValue: undefined,
  })
  const deviceProfileID = useWatch({
    control,
    name: 'deviceProfileID',
    defaultValue: undefined,
  })

  const { fetchDevices, getData, isLoading } = useFetchDevices(
    tenantIDParam,
    customerIDParam
  )
  const reducer = React.useMemo(() => {
    return createEntityQueryReducer<
      DeviceQuery,
      | PaginationActions
      | ActionUpdateTenantID
      | ActionUpdateCustomerID
      | ActionUpdateDeviceProfileID
    >({
      handleSpecificActions: {
        [CommonActionType.UPDATE_TENANT_ID]: (state, action) => {
          return { ...state, tenantID: action.payload.tenantID }
        },
        [CommonActionType.UPDATE_CUSTOMER_ID]: (state, action) => {
          return { ...state, customerID: action.payload.customerID }
        },
        [CommonActionType.UPDATE_DEVICE_PROFILE_ID]: (state, action) => {
          return { ...state, deviceProfileID: action.payload.deviceProfileID }
        },
      },
    })
  }, [])
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducer,
    DeviceQuery,
    | PaginationActions
    | ActionUpdateTenantID
    | ActionUpdateCustomerID
    | ActionUpdateDeviceProfileID
  >(reducer, {
    limit: 20,
    offset: 0,
    textSearch: '',
    tenantID: undefined,
    customerID: undefined,
    deviceProfileID: undefined,
  })

  const navigate = useNavigate()

  React.useEffect(() => {
    const search = setTimeout(() => {
      changeTextSearch(dispatchPaginationAction, textSearch)
    }, 1000)
    return () => clearTimeout(search)
  }, [textSearch])
  React.useEffect(() => {
    const currentTenantID = tenantIDParam
      ? { id: tenantIDParam, entityType: EntityType.TENANT }
      : tenantID
    dispatchPaginationAction({
      type: CommonActionType.UPDATE_TENANT_ID,
      payload: { tenantID: currentTenantID },
    })
  }, [tenantIDParam, tenantID])
  React.useEffect(() => {
    const currentCustomerID = customerIDParam
      ? { id: customerIDParam, entityType: EntityType.CUSTOMER }
      : customerID
    dispatchPaginationAction({
      type: CommonActionType.UPDATE_CUSTOMER_ID,
      payload: { customerID: currentCustomerID },
    })
  }, [customerIDParam, customerID])
  React.useEffect(() => {
    dispatchPaginationAction({
      type: CommonActionType.UPDATE_DEVICE_PROFILE_ID,
      payload: { deviceProfileID },
    })
  }, [deviceProfileID])

  // Modal
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  const [deviceId, setDeviceId] = useState<string | undefined>()
  // Pagination
  React.useEffect(() => {
    fetchDevices(paginationState, true)
  }, [
    paginationState.offset,
    paginationState.limit,
    paginationState.sortOrder,
    paginationState.textSearch,
    paginationState.tenantID,
    paginationState.customerID,
    paginationState.deviceProfileID,
  ])

  const onDeviceDelete = (id: string) => {
    setDeviceId(id)
    setShowModalDelete(true)
  }
  const onDeviceEdit = (id: string) => {
    navigate(`${id}`)
  }
  const actionsRow = defaultDeviceActionsRow(onDeviceDelete, onDeviceEdit)

  const getDisabledField = (): Array<
    keyof Omit<DeviceQuery, 'offset' | 'limit'>
  > => {
    if (user.authority === 'TENANT_ADMIN' || tenantIDParam) {
      return ['tenantID']
    } else if (user.authority === 'CUSTOMER_USER' || customerIDParam) {
      return ['customerID']
    } else {
      return []
    }
  }
  const getHiddenColumns = (): Array<keyof Device> => {
    if (user.authority === Authority.TENANT_ADMIN || tenantIDParam) {
      return ['tenantID']
    } else if (user.authority === Authority.CUSTOMER_USER || customerIDParam) {
      return ['tenantID', 'customerID']
    } else {
      return []
    }
  }
  return (
    <>
      <KopiliotList
        title={'Devices'}
        icon={'microchip'}
        isLoading={isLoading}
        actionsProps={{
          filterForm: (
            <DeviceSearchForm
              form={searchForm}
              onSubmit={() => {}}
              disabledFields={getDisabledField()}
            />
          ),
          onRefresh: () => {
            fetchDevices(paginationState, false)
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          hiddenColumns: ['id', ...getHiddenColumns()],
          rowClassName: 'default__table-row align-middle fs-10',
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          onClickRow: (row: Row<Device>) => {
            navigate(`${row.original.id?.id}`)
          },
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableDevice(actionsRow),
          sortOrder: paginationState.sortOrder,
          setSortOrder: (sorting) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SORT,
              payload: { sort: sorting },
            })
          },
        }}
        cardProps={{
          idAccessor: (item: Device) => item.id!.id,
          titleAccessor: (item: Device) => item.name,
          descriptionAccessor: (item: Device) => item.description,
          linkAccessor: (item: Device) => `${item.id!.id}`,
          actionsRow: actionsRow,
        }}
      />
      <AddDeviceModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        disabledFields={user.authority != 'MAIN_TENANT' ? ['tenantID'] : []}
        defaultTenantID={user.tenantID}
      />
      <DeleteDeviceModal
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        deviceID={deviceId}
      />
    </>
  )
}

export default DeviceList
