import React from 'react'
import {
  Col,
  ColProps,
  Form,
  FormControlProps,
  FormGroupProps,
  InputGroup,
} from 'react-bootstrap'
import { FieldError, FieldErrors, UseFormRegisterReturn } from 'react-hook-form'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Select from 'react-select'

interface InputProps {
  register: UseFormRegisterReturn<any>
  className?: string
  size?: FormControlProps['size']
  label?: string
  type?: string
  error?: FieldError
  errors?: FieldErrors
  value?: any
  isDisabled?: boolean
  isHidden?: boolean
  inline?: boolean
  options?: { value: string; text: string }[]
  textAreaRows?: number
}

const Input = ({
  type = 'text',
  size,
  label,
  className,
  register,
  error,
  value,
  isDisabled = false,
  inline = false,
  options = [],
  textAreaRows = 3,
  isHidden = false,
}: InputProps) => {
  const [showPass, setShowPass] = React.useState(false)
  const showPassword = () => {
    setShowPass(!showPass)
  }
  switch (type) {
    case 'text':
    case 'email':
    case 'phone':
      return (
        <>
          {label && <Form.Label htmlFor={register.name}>{label}</Form.Label>}
          <Form.Control
            size={size}
            className={classNames('mb-2', className)}
            disabled={isDisabled}
            type={type}
            placeholder={`Enter the ${label}`}
            isInvalid={!!error}
            {...register}
          />
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </>
      )
    case 'select':
      return (
        <>
          {label && <Form.Label htmlFor={register.name}>{label}</Form.Label>}
          <Form.Control
            size={size}
            as={type}
            {...register}
            isInvalid={!!error}
            style={{ display: isHidden ? 'none' : 'block' }}
          >
            {_.map(options, (item, index) => (
              <option key={index} value={item.value}>
                {item.text}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </>
      )
    case 'radio':
    case 'switch':
      return (
        <>
          <Form.Check
            inline={inline}
            type={type}
            label={label}
            disabled={isDisabled}
            className={classNames('form-label-nogutter', className)}
            value={value}
            {...register}
          />
        </>
      )
    case 'textarea':
      return (
        <>
          {label && <Form.Label htmlFor={register.name}>{label}</Form.Label>}
          <Form.Control
            disabled={isDisabled}
            as="textarea"
            rows={textAreaRows}
            {...register}
            isInvalid={!!error}
            placeholder={`Enter the ${label}`}
          />
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </>
      )
    case 'password':
      return (
        <>
          {label && <Form.Label htmlFor={register.name}>{label}</Form.Label>}
          <InputGroup className={classNames('mb-2', { 'is-invalid': !!error })}>
            <Form.Control
              disabled={isDisabled}
              type={showPass ? 'text' : 'password'}
              placeholder={`Enter the ${label}`}
              isInvalid={!!error}
              {...register}
            />
            <InputGroup.Text>
              <FontAwesomeIcon
                onClick={showPassword}
                icon={showPass ? 'eye-slash' : 'eye'}
              ></FontAwesomeIcon>
            </InputGroup.Text>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </>
      )
    case 'number':
      return (
        <>
          {label && <Form.Label htmlFor={register.name}>{label}</Form.Label>}
          <Form.Control
            className="mb-2"
            disabled={isDisabled}
            type={type}
            placeholder={label ? `Enter the ${label}` : ''}
            isInvalid={!!error}
            {...register}
          />
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </>
      )
    case 'numeric':
      return (
        <>
          {label && <Form.Label htmlFor={register.name}>{label}</Form.Label>}
          <Form.Control
            className="mb-2"
            disabled={isDisabled}
            type={'text'}
            inputMode={'numeric'}
            pattern="[0-9]*"
            placeholder={label ? `Enter the ${label}` : ''}
            isInvalid={!!error}
            {...register}
          />
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </>
      )
    case 'color':
      return (
        <>
          {label && <Form.Label htmlFor={register.name}>{label}</Form.Label>}
          <Form.Control
            className="mb-2"
            disabled={isDisabled}
            type={type}
            {...register}
          />
          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </>
      )
    default:
      return null
  }
}

export default Input
