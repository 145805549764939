import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const Error401 = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">401</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Vous n'êtes pas autorisé à accéder à cette page.
        </p>
        <hr />
        <p>
          Assurez-vous que vous avez le bon URL et que l'administrateur du site
          ne vous a pas bloqué l'accès à cette section.
          <a href="mailto:info@exmaple.com" className="ms-1">
            contact us
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Retourner à la page d'accueil
        </Link>
      </Card.Body>
    </Card>
  )
}

export default Error401
