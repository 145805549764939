import { BaseData } from './base-data'
import { NotificationID } from './id/notification_id'
import { NotificationTargetID } from './id/notification-target-id'
import TenantId from './id/tenant-id'

export interface Notification extends BaseData<NotificationID> {}

export interface NotificationTarget extends BaseData<NotificationTargetID> {
  name: string
  description: string
  configuration: NotificationTargetConfig
  tenantID: TenantId
}

export interface NotificationTargetConfig
  extends Partial<PlatformUsersNotificationTargetConfig> {
  type: NotificationTargetType
}

export interface PlatformUsersNotificationTargetConfig {
  usersFilter: UsersFilter
}

export interface UsersFilter
  extends Partial<
    UserListFilter & CustomerUsersFilter & TenantAdministratorsFilter
  > {
  type: NotificationTargetConfigType
}

interface UserListFilter {
  usersIDs: Array<string>
}

interface CustomerUsersFilter {
  customerID: string
}

interface TenantAdministratorsFilter {
  tenantsIDs?: Array<string>
  tenantProfilesIDs?: Array<string>
}

export interface SlackNotificationTargetConfig {
  conversationType: SlackChanelType
  conversation: SlackConversation
}

export enum SlackChanelType {
  PUBLIC_CHANNEL = 'PUBLIC_CHANNEL',
  PRIVATE_CHANNEL = 'PRIVATE_CHANNEL',
  DIRECT = 'DIRECT',
}

export interface SlackConversation {
  id: string
  title: string
  name: string
  wholeName: string
  email: string
  type: string
}

export interface MicrosoftTeamsNotificationTargetConfig {
  webhookUrl: string
  channelName: string
}

export enum NotificationTargetType {
  USERS = 'USERS',
  SLACK = 'SLACK',
  MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
}

export enum NotificationTargetConfigType {
  ALL_USERS = 'ALL_USERS',
  TENANT_ADMINISTRATORS = 'TENANT_ADMINISTRATORS',
  CUSTOMER_USERS = 'CUSTOMER_USERS',
  USER_LIST = 'USER_LIST',
  USER_GROUP_LIST = 'USER_GROUP_LIST',
  ORIGINATOR_ENTITY_OWNER_USERS = 'ORIGINATOR_ENTITY_OWNER_USERS',
  AFFECTED_USER = 'AFFECTED_USER',
  SYSTEM_ADMINISTRATORS = 'SYSTEM_ADMINISTRATORS',
  AFFECTED_TENANT_ADMINISTRATORS = 'AFFECTED_TENANT_ADMINISTRATORS',
}
