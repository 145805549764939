import { Controller, useFormContext } from 'react-hook-form'
import {
  QuickTimeInterval,
  RealTimeWindowType,
  TimeWindow,
} from '../../../../shared/models/time.model'
import LabelHelp from '../../../../components/form/LabelHelp'
import Select from 'react-select'
import React from 'react'
import { NameBasedProps } from './types'

const QuickIntervalSelector = ({ name }: NameBasedProps) => {
  const { control, watch } = useFormContext<TimeWindow>()
  const { realtime } = watch()
  return (
    <>
      <Controller
        name={name}
        shouldUnregister={true}
        control={control}
        render={({ field }) => {
          return (
            <Select
              className={'w-100'}
              classNamePrefix={'react-select'}
              options={[
                {
                  value: QuickTimeInterval.CURRENT_HOUR,
                  label: 'Heure courante',
                },
                {
                  value: QuickTimeInterval.CURRENT_DAY,
                  label: 'Jour courant',
                },
                {
                  value: QuickTimeInterval.CURRENT_DAY_SO_FAR,
                  label: "Jour courant jusqu'à maintenant",
                },
                {
                  value: QuickTimeInterval.CURRENT_WEEK_ISO,
                  label: 'Semaine courante (Lundi - Dimanche)',
                },
                {
                  value: QuickTimeInterval.CURRENT_WEEK_ISO_SO_FAR,
                  label:
                    "Semaine courante jusqu'à maintenant (Lundi - Dimanche)",
                },
                {
                  value: QuickTimeInterval.CURRENT_WEEK,
                  label: 'Semaine courante (Dimanche - Samedi)',
                },
                {
                  value: QuickTimeInterval.CURRENT_WEEK_SO_FAR,
                  label:
                    "Semaine courante jusqu'à maintenant (Dimanche - Samedi)",
                },
                {
                  value: QuickTimeInterval.CURRENT_MONTH,
                  label: 'Mois courant',
                },
                {
                  value: QuickTimeInterval.CURRENT_MONTH_SO_FAR,
                  label: "Mois courant jusqu'à maintenant",
                },
                {
                  value: QuickTimeInterval.CURRENT_QUARTER,
                  label: 'Trimestre courant',
                },
                {
                  value: QuickTimeInterval.CURRENT_QUARTER_SO_FAR,
                  label: "Trimestre courant jusqu'à maintenant",
                },
                {
                  value: QuickTimeInterval.CURRENT_HALF_YEAR,
                  label: 'Semestre courant',
                },
                {
                  value: QuickTimeInterval.CURRENT_HALF_YEAR_SO_FAR,
                  label: "Semestre courant jusqu'à maintenant",
                },
                {
                  value: QuickTimeInterval.CURRENT_YEAR,
                  label: 'Année courante',
                },
                {
                  value: QuickTimeInterval.CURRENT_YEAR_SO_FAR,
                  label: "Année courante jusqu'à maintenant",
                },
                {
                  value: QuickTimeInterval.THIS_DAY_LAST_WEEK,
                  label: 'Ce jour la semaine dernière',
                },
                {
                  value: QuickTimeInterval.PREVIOUS_WEEK,
                  label: 'Semaine précédente',
                },
                {
                  value: QuickTimeInterval.PREVIOUS_WEEK_ISO,
                  label: 'Semaine précédente (Lundi - Dimanche)',
                },
                {
                  value: QuickTimeInterval.PREVIOUS_MONTH,
                  label: 'Mois précédent',
                },
                {
                  value: QuickTimeInterval.PREVIOUS_QUARTER,
                  label: 'Trimestre précédent',
                },
                {
                  value: QuickTimeInterval.PREVIOUS_HALF_YEAR,
                  label: 'Semestre précédent',
                },
                {
                  value: QuickTimeInterval.PREVIOUS_YEAR,
                  label: 'Année précédente',
                },
              ]}
              onChange={(e) => {
                if (e) {
                  field.onChange(e.value)
                }
              }}
            />
          )
        }}
      />
    </>
  )
}

export default QuickIntervalSelector
