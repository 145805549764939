import { useFormContext } from 'react-hook-form'
import {
  RealTimeWindowType,
  TimeWindow,
} from '../../../../shared/models/time.model'
import Flex from '../../../../components/common/Flex'
import { Form } from 'react-bootstrap'
import Input from '../../../../components/form/Input'
import Divider from '../../../../components/common/Divider'
import React from 'react'
import LastSelector from './LastSelector'
import QuickIntervalSelector from './QuickIntervalSelector'
import AggregationSelector from './AggregationSelector'
import GroupingIntervalSelector from './GroupingIntervalSelector'
import { TimeWindowProps } from './types'
import LabelHelp from '../../../../components/form/LabelHelp'

const TimeWindowRealTime = ({ isEditing }: TimeWindowProps) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<TimeWindow>()
  const { realtime } = watch()
  return (
    <div>
      <Flex direction={'row'} justifyContent={'start'}>
        {isEditing && (
          <div>
            <Form.Label htmlFor={'hideInterval'} className={'px-2 text-body'}>
              Caché
            </Form.Label>
            <Input
              className={'ms-2'}
              type={'switch'}
              register={register('hideInterval', { shouldUnregister: true })}
            />
          </div>
        )}

        <Flex direction={'column'} className={'flex-grow-1'}>
          <Flex direction={'row'}>
            <Flex
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              className={'px-3'}
            >
              <Input
                register={register('realtime.realtimeType')}
                value={RealTimeWindowType.LAST}
                type={'radio'}
              />
            </Flex>

            <Flex
              direction={'row'}
              justifyContent={'start'}
              alignContent={'flex-start'}
              alignItems={'start'}
              className={'flex-grow-1'}
            >
              {isEditing && (
                <div>
                  <Form.Label
                    htmlFor={'hideLastInterval'}
                    className={'px-2 text-light'}
                  >
                    Caché
                  </Form.Label>
                  <Input
                    className={'ms-2'}
                    type={'switch'}
                    register={register('hideLastInterval', {
                      shouldUnregister: true,
                    })}
                  />
                </div>
              )}

              <div className={'px-2 flex-grow-1'}>
                <LabelHelp
                  label={'Dernières'}
                  help={
                    'Affiche les dernières valeurs selon un interval défini'
                  }
                  className={'text-body'}
                />
                {realtime?.realtimeType === RealTimeWindowType.LAST && (
                  <LastSelector name={'realtime.timeWindowsMs'} />
                )}
              </div>
            </Flex>
          </Flex>
          <Divider />
          <Flex direction={'row'}>
            <Flex direction={'row'} alignItems={'center'} className={'px-3'}>
              <Input
                register={register('realtime.realtimeType')}
                value={RealTimeWindowType.INTERVAL}
                type={'radio'}
              />
            </Flex>
            <Flex
              direction={'row'}
              justifyContent={'start'}
              alignContent={'flex-start'}
              alignItems={'start'}
              className={'flex-grow-1'}
            >
              {isEditing && (
                <div>
                  <Form.Label
                    htmlFor={'hideQuickInterval'}
                    className={'px-2 text-light'}
                  >
                    Caché
                  </Form.Label>
                  <Input
                    className={'ms-2'}
                    type={'switch'}
                    register={register('hideQuickInterval', {
                      shouldUnregister: true,
                    })}
                  />
                </div>
              )}

              <div className={'px-2 flex-grow-1'}>
                <LabelHelp
                  label={'Intervale Rapide'}
                  help={'Sélectionnez un interval rapide'}
                  className={'text-body'}
                />
                {realtime?.realtimeType === RealTimeWindowType.INTERVAL && (
                  <QuickIntervalSelector name={'realtime.quickInterval'} />
                )}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Divider />
      <Flex direction={'row'} justifyContent={'start'}>
        {isEditing && (
          <div>
            <Form.Label
              htmlFor={'hideAggregation'}
              className={'px-2 text-light'}
            >
              Caché
            </Form.Label>
            <Input
              className={'ms-2'}
              type={'switch'}
              register={register('hideAggregation', { shouldUnregister: true })}
            />
          </div>
        )}
        <div className={'px-2 flex-grow-1'}>
          <AggregationSelector />
        </div>
      </Flex>
      <Divider />
      <Flex direction={'row'} justifyContent={'start'}>
        {isEditing && (
          <div>
            <Form.Label
              htmlFor={'hideAggInterval'}
              className={'px-2 text-light'}
            >
              Caché
            </Form.Label>
            <Input
              className={'ms-2'}
              type={'switch'}
              register={register('hideAggInterval', { shouldUnregister: true })}
            />
          </div>
        )}

        <div className={'px-2 flex-grow-1'}>
          <GroupingIntervalSelector />
        </div>
      </Flex>
    </div>
  )
}

export default TimeWindowRealTime
