import React from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import Input from '../../../../components/form/Input'
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from 'react-bootstrap'
import {
  useLazyGetAliasesQuery,
  useLazyGetAliasQuery,
} from '../../../../store/kopiliot-api/dashboard'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import _ from 'lodash'
import DataKeysForm from './DataKeysForm'
import { SelectableValue } from '../../../../components/page-components/selects-entities/generic-select/selectable-value'
import { AliasID } from '../../../../shared/models/id/alias-id'
import { DataSource, PanelType } from '../../../../shared/models/panel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../../../components/common/Flex'
import Divider from '../../../../components/common/Divider'
import { Select } from '../../../../components/page-components/selects-entities/generic-select/Select'

interface DataSourceFormProps {
  index: number
  panelType: PanelType
}

const DataSourceForm = ({ index, panelType }: DataSourceFormProps) => {
  const form = useFormContext()
  const currentDashboard = useSelector((state: RootState) => state.dashboard)
  const [optionsAlias, setOptionsAlias] = React.useState<
    SelectableValue<AliasID>[]
  >([])

  // LAZY QUERIES
  const [getAlias, aliasResult] = useLazyGetAliasQuery()
  const [getAliases, aliasesResult] = useLazyGetAliasesQuery()
  const watchAliasID = form.watch(`dataSources.${index}.entityAliasID`)
  // HOOKS
  React.useEffect(() => {
    if (aliasesResult.isSuccess) {
      setOptionsAlias(
        _.map(aliasesResult.data?.rows, (value) => {
          return value.id
            ? {
                label: value.name,
                value: value.id,
              }
            : null
        }).filter((value) => value !== null) as SelectableValue<AliasID>[]
      )
    }
  }, [aliasesResult])
  React.useEffect(() => {
    if (watchAliasID) {
      const fetchData = async () => {
        try {
          if (currentDashboard.id) {
            const alias = await getAlias({
              dashboardID: currentDashboard.id.id,
              id: watchAliasID.id,
            }).unwrap()
            if (alias) {
              setOptionsAlias([{ label: alias.name, value: watchAliasID }])
            }
          }
        } catch (err) {
          //toast.error(err.message)
        }
      }
      fetchData()
    }
  }, [watchAliasID])

  return (
    <>
      <Row>
        <FormGroup as={Col} lg={8} className={'mb-3'}>
          <Input
            label={'Type'}
            type={'select'}
            options={[{ value: 'entity', text: 'Entité' }]}
            register={form.register(`dataSources.${index}.type`, {
              required: 'Type is required',
            })}
            errors={form.formState.errors}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup as={Col} lg={4} className={'mb-3'}>
          <Form.Label htmlFor={`dataSources.${index}.entityAliasID`}>
            Entity Alias
          </Form.Label>
          <Controller
            name={`dataSources.${index}.entityAliasID`}
            control={form.control}
            rules={{ required: 'Entity Alias is required' }}
            render={({ field, fieldState }) => {
              return (
                <Select
                  invalid={!!fieldState.error}
                  errorMessage={fieldState.error?.message}
                  classNamePrefix={'react-select'}
                  options={optionsAlias}
                  onChange={(value: SelectableValue<AliasID>) => {
                    field.onChange(value?.value)
                  }}
                  onInputChange={(value) => {
                    if (currentDashboard.id) {
                      getAliases({
                        dashboardID: currentDashboard.id.id,
                        textSearch: value,
                        offset: 0,
                        limit: 40,
                      }).unwrap()
                    }
                  }}
                  value={field.value ? { value: field.value } : undefined}
                  valueComparator={(a, b) => {
                    return a?.value?.id === b?.value?.id
                  }}
                />
              )
            }}
          />
        </FormGroup>
      </Row>
      <Row>
        <Form.Label htmlFor={`dataSources.${index}.dataKeys`}>
          Time Series data keys
        </Form.Label>
        <FormGroup as={Col} lg={12}>
          <DataKeysForm index={index} panelType={panelType} type={'dataKeys'} />
        </FormGroup>
      </Row>
      <Row>
        <Form.Label htmlFor={`dataSources.${index}.dataKeys`}>
          Latest data keys
        </Form.Label>
        <FormGroup as={Col} lg={12}>
          <DataKeysForm
            index={index}
            panelType={panelType}
            type={'latestDataKeys'}
          />
        </FormGroup>
      </Row>
    </>
  )
}

interface DataSourceFormsProps {
  onSubmitDataSources: (dataSources: DataSource[]) => void
  initialValues?: DataSource[]
  panelType: PanelType
}

/**
 * @category Page Components - Dashboard
 * @description This is a form for data sources
 * @returns {JSX.Element}
 */
export const DataSourceForms = ({
  onSubmitDataSources,
  initialValues,
  panelType,
}: DataSourceFormsProps): JSX.Element => {
  const form = useForm<{ dataSources: DataSource[] }>({
    defaultValues: { dataSources: initialValues },
  })
  const { formState } = form
  React.useEffect(() => {
    if (initialValues) {
      form.reset({ dataSources: initialValues })
    }
  }, [initialValues])

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: form.control,
      name: 'dataSources',
    }
  )

  const watchDataSources = form.watch('dataSources')

  return (
    <>
      <Card className={'mt-2'}>
        <Card.Header className={'border-bottom-2'}>
          <Flex
            direction={'row'}
            alignItems={'center'}
            justifyContent={'between'}
            alignContent={'space-between'}
          >
            <div>
              <h3>Data Sources</h3>
            </div>
            <div>
              <Button
                type={'button'}
                variant={
                  formState.isDirty ? 'outline-warning' : 'outline-primary'
                }
                onClick={() => {
                  form.handleSubmit(() =>
                    onSubmitDataSources(watchDataSources)
                  )()
                }}
              >
                Appliquer
              </Button>
            </div>
          </Flex>
        </Card.Header>
        <Card.Body>
          <FormProvider {...form}>
            <Form
              noValidate
              onSubmit={form.handleSubmit(() =>
                onSubmitDataSources(watchDataSources)
              )}
            >
              {fields.map((field, index) => (
                <Container key={field.id}>
                  <Flex direction={'row'} justifyContent={'between'}>
                    <h5>Data Source {index + 1}</h5>
                    <div>
                      <Button
                        variant={'outline-danger'}
                        onClick={() => remove(index)}
                        className={'float-right'}
                      >
                        <FontAwesomeIcon icon={'trash'} />
                      </Button>
                    </div>
                  </Flex>
                  <Col md={12}>
                    <DataSourceForm
                      key={field.id}
                      index={index}
                      panelType={panelType}
                    />
                  </Col>
                  {index < fields.length - 1 && <Divider />}
                </Container>
              ))}
              <Divider />
              <div className={'float-end'}>
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    append({
                      type: 'entity',
                      entityAliasID: undefined,
                      dataKeys: [],
                      latestDataKeys: [],
                    })
                  }
                >
                  Ajouter
                </Button>
              </div>
            </Form>
          </FormProvider>
        </Card.Body>
      </Card>
    </>
  )
}

export default DataSourceForms
