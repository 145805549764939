import {
  PaginationWithSearchTerm,
  SortOrder,
} from '../store/kopiliot-api/query-type'

interface PayloadUpdatePageSize {
  pageSize: number
}

const isPayloadUpdatePageSize = (
  payload: any
): payload is PayloadUpdatePageSize => {
  return payload.pageSize && typeof payload.pageSize === 'number'
}

interface PayloadChangePage {
  currentPage: number
  totalPages: number
  pageLimit: number
}

const isPayloadChangePage = (payload: any): payload is PayloadChangePage => {
  return (
    payload.currentPage &&
    typeof payload.currentPage === 'number' &&
    payload.totalPages &&
    typeof payload.totalPages === 'number' &&
    payload.pageLimit &&
    typeof payload.pageLimit === 'number'
  )
}

interface PayloadUpdateFilter {
  filter?: string
}

const isPayloadUpdateFilter = (
  payload: any
): payload is PayloadUpdateFilter => {
  return payload.filter || typeof payload.filter === 'string' || false
}

interface PayloadUpdateSort {
  sort: SortOrder
}

const isPayloadUpdateSort = (payload: any): payload is PayloadUpdateSort => {
  return payload.sort && typeof payload.sort.property === 'string'
}

enum PaginationActionType {
  PAGE_SIZE = 'PAGE_SIZE',
  PAGE_CHANGE = 'PAGE_CHANGE',
  PAGE_FILTER = 'PAGE_FILTER',
  PAGE_SORT = 'PAGE_SORT',
}

type ChangePageSize = {
  type: PaginationActionType.PAGE_SIZE
  payload: PayloadUpdatePageSize
}

const isChangePageSize = (action: any): action is ChangePageSize => {
  return (
    action.type === PaginationActionType.PAGE_SIZE &&
    isPayloadUpdatePageSize(action.payload)
  )
}

type ChangePage = {
  type: PaginationActionType.PAGE_CHANGE
  payload: PayloadChangePage
}

const isChangePage = (action: any): action is ChangePage => {
  return (
    action.type === PaginationActionType.PAGE_CHANGE &&
    isPayloadChangePage(action.payload)
  )
}

type ChangeFilter = {
  type: PaginationActionType.PAGE_FILTER
  payload: PayloadUpdateFilter
}

const isChangeFilter = (action: any): action is ChangeFilter => {
  return (
    action.type === PaginationActionType.PAGE_FILTER &&
    isPayloadUpdateFilter(action.payload)
  )
}

type ChangeSort = {
  type: PaginationActionType.PAGE_SORT
  payload: PayloadUpdateSort
}

const isChangeSort = (action: any): action is ChangeSort => {
  return (
    action.type === PaginationActionType.PAGE_SORT &&
    isPayloadUpdateSort(action.payload)
  )
}

type PaginationActions = ChangePageSize | ChangePage | ChangeFilter | ChangeSort

const isPaginationActions = (action: any): action is PaginationActions => {
  return (
    isChangePageSize(action) ||
    isChangePage(action) ||
    isChangeFilter(action) ||
    isChangeSort(action)
  )
}

const reducerPage = (
  state: PaginationWithSearchTerm,
  action: PaginationActions
) => {
  switch (action.type) {
    case PaginationActionType.PAGE_SIZE:
      return { ...state, limit: action.payload.pageSize }
    case PaginationActionType.PAGE_FILTER:
      return {
        ...state,
        textSearch: action.payload.filter ? action.payload.filter : '',
        offset: 0,
      }
    case PaginationActionType.PAGE_CHANGE:
      const { currentPage } = action.payload
      return { ...state, offset: state.limit * (currentPage - 1) }
    case PaginationActionType.PAGE_SORT:
      return { ...state, sortOrder: action.payload.sort }
    default:
      return state
  }
}

const changePageSize = (dispatcher: Function, size: number) => {
  dispatcher({
    type: PaginationActionType.PAGE_SIZE,
    payload: { pageSize: size },
  })
}

const changeTextSearch = (dispatcher: Function, textSearch?: string) => {
  dispatcher({
    type: PaginationActionType.PAGE_FILTER,
    payload: { filter: textSearch },
  })
}

export { reducerPage, PaginationActionType, isPaginationActions }

export { changePageSize, changeTextSearch }

export type {
  PaginationActions,
  PayloadChangePage,
  PayloadUpdatePageSize,
  PayloadUpdateFilter,
}
