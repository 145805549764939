import React from 'react'
import {
  DataSource,
  LineAnnotation,
  TimeSeriesLineDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import { TimeseriesData } from '../../../../../shared/models/telemetry/telemetry.model'
import {
  TimeWindow,
  TimeWindowType,
} from '../../../../../shared/models/time.model'
import { Aliases } from '../../../../../shared/models/alias.model'
import { Bar, ChartProps } from 'react-chartjs-2'
import { useAppSelector } from '../../../../../store/store'
import { ChartDataset, TimeScaleTimeOptions } from 'chart.js'
import _ from 'lodash'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'

interface BarChartProps {
  timeSeriesData?: {
    [key: string]: { datasource: DataSource; data: TimeseriesData }
  }
  timeWindow?: TimeWindow
  aliases: Aliases
  options?: ChartProps<'bar'>['options']
  specificOptions?: TimeSeriesLineDataKeySpecificProps<LineAnnotation>
}

function findLabel(dataSource: DataSource, key: string) {
  const dataKey = dataSource.dataKeys.find((d) => d.key === key)
  if (dataKey) {
    return dataKey?.label ? dataKey?.label : dataKey?.key
  }
}

const findColor = (dataSource: DataSource, key: string) => {
  const dataKey = dataSource.dataKeys.find((d) => d.key === key)
  if (dataKey) {
    return dataKey?.color ? dataKey?.color : 'red'
  }
}

const getTimeConfig = (
  timeWindow?: TimeWindow
): _DeepPartialObject<TimeScaleTimeOptions> | undefined => {
  let timeWindowsMs: number | undefined = undefined
  if (!timeWindow) {
    return undefined
  }
  const { timeWindowType } = timeWindow
  if (timeWindowType === TimeWindowType.REAL_TIME) {
    timeWindowsMs = timeWindow.realtime?.timeWindowsMs
  } else {
    timeWindowsMs = timeWindow.history?.timeWindowsMs
  }
  if (!timeWindowsMs) {
    return undefined
  }
  let timeConfig: _DeepPartialObject<TimeScaleTimeOptions>
  if (timeWindowsMs <= 5000) {
    timeConfig = {
      unit: 'second',
      displayFormats: {
        second: 'HH:mm:ss',
      },
    }
  } else if (timeWindowsMs > 5000 && timeWindowsMs <= 3600000) {
    timeConfig = {
      unit: 'minute',
      displayFormats: { second: 'HH:mm' },
    }
  } else if (timeWindowsMs > 3600000 && timeWindowsMs <= 86400000) {
    timeConfig = {
      unit: 'hour',
      displayFormats: { hour: 'HH:mm' },
    }
  } else {
    timeConfig = {
      unit: 'day',
      displayFormats: { day: 'DD/MM' },
    }
  }
  return timeConfig
}

const TimeSeriesBarChart = ({
  timeSeriesData,
  timeWindow,
  specificOptions,
  options,
  aliases,
}: BarChartProps) => {
  const { theme } = useAppSelector((state) => state.settings)
  const [chartData, setChartData] = React.useState<{
    [key: string]: ChartDataset<'bar', any>
  }>({})
  React.useEffect(() => {
    _.map(timeSeriesData, (r, aliasID) => {
      _.map(r.data, (d, key) => {
        const label = findLabel(r.datasource, key)
        const color = findColor(r.datasource, key)
        const chartKey = `${aliasID}-${key}-${label}`
        setChartData((prev) => {
          return {
            ...prev,
            [chartKey]: {
              label: label,
              data: d,
              fill: false,
              borderColor: color,
              backgroundColor: color,
              tension: 0.1,
            },
          }
        })
      })
    })
  }, [timeSeriesData, timeWindow])
  return (
    <Bar
      data={{
        datasets: _.toArray(chartData),
      }}
      options={{
        ...options,
        parsing: {
          xAxisKey: 'ts',
          yAxisKey: 'value',
        },
        scales: {
          x: {
            ticks: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            grid: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            type: 'timeseries',
            //time: getTimeConfig(timeWindow),
          },
          y: {
            ticks: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            grid: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            type: 'linear',
          },
        },
      }}
    />
  )
}

export default TimeSeriesBarChart
