import React from 'react'
import { Node } from 'reactflow'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-golang'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'

export interface ScriptConfiguration {
  script: string
}

interface ScriptFormProps {
  onSubmit: (
    data: Node<BaseNodeData<ScriptConfiguration>, NodeTypes.FILTER_SCRIPT>
  ) => void
  defaultValues?: Node<
    BaseNodeData<ScriptConfiguration>,
    NodeTypes.FILTER_SCRIPT
  >
}

const ScriptForm = ({ onSubmit, defaultValues }: ScriptFormProps) => {
  const form = useForm<
    Node<BaseNodeData<ScriptConfiguration>, NodeTypes.FILTER_SCRIPT>
  >({
    defaultValues,
  })
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = form

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={errors?.data?.name}
            />
          </Form.Group>
        </Row>
        <Row className={''}>
          <Form.Group as={Col} lg={12}>
            <Form.Label>Script</Form.Label>
            <Controller
              name={'data.configuration.script'}
              control={form.control}
              rules={{ required: 'Script is required' }}
              render={({ field }) => {
                return (
                  <AceEditor
                    width={'100%'}
                    height={'300px'}
                    placeholder="Write some code"
                    value={field.value}
                    mode="golang"
                    theme="monokai"
                    //onLoad={this.onLoad}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    onChange={(value) => {
                      field.onChange(value)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default ScriptForm
