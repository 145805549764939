import { baseArkhaleAPI } from './base-api'
import TenantProfile from '../../shared/models/tenant-profile'
import { Pagination } from '../../shared/models/others/pagination'
import TenantProfileId from '../../shared/models/id/tenant-profile-id'
import { formatSortOrder, PaginationWithSearchTerm } from './query-type'

const tenantProfile = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getTenantProfiles: build.query<
      Pagination<TenantProfile>,
      PaginationWithSearchTerm
    >({
      query: (args) => ({
        url: 'tenant-profiles',
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
          ...formatSortOrder(args.sortOrder),
        },
      }),
      providesTags: (result = new Pagination<TenantProfile>(), error, arg) => [
        ...result.rows.map((item: TenantProfile) => {
          return {
            type: 'TenantProfile' as const,
            id: item.id?.id,
          }
        }),
        { type: 'TenantProfile', id: 'LIST' },
      ],
    }),
    getTenantProfile: build.query<TenantProfile, string>({
      query: (tenantProfileId) => `tenant-profiles/${tenantProfileId}`,
      providesTags: (result, error, arg) => [
        { type: 'TenantProfile', id: arg },
      ],
    }),
    // TODO see if typed with TenantProfileId
    addNewTenantProfile: build.mutation<TenantProfile, TenantProfile>({
      query: (tenantProfile) => ({
        url: 'tenant-profiles',
        method: 'POST',
        body: tenantProfile,
      }),
      invalidatesTags: [{ type: 'TenantProfile', id: 'LIST' }],
    }),
    updateTenantProfile: build.mutation<TenantProfile, TenantProfile>({
      query: (tenantProfile) => ({
        url: `tenant-profiles/${tenantProfile.id?.id}`,
        method: 'PUT',
        body: tenantProfile,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TenantProfile', id: arg.id?.id },
      ],
    }),
    deleteTenantProfile: build.mutation<TenantProfile, TenantProfileId>({
      query: (tenantProfileId) => ({
        url: `tenant-profiles/${tenantProfileId.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TenantProfile', id: arg.id },
        { type: 'TenantProfile', id: 'LIST' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTenantProfilesQuery,
  useLazyGetTenantProfileQuery,
  useLazyGetTenantProfilesQuery,
  useGetTenantProfileQuery,
  useAddNewTenantProfileMutation,
  useUpdateTenantProfileMutation,
  useDeleteTenantProfileMutation,
} = tenantProfile
