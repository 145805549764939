import { BaseData } from './base-data'
import UserId from './id/user-id'
import TenantId from './id/tenant-id'
import { CustomerId } from './id/customer-id'
import { DashboardId } from './id/dashboard-id'

export interface UserSession {
  sessionID: string
  expireAt: Date
  ip: string
  details: string
  time: Date
}

export enum Authority {
  MAIN_TENANT = 'MAIN_TENANT',
  TENANT_ADMIN = 'TENANT_ADMIN',
  CUSTOMER_USER = 'CUSTOMER_USER',
}

interface User extends BaseData<UserId> {
  createdAt: Date
  updatedAt: Date
  lastname: string
  firstname: string
  authority: Authority
  email: string
  phone?: string
  birthday: Date
  jobTitle: string
  userMetadata: {
    theme?: 'dark' | 'light'
    isNavbarVerticalCollapsed: boolean
    userSessions: {
      [key: string]: UserSession
    }
  }
  homeDashboardID?: DashboardId
  defaultDashboardID?: DashboardId
  customerId?: CustomerId
  tenantID: TenantId
}

export interface UserCreate {
  lastname: string
  firstname: string
  email: string
  phone?: string
  birthday?: Date
  jobTitle?: string
  customerId?: CustomerId
  tenantID: TenantId
}

export interface UserUpdate
  extends BaseData<UserId>,
    Omit<UserCreate, 'customerId' | 'tenantID'> {}

export interface UserCredentialsInfo {
  id: UserId
  enabled: boolean
  userID: UserId
  activationToken: string
}

export default User
