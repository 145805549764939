import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Flex from 'components/common/Flex'
import React from 'react'
import { Nav } from 'react-bootstrap'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface NavWizardItemProps {
  index: number
  step: number
  handleNavs: (index: number) => void
  icon: IconProp
  label: string
}

const NavItemRegular = ({
  index,
  step,
  handleNavs,
  label,
  icon,
}: NavWizardItemProps) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  )
}

const NavItemPills = ({
  index,
  step,
  handleNavs,
  label,
  icon,
}: NavWizardItemProps) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  )
}

const NavWizardItem = {
  Pills: NavItemPills,
  Regular: NavItemRegular,
}

export default NavWizardItem
