import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import 'reactflow/dist/style.css'
import './ruleChainEdit.css'
import {
  useGetRuleChainFmtQuery,
  useUpdateRuleChainMutation,
} from '../../store/kopiliot-api/rule-chain'
import Spinner from '../../components/spinner/Spinner'
import { useResizeObserver } from 'usehooks-ts'
import { Container } from 'react-bootstrap'
import RuleChainHeader from '../../features/rule-chain/components/header/RuleChainHeader'
import {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  MiniMap,
  Node,
  Position,
  ReactFlow,
  ReactFlowInstance,
  ReactFlowProvider,
  updateEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow'
import SideBar from '../../features/rule-chain/components/sidebar/SideBar'
import CheckFieldExistNode from '../../features/rule-chain/components/nodes/filter/check-field-exist/CheckFieldExist'
import MessageTypeNode from '../../features/rule-chain/components/nodes/filter/message-type/MessageTypeNode'
import SaveTimeseries from '../../features/rule-chain/components/nodes/action/timeseries/SaveTimeSeries'
import { NIL, v4 as UUIDv4 } from 'uuid'
import { RuleNodeId } from '../../shared/models/id/rule-node-id'
import ModalNode from '../../features/rule-chain/components/modal/ModalNode'
import ModalEdge from '../../features/rule-chain/components/modal/ModalEdge'
import _ from 'lodash'
import { NodeTypes } from '../../shared/models/rule-node'
import {
  EdgeData,
  edgeLibsFromEdgesFmts,
  edgesFmtsFromEdgeLibs,
  nodesLibsFromNodesFmts,
  ruleNodeFmtFromNodeLib,
} from '../../features/rule-chain/model'
import InputNode from '../../features/rule-chain/components/nodes/input/InputNode'
import RenameKeysNode from '../../features/rule-chain/components/nodes/transform/RenameKeysNode'
import { BaseNodeData } from '../../features/rule-chain/components/nodes/base/types'
import EntityTypeNode from '../../features/rule-chain/components/nodes/filter/entity-type/EntityTypeNode'
import { toast } from 'react-toastify'
import KopiliotDefault from '../../features/rule-chain/edges/KopiliotDefault'
import ScriptNode from '../../features/rule-chain/components/nodes/filter/script/ScriptNode'
import CreateAlarm from '../../features/rule-chain/components/nodes/action/alarm/CreateAlarm'
import ClearAlarm from '../../features/rule-chain/components/nodes/action/alarm/ClearAlarm'
import GetOriginatorAttributes from '../../features/rule-chain/components/nodes/enrichment/origin-attributes/GetOriginatorAttributes'
import TransformScriptNode from '../../features/rule-chain/components/nodes/action/script/TransformScriptNode'
import GetCustomerAttributesForm from '../../features/rule-chain/components/nodes/enrichment/origin-attributes/GetCustomerAttributesForm'
import GetCustomerAttributes from '../../features/rule-chain/components/nodes/enrichment/origin-attributes/GetCustomerAttributes'

// Area to remove
const initialNodes: Node<BaseNodeData<any>, NodeTypes>[] = [
  {
    id: 'main_input',
    position: { x: 0, y: 0 },
    data: {
      name: 'Input',
      added: false,
      isDebugMode: false,
      configuration: undefined,
    },
    sourcePosition: Position.Right,
    type: NodeTypes.INPUT_NODE,
    draggable: false,
    deletable: false,
    selectable: false,
  },
]

// Define Request type
type RequestRuleChain = {
  ruleChainID: string
}

const RuleChainEdit = () => {
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null)
  const allNodeTypes = React.useMemo(
    () => ({
      [NodeTypes.INPUT_NODE]: InputNode,
      [NodeTypes.FILTER_ASSET_PROFILE_SWITCH]: CheckFieldExistNode,
      [NodeTypes.FILTER_CHECK_FIELD_EXIST]: CheckFieldExistNode,
      [NodeTypes.FILTER_MESSAGE_TYPE]: CheckFieldExistNode,
      [NodeTypes.FILTER_ENTITY_TYPE]: EntityTypeNode,
      [NodeTypes.FILTER_ENTITY_TYPE_SWITCH]: CheckFieldExistNode,
      [NodeTypes.FILTER_MESSAGE_TYPE]: MessageTypeNode,
      [NodeTypes.FILTER_MESSAGE_TYPE_SWITCH]: CheckFieldExistNode,
      [NodeTypes.FILTER_CHECK_ALARM_STATUS]: CheckFieldExistNode,
      [NodeTypes.FILTER_SCRIPT]: ScriptNode,
      [NodeTypes.ACTION_SAVE_TELEMETRY]: SaveTimeseries,
      [NodeTypes.ACTION_CREATE_ALARM]: CreateAlarm,
      [NodeTypes.ACTION_CLEAR_ALARM]: ClearAlarm,
      [NodeTypes.TRANSFORM_RENAME_KEYS]: RenameKeysNode,
      [NodeTypes.ENRICHMENT_CUSTOMER_ATTRIBUTES]: GetCustomerAttributes,
      [NodeTypes.ENRICHMENT_ORIGINATOR_ATTRIBUTES]: GetOriginatorAttributes,
      [NodeTypes.TRANSFORM_SCRIPT]: TransformScriptNode,
    }),
    []
  )
  const allEdgeTypes = React.useMemo(
    () => ({
      kopiliotDefault: KopiliotDefault,
    }),
    []
  )
  // Get params
  const { ruleChainID } = useParams<RequestRuleChain>() as RequestRuleChain
  // Node state
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null)
  const [nodes, setNodes, onNodesChange] =
    useNodesState<BaseNodeData<any>>(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeData>([])
  // State for current Node edit
  const [currentNodeEdit, setCurrentNodeEdit] = useState<Node<
    BaseNodeData<any>
  > | null>(null)
  const [currentEdgeEdit, setCurrentEdgeEdit] = useState<Edge<EdgeData> | null>(
    null
  )
  const { data, isFetching, isLoading, error } =
    useGetRuleChainFmtQuery(ruleChainID)
  useEffect(() => {
    setNodes((prevNodes) => {
      if (data) {
        const nodesFmt = nodesLibsFromNodesFmts(data.ruleNodes)
        return [...initialNodes, ...nodesFmt]
      }
      return prevNodes
    })

    setEdges((prevEdges) => {
      if (data) {
        const edgesFmt = edgeLibsFromEdgesFmts(data.edges)
        // first edge
        const firstRuleNode = data.firstRuleNodeId?.id
        if (firstRuleNode) {
          const firstEdge = {
            id: `main_input-${firstRuleNode}`,
            source: 'main_input',
            target: firstRuleNode,
          }
          return [...[], ...edgesFmt, firstEdge]
        }
        return [...[], ...edgesFmt]
      }
      return prevEdges
    })
    reactFlowInstance?.fitView()
  }, [data, setNodes, setEdges])
  const [updateRuleChain, { isLoading: isLoadingUpdate }] =
    useUpdateRuleChainMutation()
  // Actions
  const onSaveRuleChain = async () => {
    try {
      const nodesFmt = _.chain(nodes)
        .filter((node) => node.type != NodeTypes.INPUT_NODE)
        .map((node) => ruleNodeFmtFromNodeLib(node))
        .value()
      const edgesFmt = edgesFmtsFromEdgeLibs(edges)
      // Get First Rule Node
      const firstRuleNodeID = _.find(edges, { source: 'main_input' })?.target
      if (data) {
        await updateRuleChain({
          description: data.description,
          edges: edgesFmt,
          firstRuleNodeId: firstRuleNodeID
            ? new RuleNodeId(firstRuleNodeID)
            : undefined,
          id: data.id,
          name: data.name,
          ruleNodes: nodesFmt,
        }).unwrap()
        toast.success('Rule chain enregistrée avec succès')
      }
    } catch (error) {
      console.log(error)
    }
  }
  // Utility functions
  const squareRef = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({ ref: squareRef })
  const isLoadingRuleChain = isLoading || isFetching
  const nodeModalShow = currentNodeEdit != null
  const edgeModalShow = currentEdgeEdit != null
  // Section action react Flow
  // Actions
  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])
  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      if (reactFlowWrapper.current != null && reactFlowInstance != null) {
        const type = event.dataTransfer.getData('application/reactflow')
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        })
        // Create UUID for the node
        const uuid = UUIDv4()
        const nodeToAdd: Node = {
          id: uuid.toString(),
          type,
          position: position,
          data: {
            id: new RuleNodeId(uuid.toString()),
            added: true,
          },
        }
        setCurrentNodeEdit(nodeToAdd)
      }
    },
    [reactFlowInstance]
  )
  const onNodeDoubleClick = (event: MouseEvent, item: Node) => {
    setCurrentNodeEdit(item)
  }
  const onEdgeDoubleClick = (event: MouseEvent, item: Edge) => {
    if (item.source !== 'main_input') {
      setCurrentEdgeEdit(item)
    }
  }
  const onEdgeUpdate = useCallback(
    (oldEdge: Edge<any>, newConnection: Connection) =>
      setEdges((els) => updateEdge(oldEdge, newConnection, els)),
    []
  )
  const isValidConnection = (c: Connection) => {
    if (c.source === 'main_input' && _.find(edges, { source: 'main_input' })) {
      return false
    }
    return c.source !== c.target
  }
  const onConnect = useCallback((params: Edge<any> | Connection) => {
    if (params.source != null && params.target != null) {
      if (params.source === 'main_input') {
        setEdges((edges) => {
          return addEdge(params, edges)
        })
      } else {
        const edgeToAdd: Edge<any> = {
          id: `${params.source}-${params.target}`,
          source: params.source,
          target: params.target,
        }
        setCurrentEdgeEdit(edgeToAdd)
      }
    }
  }, [])

  return (
    <>
      <RuleChainHeader title={data?.name} onSave={onSaveRuleChain} />
      <Container
        ref={squareRef}
        fluid
        className={'d-flex p-0'}
        style={{
          height: 'calc(100vh - 14rem)',
        }}
      >
        {isLoadingRuleChain && <Spinner />}{' '}
        <div className={'scrollbar p-0'} style={{ minWidth: '250px' }}>
          <SideBar />
        </div>
        <div className={'scrollbar p-0'}>
          {width > 0 && height > 0 && (
            <ReactFlowProvider>
              <div
                id={'container_react_flow'}
                style={{
                  width: `${width - 250}px`,
                  height: `${height}px`,
                }}
                ref={reactFlowWrapper}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={allNodeTypes}
                  edgeTypes={allEdgeTypes}
                  className={'rule_chain_flow'}
                  fitView
                  onInit={setReactFlowInstance}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  onConnect={onConnect}
                  onNodeDoubleClick={onNodeDoubleClick}
                  onEdgeDoubleClick={onEdgeDoubleClick}
                  defaultEdgeOptions={{
                    animated: true,
                  }}
                  onEdgeUpdate={onEdgeUpdate}
                  isValidConnection={isValidConnection}
                >
                  <Controls />
                  <MiniMap zoomable pannable />
                  <Background
                    color="#aaa"
                    variant={BackgroundVariant.Cross}
                    gap={30}
                  />
                </ReactFlow>
              </div>
              <ModalNode
                onHide={() => setCurrentNodeEdit(null)}
                currentNodeEdit={currentNodeEdit}
                setNodeModalShow={() => setCurrentNodeEdit(null)}
                show={nodeModalShow}
              />
              <ModalEdge
                show={edgeModalShow}
                onHide={() => {
                  setCurrentEdgeEdit(null)
                }}
                currentEdgeEdit={currentEdgeEdit ? currentEdgeEdit : undefined}
              />
            </ReactFlowProvider>
          )}
        </div>
      </Container>
    </>
  )
}

export default RuleChainEdit
