import React from 'react'
import {
  formatValue,
  isFiniteOrStringRepresentation,
} from '../../../../../utils/utils'
import classNames from 'classnames'

interface Props {
  value?: any
  unit?: string
  fontSize?: number
  color?: string
}

const SingleValueValue = ({ fontSize = 0, unit, value, color }: Props) => {
  const renderValue = React.useMemo(() => {
    if (value === undefined || value === null) {
      return <>N/A</>
    } else if (!isFiniteOrStringRepresentation(value)) {
      return (
        <>
          {value} {unit || ''}
        </>
      )
    }
    return <>{formatValue(value, 2, unit, false)}</>
  }, [value, unit])

  return (
    <div
      className={classNames('display-4 mb-2 fw-normal font-sans-serif')}
      style={{
        color,
        fontSize,
      }}
    >
      {renderValue}
    </div>
  )
}

export default SingleValueValue
