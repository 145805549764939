import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { DashboardId } from '../../../shared/models/id/dashboard-id'
import {
  useDeleteDashboardMutation,
  useGetDashboardQuery,
  useLazyGetDashboardQuery,
} from '../../../store/kopiliot-api/dashboard'
import { toast } from 'react-toastify'
import Spinner from '../../../components/spinner/Spinner'

interface DeleteDashboardModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  dashboardID?: DashboardId
}

const DeleteDashboardModal = ({
  isOpen,
  setIsOpen,
  dashboardID,
}: DeleteDashboardModalProps) => {
  const [getDashboard, { data, isLoading, error }] = useLazyGetDashboardQuery()
  const [
    deleteDashboard,
    { data: dataDelete, isLoading: isLoadingDelete, error: errorDelete },
  ] = useDeleteDashboardMutation()
  React.useEffect(() => {
    if (dashboardID) {
      getDashboard(dashboardID.id)
    }
  }, [dashboardID])
  const onDelete = () => {
    if (dashboardID) {
      deleteDashboard(dashboardID)
        .unwrap()
        .then(() => {
          setIsOpen(false)
          toast.success('Dashboard supprimé avec succès')
        })
        .catch(() => {
          toast.error('Erreur lors de la suppression du Dashboard')
          setIsOpen(false)
        })
    }
  }
  const loading = isLoading || isLoadingDelete
  return (
    <Modal show={isOpen} centered onHide={() => setIsOpen(false)} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Supprimer le Dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Spinner />}
        <p>Êtes-vous sûr de vouloir supprimer le Dashboard {data?.name} ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={() => setIsOpen(false)}
          disabled={loading}
        >
          Annuler
        </Button>
        <Button
          type={'button'}
          variant={'outline-danger'}
          onClick={onDelete}
          disabled={loading}
        >
          Supprimer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteDashboardModal
