import React from 'react'
import ContentBlock from '../../../components/common/component-card/ContentBlock'
import Flex from '../../../components/common/Flex'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import {
  setCurrentAction,
  setEditing,
  setShowAliasList,
  setTime,
} from '../../../store/slices/dashboardSlice'
import { useUpdateDashboardMutation } from '../../../store/kopiliot-api/dashboard'
import IconButton from '../../../components/common/IconButton'
import { toast } from 'react-toastify'
import TimeWindowDropdown from '../form/timeWindow/TimeWindowDropdown'
import { defaultTimeWindow } from '../../../shared/models/time.model'
import EditDashboardModal from '../modal/EditDashboardModal'
import { Authority } from '../../../shared/models/user'

interface Props {
  title?: string
}

const DashboardHeader = ({ title }: Props) => {
  const { authority } = useAppSelector((state) => state.currentUser)
  const appDispatch = useAppDispatch()

  const [showModalEditDashboard, setShowModalEditDashboard] =
    React.useState(false)

  const onAddPanel = () => {
    appDispatch(setCurrentAction({ action: 'create' }))
  }

  const [updateDashboard, { isLoading, isError }] = useUpdateDashboardMutation()
  const dash = useAppSelector((state) => state.dashboard)

  const submit = async () => {
    try {
      await updateDashboard(dash).unwrap()
      toast('Dashboard saved', { type: 'success' })
    } catch (e) {
      console.log(e)
    }
  }
  const showEditMenu = () => {
    return authority === Authority.MAIN_TENANT ||
      authority === Authority.TENANT_ADMIN ? (
      <>
        <div className={'px-2'}>
          <IconButton
            variant={'outline-primary'}
            iconClassName={'pen-to-square'}
            onClick={() => setShowModalEditDashboard(true)}
          />
        </div>
        <div className={'px-2'}>
          <IconButton
            variant={'outline-primary'}
            iconClassName={'window-maximize'}
            onClick={onAddPanel}
          />
        </div>
        <div className={'px-2'}>
          <IconButton
            variant={'outline-primary'}
            iconClassName={'database'}
            onClick={() => appDispatch(setShowAliasList())}
          />
        </div>
        <div className={'px-2 border-start'}>
          <IconButton
            iconClassName={'cancel'}
            variant={'outline-danger'}
            title={'Cancel'}
            iconAlign="left"
            className={'me-2'}
            onClick={() => appDispatch(setEditing({ isEditing: false }))}
          />
          <IconButton
            iconClassName={'save'}
            variant={'outline-primary'}
            title={'Sauvegarder'}
            iconAlign="left"
            onClick={() => submit()}
          />
        </div>
      </>
    ) : null
  }
  const showOpenEditMenu = () => {
    return authority === Authority.MAIN_TENANT ||
      authority === Authority.TENANT_ADMIN ? (
      <IconButton
        iconClassName={'edit'}
        variant={'outline-primary'}
        title={'Edit'}
        iconAlign="left"
        size={'sm'}
        onClick={() => appDispatch(setEditing({ isEditing: true }))}
      />
    ) : null
  }
  return (
    <>
      <ContentBlock className={'mb-1'}>
        <Flex
          direction={'md-row'}
          breakpoint={'sm'}
          justifyContent={'between'}
          alignContent={'flex-end'}
          className={'m-2'}
        >
          <div className={'px-2'}>
            <h4 className={'pt-2 text-primary'}>{dash.name}</h4>
          </div>
          <Flex
            direction={'row'}
            justifyContent={'between'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <TimeWindowDropdown
              isEditing={dash.isEditing}
              className={'px-2'}
              onSubmitTimeWindow={(data) => appDispatch(setTime(data))}
              defaultTimeWindow={
                dash.config.timeWindow
                  ? dash.config.timeWindow
                  : defaultTimeWindow()
              }
            />
            {dash.isEditing ? showEditMenu() : showOpenEditMenu()}
          </Flex>
        </Flex>
      </ContentBlock>
      <EditDashboardModal
        isOpen={showModalEditDashboard}
        setIsOpen={setShowModalEditDashboard}
        initialDashboard={{
          id: dash.id,
          name: dash.name,
          description: dash.description,
          tenantID: dash.tenantID,
          assignedCustomers: dash.assignedCustomers,
          image: dash.image,
        }}
      />
    </>
  )
}

export default DashboardHeader
