import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
  useDeleteAssetMutation,
  useLazyGetAssetQuery,
} from '../../../../store/kopiliot-api/asset'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  assetID?: string
}

const DeleteCustomerModal = ({ isOpen, setIsOpen, assetID }: Props) => {
  const [triggerGetAsset, resultGetAsset] = useLazyGetAssetQuery()
  useEffect(() => {
    if (assetID) {
      triggerGetAsset(assetID, true)
    }
  }, [assetID])
  const [deleteCustomer, { isLoading: isDeleting }] = useDeleteAssetMutation()
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Modal
        show={isOpen && assetID !== undefined}
        onHide={handleClose}
        size={'lg'}
        className={'border'}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title as="h5">
            Supprimer l'asset {`${resultGetAsset?.data?.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'p-2'}>
          <p>
            {`Êtes - vous sûr de vouloir supprimer l'asset ${resultGetAsset?.data?.name} ?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={'button'}
            variant={'outline-secondary'}
            onClick={handleClose}
            disabled={isDeleting}
          >
            Annuler
          </Button>
          <Button
            children={'Supprimer'}
            variant={'outline-danger'}
            disabled={isDeleting}
            onClick={() => {
              if (resultGetAsset?.data && resultGetAsset?.data.id) {
                deleteCustomer({
                  id: resultGetAsset?.data.id.id,
                  asset: resultGetAsset?.data,
                })
              }
              handleClose()
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteCustomerModal
