import React from 'react'
import { Spinner as SpinnerReactBootstrap } from 'react-bootstrap'
import Flex from '../common/Flex'

interface SpinnerProps {
  size?: 'sm'
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
  animation?: 'border' | 'grow'
}

const Spinner = ({
  size,
  variant = 'primary',
  animation = 'border',
}: SpinnerProps) => {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      className={
        'h-100 w-100 position-absolute top-0 start-0 z-index-1 opacity-75 bg-transparent'
      }
    >
      <SpinnerReactBootstrap
        size={size}
        animation={animation}
        variant={variant}
      />
    </Flex>
  )
}
export default Spinner
