import React from 'react'
import { TimeWindow } from '../../../../../../shared/models/time.model'
import {
  DataKey,
  DataSource,
  isJsonTableDataKeySpecificProps,
  JsonTableDataKeySpecificProps,
} from '../../../../../../shared/models/panel'
import {
  TimeseriesData,
  TsValue,
} from '../../../../../../shared/models/telemetry/telemetry.model'
import { useAppSelector } from '../../../../../../store/store'
import _ from 'lodash'
import { Tab, Tabs } from 'react-bootstrap'
import JsonTableWrapper from './JsonTableWrapper'

interface JsonTableProps {
  timeSeriesData?: {
    [key: string]: { datasource: DataSource; data: TimeseriesData }
  }
  timeWindow?: TimeWindow
  options?: {}
}

const JsonTable = ({ timeSeriesData, timeWindow, options }: JsonTableProps) => {
  const dash = useAppSelector((state) => state.dashboard)
  const [tabs, setTabs] = React.useState<{
    [key: string]: {
      dataKey: DataKey<JsonTableDataKeySpecificProps>
      values: Array<TsValue>
    }
  }>({})
  React.useEffect(() => {
    if (timeSeriesData && dash.aliases) {
      const newTabs: {
        [key: string]: {
          dataKey: DataKey<JsonTableDataKeySpecificProps>
          values: Array<TsValue>
        }
      } = {}
      _.chain(timeSeriesData)
        .map((value, key) => {
          return { key, value: value.datasource.dataKeys }
        })
        .forEach((value) => {
          _.map(value.value, (dataKey) => {
            if (isJsonTableDataKeySpecificProps(dataKey.specificProps)) {
              newTabs[dataKey.key] = {
                dataKey,
                values: timeSeriesData[value.key].data[dataKey.key],
              }
            }
          })
        })
        .value()
      setTabs(newTabs)
    }
  }, [timeSeriesData, dash.aliases])
  React.useEffect(() => {}, [])
  return (
    <>
      <Tabs>
        {_.map(tabs, (tab, key) => {
          return (
            <Tab key={key} eventKey={key} title={tab.dataKey.label}>
              <JsonTableWrapper config={tab} options={options} />
            </Tab>
          )
        })}
      </Tabs>
    </>
  )
}

export default JsonTable
