import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import { Node } from 'reactflow'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'

export interface SaveTimeseriesConfiguration {
  ttl: number
}

interface SaveTimeseriesFormProps {
  onSubmit: (
    data: Node<
      BaseNodeData<SaveTimeseriesConfiguration>,
      NodeTypes.ACTION_SAVE_TELEMETRY
    >
  ) => void
  defaultValues: Node<
    BaseNodeData<SaveTimeseriesConfiguration>,
    NodeTypes.ACTION_SAVE_TELEMETRY
  >
}

const SaveTimeseriesForm = ({
  onSubmit,
  defaultValues,
}: SaveTimeseriesFormProps) => {
  const form = useForm({ defaultValues })
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = form

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={errors.data?.name ? errors.data?.name : undefined}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Check
              type="switch"
              label="Debug Mode ?"
              {...register('data.isDebugMode')}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <Input
              register={register('data.configuration.ttl', {
                required: 'Le TTL est obligatoire',
                valueAsNumber: true,
              })}
              type={'numeric'}
              label={'TTL (secondes)'}
              error={
                errors.data?.configuration?.ttl
                  ? errors.data?.configuration?.ttl
                  : undefined
              }
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default SaveTimeseriesForm
