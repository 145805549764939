import React from 'react'
import { Card, Col, Container, ProgressBar, Row } from 'react-bootstrap'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import StatisticCard from '../../components/common/widgets/StatisticCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../components/common/Flex'
import SubtleBadge from '../../components/common/SubtleBadge'
import { useGetHubInfoByTenantIDQuery } from '../../store/kopiliot-api/tenant'
import {
  getColorForHubInfoStatus,
  HubInfoStatus,
} from '../../shared/models/hub-info.model'
import Spinner from '../../components/spinner/Spinner'
import { useGetApiUsageQuery } from '../../store/kopiliot-api/api-usage'
import ProgressBarWithTooltip from '../../components/common/ProgressBarWithTooltip'
import GaugeComponent from 'react-gauge-component'
import { toast } from 'react-toastify'
import HubMetrics from '../../features/scw-metrics/HubMetrics'
import { useLazyGetAlarmCountQuery } from '../../store/kopiliot-api/alarm'

interface Props {
  tenantID: string
}

const DefaultHomeDashboard = ({ tenantID }: Props) => {
  const {
    data: hubData,
    isLoading: isLoadingHubInfo,
    isFetching: isFetchingHubInfo,
    error: errorGetHubInfos,
  } = useGetHubInfoByTenantIDQuery(tenantID)

  const {
    data: apiUsageData,
    isLoading: isLoadingApiUsage,
    isFetching: isFetchingApiUsage,
    error: errorGetApiUsage,
  } = useGetApiUsageQuery()
  // Check for alarms
  const [getAlarmCount] = useLazyGetAlarmCountQuery()
  React.useEffect(() => {
    const error = errorGetHubInfos || errorGetApiUsage
    if (error) {
      //toast.error(error)
    }
  }, [errorGetApiUsage, errorGetHubInfos])
  const isLoadingHub = isLoadingHubInfo || isFetchingHubInfo
  const isLoadingApi = isLoadingApiUsage || isFetchingApiUsage
  return (
    <Container fluid className={'h-100'}>
      <Row className={'mt-2'}>
        <Col sm={12} md={6} lg={4} className={'px-1'}>
          <ContentBlock className={'mb-2'}>
            <ContentBlock.Header className={'border-bottom py-1'}>
              <Flex>
                <FontAwesomeIcon
                  icon={'microchip'}
                  className={'me-1 mt-1 text-primary'}
                />
                <h5 className={'text-primary mb-0'}>Devices</h5>
              </Flex>
            </ContentBlock.Header>
            <ContentBlock.Body noLight className={'pt-1 h-100'}>
              {isLoadingHub ? (
                <Spinner />
              ) : (
                <Row>
                  <Col className={'p-1 h-100'}>
                    <StatisticCard
                      title={'Connectés'}
                      value={hubData?.connectedDeviceCount || 0}
                      className={'shadow-none bg-success-subtle'}
                    />
                  </Col>
                  <Col className={'p-1 h-100'}>
                    <StatisticCard
                      value={
                        (hubData?.deviceCount || 0) -
                        (hubData?.connectedDeviceCount || 0)
                      }
                      title={'Déconnectés'}
                      className={'shadow-none bg-danger-subtle'}
                    />
                  </Col>
                  <Col className={'p-1 h-100'}>
                    <StatisticCard
                      title={'Total'}
                      value={hubData?.deviceCount || 0}
                      className={'shadow-none bg-info-subtle'}
                    />
                  </Col>
                </Row>
              )}
            </ContentBlock.Body>
          </ContentBlock>
        </Col>
        <Col sm={12} md={6} lg={4} className={'px-1 h-100'}>
          <ContentBlock>
            <ContentBlock.Header className={'border-bottom py-1'}>
              <Flex>
                <FontAwesomeIcon
                  icon={'triangle-exclamation'}
                  className={'me-1 mt-1 text-primary'}
                />
                <h5 className={'text-primary mb-0'}>Alertes</h5>
              </Flex>
            </ContentBlock.Header>
            <ContentBlock.Body noLight className={'pt-1'}>
              <Row>
                <Col className={'p-1'}>
                  <StatisticCard
                    title={'Assignées'}
                    value={3}
                    className={'shadow-none bg-success-subtle'}
                  />
                </Col>
                <Col className={'p-1'}>
                  <StatisticCard
                    value={2}
                    title={'Critiques'}
                    className={'shadow-none bg-danger-subtle'}
                  />
                </Col>
                <Col className={'p-1'}>
                  <StatisticCard
                    title={'Total'}
                    value={10}
                    className={'shadow-none bg-info-subtle'}
                  />
                </Col>
              </Row>
            </ContentBlock.Body>
          </ContentBlock>
        </Col>
        <Col sm={12} md={12} lg={4} className={'px-1 h-100'}>
          <ContentBlock className={'mb-3'}>
            <ContentBlock.Header className={'border-bottom py-1'}>
              <Flex>
                <FontAwesomeIcon
                  icon={['fab', 'hubspot']}
                  className={'me-1 mt-1 text-primary'}
                />
                <h5 className={'text-primary mb-0'}>Hub</h5>
              </Flex>
            </ContentBlock.Header>
            <ContentBlock.Body noLight className={'h-100 '}>
              {isLoadingHub ? (
                <Spinner />
              ) : (
                <>
                  <Flex className={'mb-3'} direction={'column'}>
                    <div>
                      <strong>Nom:</strong> {hubData?.name || 'N/A'}
                    </div>
                    <div>
                      <strong>Status:</strong>
                      <SubtleBadge
                        bg={getColorForHubInfoStatus(
                          hubData?.status || HubInfoStatus.Unknown
                        )}
                        pill
                      >
                        {hubData?.status || HubInfoStatus.Unknown}
                      </SubtleBadge>
                    </div>
                    <div>
                      <strong>Endpoint:</strong> {hubData?.endpoint || 'N/A'}
                    </div>
                  </Flex>
                </>
              )}
            </ContentBlock.Body>
          </ContentBlock>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={8} className={'px-1'}>
          <ContentBlock>
            <ContentBlock.Header>
              <Flex>
                <FontAwesomeIcon
                  icon={['fab', 'hubspot']}
                  className={'me-1 mt-1 text-primary'}
                />
                <h5 className={'text-primary'}>Hub</h5>
              </Flex>
            </ContentBlock.Header>
            <ContentBlock.Body noLight>
              <HubMetrics tenantID={tenantID} />
            </ContentBlock.Body>
          </ContentBlock>
        </Col>
        <Col sm={12} md={12} lg={4} className={'px-1'}>
          <ContentBlock>
            <ContentBlock.Header>
              <h5>Test</h5>
            </ContentBlock.Header>
            <ContentBlock.Body noLight>
              <p>test</p>
            </ContentBlock.Body>
          </ContentBlock>
        </Col>
      </Row>
      <Row>
        <Col className={'px-1'}>
          <ContentBlock className={''}>
            <ContentBlock.Header className={'border-bottom py-1'}>
              <Flex>
                <FontAwesomeIcon
                  icon={['far', 'circle-question']}
                  className={'me-1 mt-1 text-primary'}
                />
                <h5 className={'text-primary mb-0'}>Quotas</h5>
              </Flex>
            </ContentBlock.Header>
            <ContentBlock.Body noLight>
              <Card.Body className="position-relative">
                <Row>
                  <Col md={12} lg={4} className={'border-end'}>
                    <p className={'mb-0'}>Customers</p>
                    <ProgressBar
                      now={75}
                      max={100}
                      className="rounded-pill mb-3"
                    />
                    <p className={'mb-0'}>Assets</p>
                    <ProgressBarWithTooltip
                      tooltip={`${apiUsageData?.assets || 0} sur ${
                        apiUsageData?.maxAssets || 'Unlimited'
                      }`}
                      now={apiUsageData?.assets || 0}
                      max={apiUsageData?.maxAssets || 0}
                      className="rounded-pill mb-3"
                    />
                    <p className={'mb-0'}>Devices</p>
                    <ProgressBarWithTooltip
                      tooltip={`${apiUsageData?.devices || 0} sur ${
                        apiUsageData?.maxDevices || 'Unlimited'
                      }`}
                      now={apiUsageData?.devices || 0}
                      max={apiUsageData?.maxDevices || 0}
                      className="rounded-pill mb-3"
                    />
                  </Col>
                  <Col sm={12} md={12} lg={4} className={'border-end'}>
                    <p className={'mb-0'}>Users</p>
                    <ProgressBarWithTooltip
                      tooltip={`${apiUsageData?.users || 0} sur ${
                        apiUsageData?.maxUsers || 'Unlimited'
                      }`}
                      now={apiUsageData?.users || 0}
                      max={apiUsageData?.maxUsers || 0}
                      className="rounded-pill mb-3"
                    />
                    <p className={'mb-0'}>Rule chains</p>
                    <ProgressBarWithTooltip
                      tooltip={`${apiUsageData?.ruleChains || 0} sur ${
                        apiUsageData?.maxRuleChains || 'Unlimited'
                      }`}
                      now={apiUsageData?.ruleChains || 0}
                      max={apiUsageData?.maxRuleChains || 0}
                      className="rounded-pill mb-3"
                    />
                    <p className={'mb-0'}>Dashboards</p>
                    <ProgressBarWithTooltip
                      tooltip={`${apiUsageData?.dashboards || 0} sur ${
                        apiUsageData?.maxDashboards || 'Unlimited'
                      }`}
                      now={apiUsageData?.dashboards || 0}
                      max={apiUsageData?.maxDashboards || 0}
                      className="rounded-pill mb-3"
                    />
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <p className={'mb-0'}>Resources</p>
                    <ProgressBar
                      now={75}
                      className="rounded-pill mb-3"
                      label={'75 sur 100'}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </ContentBlock.Body>
          </ContentBlock>
        </Col>
      </Row>
    </Container>
  )
}

export default DefaultHomeDashboard
