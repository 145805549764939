import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import Tenant from '../../shared/models/tenant'
import React from 'react'
import TenantProfileName from '../../features/helper/TenantProfileName'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useOutletContext } from 'react-router-dom'
import Flex from '../../components/common/Flex'
import TenantId from '../../shared/models/id/tenant-id'
import { CustomerId } from '../../shared/models/id/customer-id'
import { ActionRowProps } from '../../components/kopiliot-list/type'

const getColumnsForTableTenant = (actionRows: ActionRowProps[]) => {
  const columnHelper = createColumnHelper<Tenant>()
  const columns: ColumnDef<Tenant, string>[] = [
    columnHelper.accessor((row) => row.id?.id, {
      id: 'id',
      cell: (info) => info.getValue(),
      header: () => 'Id',
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'name',
      header: () => 'Nom',
      enableSorting: true,
      meta: {
        className: 'align-middle',
      },
      cell: (props) => {
        const { id, name } = props.row.original

        return (
          <h5 className={'mb-0 fs-10'}>
            <Link to={id ? `${id.id}` : '#'} className={'fw-semibold'}>
              {name}
            </Link>
          </h5>
        )
      },
    }),
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: () => 'Description',
      enableSorting: true,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: 'phone',
      cell: (info) => info.getValue(),
      header: () => 'Téléphone',
      enableSorting: true,
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => info.getValue(),
      header: () => 'Email',
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'address',
      cell: (props) => {
        const { address, additionalAddressDetails, zip, city } =
          props.row.original
        return (
          <>
            {address}
            <br />
            {additionalAddressDetails}
            <br />
            {zip}
            <br />
            {city}
          </>
        )
      },
      header: () => 'Address',
    }),
    columnHelper.accessor((row) => row.country, {
      id: 'country',
      cell: (info) => info.getValue(),
      header: 'Pays',
      enableSorting: true,
    }),

    columnHelper.display({
      id: 'tenantProfile',
      cell: (props) => {
        return (
          <>
            <TenantProfileName
              tenantProfileId={props.row.original.tenantProfileId.id}
            />
          </>
        )
      },
      header: () => 'Tenant Profile',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        const tenantId = props.row.original.id?.id
        return (
          <>
            {actionRows && tenantId && (
              <Dropdown
                className={'text-center btn-reveal-trigger'}
                onClick={(event) => event.stopPropagation()}
              >
                <Dropdown.Toggle
                  size={'sm'}
                  className={'btn-reveal'}
                  variant="falcon-default"
                  id="dropdown-assets-actions"
                >
                  <FontAwesomeIcon icon={'ellipsis-h'} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  popperConfig={{ strategy: 'fixed' }}
                  renderOnMount={true}
                >
                  {actionRows.map((item) => {
                    return (
                      <Dropdown.Item
                        className={`text-${item.color || 'primary'}`}
                        key={item.title}
                        onClick={() => {
                          if (item.onClick) {
                            item.onClick(tenantId)
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fs-0 me-2"
                        />
                        {item.title}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )
      },
      header: () => 'Actions',
    }),
  ]
  return columns
}

const defaultTenantActionsRow = (
  onDeleteItem: (id: string) => void,
  onEditItem: (id: string) => void
) => {
  const actionsRow: ActionRowProps[] = [
    {
      title: 'Edit',
      color: 'primary',
      icon: 'edit',
      onClick: (id) => {
        onEditItem(id)
      },
    },
    {
      title: 'Delete',
      color: 'danger',
      icon: 'trash-alt',
      onClick: (id) => {
        onDeleteItem(id)
      },
    },
  ]
  return actionsRow
}

export type TenantIDRouteParams = {
  tenantID: string
}

export type TenantContext = { tenantID: TenantId }

const useTenantContext = () => {
  return useOutletContext<TenantContext>()
}

export { getColumnsForTableTenant, useTenantContext, defaultTenantActionsRow }
