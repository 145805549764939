import React, { useEffect } from 'react'
import { useMeQuery } from '../../store/kopiliot-api/base-api'
import { useLazyGetUserQuery } from '../../store/kopiliot-api/user'
import Spinner from '../../components/spinner/Spinner'
import DefaultHomeDashboard from './DefaultHomeDashboard'
import GaugeComponent from 'react-gauge-component'
import { Col, Row } from 'react-bootstrap'
import DefaultHomeCustomerDashboard from './DefaultHomeCustomerDashboard'

const Home = () => {
  const {
    data: currentMe,
    isLoading: isLoadingMe,
    isFetching: isFetchingMe,
  } = useMeQuery(null)
  const [
    getUser,
    { isLoading: isLoadingUser, isFetching: isFetchingUser, data: user },
  ] = useLazyGetUserQuery()
  React.useEffect(() => {
    if (currentMe && currentMe.id) {
      getUser(currentMe.id.id)
    }
  }, [currentMe])
  const isLoading =
    isLoadingMe || isFetchingMe || isLoadingUser || isFetchingUser
  const renderHome = () => {
    if (user && user.homeDashboardID) {
      return <div>Home Dashboard ID: {user.homeDashboardID.id}</div>
    } else if (
      user?.authority === 'MAIN_TENANT' ||
      user?.authority === 'TENANT_ADMIN'
    ) {
      return <DefaultHomeDashboard tenantID={user.tenantID.id} />
    } else {
      return <DefaultHomeCustomerDashboard />
    }
  }
  return (
    <>
      {isLoading && <Spinner />}
      {renderHome()}
    </>
  )
}

export default Home
