import {
  Controller,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'
import { AssetProfile } from '../../../shared/models/asset-profile'
import Asset from '../../../shared/models/asset'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import React from 'react'
import SelectBase from '../../../components/page-components/selects-entities/generic-select/SelectBase'
import {
  useLazyGetRuleChainQuery,
  useLazyGetRuleChainsQuery,
} from '../../../store/kopiliot-api/rule-chain'
import { RuleChainId } from '../../../shared/models/id/rule-chain-id'
import { SelectableValue } from '../../../components/page-components/selects-entities/generic-select/selectable-value'
import _ from 'lodash'
import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../../store/kopiliot-api/tenant'
import TenantId from '../../../shared/models/id/tenant-id'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

interface AssetProfileFormProps {
  form: UseFormReturn<AssetProfile>
  isLoading?: boolean
  onSubmit: SubmitHandler<AssetProfile>
  initialValues?: Partial<AssetProfile>
  isEditing?: boolean
  hideFields?: Array<keyof AssetProfile>
}

const AssetProfileForm = ({
  form,
  isLoading,
  isEditing,
  initialValues,
  onSubmit,
  hideFields = [],
}: AssetProfileFormProps) => {
  const user = useSelector((state: RootState) => state.currentUser)
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form
  const watchDefaultRuleChainID = form.watch('defaultRuleChainId')
  const watchTenantID = form.watch('tenantId')
  const [getRuleChains, ruleChainResult] = useLazyGetRuleChainsQuery()
  const [getRuleChain] = useLazyGetRuleChainQuery()
  const [ruleChainOptions, setRuleChainOptions] = React.useState<
    SelectableValue<RuleChainId>[]
  >([])
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [getTenant, tenantResult] = useLazyGetTenantQuery()
  const [tenantOptions, setTenantOptions] = React.useState<
    SelectableValue<TenantId>[]
  >([])
  React.useEffect(() => {
    if (watchDefaultRuleChainID) {
      const fetchData = async () => {
        try {
          const rc = await getRuleChain(watchDefaultRuleChainID.id).unwrap()
          if (rc) {
            setRuleChainOptions([
              { label: rc.name, value: watchDefaultRuleChainID },
            ])
          }
        } catch (err) {
          //toast.error(err.message)
        }
      }
      fetchData()
    }
  }, [watchDefaultRuleChainID])
  React.useEffect(() => {
    const res = _.map(ruleChainResult.data?.rows, (tenant) => {
      return {
        label: tenant.name,
        value: tenant.id!,
      }
    })
    setRuleChainOptions(res)
  }, [ruleChainResult.data])
  React.useEffect(() => {
    // We update the list only if the user is a main tenant
    if (watchTenantID && user && user.authority == 'MAIN_TENANT') {
      const fetchData = async () => {
        try {
          const t = await getTenant(watchTenantID.id).unwrap()
          if (t) {
            setTenantOptions([{ label: t.name, value: watchTenantID }])
          }
        } catch (err) {
          //toast.error(err.message)
        }
      }
      fetchData()
    }
  }, [watchTenantID])
  React.useEffect(() => {
    const res = _.map(tenantsResult.data?.rows, (tenant) => {
      return {
        label: tenant.name,
        value: tenant.id!,
      }
    })
    setTenantOptions(res)
  }, [tenantsResult.data])
  const hideIt = (field: keyof AssetProfile) => {
    return !hideFields.includes(field)
  }
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={6}>
            <Input
              register={register('name', {
                required: 'Name field is required',
              })}
              label={'Nom'}
              error={errors.name}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={12}>
            <Input
              type={'textarea'}
              textAreaRows={4}
              register={register('description', {
                required: 'Description field is required',
              })}
              label={'Description'}
              error={errors.description}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={6}>
            <Form.Label htmlFor={'defaultRuleChainId'}>
              Default Rule Chain
            </Form.Label>
            <Controller
              control={control}
              name={'defaultRuleChainId'}
              rules={{ required: 'Please select a Default Rule Chain ID' }}
              render={({ field }) => {
                return (
                  <SelectBase
                    isLoading={
                      ruleChainResult.isLoading || ruleChainResult.isLoading
                    }
                    placeholder={'Rechercher...'}
                    options={ruleChainOptions}
                    onInputChange={(value) => {
                      getRuleChains({
                        textSearch: value,
                        offset: 0,
                        limit: 40,
                      }).unwrap()
                    }}
                    isClearable={true}
                    debounceDelay={1000}
                    minCharsForSearch={2}
                    value={field.value ? { value: field.value } : undefined}
                    onChange={(value: SelectableValue<RuleChainId>) => {
                      if (value) {
                        field.onChange(value?.value)
                      }
                    }}
                    valueComparator={(a, b) => {
                      return a?.value?.id === b?.value?.id
                    }}
                    errorMessage={errors.defaultRuleChainId?.message}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3 g-3">
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Label htmlFor={'tenantID'}>Tenant</Form.Label>
            <Controller
              control={control}
              name={'tenantId'}
              rules={{ required: 'Please select a tenant' }}
              render={({ field }) => {
                return (
                  <SelectBase
                    isLoading={
                      tenantsResult.isLoading || tenantResult.isLoading
                    }
                    placeholder={'Rechercher...'}
                    options={tenantOptions}
                    onInputChange={(value) => {
                      getTenants({
                        textSearch: value,
                        offset: 0,
                        limit: 40,
                      }).unwrap()
                    }}
                    isClearable={true}
                    debounceDelay={1000}
                    minCharsForSearch={2}
                    value={field.value ? { value: field.value } : undefined}
                    onChange={(value: SelectableValue<TenantId>) => {
                      if (value) {
                        field.onChange(value?.value)
                      }
                    }}
                    valueComparator={(a, b) => {
                      return a?.value?.id === b?.value?.id
                    }}
                    errorMessage={errors.tenantId?.message}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default AssetProfileForm
