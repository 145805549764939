import { ActionRowProps } from '../../components/kopiliot-list/type'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Alarm, AlarmStatus } from '../../shared/models/alarm.model'
import React from 'react'
import CustomerName from '../../features/helper/CustomerName'
import TenantName from '../../features/helper/TenantName'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import SubtleBadge from '../../components/common/SubtleBadge'
import {
  getColorForAcknowledge,
  getColorForActive,
  getColorForAlarmSeverity,
} from '../../features/alarm/tools'

const getColumnsForTableAlarm = (actionRows: ActionRowProps[]) => {
  const columnHelper = createColumnHelper<Alarm>()

  const columns: ColumnDef<Alarm, any>[] = [
    columnHelper.accessor((row) => row.id?.id, {
      id: 'id',
      cell: (info) => info.getValue(),
      header: () => 'Id',
    }),
    columnHelper.accessor((row) => row.createdAt?.toLocaleString(), {
      id: 'createdAt',
      cell: (info) => {
        try {
          return dayjs(info.getValue()).format('DD/MM/YYYY HH:mm:ss.SSS')
        } catch (error) {
          return 'Impossible de formater la date'
        }
      },
      header: () => 'Date',
    }),
    columnHelper.accessor((row) => row.type, {
      id: 'type',
      cell: (info) => info.getValue(),
      header: () => 'Type',
    }),
    columnHelper.display({
      id: 'actionType',
      cell: (props) => {
        const { severity } = props.row.original
        return (
          <>
            <SubtleBadge bg={getColorForAlarmSeverity(severity)} pill>
              {severity.toUpperCase()}
            </SubtleBadge>
          </>
        )
      },
      header: () => 'Action',
    }),
    columnHelper.accessor((row) => row.status.toString(), {
      id: 'acknowledged',
      cell: (info) => {
        return (
          <SubtleBadge bg={getColorForAcknowledge(info.getValue())} pill>
            {info.getValue() === AlarmStatus.CLEARED_UNACK ||
            info.getValue() === AlarmStatus.ACTIVE_UNACK
              ? 'Oui'
              : 'Non'}
          </SubtleBadge>
        )
      },
      header: () => 'Aquittée',
    }),
    columnHelper.accessor((row) => row.status.toString(), {
      id: 'cleared',
      cell: (info) => {
        return (
          <SubtleBadge bg={getColorForActive(info.getValue())} pill>
            {info.getValue() === AlarmStatus.ACTIVE_ACK ||
            info.getValue() === AlarmStatus.ACTIVE_UNACK
              ? 'Oui'
              : 'Non'}
          </SubtleBadge>
        )
      },
      header: () => 'Active',
    }),
    columnHelper.display({
      id: 'customer',
      cell: (props) => {
        return (
          <>
            {props.row.original.customerID && (
              <CustomerName customerId={props.row.original.customerID.id} />
            )}
          </>
        )
      },
      header: () => 'Customer',
    }),
    columnHelper.display({
      id: 'tenantID',
      cell: (props) => {
        return (
          <>
            <TenantName tenantId={props.row.original.tenantID.id} />
          </>
        )
      },
      header: () => 'Tenant',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        const userID = props.row.original.id?.id
        return (
          <>
            {actionRows && userID && (
              <Dropdown
                className={'text-center btn-reveal-trigger'}
                onClick={(event) => event.stopPropagation()}
              >
                <Dropdown.Toggle
                  size={'sm'}
                  className={'btn-reveal'}
                  variant="falcon-default"
                  id="dropdown-assets-actions"
                >
                  <FontAwesomeIcon icon={'ellipsis-h'} />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  popperConfig={{ strategy: 'fixed' }}
                  renderOnMount={true}
                >
                  {actionRows.map((item) => {
                    return (
                      <Dropdown.Item
                        className={`text-${item.color || 'primary'}`}
                        key={item.title}
                        onClick={() => {
                          if (item.onClick) {
                            item.onClick(userID)
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fs-0 me-2"
                        />
                        {item.title}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )
      },
      header: () => 'Actions',
    }),
  ]
  return columns
}

const defaultAlarmActionRow = (onEditItem: (id: string) => void) => {
  const actionsRow: ActionRowProps[] = [
    {
      title: 'Edit',
      color: 'primary',
      icon: 'edit',
      onClick: (id) => {
        onEditItem(id)
      },
    },
  ]
  return actionsRow
}

export { getColumnsForTableAlarm, defaultAlarmActionRow }
