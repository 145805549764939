import React from 'react'
import { useGetDeviceInfoByDeviceIdQuery } from '../../store/kopiliot-api/device'
import Spinner from '../../components/spinner/Spinner'
import { Card, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubtleBadge from '../../components/common/SubtleBadge'
import { HubInfoID } from '../../shared/models/id/hub_info_id'
import { useGetHubInfoByTenantIDQuery } from '../../store/kopiliot-api/tenant'
import TenantId from '../../shared/models/id/tenant-id'

interface HubInfoProps {
  tenantID: TenantId
}

const HubInfo = ({ tenantID }: HubInfoProps) => {
  const { data, isLoading, isFetching, error } = useGetHubInfoByTenantIDQuery(
    tenantID.id
  )
  const isLoadingOrFetching = isLoading || isFetching
  return (
    <>
      {isLoadingOrFetching && <Spinner />}
      {JSON.stringify(data)}
      <Row className={'g-0'}></Row>
    </>
  )
}

export default HubInfo
