import React from 'react'
import NotFound from '../pages/error/NotFound'
import Forbidden from '../pages/error/Forbidden'

interface Props {
  error: any
}

const HandleError = ({ error }: Props) => {
  console.log(error)
  switch (error?.status) {
    case 401:
      return <div>401</div>
    case 403:
      return <Forbidden />
    case 404:
      return <NotFound />
  }
  if (error?.status >= 500) {
    return <div>500</div>
  }
  return <div>Unknown error</div>
}

export default HandleError
