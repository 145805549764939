import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { baseArkhaleAPI } from '../kopiliot-api/base-api'
import User, { Authority } from '../../shared/models/user'
import TenantId from '../../shared/models/id/tenant-id'
import { EntityType } from '../../shared/models/entity-type.models'
import { CustomerId } from '../../shared/models/id/customer-id'

export interface CurrentUserState {
  isLogged: boolean
  email: string
  firstname: string
  lastname: string
  jobTitle: string
  authority: Authority
  userMetadata: {
    theme?: 'dark' | 'light'
    isNavbarVerticalCollapsed: boolean
  }
  customerID?: CustomerId
  tenantID: TenantId
}

// TODO CLEAN THIS STATE
const initialState: CurrentUserState = {
  isLogged: false,
  email: 's.tachier@arkhale.com',
  firstname: 'Sébastien',
  lastname: 'Tachier',
  jobTitle: 'Lead Devloper',
  authority: Authority.MAIN_TENANT,
  userMetadata: {
    theme: 'light',
    isNavbarVerticalCollapsed: false,
  },
  tenantID: {
    id: '1',
    entityType: EntityType.TENANT,
  },
}

export const currentUserSlice = createSlice({
  name: 'current_user',
  initialState,
  reducers: {
    switchTheme: (state) => {
      state.userMetadata.theme =
        state.userMetadata.theme === 'light' ? 'dark' : 'light'
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      baseArkhaleAPI.endpoints.me.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        state.isLogged = true
        state.email = action.payload.email
        state.firstname = action.payload.firstname
        state.lastname = action.payload.lastname
        state.authority = action.payload.authority
        state.jobTitle = action.payload.jobTitle
        state.userMetadata = action.payload.userMetadata
        state.customerID = action.payload.customerId
        state.tenantID = action.payload.tenantID
      }
    )
  },
})

// Action creators are generated for each case reducer function
export const { switchTheme } = currentUserSlice.actions

export default currentUserSlice.reducer
