import React, { useEffect, useState } from 'react'
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext,
  UseFormRegisterReturn,
} from 'react-hook-form'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { QueryDefinition } from '@reduxjs/toolkit/query'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import './genericSelect.css'

interface GenericSelectProps<
  T extends QueryDefinition<any, any, any, any>,
  U extends QueryDefinition<any, any, any, any>
> {
  listQuery: UseLazyQuery<T>
  getQuery: UseLazyQuery<U>
  register: UseFormRegisterReturn<any>
  label?: string
  error?: { message?: string }
  valueConstructor: (value: any) => any
  isDisabled?: boolean
}

const GenericSelect = <
  T extends QueryDefinition<any, any, any, any>,
  U extends QueryDefinition<any, any, any, any>
>({
  listQuery,
  getQuery,
  register,
  label,
  error,
  valueConstructor,
  isDisabled,
}: GenericSelectProps<T, U>) => {
  const { theme } = useSelector((state: RootState) => state.settings)
  // Using form context
  const form = useFormContext()
  // State
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  )
  // List result
  const [
    getList,
    { data: listResult, isLoading: isLoadingList, isFetching: isFetchingList },
  ] = listQuery()
  // Get result
  const [
    get,
    { data: getResult, isLoading: isLoadingGet, isFetching: isFetchingGet },
  ] = getQuery()
  // useEffect
  useEffect(() => {
    const search = setTimeout(() => {
      if (searchTerm && searchTerm.length > 3) {
        getList({ textSearch: searchTerm, offset: 0, limit: 40 } as any, true)
      }
    }, 1000)
    return () => clearTimeout(search)
  }, [searchTerm])
  useEffect(() => {
    if (selectedValue) {
      get(selectedValue as any, true)
    }
    if (form.getValues(register.name)?.id) {
      setSelectedValue(form.getValues(register.name)?.id)
    }
  }, [selectedValue, form.getValues(register.name)?.id])

  // helper functions
  const isFetching = isFetchingList || isFetchingGet
  const isLoading = isLoadingList || isLoadingGet
  const isLoadingOrFetching = isLoading || isFetching
  const onInputChange = (value: string) => {
    setSearchTerm(value)
  }

  const getOptions = () => {
    const res = _.chain(listResult?.rows)
      .map((result) => {
        if (result.id) {
          return { value: result.id.id, label: result.name }
        }
      })
      .compact()
      .value()
    if (getResult?.id && !_.find(res, { value: getResult.id.id })) {
      res.unshift({ value: getResult.id.id, label: getResult.name })
    }
    return res
  }
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: error ? '#80bdf2' : provided.borderColor,
    }),
  }
  return (
    <>
      <Controller
        name={register.name}
        render={({ field: { onChange } }) => {
          return (
            <>
              {label && (
                <Form.Label htmlFor={register.name}>{label}</Form.Label>
              )}
              <Select
                styles={customStyles}
                className={'generic-select ' + theme}
                aria-invalid={error ? 'true' : 'false'}
                classNamePrefix={'react-select'}
                onChange={(value, actionMeta) => {
                  if (value) {
                    setSelectedValue(value.value)
                    onChange(valueConstructor(value.value))
                  } else {
                    onChange(undefined)
                  }
                  if (actionMeta.action === 'clear') {
                    setSelectedValue(undefined)
                    onChange(undefined)
                  }
                }}
                isDisabled={isDisabled}
                isClearable
                backspaceRemovesValue
                isLoading={isLoadingOrFetching}
                onInputChange={onInputChange}
                options={getOptions()}
                {..._.omit(register, ['onChange'])}
                value={
                  getResult?.id && selectedValue
                    ? { value: getResult.id.id, label: getResult.name }
                    : undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                {error && error.message}
              </Form.Control.Feedback>
            </>
          )
        }}
      />
    </>
  )
}

export default GenericSelect
