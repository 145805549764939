import React from 'react'
import { useGetDeviceInfoByDeviceIdQuery } from '../../store/kopiliot-api/device'
import Spinner from '../../components/spinner/Spinner'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalizeFirstLetter } from '../../utils/utils'
import { DeviceStatus } from '../../shared/models/device-info'
import Flex from '../../components/common/Flex'
import { formatDate } from '../../helpers/utls'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import DeviceMetrics from '../scw-metrics/DeviceMetrics'
import RenewDeviceCertificateModal from './modal/RenewDeviceCertificateModal'
import { EntityType } from '../../shared/models/entity-type.models'
import AssignDeviceToCustomerModal from './modal/AssignDeviceToCustomerModal'

interface DeviceInfosProps {
  deviceId: string
}

const DeviceInfos = ({ deviceId }: DeviceInfosProps) => {
  const [showRenewModal, setShowRenewModal] = React.useState(false)
  const [showAssignModal, setShowAssignModal] = React.useState(false)
  const { data, isLoading, isFetching, error } =
    useGetDeviceInfoByDeviceIdQuery(deviceId)
  const isLoadingOrFetching = isLoading || isFetching
  const statusColor = (status?: DeviceStatus): string => {
    switch (status) {
      case DeviceStatus.ENABLED:
        return '#2c8564'
      case DeviceStatus.DISABLED:
        return '#c4c4c4'
      case DeviceStatus.ERROR:
        return '#b3144d'
      default:
        return '#FFFFFF'
    }
  }

  return (
    <>
      {isLoadingOrFetching && <Spinner />}
      <h5 className={'mt-3'}>Device Informations</h5>
      {data && (
        <Container fluid className={'border mt-2 rounded p-2'}>
          <Row className={'mx-1'}>
            <Col sm={3} className={''}>
              <Flex direction={'column'}>
                <p className={'mb-0'}>
                  <b>Status:</b>{' '}
                </p>
                <Flex alignItems={'center'}>
                  <span
                    className={'rounded-circle d-inline-block me-1'}
                    style={{
                      backgroundColor: statusColor(data.status),
                      width: '10px',
                      height: '10px',
                    }}
                  />
                  {capitalizeFirstLetter(data.status)}
                </Flex>
              </Flex>
            </Col>
            <Col sm={3}>
              <Flex direction={'column'}>
                <p className={'mb-0'}>
                  <b>Connection:</b>{' '}
                </p>
                <Flex alignItems={'center'}>
                  <span
                    className={'rounded-circle d-inline-block me-1'}
                    style={{
                      backgroundColor: data.isConnected ? '#2c8564' : '#c4c4c4',
                      width: '10px',
                      height: '10px',
                    }}
                  />
                  {data.isConnected ? 'Connected' : 'Disconnected'}
                </Flex>
              </Flex>
            </Col>
            <Col sm={3}>
              <Flex direction={'column'}>
                <p className={'mb-0'}>
                  <b>Added:</b>{' '}
                </p>
                {formatDate(data.lastActivityAt)}
              </Flex>
            </Col>
            <Col sm={3}>
              <Flex direction={'column'}>
                <p className={'mb-0'}>
                  <b>Modified:</b>{' '}
                </p>
                {formatDate(data.updated_at)}
              </Flex>
            </Col>
          </Row>
          <hr />
          <Row className={'mx-1'}>
            <Col sm={9}>
              <b>Device ID:</b> {data.id.id}{' '}
              <Button
                variant={'light'}
                size={'sm'}
                onClick={() => {
                  navigator.clipboard
                    .writeText(data.id.id)
                    .then()
                    .catch((err) => {
                      alert('Failed to copy: ' + err)
                    })
                }}
              >
                <FontAwesomeIcon className={'cursor-pointer'} icon={faCopy} />
              </Button>
            </Col>
            <Col sm={3}>
              <Flex direction={'column'}>
                <p className={'mb-0'}>
                  <b>Connection security:</b>{' '}
                </p>
                <div>
                  <FontAwesomeIcon
                    icon={data.allowInsecure ? 'times' : 'check'}
                    className={
                      data.allowInsecure ? 'text-danger' : 'text-success'
                    }
                  />{' '}
                  {data.allowInsecure ? 'Insecure' : 'Secure'}
                </div>
              </Flex>
            </Col>
          </Row>
          <hr />
          <Row className={'mx-1'}>
            <Col md={6} sm={12}>
              <Flex direction={'column'}>
                <p className={'mb-0'}>
                  <b>Description:</b>{' '}
                </p>
                {data.description}
              </Flex>
            </Col>
            <Col md={6} sm={12}>
              <Flex direction={'column'}>
                <p className={'mb-0'}>
                  <b>Filtres de publication :</b>{' '}
                </p>
                <p>
                  Politique: {data.messageFilters.publish.policy.toUpperCase()}
                </p>
                <p>Topics</p>
                <p>
                  {data.messageFilters.publish.topics.map((item, index) => {
                    return (
                      <>
                        <span key={index}>{item}</span>
                        <br />
                      </>
                    )
                  })}
                </p>
                <p className={'mb-0'}>
                  <b>Filtres de souscription :</b>{' '}
                </p>
                <p>
                  Politique:{' '}
                  {data.messageFilters.subscribe.policy.toUpperCase()}
                </p>
                <p>Topics</p>
                <p>
                  {data.messageFilters.subscribe.topics.map((item, index) => {
                    return (
                      <>
                        <span key={index}>{item}</span>
                        <br />
                      </>
                    )
                  })}
                </p>
              </Flex>
            </Col>
          </Row>
        </Container>
      )}
      <h5 className={'mt-3'}>Metrics</h5>
      <Container fluid className={'border mt-2 rounded p-2'}>
        <DeviceMetrics deviceID={deviceId} />
      </Container>
      <h5 className={'mt-3 text-danger'}>Danger zone</h5>
      <Container fluid>
        <Row className={'border mt-2 rounded p-2'}>
          <Flex direction={'row'} justifyContent={'between'}>
            <h5 className={'mt-2 text-danger'}>Renouveler les certificats</h5>
            <div>
              <Button
                variant={'outline-danger'}
                onClick={() => setShowRenewModal(true)}
              >
                Renouveler
              </Button>
            </div>
          </Flex>
        </Row>
        <Row className={'border mt-2 rounded p-2'}>
          <Flex direction={'row'} justifyContent={'between'}>
            <h5 className={'mt-2 text-danger'}>
              Assigner ce device à un client
            </h5>
            <div>
              <Button
                variant={'outline-danger'}
                onClick={() => setShowAssignModal(true)}
              >
                Assigner
              </Button>
            </div>
          </Flex>
        </Row>
      </Container>
      <RenewDeviceCertificateModal
        isOpen={showRenewModal}
        onClose={() => setShowRenewModal(false)}
        deviceID={{ id: deviceId, entityType: EntityType.DEVICE }}
      />
      <AssignDeviceToCustomerModal
        isOpen={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        deviceID={{ id: deviceId, entityType: EntityType.DEVICE }}
      />
    </>
  )
}

export default DeviceInfos
