import React, { useContext, useEffect, Fragment } from 'react'
import { Nav, Navbar, Row, Col } from 'react-bootstrap'
import Flex from '../../common/Flex'
import ToggleButton from './ToggleButton'
import Logo from '../../common/Logo'
import routes from '../../../routes/routes'
import NavbarVerticalMenu from './NavbarVerticalMenu'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

const NavbarLabel = ({ label }: { label: string }) => (
  <Nav.Item as="li">
    <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
      <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
        {label}
      </Col>
      <Col className="ps-0">
        <hr className="mb-0 navbar-vertical-divider"></hr>
      </Col>
    </Row>
  </Nav.Item>
)

const NavbarVertical = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  const { isBurgerMenuCollapsed } = useSelector(
    (state: RootState) => state.menus
  )
  const { isNavbarVerticalCollapsed } = useSelector(
    (state: RootState) => state.settings
  )
  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed')
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed')
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover')
    }
  }, [isNavbarVerticalCollapsed, HTMLClassList])
  //Control mouseEnter event
  let time: null | NodeJS.Timeout = null
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover')
      }, 100)
    }
  }
  const handleMouseLeave = () => {
    if (time) {
      clearTimeout(time)
    }
    HTMLClassList.remove('navbar-vertical-collapsed-hover')
  }

  return (
    <Navbar
      expand={'lg'}
      className={'navbar-vertical navbar-expand-xl navbar-light'}
      variant="light"
    >
      <Flex alignItems={'center'}>
        <ToggleButton />
        <Logo at={'navbar-vertical'} />
      </Flex>
      <Navbar.Collapse
        in={!isBurgerMenuCollapsed}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="navbar-vertical-content scrollbar">
          <Nav className={'flex-column'} as={'ul'}>
            {routes.map((route) => (
              <Fragment key={route.label}>
                {!route.labelDisabled && (
                  <NavbarLabel label={route.label.toUpperCase()} />
                )}
                <NavbarVerticalMenu routes={route.children} />
              </Fragment>
            ))}
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavbarVertical
