import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Panel } from '../../../../shared/models/panel'
import Input from '../../../../components/form/Input'

const TooltipForm = () => {
  const { register, watch, control, formState, getValues, setValue } =
    useFormContext<Panel>()
  const watchTooltip = watch('config.settings.plugins.tooltip.enabled')
  return (
    <>
      <Input
        register={register('config.settings.plugins.tooltip.enabled', {
          shouldUnregister: true,
        })}
        type={'switch'}
        label={'Visibility'}
      />
      {watchTooltip && (
        <>
          <Input
            register={register('config.settings.plugins.tooltip.mode', {
              shouldUnregister: true,
            })}
            type={'select'}
            label={'Mode'}
            options={[
              { value: 'point', text: 'Point' },
              { value: 'nearest', text: 'Nearest' },
              { value: 'index', text: 'Index' },
              { value: 'dataset', text: 'Dataset' },
              { value: 'x', text: 'X' },
              { value: 'y', text: 'Y' },
            ]}
          />
          <Input
            register={register('config.settings.plugins.tooltip.intersect', {
              shouldUnregister: true,
            })}
            type={'switch'}
            label={'Intersect'}
          />
          <Input
            register={register('config.settings.plugins.tooltip.position', {
              shouldUnregister: true,
            })}
            type={'select'}
            label={'Position'}
            options={[
              { value: 'average', text: 'Average' },
              { value: 'nearest', text: 'Nearest' },
            ]}
          />
          <Input
            type={'color'}
            label={'Background color'}
            register={register(
              'config.settings.plugins.tooltip.backgroundColor',
              { shouldUnregister: true }
            )}
          />
        </>
      )}
    </>
  )
}

export default TooltipForm
