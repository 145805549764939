import React from 'react'
import { Table, TableProps } from 'react-bootstrap'
import {
  flexRender,
  getCoreRowModel,
  InitialTableState,
  Row,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import SimpleBarReact from 'simplebar-react'

interface AdvanceTableProps {
  tableProps?: TableProps
  headerClassName?: string
  rowClassName?: string
  columns?: any[]
  data?: any[]
  hiddenColumns?: string[]
  onClickRow?: (row: Row<any>) => void
}

const AdvanceTable = ({
  tableProps,
  headerClassName,
  rowClassName,
  columns = [],
  data = [],
  hiddenColumns,
  onClickRow,
}: AdvanceTableProps) => {
  const initialState: InitialTableState = {}
  if (hiddenColumns) {
    const columnVisibilityConfig: VisibilityState = {}
    hiddenColumns.map((item) => (columnVisibilityConfig[item] = false))
    initialState.columnVisibility = columnVisibilityConfig
  }
  const table = useReactTable({
    data,
    columns,
    initialState,
    getCoreRowModel: getCoreRowModel(),
  })

  React.useEffect(() => {
    if (hiddenColumns) {
      const columnVisibilityConfig: VisibilityState = {}
      hiddenColumns.map((item) => (columnVisibilityConfig[item] = false))
      table.setColumnVisibility(columnVisibilityConfig)
    }
  }, [hiddenColumns])

  // https://stackoverflow.com/questions/62152372/changing-column-width-in-react-table
  return (
    <SimpleBarReact>
      <Table {...tableProps}>
        <thead className={headerClassName}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={'ps-2'}
                  style={{ width: header.getSize(), height: '46px' }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      role={header.column.getCanSort() ? 'button' : ''}
                      onClick={() => console.log('click')}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={rowClassName}
              onClick={onClickRow ? () => onClickRow(row) : undefined}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={'py-2 pe-4'}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </SimpleBarReact>
  )
}
export default AdvanceTable
