import React from 'react'
import { Button, ButtonProps, Spinner } from 'react-bootstrap'
import classNames from 'classnames'

interface ButtonLoadingProps extends ButtonProps {
  text?: string
  isLoading?: boolean
}

const ButtonLoading = ({ isLoading, text, ...rest }: ButtonLoadingProps) => {
  return (
    <Button {...rest}>
      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className={'pe-2'}
        />
      )}
      <span className={classNames(isLoading, 'ps-1')}>
        {text ? text : ' Submit'}
      </span>
    </Button>
  )
}

export default ButtonLoading
