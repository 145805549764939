import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ProfileBanner from './ProfileBanner'
import User from '../../../shared/models/user'
import VerifiedBadge from '../../../components/common/VerifiedBadge'
import {
  useMyCustomerQuery,
  useMyTenantQuery,
} from '../../../store/kopiliot-api/me'
import UserSessions from './UserSessions'

interface ProfileProps {
  user: User
}

const Profile = ({ user }: ProfileProps) => {
  const {
    data: tenant,
    error: errorTenant,
    isLoading: isLoadingTenant,
  } = useMyTenantQuery()
  const {
    data: customer,
    error: errorCustomer,
    isLoading: isLoadingCustomer,
  } = useMyCustomerQuery()
  const atText = () => {
    if (isLoadingTenant || isLoadingCustomer) return '...'
    if (errorTenant || errorCustomer) return 'Error loading tenant or customer'
    if (user.customerId && customer) return `chez ${customer?.name}`
    if (tenant) return `chez ${tenant?.name}`
  }
  return (
    <>
      <Row className="g-3 mb-3">
        <ProfileBanner>
          <ProfileBanner.Header name={`${user.firstname} ${user.lastname}`} />
          <ProfileBanner.Body>
            <Row>
              <Col lg={8}>
                <h4 className="mb-1">
                  {user.firstname} {user.lastname} <VerifiedBadge />
                </h4>
                <h5 className="fs-0 fw-normal">
                  {user.jobTitle} chez {atText()}
                </h5>
              </Col>
            </Row>
          </ProfileBanner.Body>
        </ProfileBanner>
      </Row>
    </>
  )
}

export default Profile
