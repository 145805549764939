import React, { useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  SortingState,
  Updater,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import { Pagination } from '../../shared/models/others/pagination'
import { Table, TableProps } from 'react-bootstrap'
import SimpleBarReact from 'simplebar-react'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import './KopiliotListTypeTable.css'
import {
  SortOrder,
  SortOrderDirection,
} from '../../store/kopiliot-api/query-type'

declare module '@tanstack/table-core/build/lib/types' {
  export interface ColumnMeta<TData extends RowData, TValue> {
    className?: string
  }
}

export interface KopiliotListTypeTableProps<T extends any> {
  columns: ColumnDef<T, string>[]
  data?: Pagination<T>
  tableProps?: TableProps
  headerClassName?: string
  rowClassName?: string
  cellClassName?: string
  hiddenColumns?: string[]
  onClickRow?: (row: Row<T>) => void
  sortOrder?: SortOrder
  setSortOrder?: (sorting: SortOrder) => void
}

const KopiliotListTypeTable = <T extends any>({
  columns,
  data,
  tableProps,
  headerClassName,
  rowClassName,
  cellClassName,
  hiddenColumns,
  onClickRow,
  sortOrder,
  setSortOrder,
}: KopiliotListTypeTableProps<T>) => {
  // outside component
  const emptyArray: any[] = []
  const memoizedData = React.useMemo(() => {
    return data?.rows.length && data.rows.length > 0 ? data.rows : emptyArray
  }, [data])
  const [sorting, setSorting] = useState<SortingState>([])
  React.useEffect(() => {
    if (setSortOrder && sorting) {
      if (sorting.length > 0) {
        const newSortOrder: SortOrder = {
          property: sorting[0].id,
          direction: sorting[0].desc
            ? SortOrderDirection.DESC
            : SortOrderDirection.ASC,
        }
        setSortOrder(newSortOrder)
      } else {
        setSortOrder({ property: '', direction: SortOrderDirection.ASC })
      }
    }
  }, [sorting])
  const table = useReactTable({
    data: memoizedData,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    //getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    manualSorting: true,
  })
  React.useEffect(() => {
    if (hiddenColumns) {
      const columnVisibilityConfig: VisibilityState = {}
      hiddenColumns.map((item) => (columnVisibilityConfig[item] = false))
      table.setColumnVisibility(columnVisibilityConfig)
    }
  }, [hiddenColumns])
  return (
    <SimpleBarReact>
      <Table {...tableProps}>
        <thead className={headerClassName}>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id} className={rowClassName}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      className={classNames(
                        'ps-2 text-800 user-select-none',
                        header.column.columnDef.meta?.className
                      )}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          role={header.column.getCanSort() ? 'button' : ''}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {(header.column.getCanSort() &&
                            {
                              asc: <span className="sort desc" />,
                              desc: <span className="sort asc" />,
                            }[header.column.getIsSorted() as string]) ?? (
                            <span className="sort" />
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>
        <tbody>
          {memoizedData.length <= 0 ? (
            <tr>
              <td colSpan={columns.length} className="text-center">
                Aucun enregistrement trouvé
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => {
              if (row && row.original) {
                try {
                  return (
                    <tr
                      key={row.id}
                      className={rowClassName}
                      onClick={onClickRow ? () => onClickRow(row) : undefined}
                    >
                      {row.getVisibleCells().map((cell) => {
                        try {
                          return (
                            <td
                              key={cell.id}
                              className={
                                cell.column.columnDef.meta
                                  ? cell.column.columnDef.meta.className
                                  : 'text-800'
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        } catch (error) {
                          toast.error('Error while creating cell')
                        }
                      })}
                    </tr>
                  )
                } catch (error) {
                  toast.error('Error while creating row')
                }
              }
            })
          )}
        </tbody>
      </Table>
    </SimpleBarReact>
  )
}

export default KopiliotListTypeTable
