import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

interface AdvanceTableSearchBoxProps {
  placeholder?: string
  globalFilter?: string
  setGlobalFilter?: (filter?: string) => void
}

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  placeholder = 'Search...',
}: AdvanceTableSearchBoxProps) => {
  const [filter, setFilter] = useState(globalFilter)

  useEffect(() => {
    const search = setTimeout(() => {
      if (setGlobalFilter) {
        setGlobalFilter(filter || undefined)
      }
    }, 1000)
    return () => clearTimeout(search)
  }, [filter])

  return (
    <InputGroup className="position-relative">
      <FormControl
        value={filter || ''}
        onInput={(e) => {
          setFilter(e.currentTarget.value)
        }}
        size="sm"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
      />
      <InputGroup.Text className="bg-transparent">
        <FontAwesomeIcon icon="search" className="fs--1 text-600" />
      </InputGroup.Text>
    </InputGroup>
  )
}

export default AdvanceTableSearchBox
