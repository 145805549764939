import React, { useEffect } from 'react'
import { Handle, Position } from 'reactflow'
import NodeTemplate from '../../base/NodeTemplate'
import { SaveTimeseriesConfiguration } from '../../action/timeseries/SaveTimeseriesForm'
import { BaseNodeProps } from '../../base/types'

const EntityTypeNode = React.memo(
  ({ data, isConnectable }: BaseNodeProps<SaveTimeseriesConfiguration>) => {
    const title = 'Entity type'
    return (
      <>
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
        />
        <NodeTemplate icon={'filter'} title={title} name={data.name} />
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          isConnectable={isConnectable}
        />
      </>
    )
  }
)

export default EntityTypeNode
