import React from 'react'
import { useSearchParams } from 'react-router-dom'
import PasswordResetForm from './PasswordResetForm'

const ActivateAccount = () => {
  const [searchParams] = useSearchParams()

  return (
    <PasswordResetForm
      token={searchParams.get('activation_token')}
      title={'Activer votre compte'}
      action={'activate'}
    />
  )
}

export default ActivateAccount
