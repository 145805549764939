import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
  useLazyGetDeviceQuery,
  useDeleteDeviceMutation,
} from '../../../store/kopiliot-api/device'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  deviceID?: string
}

const DeleteDeviceModal = ({ isOpen, setIsOpen, deviceID }: Props) => {
  const [triggerGetDevice, resultGetDevice] = useLazyGetDeviceQuery()
  useEffect(() => {
    if (deviceID) {
      triggerGetDevice(deviceID, true)
    }
  }, [deviceID])
  const [deleteCustomer, { isLoading: isDeleting }] = useDeleteDeviceMutation()
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Modal
        show={isOpen && deviceID !== undefined}
        onHide={handleClose}
        size={'lg'}
        className={'border'}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title as="h5">
            Supprimer le client {`${resultGetDevice?.data?.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'p-2'}>
          <p>
            {`Êtes-vous sûr de vouloir supprimer le client ${resultGetDevice?.data?.name} ?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={'button'}
            variant={'secondary'}
            onClick={handleClose}
            disabled={isDeleting}
          >
            Annuler
          </Button>
          <Button
            children={'Supprimer'}
            variant={'danger'}
            disabled={isDeleting}
            onClick={() => {
              if (deviceID) {
                deleteCustomer(deviceID)
              }
              handleClose()
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteDeviceModal
