import { baseArkhaleAPI } from './base-api'
import { AssetProfile } from '../../shared/models/asset-profile'
import { Pagination } from '../../shared/models/others/pagination'
import { PaginationWithSearchTerm } from './query-type'

const assetProfileApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getAssetProfile: build.query<AssetProfile, string>({
      query: (id) => `asset-profiles/${id}`,
      providesTags: (result, error, arg) => [{ type: 'AssetProfile', id: arg }],
    }),
    getAssetProfiles: build.query<
      Pagination<AssetProfile>,
      PaginationWithSearchTerm
    >({
      query: ({ limit, offset, textSearch }) =>
        `asset-profiles?textSearch=${textSearch}&limit=${limit}&offset=${offset}`,
      providesTags: (result = new Pagination<AssetProfile>(), error, arg) => [
        ...result.rows.map((item: AssetProfile) => {
          return {
            type: 'AssetProfile' as const,
            id: item.id?.id,
          }
        }),
        { type: 'AssetProfile', id: 'LIST' },
      ],
    }),
    addAssetProfile: build.mutation<AssetProfile, AssetProfile>({
      query: (assetProfile) => ({
        url: 'asset-profiles',
        method: 'POST',
        body: assetProfile,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'AssetProfile', id: 'LIST' },
      ],
    }),
    updateAssetProfile: build.mutation<AssetProfile, AssetProfile>({
      query: (assetProfile) => ({
        url: `asset-profiles/${assetProfile.id?.id}`,
        method: 'PUT',
        body: assetProfile,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'AssetProfile', id: arg.id?.id },
      ],
    }),
  }),
})

export const {
  useGetAssetProfileQuery,
  useLazyGetAssetProfileQuery,
  useGetAssetProfilesQuery,
  useLazyGetAssetProfilesQuery,
  useAddAssetProfileMutation,
  useUpdateAssetProfileMutation,
} = assetProfileApi
