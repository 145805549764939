import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from '../../store/store'
import classNames from 'classnames'

export interface KopiliotListActionsProps {
  listType: 'table' | 'card'
  setListType: React.Dispatch<React.SetStateAction<'table' | 'card'>>
  filterForm?: React.ReactNode
  onRefresh?: () => void
  onAdd?: () => void
}

const KopiliotListActions = ({
  listType,
  setListType,
  filterForm,
  onRefresh,
  onAdd,
}: KopiliotListActionsProps) => {
  const { theme } = useAppSelector((state) => state.settings)

  const [showInputSearch, setShowInputSearch] = React.useState(false)
  const isDark = theme === 'dark'
  return (
    <div
      className={
        'd-flex flex-wrap justify-content-center justify-content-sm-center justify-content-md-end'
      }
    >
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-primary"
          size={'sm'}
          className={'me-2'}
        >
          <span className={'me-2'}>Filter</span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={classNames({ 'text-700': isDark, 'text-500': !isDark })}
        >
          {filterForm}
        </Dropdown.Menu>
      </Dropdown>
      <Button
        size={'sm'}
        variant={'outline-primary'}
        className={'me-1'}
        onClick={() => setListType(listType === 'table' ? 'card' : 'table')}
      >
        <FontAwesomeIcon
          icon={listType === 'table' ? 'table-list' : 'rectangle-list'}
          className="fs-0"
        />
      </Button>
      {onRefresh && (
        <Button
          size={'sm'}
          variant={'outline-primary'}
          className={'me-2'}
          aria-controls="example-collapse-text"
          onClick={onRefresh}
        >
          <FontAwesomeIcon icon={'rotate-right'} className="fs-0" />
        </Button>
      )}
      {onAdd && (
        <Button
          size={'sm'}
          variant={'outline-primary'}
          className={'me-2'}
          aria-controls="example-collapse-text"
          onClick={onAdd}
        >
          <FontAwesomeIcon icon={'plus'} className="fs-0" />
        </Button>
      )}

      <Button
        size={'sm'}
        variant={'outline-primary'}
        className={'me-2'}
        aria-controls="example-collapse-text"
      >
        <FontAwesomeIcon icon={'external-link-alt'} className="fs-0" />
      </Button>
    </div>
  )
}

export default KopiliotListActions
