import React from 'react'
import ContentBlock from '../../../components/common/component-card/ContentBlock'
import User, { UserSession } from '../../../shared/models/user'
import _ from 'lodash'
import { Col, Row } from 'react-bootstrap'
import ButtonLoading from '../../../components/button/ButtonLoading'
import Divider from '../../../components/common/Divider'
import dayjs from 'dayjs'
import { useRevokeUserSessionMutation } from '../../../store/kopiliot-api/user'

interface UserSessionsProps {
  user: User
}

const UserSessions = ({ user }: UserSessionsProps) => {
  const [revoke, data] = useRevokeUserSessionMutation()
  const onRevokeSession = (session: UserSession) => {
    if (user.id) {
      revoke({ sessionID: session.sessionID, userID: user.id })
    }
  }
  return (
    <ContentBlock>
      <ContentBlock.Header>
        <h5>Sessions</h5>
      </ContentBlock.Header>
      <ContentBlock.Body>
        {_.map(user.userMetadata.userSessions, (session, key) => {
          return (
            <Row key={key}>
              <Col md={10} sm={12}>
                <h5>{session.sessionID}</h5>
                <span className={'d-block'}>
                  <b>IP : </b>
                  {session.ip}
                </span>
                <span className={'d-block'}>
                  <b>Date connexion : </b>
                  {dayjs(session.time).format('DD/MM/YYYY HH:mm:ss')}
                </span>
                <span className={'d-block'}>
                  <b>Expire à : </b>
                  {dayjs(session.expireAt).format('DD/MM/YYYY HH:mm:ss')}
                </span>
                <span className={'d-block'}>
                  <b>Détails : </b>
                  {session.details}
                </span>
              </Col>
              <Col md={2} sm={12}>
                <ButtonLoading
                  variant={'outline-danger'}
                  title={'Révoquer'}
                  text={'Révoquer'}
                  onClick={() => onRevokeSession(session)}
                />
              </Col>
              <Divider />
            </Row>
          )
        })}
      </ContentBlock.Body>
    </ContentBlock>
  )
}

export default UserSessions
