import React, { useRef } from 'react'
import ContentBlock from '../common/component-card/ContentBlock'
import KopiliotListActions, {
  KopiliotListActionsProps,
} from './KopiliotListActions'
import { Col, Row, TableProps } from 'react-bootstrap'
import { Pagination } from '../../shared/models/others/pagination'
import KopiliotListTypeTable from './KopiliotListTypeTable'
import KopiliotListTypeCard, {
  KopiliotListTypeCardProps,
} from './KopiliotListTypeCard'
import {
  ColumnDef,
  OnChangeFn,
  Row as ReactTableRow,
  SortingState,
} from '@tanstack/react-table'
import KopiliotListPagination from './KopiliotListPagination'
import Spinner from '../spinner/Spinner'
import KopiliotListRowPerPage from './KopiliotListRowPerPage'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useResizeObserver } from 'usehooks-ts'
import { SortOrder } from '../../store/kopiliot-api/query-type'

interface KopiliotListProps<T extends any> {
  title?: string
  icon?: IconProp
  isLoading?: boolean
  actionsProps?: Omit<KopiliotListActionsProps, 'listType' | 'setListType'>
  paginationProps: {
    pageLimit: number
    totalRecords: number
    onPageChanged: (page: {
      currentPage: number
      totalPages: number
      pageLimit: number
    }) => void
    onChangePageSize: (size: number) => void
  }
  data?: Pagination<T>
  tableProps: {
    columns: ColumnDef<T, string>[]
    tableProps?: TableProps
    headerClassName?: string
    rowClassName?: string
    cellClassName?: string
    hiddenColumns?: string[]
    onClickRow?: (row: ReactTableRow<T>) => void
    sortOrder?: SortOrder
    setSortOrder?: (sorting: SortOrder) => void
  }
  cardProps: Omit<KopiliotListTypeCardProps<T>, 'data'>
}

const KopiliotList = <T extends any>({
  title,
  icon,
  isLoading,
  actionsProps,
  data,
  tableProps,
  paginationProps,
  cardProps,
}: KopiliotListProps<T>) => {
  const [listType, setListType] = React.useState<'table' | 'card'>('table')
  const elementRef = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({
    ref: elementRef,
  })
  return (
    <div ref={elementRef}>
      <ContentBlock>
        <ContentBlock.Header>
          <Row>
            <Col md={6}>
              <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                {icon && (
                  <FontAwesomeIcon
                    icon={icon}
                    className="fs-0 pe-1 text-primary"
                  />
                )}
                <h4 className="mb-0 text-center text-md-start me-2 text-primary">
                  {title}
                </h4>
                <KopiliotListRowPerPage
                  onChange={paginationProps.onChangePageSize}
                  pageSize={paginationProps.pageLimit}
                />
              </div>
            </Col>
            <Col md={6} className={'mt-2 mt-md-0'}>
              <KopiliotListActions
                listType={listType}
                setListType={setListType}
                {...actionsProps}
              />
            </Col>
          </Row>
        </ContentBlock.Header>
        <ContentBlock.Body className={'p-0'} noLight>
          {isLoading && <Spinner />}
          {/*{listType === 'card' || width < 800 ? (
            <KopiliotListTypeCard data={data} {...cardProps} />
          ) : (
            <KopiliotListTypeTable {...tableProps} data={data} />
          )}*/}
          <KopiliotListTypeTable {...tableProps} data={data} />
        </ContentBlock.Body>
        <ContentBlock.Footer>
          <KopiliotListPagination
            pageLimit={paginationProps?.pageLimit}
            totalRecords={data?.totalRows ? data.totalRows : 0}
            onPageChanged={paginationProps?.onPageChanged}
          />
        </ContentBlock.Footer>
      </ContentBlock>
    </div>
  )
}

export default KopiliotList
