import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RecentSearchState {
  recentSearchTerms: string[]
}

const initialState: RecentSearchState = {
  recentSearchTerms: [],
}

export const recentSearchSlice = createSlice({
  name: 'recent_search',
  initialState,
  reducers: {
    addRecentSearchTerm: (state, action: PayloadAction<string>) => {
      const searchTerm = action.payload

      const index = state.recentSearchTerms.indexOf(searchTerm)
      if (index !== -1) {
        state.recentSearchTerms.splice(index, 1)
      }
      state.recentSearchTerms.unshift(searchTerm)
    },
    removeRecentSearchTerm: (state, action) => {
      const searchTerm = action.payload
      const index = state.recentSearchTerms.indexOf(searchTerm)
      if (index !== -1) {
        state.recentSearchTerms.splice(index, 1)
      }
    },
    clearRecentSearchTerms: (state) => {
      state.recentSearchTerms = []
    },
  },
})

export const {
  addRecentSearchTerm,
  removeRecentSearchTerm,
  clearRecentSearchTerms,
} = recentSearchSlice.actions

export default recentSearchSlice.reducer
