import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  Icons,
  IconSize,
  SimpleIndicatorDataKeySpecificProps,
  ThresholdOperator,
} from '../../../../../shared/models/panel'
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../components/form/Input'
import IconButton from '../../../../../components/common/IconButton'
import Divider from '../../../../../components/common/Divider'

interface SimpleIndicatorDataKeySpecificForm {
  initialValues?: SimpleIndicatorDataKeySpecificProps
  onSubmitted?: (data: any) => void
}

const SimpleIndicatorDataKeySpecificForm = ({
  initialValues,
  onSubmitted,
}: SimpleIndicatorDataKeySpecificForm) => {
  const form = useFormContext<SimpleIndicatorDataKeySpecificProps>()
  const {
    reset,
    watch,
    register,
    trigger,
    formState: { errors },
  } = form

  const { fields, append, remove, swap } =
    useFieldArray<SimpleIndicatorDataKeySpecificProps>({
      control: form.control,
      name: `thresholds`,
    })
  React.useEffect(() => {
    if (initialValues) {
      form.reset(initialValues)
    }
  }, [initialValues])
  return (
    <Form onSubmit={onSubmitted ? form.handleSubmit(onSubmitted) : undefined}>
      <Row>
        <Form.Group as={Col}>
          <Input
            type={'select'}
            label={'Alignement du texte'}
            register={register('align')}
            options={[
              { value: 'right', text: 'Droite' },
              { value: 'left', text: 'Gauche' },
            ]}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Input
            type={'select'}
            label={"Taille de l'icone"}
            register={register('iconSize')}
            options={Object.keys(IconSize).map((key) => ({
              text: key,
              value: (IconSize as { [index: string]: any })[key],
            }))}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Input
            type={'switch'}
            label={'Afficher le label'}
            register={register('showLabel')}
          />
        </Form.Group>
      </Row>

      <Divider />
      <Form.Group>
        <Form.Label>Couleurs</Form.Label>
        {fields.map((field, index) => {
          return (
            <Row key={field.id}>
              <Form.Group as={Col} md={2}>
                <Form.Label>Opérateur</Form.Label>
                <Input
                  type={'select'}
                  error={form.formState.errors?.thresholds?.[index]?.operator}
                  options={[
                    {
                      value: ThresholdOperator.GREATER_THAN,
                      text: 'Supérieur à',
                    },
                    {
                      value: ThresholdOperator.GREATER_THAN_OR_EQUAL,
                      text: 'Supérieur ou égal à',
                    },
                    { value: ThresholdOperator.LESS_THAN, text: 'Inférieur à' },
                    {
                      value: ThresholdOperator.LESS_THAN_OR_EQUAL,
                      text: 'Inférieur ou égal à',
                    },
                    { value: ThresholdOperator.EQUAL, text: 'Egal à' },
                    {
                      value: ThresholdOperator.NOT_EQUAL,
                      text: 'Différent de',
                    },
                    { value: ThresholdOperator.IN, text: 'Dans' },
                    { value: ThresholdOperator.NOT_IN, text: 'Pas dans' },
                    { value: ThresholdOperator.CONTAINS, text: 'Contiens' },
                    {
                      value: ThresholdOperator.NOT_CONTAINS,
                      text: 'Ne contiens pas',
                    },
                    { value: ThresholdOperator.MATCHES, text: 'Matches' },
                    {
                      value: ThresholdOperator.NOT_MATCHES,
                      text: 'Ne matches pas',
                    },
                  ]}
                  register={form.register(`thresholds.${index}.operator`, {
                    required: 'La limite est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={3}>
                <Form.Label>Référence</Form.Label>
                <Input
                  type={'numeric'}
                  error={form.formState.errors?.thresholds?.[index]?.reference}
                  register={form.register(`thresholds.${index}.reference`, {
                    required: 'La limite est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={1}>
                <Form.Label>Color</Form.Label>
                <Input
                  type={'color'}
                  register={form.register(`thresholds.${index}.color`, {
                    required: 'La couleur est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={2}>
                <Form.Label>Icone</Form.Label>
                <Input
                  type={'select'}
                  error={form.formState.errors?.thresholds?.[index]?.icon}
                  register={form.register(`thresholds.${index}.icon`, {
                    required: "L'icone est requise",
                  })}
                  options={Object.keys(Icons).map((key) => ({
                    text: key,
                    value: (Icons as { [index: string]: any })[key],
                  }))}
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label></Form.Label>
                <div>
                  <ButtonGroup>
                    <IconButton
                      variant={'outline-secondary'}
                      iconClassName="chevron-up"
                      type="button"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0) {
                          swap(index - 1, index)
                        }
                      }}
                    />
                    <IconButton
                      variant={'outline-secondary'}
                      iconClassName="chevron-down"
                      type="button"
                      disabled={index === fields.length - 1}
                      onClick={() => {
                        if (index < fields.length - 1) {
                          swap(index, index + 1)
                        }
                      }}
                    />
                    <Button
                      variant={'outline-danger'}
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Remove
                    </Button>
                  </ButtonGroup>
                </div>
              </Form.Group>
            </Row>
          )
        })}
        <div>
          <Button
            type="button"
            variant={'outline-primary'}
            onClick={() =>
              append({
                color: 'black',
                reference: 0,
                operator: ThresholdOperator.GREATER_THAN,
                icon: Icons.CIRCLE,
              })
            }
          >
            Ajouter
          </Button>
        </div>
      </Form.Group>
    </Form>
  )
}

export default SimpleIndicatorDataKeySpecificForm
