import React from 'react'
import {
  processThresholdOperator,
  SimpleIndicatorDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import {
  ComparisonTsValue,
  isComparisonTsValue,
  isTsValue,
  TsValue,
} from '../../../../../shared/models/telemetry/telemetry.model'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'

export interface CustomSimpleIndicatorProps {
  value?: TsValue | ComparisonTsValue
  config?: SimpleIndicatorDataKeySpecificProps
  label?: string
}

const getColorAndIcon = (
  value: TsValue | ComparisonTsValue | undefined,
  thresholds?: SimpleIndicatorDataKeySpecificProps['thresholds']
): { icon: string; color: string } => {
  if (!thresholds || !value) {
    return { icon: 'circle', color: 'white' }
  }
  let valueToCheck: any = 0
  if (isTsValue(value)) {
    valueToCheck = value.value
  } else if (isComparisonTsValue(value)) {
    valueToCheck = value.current?.value
  }
  for (const threshold of thresholds) {
    const is = processThresholdOperator(
      threshold.operator,
      valueToCheck,
      threshold.reference
    )
    if (is) {
      return { icon: threshold.icon, color: threshold.color }
    }
  }
  return { icon: 'circle', color: 'white' }
}

const SimpleIndicator = ({
  value,
  config,
  label,
}: CustomSimpleIndicatorProps) => {
  const [colorState, setColorState] = React.useState<string>('#000000')
  const [iconState, setIconState] = React.useState<IconProp>('circle')
  React.useEffect(() => {
    const { icon, color } = getColorAndIcon(value, config?.thresholds)
    setColorState(color)
    setIconState(icon as IconProp)
  }, [value, config?.thresholds])
  return (
    <Card className={'shadow-none border-2'}>
      <Card.Body>
        <div
          className={classNames('d-flex flex-row', {
            'justify-content-center': !label && !config?.showLabel,
            'justify-content-between': label && config?.showLabel,
            'row-reverse': config?.align === 'right',
          })}
        >
          <FontAwesomeIcon
            style={{ color: colorState }}
            icon={iconState}
            size={(config?.iconSize as SizeProp) || '2x'}
          />
          {label && config?.showLabel && <h5>{label}</h5>}
        </div>
      </Card.Body>
    </Card>
  )
}

export default SimpleIndicator
