import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useLazyGetNotificationTargetsQuery } from '../../store/kopiliot-api/notification'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  AssetQuery,
  NotificationTargetQuery,
} from '../../store/kopiliot-api/query-type'
import {
  PaginationActions,
  PaginationActionType,
} from '../../hooks/pagination-reducer'
import {
  ActionUpdateAssetProfileID,
  ActionUpdateCustomerID,
  ActionUpdateTenantID,
  CommonActionType,
} from '../../hooks/common-actions'
import { createEntityQueryReducer } from '../../hooks/create-entity-pagination-reducer'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { useNavigate } from 'react-router-dom'
import {
  defaultNotificationTargetActionsRow,
  getColumnsForTableNotificationTargets,
} from '../../features/notifications/utils'
import { NotificationTarget } from '../../shared/models/notification.model'
import AddOrEditNotificationTargetModal from '../../features/notifications/list/AddOrEditNotificationTargetModal'

const NotificationRecipient = () => {
  const user = useSelector((state: RootState) => state.currentUser)
  const navigate = useNavigate()
  // Redux Hooks
  const [getNotificationTargets, resultGetNotificationTarget] =
    useLazyGetNotificationTargetsQuery()
  // State
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  // Pagination
  const reducer = React.useMemo(() => {
    return createEntityQueryReducer<
      NotificationTargetQuery,
      PaginationActions | ActionUpdateTenantID
    >({
      handleSpecificActions: {
        [CommonActionType.UPDATE_TENANT_ID]: (state, action) => {
          return { ...state, tenantID: action.payload.tenantID }
        },
      },
    })
  }, [])
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducer,
    NotificationTargetQuery,
    PaginationActions | ActionUpdateTenantID
  >(reducer, {
    textSearch: '',
    tenantID: undefined,
    limit: 10,
    offset: 0,
  })

  const fetchNotificationTarget = () => {
    getNotificationTargets({
      textSearch: paginationState.textSearch,
      offset: paginationState.offset,
      limit: paginationState.limit,
    })
  }
  const getData = () => {
    return resultGetNotificationTarget.data
  }

  // Hooks
  React.useEffect(() => {
    fetchNotificationTarget()
  }, [
    paginationState.offset,
    paginationState.limit,
    paginationState.textSearch,
  ])
  const isLoadingData =
    resultGetNotificationTarget.isLoading ||
    resultGetNotificationTarget.isFetching
  const onDelete = () => {}
  const onEdit = () => {}
  return (
    <>
      <KopiliotList
        title={'Destinataires'}
        icon={'users-viewfinder'}
        isLoading={isLoadingData}
        actionsProps={{
          onRefresh: () => {
            fetchNotificationTarget()
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableNotificationTargets(
            defaultNotificationTargetActionsRow(onDelete, onEdit)
          ),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: NotificationTarget) => item.id!.id,
          titleAccessor: (item: NotificationTarget) => item.name,
          descriptionAccessor: (item: NotificationTarget) => item.description,
          linkAccessor: (item: NotificationTarget) => `${item.id!.id}`,
          actionsRow: defaultNotificationTargetActionsRow(onDelete, onEdit),
        }}
      />
      <AddOrEditNotificationTargetModal
        show={showModalCreate}
        onHide={() => setShowModalCreate(false)}
      />
    </>
  )
}

export default NotificationRecipient
