import React from 'react'
import { useGetTenantQuery } from '../../store/kopiliot-api/tenant'
import { Spinner } from 'react-bootstrap'

interface TenantNameProps {
  tenantId: string
}

const TenantName = ({ tenantId }: TenantNameProps) => {
  const { data, isLoading, isFetching, error } = useGetTenantQuery(tenantId)
  if (error) {
    return <span>Error while getting the tenant name</span>
  }
  return isLoading && isFetching ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <span>{data?.name}</span>
  )
}

export default TenantName
