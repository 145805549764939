import React, { MouseEvent, useEffect } from 'react'
import { Handle, Position } from 'reactflow'
import NodeTemplate from '../../base/NodeTemplate'
import { BaseNodeProps } from '../../base/types'
import { CreateAlarmConfiguration } from './CreateAlarmForm'

const CreateAlarm = React.memo(
  ({ data, isConnectable }: BaseNodeProps<CreateAlarmConfiguration>) => {
    const title = 'Créer une alarme'
    return (
      <>
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
        />
        <NodeTemplate icon={'bell'} title={title} name={data.name} />
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
      </>
    )
  }
)

export default CreateAlarm
