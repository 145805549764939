import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

export interface SubtleBadgeProps extends PropsWithChildren {
  bg?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark'
  style?: React.CSSProperties
  pill?: boolean
  className?: string
}

const SubtleBadge = ({
  bg = 'primary',
  style,
  pill,
  children,
  className,
}: SubtleBadgeProps) => {
  return (
    <div
      style={{ fontSize: '12px', ...style }}
      className={classNames(className, `badge badge-subtle-${bg}`, {
        'rounded-pill': pill,
      })}
    >
      {children}
    </div>
  )
}

export default SubtleBadge
