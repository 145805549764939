import React from 'react'
import TooltipBadge from '../../../components/common/TooltipBadge'
import ContentBlock from '../../../components/common/component-card/ContentBlock'
import { Button, Form } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { ChangeSettings } from '../../../shared/models/others/change-settings'
import { toast } from 'react-toastify'
import Input from '../../../components/form/Input'
import { useMeQuery } from '../../../store/kopiliot-api/base-api'
import { useUpdateSettingsMutation } from '../../../store/kopiliot-api/me'

const AccountSettings = () => {
  const { data, isLoading, isFetching, error } = useMeQuery(null)
  const [changeSettings, {}] = useUpdateSettingsMutation()
  const form = useForm<ChangeSettings>({
    defaultValues: data?.userMetadata,
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form
  const submitSettings = async (data: ChangeSettings) => {
    try {
      const result = await changeSettings(data).unwrap()
      toast.success('Done')
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <ContentBlock className={'mb-3'}>
      <ContentBlock.Header title={'Account settings'} />
      <ContentBlock.Body>
        <FormProvider {...form}>
          <Form noValidate onSubmit={handleSubmit(submitSettings)}>
            <div>
              <h6 className={'fw-bold'}>
                Paramètres du compte
                <TooltipBadge
                  tooltip={
                    'Le thème par défaut qui sera utilisé lors de votre ouverture de session'
                  }
                  icon={'question-circle'}
                />
              </h6>
              <div className={'ps-2 mb-2'}>
                <Input
                  type={'radio'}
                  register={register('theme')}
                  label={'Dark'}
                  value={'dark'}
                />
                <Input
                  type={'radio'}
                  register={register('theme')}
                  label={'Light'}
                  value={'light'}
                />
              </div>
            </div>
            <div className="border-dashed border-bottom my-3" />
            <div className={'ps-2 mb-2'}>
              <Input
                type={'switch'}
                register={register('isNavbarVerticalCollapsed')}
                label={'Menu rétracté'}
              />
            </div>
            <Button className="w-100" type="submit">
              Enregistrer
            </Button>
          </Form>
        </FormProvider>
      </ContentBlock.Body>
    </ContentBlock>
  )
}

export default AccountSettings
