import React from 'react'
import { Modal } from 'react-bootstrap'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  details: any
}

const AuditLogDetailModal = ({ isOpen, setIsOpen, details }: Props) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'xl'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Détails de l'audit log</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <pre>{JSON.stringify(details, null, 2)}</pre>
      </Modal.Body>
    </Modal>
  )
}

export default AuditLogDetailModal
