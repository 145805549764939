import React from 'react'
import {
  LineAnnotation,
  TimeSeriesLineDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import { useFormContext } from 'react-hook-form'

interface Props {
  initialValues?: TimeSeriesLineDataKeySpecificProps<LineAnnotation>
  onSubmitted?: (data: any) => void
}

const TimeSeriesLineChartDataKeySpecificForm = ({
  initialValues,
  onSubmitted,
}: Props) => {
  const form =
    useFormContext<TimeSeriesLineDataKeySpecificProps<LineAnnotation>>()
  React.useEffect(() => {
    form.reset(initialValues)
  }, [initialValues])
  return <div>Test</div>
}

export default TimeSeriesLineChartDataKeySpecificForm
