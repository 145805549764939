import React from 'react'
import {
  ToIDQuery,
  useGetToByEntityIDQuery,
} from '../../store/kopiliot-api/relation'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import Flex from '../../components/common/Flex'
import AdvanceTableRowPerPage from '../../components/advance-table/AdvanceTableRowPerPage'
import AddEntityButton from '../../components/advance-table/AddEntityButton'
import AdvanceTableSearchBox from '../../components/advance-table/AdvanceTableSearchBox'
import AdvanceTable from '../../components/advance-table/AdvanceTable'
import getColumnsForRelationTable from './columns'
import { EntityType } from '../../shared/models/entity-type.models'
import Select from 'react-select'
import AddOrEditRelationModal from './modals/AddOrEditRelationModal'
import {
  Relation,
  RelationType,
  RelationTypeGroup,
} from '../../shared/models/entity-relation'

interface Props {
  entityID: string
  entityType: EntityType
}
const options: { value: 'to' | 'from'; label: string }[] = [
  {
    value: 'to',
    label: 'Vers',
  },
  {
    value: 'from',
    label: 'Depuis',
  },
]

type RelationPaginationAction =
  | PaginationActions
  | {
      type: RelationPaginationActionType.CHANGE_DIRECTION
      payload: {
        direction: 'to' | 'from'
      }
    }

enum RelationPaginationActionType {
  CHANGE_DIRECTION = 'CHANGE_DIRECTION',
}

const reducerRelationPage = (
  state: ToIDQuery,
  action: RelationPaginationAction
) => {
  if (
    action.type === PaginationActionType.PAGE_CHANGE ||
    action.type === PaginationActionType.PAGE_SIZE ||
    action.type === PaginationActionType.PAGE_FILTER
  ) {
    return {
      ...reducerPage(state, action),
      direction: state.direction,
      entityID: state.entityID,
      entityType: state.entityType,
    }
  }
  switch (action.type) {
    case RelationPaginationActionType.CHANGE_DIRECTION:
      return { ...state, direction: action.payload.direction }
    default:
      return state
  }
}
const defaultFormValues: Partial<Relation> = {
  relationType: RelationType.CONTAIN,
  relationTypeGroup: RelationTypeGroup.COMMON,
}

const RelationListing = ({ entityID, entityType }: Props) => {
  // Modal Edit or create
  const [isOpen, setIsOpen] = React.useState(false)

  // pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerRelationPage,
    ToIDQuery,
    RelationPaginationAction
  >(reducerRelationPage, {
    direction: 'to',
    entityID: entityID,
    entityType: entityType,
    limit: 20,
    offset: 0,
    textSearch: '',
  })
  // Default values
  const [defaultValues, setDefaultValues] =
    React.useState<Partial<Relation>>(defaultFormValues)
  React.useEffect(() => {
    setDefaultValues({
      ...defaultFormValues,
      from:
        paginationState.direction === 'from'
          ? { entityType, id: entityID }
          : undefined,
      to:
        paginationState.direction === 'to'
          ? { entityType, id: entityID }
          : undefined,
    })
  }, [paginationState.direction])
  const { data, isLoading, isFetching, error } =
    useGetToByEntityIDQuery(paginationState)
  const getHiddenColumns = () => {
    if (paginationState.direction === 'to') {
      return ['toID', 'toName']
    }
    return ['fromID', 'fromName']
  }
  const loading = isLoading || isFetching
  return (
    <>
      <ContentBlock>
        <ContentBlock.Header>
          <Flex
            className={'align-items-center justify-content-between mb-3 pt-2'}
          >
            <Flex alignItems="center" className="fs--1">
              <AdvanceTableRowPerPage
                onChange={(pageSize) => {
                  dispatchPaginationAction({
                    type: PaginationActionType.PAGE_SIZE,
                    payload: { pageSize: pageSize },
                  })
                }}
                pageSize={paginationState.limit}
              />
            </Flex>
            <div className="w-25">
              <Select
                isSearchable={false}
                options={options}
                defaultValue={options[0]}
                getOptionLabel={(option) => option.label}
                classNamePrefix="react-select"
                onChange={(value) => {
                  dispatchPaginationAction({
                    type: RelationPaginationActionType.CHANGE_DIRECTION,
                    payload: {
                      direction: value ? value.value : 'to',
                    },
                  })
                }}
              />
            </div>
            <Flex>
              <AddEntityButton
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
              />
              <AdvanceTableSearchBox
                setGlobalFilter={(filter) => {
                  dispatchPaginationAction({
                    type: PaginationActionType.PAGE_FILTER,
                    payload: { filter: filter },
                  })
                }}
              />
            </Flex>
          </Flex>
        </ContentBlock.Header>
        <ContentBlock.Body className={'p-0'}>
          {loading && <div>Loading...</div>}
          <AdvanceTable
            columns={getColumnsForRelationTable()}
            data={data?.rows || []}
            tableProps={{
              size: 'sm',
              className: 'fs--1 mb-0 overflow-hidden',
              striped: true,
              bordered: false,
            }}
            rowClassName="btn-reveal-trigger text-nowrap align-middle"
            hiddenColumns={getHiddenColumns()}
            headerClassName="bg-200 text-900 text-nowrap align-middle"
          />
        </ContentBlock.Body>
      </ContentBlock>
      <AddOrEditRelationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        defaultValues={defaultValues}
        direction={paginationState.direction}
      />
    </>
  )
}

export default RelationListing
