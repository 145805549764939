import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Alias } from '../../../../shared/models/alias.model'
import { Col, FormGroup, Row } from 'react-bootstrap'
import SelectEntity from '../../../../components/form/SelectEntity'
import { EntityType } from '../../../../shared/models/entity-type.models'
import {
  useLazyGetCustomerQuery,
  useLazyGetCustomersQuery,
} from '../../../../store/kopiliot-api/customer'
import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../../../store/kopiliot-api/tenant'
import {
  useLazyGetAssetQuery,
  useLazyGetAssetsQuery,
} from '../../../../store/kopiliot-api/asset'
import { useLazyGetUsersQuery } from '../../../../store/kopiliot-api/user'
import {
  useLazyGetDeviceQuery,
  useLazyGetDevicesQuery,
} from '../../../../store/kopiliot-api/device'
import _ from 'lodash'

const EntityList = () => {
  const form = useFormContext<Alias>()
  const watchEntityType = form.watch('config.entityList.entityType')
  const watchEntityIDs = form.watch('config.entityList.ids')
  // STATE
  const [selectedValues, setSelectedValues] = React.useState<string[]>(
    form.getValues('config.entityList.ids') ?? []
  )
  const [options, setOptions] = React.useState<{ label: string; value: any }[]>(
    []
  )
  // LAZY LIST QUERIES
  const [getCustomers, customersResult] = useLazyGetCustomersQuery()
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [getAssets, assetsResult] = useLazyGetAssetsQuery()
  const [getUsers, usersResult] = useLazyGetUsersQuery()
  const [getDevices, devicesResult] = useLazyGetDevicesQuery()

  // LAZY SINGLE QUERIES
  const [getCustomer, customerResult] = useLazyGetCustomerQuery()
  const [getTenant, tenantResult] = useLazyGetTenantQuery()
  const [getAsset, assetResult] = useLazyGetAssetQuery()
  //const [getUser, userResult] = useLazyGetUserQuery()
  const [getDevice, deviceResult] = useLazyGetDeviceQuery()
  // HOOKS
  React.useEffect(() => {
    setOptions([...getOptions()])
  }, [customersResult, tenantsResult, assetsResult, devicesResult])
  React.useEffect(() => {
    const fetchData = async () => {
      const results = await Promise.all(
        _.map(selectedValues, async (value) => {
          const query = getSingleQuery()
          if (query) {
            const result = await query(value, false).unwrap()
            return {
              label: result.name,
              value: result.id?.id ?? '',
            }
          }
        })
      )
      setOptions(_.compact(results))
    }

    fetchData()
  }, [selectedValues])
  React.useEffect(() => {
    setSelectedValues(watchEntityIDs ?? [])
  }, [watchEntityIDs])

  // FUNCTIONS
  const getOptions = () => {
    let options: any[] = []
    switch (watchEntityType) {
      case EntityType.CUSTOMER:
        const customersOptions = _.map(
          customersResult.data?.rows,
          (customer) => {
            return {
              label: customer.name,
              value: customer.id?.id,
            }
          }
        )
        options.push(...customersOptions)
        break
      case EntityType.TENANT:
        const tenantsOptions = _.map(tenantsResult.data?.rows, (tenant) => {
          return {
            label: tenant.name,
            value: tenant.id?.id,
          }
        })
        options.push(...tenantsOptions)
        break
      case EntityType.ASSET:
        const assetsOptions = _.map(assetsResult.data?.rows, (asset) => {
          return {
            label: asset.name,
            value: asset.id?.id,
          }
        })
        options.push(...assetsOptions)
        break
      case EntityType.DEVICE:
        const devicesOptions = _.map(devicesResult.data?.rows, (device) => {
          return {
            label: device.name,
            value: device.id?.id,
          }
        }).values()
        options.push(...devicesOptions)
        break
    }
    return options
  }
  const getListQuery = () => {
    switch (watchEntityType) {
      case EntityType.CUSTOMER:
        return getCustomers
      case EntityType.TENANT:
        return getTenants
      case EntityType.ASSET:
        return getAssets
      case EntityType.USER:
        return getUsers
      case EntityType.DEVICE:
        return getDevices
    }
    return null
  }
  const getSingleQuery = () => {
    switch (watchEntityType) {
      case EntityType.CUSTOMER:
        return getCustomer
      case EntityType.TENANT:
        return getTenant
      case EntityType.ASSET:
        return getAsset
      case EntityType.USER:
      //return getUser
      case EntityType.DEVICE:
        return getDevice
    }
    return null
  }
  // RETURN
  return (
    <>
      <Row>
        <FormGroup as={Col} lg={12}>
          <SelectEntity
            options={options}
            labelEntityType={'Entity'}
            labelEntityID={'IDs'}
            entityTypeField={'config.entityList.entityType'}
            entityIdField={'config.entityList.ids'}
            allowClear={true}
            isMultiple={true}
            allowedEntityTypes={[
              EntityType.DEVICE,
              EntityType.ASSET,
              EntityType.TENANT,
              EntityType.CUSTOMER,
              EntityType.USER,
              EntityType.DASHBOARD,
            ]}
            onChangeSelectID={(value) => {
              setSelectedValues([...selectedValues, value.value])
            }}
            onChangeSearchTerm={(searchTerm) => {
              const query = getListQuery()
              if (query) {
                query(
                  { page: 0, pageSize: 40, textSearch: searchTerm } as any,
                  false
                )
              }
            }}
          />
        </FormGroup>
      </Row>
    </>
  )
}

export default EntityList
