import React, { PropsWithChildren } from 'react'
import { Button, ButtonProps, Modal } from 'react-bootstrap'

interface ArkModalProps extends PropsWithChildren {
  title?: string
  isOpen?: boolean
  handleClose: () => void
  validButton?: ButtonProps
  size?: 'sm' | 'lg' | 'xl'
}

const ArkModal = ({
  title,
  isOpen,
  handleClose,
  children,
  validButton,
  size,
}: ArkModalProps) => {
  return (
    <Modal
      centered
      show={isOpen}
      onHide={() => {
        handleClose()
      }}
      size={size}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuler
        </Button>
        {validButton && <Button {...validButton}></Button>}
      </Modal.Footer>
    </Modal>
  )
}

export default ArkModal
