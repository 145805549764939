import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import User, {
  UserCreate,
  UserCredentialsInfo,
  UserUpdate,
} from '../../shared/models/user'
import { UserQuery } from './query-type'
import UserId from '../../shared/models/id/user-id'
import { AlarmID } from '../../shared/models/id/alarm-id'
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

const userApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<Pagination<User>, UserQuery>({
      query: (args) => ({
        url: 'users',
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
          tenantID: args.tenantID?.id,
        },
      }),
      providesTags: (result = new Pagination<User>(), error, arg) => [
        ...result.rows.map((item: User) => {
          return {
            type: 'User' as const,
            id: item.id?.id,
          }
        }),
        { type: 'User', id: 'LIST' },
      ],
    }),
    getUser: build.query<User, string>({
      query: (id) => ({
        url: `users/${id}`,
      }),
    }),
    getUsersForAssign: build.query<
      Pagination<User>,
      UserQuery & { alarmID: AlarmID }
    >({
      query: (args) => ({
        url: `users/assign/${args.alarmID.id}`,
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
          tenantID: args.tenantID?.id,
        },
      }),
    }),
    getUserCredentialsInfo: build.query<UserCredentialsInfo, string>({
      query: (id) => ({
        url: `users/${id}/credentials`,
      }),
    }),
    resendActivationLink: build.mutation<void, UserId>({
      query: (id) => ({
        url: `users/${id.id}/resendActivationLink`,
        method: 'POST',
      }),
    }),
    addUser: build.mutation<User, UserCreate>({
      query: (user) => ({
        url: 'users',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    updateUser: build.mutation<User, UserUpdate>({
      query: (user) => ({
        url: `users/${user.id}`,
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id?.id },
      ],
    }),
    enableUser: build.mutation<void, { userID: UserId; enabled: boolean }>({
      query: (args) => ({
        url: `users/${args.userID.id}/enable`,
        method: 'PATCH',
        body: { enabled: args.enabled },
      }),
    }),
    revokeUserSession: build.mutation<
      void,
      { userID: UserId; sessionID: string }
    >({
      query: (args) => ({
        url: `users/${args.userID.id}/sessions/${args.sessionID}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetUsersForAssignQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserCredentialsInfoQuery,
  useLazyGetUserCredentialsInfoQuery,
  useAddUserMutation,
  useEnableUserMutation,
  useResendActivationLinkMutation,
  useUpdateUserMutation,
  useRevokeUserSessionMutation,
} = userApi
