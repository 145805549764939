import { createSlice } from '@reduxjs/toolkit'

export interface MenuState {
  isBurgerMenuCollapsed: boolean
  isNotificationOpen: boolean
}

const initialState: MenuState = {
  isBurgerMenuCollapsed: true,
  isNotificationOpen: false,
}

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    collapseBurgerMenu: (state) => {
      state.isBurgerMenuCollapsed = !state.isBurgerMenuCollapsed
    },
    collapseNotificationDropdown: (state) => {
      state.isNotificationOpen = !state.isNotificationOpen
    },
  },
})

// Action creators are generated for each case reducer function
export const { collapseBurgerMenu, collapseNotificationDropdown } =
  menusSlice.actions

export default menusSlice.reducer
