import React, { PropsWithChildren } from 'react'
import { Card } from 'react-bootstrap'
import Background from '../../../components/common/Background'
import classNames from 'classnames'
import Avatar, { AvatarProps } from '../../../components/common/Avatar'
import defaultProfileImage from '../../../assets/img/team/2.jpg'
import defaultCoverImage from '../../../assets/img/generic/4.jpg'

interface ProfileBannerHeaderProps {
  coverSrc?: string
  className?: string
}

const ProfileBannerHeader = ({
  coverSrc,
  className,
  ...avatarProps
}: ProfileBannerHeaderProps & Pick<AvatarProps, 'src' | 'name'>) => {
  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      <Background
        image={coverSrc ? coverSrc : defaultCoverImage}
        className="rounded-3 rounded-bottom-0"
      />
      <Avatar
        size="5xl"
        className="avatar-profile"
        {...avatarProps}
        mediaClass="img-thumbnail shadow-sm"
      />
    </Card.Header>
  )
}

const ProfileBannerBody = ({ children }: PropsWithChildren) => {
  return <Card.Body>{children}</Card.Body>
}

const ProfileBanner = ({ children }: PropsWithChildren) => {
  return <Card className="mb-3">{children}</Card>
}

ProfileBanner.Header = ProfileBannerHeader
ProfileBanner.Body = ProfileBannerBody

export default ProfileBanner
