import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import { Alarm, AlarmQuery } from '../../shared/models/alarm.model'
import UserId from '../../shared/models/id/user-id'
import { AlarmID } from '../../shared/models/id/alarm-id'

const alarmApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getAlarms: build.query<Pagination<Alarm>, AlarmQuery>({
      query: (args) => ({
        url: 'alarms',
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
        },
      }),
      providesTags: (result) => [{ type: 'Alarm', id: 'list' }],
    }),
    getAlarm: build.query<Alarm, AlarmID>({
      query: (id) => ({
        url: `alarms/${id.id}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Alarm', id: arg.id }]
      },
    }),
    getAlarmCount: build.query<number, AlarmQuery>({
      query: (args) => ({
        url: 'alarms/count',
        method: 'GET',
        params: {
          textSearch: args.textSearch,
        },
      }),
    }),
    assignAlarm: build.mutation<Alarm, { id: AlarmID; assigneeID?: UserId }>({
      query: ({ id, assigneeID }) => ({
        url: `alarms/${id.id}/assign`,
        method: 'PATCH',
        body: {
          assigneeID: assigneeID,
        },
      }),
    }),
    clearAlarm: build.mutation<Alarm, AlarmID>({
      query: (id) => ({
        url: `alarms/${id.id}/clear`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Alarm', id: arg.id },
        { type: 'Alarm', id: 'list' },
      ],
    }),
    acknowledgeAlarm: build.mutation<Alarm, AlarmID>({
      query: (id) => ({
        url: `alarms/${id.id}/acknowledge`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Alarm', id: arg.id },
        { type: 'Alarm', id: 'list' },
      ],
    }),
  }),
})

export const {
  useLazyGetAlarmsQuery,
  useGetAlarmQuery,
  useLazyGetAlarmQuery,
  useLazyGetAlarmCountQuery,
  useAssignAlarmMutation,
  useClearAlarmMutation,
  useAcknowledgeAlarmMutation,
} = alarmApi
