import React, { MouseEvent, PropsWithChildren } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../../../../components/common/Flex'
import './nodeTemplate.css'

interface NodeTemplateProps extends PropsWithChildren {
  icon?: IconProp
  title: string
  name?: string
  color?: string
}

const NodeTemplate = ({
  icon,
  color,
  title,
  name,
  children,
}: NodeTemplateProps) => {
  return (
    <div
      className={'react-flow__node__content'}
      style={{ backgroundColor: color }}
    >
      <Flex alignItems={'center'} className={'p-1 node_content'}>
        <div>
          {icon && (
            <FontAwesomeIcon icon={icon} size={'lg'} className={'me-1 ms-1'} />
          )}
        </div>
        <div className={'node_text'}>
          <div className={'node_title'}>{title}</div>
          <div className={'node_name'}>{name}</div>
        </div>
      </Flex>
    </div>
  )
}

export default NodeTemplate
