import { useNavigate } from 'react-router-dom'
import { useMeQuery } from '../../store/kopiliot-api/base-api'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import React from 'react'
import bgImg from '../../assets/img/generic/18.jpg'
import { Button, Form } from 'react-bootstrap'
import Input from '../../components/form/Input'
import SplitPageLayout from '../_layout/SplitPageLayout'
import { useRequestPasswordResetMutation } from '../../store/kopiliot-api/auth'
import ButtonLoading from '../../components/button/ButtonLoading'

const PasswordResetRequest = () => {
  const navigate = useNavigate()
  const { data: me } = useMeQuery(null)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [request, { isLoading, error }] = useRequestPasswordResetMutation()
  const requestPasswordResetForm = useForm<{ email: string }>()
  if (me) {
    toast('You are already logged in')
    navigate('/')
  }
  const {
    formState: { errors },
  } = requestPasswordResetForm

  const onSubmit = async (data: { email: string }) => {
    try {
      await request(data).unwrap()
      setIsSubmitted(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <SplitPageLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <h3>Demander un nouveau mot de passe</h3>
      </div>
      {isSubmitted ? (
        <>Un email vous a été envoyé</>
      ) : (
        <FormProvider {...requestPasswordResetForm}>
          <Form
            noValidate
            onSubmit={requestPasswordResetForm.handleSubmit(onSubmit)}
          >
            <Input
              register={requestPasswordResetForm.register('email', {
                required: 'Email is required',
              })}
              label="Email"
              type="email"
              error={errors.email}
            />
            <Form.Group>
              <ButtonLoading
                isLoading={isLoading}
                type="submit"
                color="primary"
                className="mt-3 w-100"
              >
                Valider
              </ButtonLoading>
            </Form.Group>
          </Form>
        </FormProvider>
      )}
    </SplitPageLayout>
  )
}

export default PasswordResetRequest
