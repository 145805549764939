import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { LoginInput } from '../../shared/models/others/login-input'

interface LoginFormProps {
  hasLabel?: boolean
  onSubmit: SubmitHandler<LoginInput>
}

const LoginForm = ({ hasLabel = true, onSubmit }: LoginFormProps) => {
  // Init Form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginInput>()

  // END TOTO
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          {...register('login')}
          type="email"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          {...register('password')}
          type="password"
        />
      </Form.Group>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input type="checkbox" {...register('rememberMe')} />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs--1 mb-0" to={'/auth/forgot-password'}>
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          //disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  )
}

export default LoginForm
