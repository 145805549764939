import React from 'react'
import Divider from '../../components/common/Divider'
import ButtonLoading from '../../components/button/ButtonLoading'
import {
  useEnableUserMutation,
  useGetUserCredentialsInfoQuery,
  useResendActivationLinkMutation,
} from '../../store/kopiliot-api/user'
import { toast } from 'react-toastify'
import UserId from '../../shared/models/id/user-id'

interface CredentialsProps {
  userID: string
}

const Credentials = ({ userID }: CredentialsProps) => {
  const userCredentials = useGetUserCredentialsInfoQuery(userID)
  const [enableUser] = useEnableUserMutation()
  const [resendActivationLink] = useResendActivationLinkMutation()
  return (
    <>
      <div className={'d-flex flex-row justify-content-between'}>
        <h4>Activer ou désactiver</h4>
        <div>
          <ButtonLoading
            variant={
              userCredentials.data?.enabled
                ? 'outline-danger'
                : 'outline-success'
            }
            size={'sm'}
            className={'me-2'}
            onClick={async () => {
              try {
                if (!userCredentials.data?.userID) return
                await enableUser({
                  userID: userCredentials.data?.userID,
                  enabled: !userCredentials.data?.enabled,
                }).unwrap()
                toast(
                  `Utilisateur ${
                    userCredentials.data?.enabled ? 'désactivé' : 'activé'
                  }`,
                  { type: 'success' }
                )
              } catch (err) {
                console.error(err)
              }
            }}
            text={userCredentials.data?.enabled ? 'Désactiver' : 'Activer'}
          />
        </div>
      </div>
      <Divider />
      <div className={'d-flex flex-row justify-content-between'}>
        <h4>Lien d'activation</h4>
        <div>
          <ButtonLoading
            variant={'outline-primary'}
            disabled={userCredentials.data?.activationToken === ''}
            size={'sm'}
            className={'me-2'}
            onClick={() => {
              const token = userCredentials.data?.activationToken

              navigator.clipboard
                .writeText(
                  `https://${process.env.DOMAIN}/auth/activate/?activation_token=${token}`
                )
                .then(() => {
                  toast('Lien copié dans le presse-papier', { type: 'success' })
                })
                .catch((err) => {
                  toast('Erreur lors de la copie du lien', { type: 'error' })
                })
            }}
            text={'Récupérer'}
          />
          <ButtonLoading
            variant={'outline-primary'}
            disabled={false}
            size={'sm'}
            className={'me-2'}
            onClick={async () => {
              try {
                if (!userCredentials.data?.userID) return
                await resendActivationLink(
                  userCredentials.data?.userID
                ).unwrap()
                toast("Lien d'activation renvoyé", { type: 'success' })
              } catch (err) {
                console.error(err)
              }
            }}
            text={'Régénérer et renvoyer'}
          />
        </div>
      </div>
      <Divider />
    </>
  )
}

export default Credentials
