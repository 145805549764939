import React, { useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import Select from 'react-select'
import _ from 'lodash'
import { CustomEdge } from '../../model'
import { Edge, useReactFlow } from 'reactflow'
import {
  getConditionForNode,
  NodeTypes,
} from '../../../../shared/models/rule-node'

interface ModalEdgeProps {
  show?: boolean
  onHide: () => void
  currentEdgeEdit?: Edge
}

const ModalEdge = ({ show, onHide, currentEdgeEdit }: ModalEdgeProps) => {
  const instance = useReactFlow()
  const [filterOptions, setFilterOptions] = React.useState<
    { value: string; label: string }[]
  >([])
  React.useEffect(() => {
    if (instance && currentEdgeEdit) {
      const source = instance.getNode(currentEdgeEdit?.source)
      const conditions = getConditionForNode(source?.type as NodeTypes)
      setFilterOptions(conditions)
    }
  }, [currentEdgeEdit])
  const form = useForm<CustomEdge>({
    defaultValues: currentEdgeEdit ? currentEdgeEdit : {},
  })
  const { handleSubmit, formState, register, reset } = form
  useEffect(() => {
    if (currentEdgeEdit) {
      reset(currentEdgeEdit)
    }
  }, [currentEdgeEdit])
  const onSubmit = (edge: CustomEdge) => {
    instance.setEdges((edges) => {
      const edgeToAdd = {
        ...edge,
        label: edge.data.conditions.join('/') || '',
      }
      const edgeIndex = edges.findIndex((e) => e.id === edge.id)
      if (edgeIndex !== -1) {
        edges[edgeIndex] = edgeToAdd
      } else {
        edges = edges.concat(edgeToAdd)
      }
      return edges
    })
    onHide()
  }
  return (
    <Modal size={'xl'} show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <h3>Editer un edge</h3>
      </Modal.Header>
      <Modal.Body>
        {currentEdgeEdit && (
          <>
            <FormProvider {...form}>
              <Form
                noValidate
                id={'form_rule_edge'}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  {JSON.stringify(form.getValues())}
                  <Form.Group as={Col} lg={12}>
                    <Form.Label>Result</Form.Label>
                    <Controller
                      name={'data.conditions'}
                      render={({ field: { onChange } }) => {
                        return (
                          <Select
                            isClearable
                            isMulti
                            options={filterOptions}
                            defaultValue={currentEdgeEdit?.data?.conditions.map(
                              (c: string) => {
                                return { value: c, label: c }
                              }
                            )}
                            onChange={(values, actionMeta) => {
                              const updValues = _.map(values, 'value')
                              onChange(updValues)
                            }}
                            {..._.omit(register('data.conditions'), [
                              'onChange',
                            ])}
                          />
                        )
                      }}
                    />
                  </Form.Group>
                </Row>
              </Form>
            </FormProvider>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type={'submit'} form={'form_rule_edge'}>
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalEdge
