import { configureStore } from '@reduxjs/toolkit'
import menusReducer from './slices/menus'
import currentUser from './slices/current-user'
import settings from './slices/settings'
import { baseArkhaleAPI } from './kopiliot-api/base-api'
import { setupListeners } from '@reduxjs/toolkit/query'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import dashboardSlice from './slices/dashboardSlice'
import ruleChainEditSlice from './slices/rule-chain-edit-slice'
import recentSearchSlice from './slices/recent-search'
import breadcrumbSlice from './slices/breadcrumb'
import {
  rtkQueryErrorLoggerMiddleware,
  webSocketMiddleware,
} from './middlewares'
import { websocketReducer } from './slices/websocket'

export const store = configureStore({
  reducer: {
    [baseArkhaleAPI.reducerPath]: baseArkhaleAPI.reducer,
    menus: menusReducer,
    breadcrumb: breadcrumbSlice,
    currentUser: currentUser,
    settings: settings,
    ruleChainEdit: ruleChainEditSlice,
    recentSearch: recentSearchSlice,
    dashboard: dashboardSlice,
    websocket: websocketReducer,
  },
  // middlewares
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      baseArkhaleAPI.middleware,
      rtkQueryErrorLoggerMiddleware,
      webSocketMiddleware()
    )
  },
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
