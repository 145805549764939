import TenantId from './id/tenant-id'
import { CustomerId } from './id/customer-id'
import { EntityId } from './id/entity-id'
import { BaseData } from './base-data'
import { AlarmID } from './id/alarm-id'
import { PaginationWithSearchTerm } from '../../store/kopiliot-api/query-type'
import UserId from './id/user-id'

export enum AlarmSeverity {
  CRITICAL = 'CRITICAL',
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
  WARNING = 'WARNING',
  INDETERMINATE = 'INDETERMINATE',
}

export enum AlarmStatus {
  ACTIVE_UNACK = 'ACTIVE_UNACK',
  ACTIVE_ACK = 'ACTIVE_ACK',
  CLEARED_UNACK = 'CLEARED_UNACK',
  CLEARED_ACK = 'CLEARED_ACK',
}

export interface Alarm extends BaseData<AlarmID> {
  tenantID: TenantId
  customerID?: CustomerId
  type: string
  originator: EntityId
  severity: AlarmSeverity
  status: AlarmStatus
  startTs: number
  endTs: number
  ackTs: number
  clearTs: number
  propagate: boolean
  details?: any
  assignedUserID?: UserId
}

export interface AlarmQuery extends PaginationWithSearchTerm {
  tenantID?: TenantId
  customerID?: CustomerId
  type?: string
  originator?: EntityId
  severity?: AlarmSeverity
  status?: AlarmStatus
}
