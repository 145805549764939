import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import { EntityGroup } from '../../shared/models/entity-group.model'
import { EntityGroupQuery, EntityGroupQueryWithOwner } from './query-type'

export interface CreateEntityGroupCommand {
  name: string
  ownerID: string
  ownerType: string
  entityType: string
}

const entityGroupsApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getEntityGroupsByType: build.query<
      Pagination<EntityGroup>,
      EntityGroupQuery
    >({
      query: ({ offset, limit, textSearch, entityType }) => ({
        url: `entity-group/${entityType}`,
        method: 'GET',
        params: {
          offset,
          limit,
          textSearch,
        },
      }),
    }),
    getEntityGroupsByTypeAndOwner: build.query<
      Pagination<EntityGroup>,
      EntityGroupQueryWithOwner
    >({
      query: ({
        offset,
        limit,
        textSearch,
        entityType,
        ownerType,
        ownerID,
      }) => ({
        url: `entity-group/${entityType}/${ownerType}/${ownerID}`,
        method: 'GET',
        params: {
          offset,
          limit,
          textSearch,
        },
      }),
      providesTags: (result) => {
        if (!result) return ['EntityGroups']
        return [
          ...result.rows.map(({ id }) => ({
            type: 'EntityGroups' as const,
            id: id?.id,
          })),
          { type: 'EntityGroups', id: 'LIST' },
        ]
      },
    }),
    addEntityGroup: build.mutation<EntityGroup, CreateEntityGroupCommand>({
      query: (cmd) => ({
        url: 'entity-group',
        method: 'POST',
        body: cmd,
      }),
      invalidatesTags: [{ type: 'EntityGroups', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetEntityGroupsByTypeQuery,
  useLazyGetEntityGroupsByTypeQuery,
  useGetEntityGroupsByTypeAndOwnerQuery,
  useLazyGetEntityGroupsByTypeAndOwnerQuery,
  useAddEntityGroupMutation,
} = entityGroupsApi
