import React from 'react'
import {
  DataKey,
  DataSource,
  JsonTableDataKeySpecificProps,
} from '../../../../../../shared/models/panel'
import {
  TimeseriesData,
  TsValue,
} from '../../../../../../shared/models/telemetry/telemetry.model'
import getColumns, { JsonTableType } from './columns'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import TimeSeriesTableHeader from '../time-series-table/TimeSeriesTableHeader'
import { Button, ButtonGroup, Table } from 'react-bootstrap'
import _ from 'lodash'
import Flex from '../../../../../../components/common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface JsonTableWrapperProps {
  config?: {
    dataKey: DataKey<JsonTableDataKeySpecificProps>
    values: Array<TsValue>
  }
  options: any
}

const JsonTableWrapper = ({ config, options }: JsonTableWrapperProps) => {
  const columns = React.useMemo(() => {
    if (config?.dataKey?.specificProps) {
      return getColumns(config.dataKey.specificProps)
    }
    return []
  }, [config?.dataKey?.specificProps])
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const data = React.useMemo(() => {
    if (config?.values) {
      return _.map(config.values, (value) => {
        return {
          ts: value.ts,
          ...value.value,
        }
      })
    }
    return []
  }, [config?.values])
  const table = useReactTable<JsonTableType>({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination: pagination,
    },
  })
  return (
    <div>
      <Table striped={true} hover>
        <TimeSeriesTableHeader columns={table.getHeaderGroups()} />
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={'text-800'}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={'text-800'}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Flex direction={'row'} justifyContent={'between'}>
        <Flex direction={'row'} alignItems={'center'}>
          <div>Page</div>
          {table.getState().pagination.pageIndex + 1} sur{' '}
          {table.getPageCount().toLocaleString()}
        </Flex>
        <Flex direction={'row'}>
          <ButtonGroup className="me-2">
            <Button
              variant="outline-primary"
              onClick={() => {
                setPagination({ ...pagination, pageIndex: 0 })
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon={'angle-double-left'} />
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                table.previousPage()
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon={'angle-left'} />
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                table.nextPage()
              }}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon={'angle-right'} />
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                //table.lastPage()
              }}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon={'angle-double-right'} />
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </div>
  )
}

export default JsonTableWrapper
