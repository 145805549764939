import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import RuleChain, { RuleChainFmt } from '../../shared/models/rule-chain.model'
import { RuleChainQuery } from './query-type'

const ruleChainApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getRuleChains: build.query<Pagination<RuleChain>, RuleChainQuery>({
      query: (args) =>
        `rule-chains?textSearch=${args.textSearch}&limit=${args.limit}&offset=${args.offset}`,
      providesTags: (result = new Pagination<RuleChain>(), error, arg) => [
        ...result.rows.map((item: RuleChain) => {
          return {
            type: 'RuleChain' as const,
            id: item.id?.id,
          }
        }),
        { type: 'RuleChain', id: 'LIST' },
      ],
    }),
    getRuleChain: build.query<RuleChain, string>({
      query: (ruleChainId) => `rule-chains/${ruleChainId}`,
    }),
    getRuleChainFmt: build.query<RuleChainFmt, string>({
      query: (ruleChainId) => `rule-chains/${ruleChainId}/fmt`,
      providesTags: (result, error, arg) => [
        { type: 'RuleChain', id: arg },
        { type: 'RuleChain', id: 'LIST' },
      ],
    }),
    addRuleChain: build.mutation<RuleChain, RuleChain>({
      query: (ruleChain) => ({
        url: 'rule-chains',
        method: 'POST',
        body: ruleChain,
      }),
      invalidatesTags: [{ type: 'RuleChain', id: 'LIST' }],
    }),
    updateRuleChain: build.mutation<RuleChainFmt, RuleChainFmt>({
      query: (ruleChain) => ({
        url: `rule-chains/${ruleChain.id?.id}`,
        method: 'PUT',
        body: ruleChain,
      }),
      invalidatesTags: [{ type: 'RuleChain', id: 'LIST' }],
    }),
    deleteRuleChain: build.mutation<RuleChain, string>({
      query: (ruleChainId) => ({
        url: `rule-chains/${ruleChainId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetRuleChainsQuery,
  useLazyGetRuleChainsQuery,
  useGetRuleChainQuery,
  useLazyGetRuleChainQuery,
  useGetRuleChainFmtQuery,
  useLazyGetRuleChainFmtQuery,
  useAddRuleChainMutation,
  useUpdateRuleChainMutation,
} = ruleChainApi
