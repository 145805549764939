import moment from 'moment'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'

export const isIterableArray = (array: any): array is string[] => {
  return (
    Array.isArray(array) &&
    !!array.length &&
    array.every((item) => typeof item === 'string')
  )
}
export const getColor = (
  name: string,
  dom: HTMLElement = document.documentElement
) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim()
}

export const formatDate = (
  date: string | Date,
  duration: boolean = true,
  format: string = 'YYYY-MM-DD HH:mm:ss.SSS'
): string => {
  const dateFormatted = moment(date)
  if (!dateFormatted.isValid()) {
    return 'Invalid Date'
  }
  return duration
    ? dateFormatted.fromNow()
    : moment(dateFormatted).format(format)
}
