import React from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import ContentBlock from '../../../components/common/component-card/ContentBlock'
import User from '../../../shared/models/user'
import { SubmitHandler, useForm } from 'react-hook-form'
import TenantProfile from '../../../shared/models/tenant-profile'
import UserSessions from './UserSessions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

interface ProfileSettingsProps {
  user?: User
}

const ProfileSettings = ({ user }: ProfileSettingsProps) => {
  const onSubmit: SubmitHandler<User> = async (data: User) => {
    try {
      //const res = await addNewTenantProfile(data).unwrap()
      //navigate(`/profiles/tenant-profiles`)
    } catch (e) {
      console.error(e)
    }
  }
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<User>({ defaultValues: user })
  return (
    <ContentBlock>
      <ContentBlock.Header title={'Réglages'} />
      <ContentBlock.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Firstname"
                isInvalid={!!errors.firstname}
                {...register('firstname', {
                  required: 'Firstname field is required',
                })}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Firstname"
                isInvalid={!!errors.lastname}
                {...register('lastname', {
                  required: 'Lastname field is required',
                })}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                isInvalid={!!errors.jobTitle}
                {...register('email', {
                  required: 'Email field is required',
                })}
              />
            </Form.Group>
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                isInvalid={!!errors.jobTitle}
                {...register('phone')}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="heading">
            <Form.Label>Heading</Form.Label>
            <Form.Control
              type="text"
              placeholder="Heading"
              isInvalid={!!errors.jobTitle}
              {...register('jobTitle')}
            />
          </Form.Group>
        </Form>
        <Row className="g-3 mb-3">
          <Col md={8} sm={12}>
            {user && user.userMetadata && user.userMetadata.userSessions && (
              <UserSessions user={user} />
            )}
          </Col>
        </Row>
      </ContentBlock.Body>
    </ContentBlock>
  )
}

export default ProfileSettings
