import {
  CategoryScale,
  Chart as ChartJS,
  ChartDataset,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeScaleTimeOptions,
  TimeSeriesScale,
  Title,
  Tooltip as ChartJSTooltip,
  TooltipItem,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { ChartProps, Line } from 'react-chartjs-2'
import {
  DataSource,
  LineAnnotation,
  TimeSeriesLineDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import {
  TimeWindow,
  TimeWindowType,
} from '../../../../../shared/models/time.model'
import { Aliases } from '../../../../../shared/models/alias.model'
import React from 'react'
import { TimeseriesData } from '../../../../../shared/models/telemetry/telemetry.model'
import _ from 'lodash'
import { useAppSelector } from '../../../../../store/store'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { formatValue, isNumericValue } from '../../../../../utils/utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartJSTooltip,
  Legend,
  TimeSeriesScale,
  TimeScale,
  annotationPlugin
)

// Other types
interface CustomToolTipItems extends TooltipItem<'line'> {
  dataset: TooltipItem<'line'>['dataset'] & {
    tooltipLabelSuffix?: string
    tooltipLabelPrefix?: string
  }
}

// End

interface LineChartProps {
  timeSeriesData?: {
    [key: string]: { datasource: DataSource; data: TimeseriesData }
  }
  timeWindow?: TimeWindow
  aliases: Aliases
  options?: ChartProps<'line'>['options']
  specificOptions?: TimeSeriesLineDataKeySpecificProps<LineAnnotation>
}

function findLabel(dataSource: DataSource, key: string) {
  const dataKey = dataSource.dataKeys.find((d) => d.key === key)
  if (dataKey) {
    return dataKey?.label ? dataKey?.label : dataKey?.key
  }
}

const findColor = (dataSource: DataSource, key: string) => {
  const dataKey = dataSource.dataKeys.find((d) => d.key === key)
  if (dataKey) {
    return dataKey?.color ? dataKey?.color : 'red'
  }
}

const findUnit = (dataSource: DataSource, key: string) => {
  const dataKey = dataSource.dataKeys.find((d) => d.key === key)
  if (dataKey) {
    return dataKey?.units ? dataKey?.units : undefined
  }
}

const TimeSeriesLineChart = ({
  options: initialOptions,
  timeSeriesData = {},
  timeWindow,
}: LineChartProps) => {
  const { theme } = useAppSelector((state) => state.settings)
  // Créer un nouvel état pour les options
  const [options, setOptions] = React.useState(initialOptions)
  React.useEffect(() => {
    if (initialOptions?.plugins?.tooltip) {
      setOptions({
        ...initialOptions,
        parsing: {
          xAxisKey: 'ts',
          yAxisKey: 'value',
        },
        scales: {
          x: {
            ticks: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            grid: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            type: 'timeseries',
            //time: getTimeConfig(),
          },
          y: {
            ticks: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            grid: {
              color: theme === 'dark' ? '#748194' : undefined,
            },
            type: 'linear',
          },
        },
        plugins: {
          ...initialOptions.plugins,
          tooltip: {
            ...initialOptions.plugins.tooltip,
            callbacks: {
              label: (context: CustomToolTipItems) => {
                let label = context.dataset.label || ''
                let suffix = context.dataset.tooltipLabelSuffix || ''
                let prefix = context.dataset.tooltipLabelPrefix || ''

                if (label) {
                  label += ': '
                }
                if (context.parsed.y !== null) {
                  if (isNumericValue(context.parsed.y)) {
                    label += formatValue(context.parsed.y, 2, suffix, false)
                  } else {
                    label += context.parsed.y
                  }
                }
                return label
              },
            },
          },
        },
      })
    }
  }, [initialOptions, theme])
  // State management for chart data
  const [chartData, setChartData] = React.useState<{
    [key: string]: ChartDataset<'line', any>
  }>({})
  React.useEffect(() => {
    _.map(timeSeriesData, (r, aliasID) => {
      _.map(r.data, (d, key) => {
        const label = findLabel(r.datasource, key)
        const color = findColor(r.datasource, key)
        const unit = findUnit(r.datasource, key)
        const chartKey = `${aliasID}-${key}-${label}`
        setChartData((prev) => {
          return {
            ...prev,
            [chartKey]: {
              label: label,
              data: d,
              fill: false,
              backgroundColor: color,
              borderColor: color,
              tension: 0.1,
              tooltipLabelSuffix: unit,
              line: {
                color,
              },
              /*pointBorderWidth: 1,
              pointRadius: 3,*/
            },
          }
        })
      })
    })
  }, [timeSeriesData, timeWindow])
  const getTimeConfig = (): _DeepPartialObject<TimeScaleTimeOptions> => {
    let timeConfig: _DeepPartialObject<TimeScaleTimeOptions> = {
      unit: 'day',
      displayFormats: {
        day: 'MMM DD',
      },
    }
    if (timeWindow) {
      let timeWindowsMs: number | undefined = undefined
      const { timeWindowType } = timeWindow
      switch (timeWindowType) {
        case TimeWindowType.REAL_TIME:
          timeWindowsMs = timeWindow.realtime?.timeWindowsMs
          break
        case TimeWindowType.HISTORY:
          timeWindowsMs = timeWindow.history?.timeWindowsMs
          break
        default:
          break
      }
      if (timeWindowsMs) {
        if (timeWindowsMs <= 5000) {
          timeConfig = {
            unit: 'second',
            displayFormats: {
              second: 'HH:mm:ss',
            },
          }
        }
      }
    }
    return timeConfig
  }

  return (
    <>
      <Line
        data={{
          datasets: _.toArray(chartData),
        }}
        options={{
          ...options,
          /*plugins: {
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  yMin: 60,
                  yMax: 60,
                  borderColor: 'rgb(255, 99, 132)',
                  borderWidth: 2,
                  label: {
                    display: true,
                    content: 'Threshold',
                    textAlign: 'start',
                  },
                },
              },
            },
          },*/
        }}
      />
    </>
  )
}

export default TimeSeriesLineChart
