import React, { PropsWithChildren } from 'react'
import AddEntityButton, { AddEntityButtonProps } from './AddEntityButton'
import Flex from '../common/Flex'
import AdvanceTableRowPerPage from './AdvanceTableRowPerPage'
import AdvanceTableSearchBox from './AdvanceTableSearchBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { PaginationWithSearchTerm } from '../../store/kopiliot-api/query-type'

interface AdvanceTableHeaderProps extends PropsWithChildren {
  onPageSizeChange?: (size: number) => void
  paginationSettings: PaginationWithSearchTerm
  filterFunc?: (filter?: string | undefined) => void
  addEntityButton?: AddEntityButtonProps
  onRefresh?: () => void
}

const AdvanceTableHeader = ({
  onPageSizeChange,
  paginationSettings,
  filterFunc,
  addEntityButton,
  onRefresh,
}: AdvanceTableHeaderProps) => {
  return (
    <>
      <Flex
        justifyContent={'between'}
        alignItems={'center'}
        className={'mb-3 pt-2 w-100'}
      >
        {onPageSizeChange && (
          <Flex alignItems="center" className="fs--1">
            <AdvanceTableRowPerPage
              onChange={onPageSizeChange}
              pageSize={paginationSettings.limit}
            />
          </Flex>
        )}
        <Flex>
          {onRefresh && (
            <Button
              size={'sm'}
              variant={'outline-primary'}
              className={'me-2'}
              onClick={onRefresh}
            >
              <FontAwesomeIcon icon={'rotate-right'} className="fs-0" />
            </Button>
          )}
          <AddEntityButton {...addEntityButton} />
          {filterFunc && <AdvanceTableSearchBox setGlobalFilter={filterFunc} />}
        </Flex>
      </Flex>
    </>
  )
}

export default AdvanceTableHeader
