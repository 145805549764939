import React, { Dispatch, SetStateAction } from 'react'
import { KopiliotListingState } from './types'

// Créer un nouveau type qui inclut state et setState
interface KopiliotListingContextValue<T> {
  state: KopiliotListingState<T> | null
  setState: Dispatch<SetStateAction<KopiliotListingState<T> | null>>
}

// Créer le contexte
const KopiliotListingContext =
  React.createContext<KopiliotListingContextValue<any> | null>(null)

export const KopiliotListingProvider = <T extends any>({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, setState] = React.useState<KopiliotListingState<T> | null>(null)
  const value = { state, setState }

  return (
    <KopiliotListingContext.Provider value={value}>
      {children}
    </KopiliotListingContext.Provider>
  )
}

// Créer le hook personnalisé pour utiliser le contexte
export const useKopiliotListing = <T extends any>() => {
  const context = React.useContext(KopiliotListingContext)
  if (context === undefined) {
    throw new Error(
      'useKopiliotListing must be used within a KopiliotListingProvider'
    )
  }
  return context as KopiliotListingContextValue<T>
}
