import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import { AuditLogsQuery } from './query-type'
import AuditLog from '../../shared/models/audit-log'
import { EntityType } from '../../shared/models/entity-type.models'

const auditLogApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getAuditLogs: build.query<Pagination<AuditLog>, AuditLogsQuery>({
      query: (args) => ({
        url: 'audit/logs/all',
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
          tenantID: args.tenantID?.id,
          customerID: args.customerID?.id,
        },
      }),
    }),
    getAuditLogsByEntityId: build.query<
      Pagination<AuditLog>,
      AuditLogsQuery & { entityId: { id: string; entityType: EntityType } }
    >({
      query: (args) => ({
        url: `audit/logs/entity/${args.entityId.entityType}/${args.entityId.id}`,
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
        },
      }),
    }),
  }),
})

export const {
  useGetAuditLogsQuery,
  useLazyGetAuditLogsQuery,
  useGetAuditLogsByEntityIdQuery,
  useLazyGetAuditLogsByEntityIdQuery,
} = auditLogApi
