import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

interface HoverBoxProps extends PropsWithChildren {
  className?: string
}

const HoverBox = ({ children, className = '' }: HoverBoxProps) => {
  return (
    <div className={classNames('hoverbox', { [className]: className })}>
      {children}
    </div>
  )
}

export const HoverboxContent = ({
  children,
  className = '',
}: HoverBoxProps) => {
  return (
    <div className={classNames('hoverbox-content', { [className]: className })}>
      {children}
    </div>
  )
}

HoverBox.Content = HoverboxContent

export default HoverBox
