import React from 'react'
import { Line } from 'react-chartjs-2'

interface Props {
  width: number
  height: number
}

const SingleValueChart = ({ width, height }: Props) => {
  return (
    <div style={{ width, height }}>
      <Line
        data={{
          datasets: [
            {
              borderColor: 'red',
              borderWidth: 2,
              showLine: true,
              data: [
                { ts: 1715171032410, value: 12 },
                { ts: 1715171042410, value: 1 },
                { ts: 1715171052410, value: 45 },
                { ts: 1715171062410, value: 23 },
              ],
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
          elements: {
            line: {
              tension: 0.4,
            },
            point: {
              radius: 0,
            },
          },
          scales: {
            x: {
              display: false,
              type: 'timeseries',
            },
            y: {
              display: false,
              type: 'linear',
            },
          },
          animation: {
            duration: 0,
          },
          parsing: {
            xAxisKey: 'ts',
            yAxisKey: 'value',
          },
          showLine: false,
        }}
      />
    </div>
  )
}

export default SingleValueChart
