import React, { useContext, useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import Logo from '../../common/Logo'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../store/store'
import { collapseBurgerMenu } from '../../../store/slices/menus'
import TopNavRightSideNavItem from './TopNavRightSideNavItem'
import SearchBox from './search-box/SearchBox'

const NavbarTop = () => {
  // Redux
  const dispatch = useAppDispatch()
  // Local state
  const [_, setShowDropShadow] = useState(false)

  const handleBurgerMenu = () => {
    dispatch(collapseBurgerMenu())
  }

  const setDropShadow = () => {
    const el = document.documentElement
    if (el.scrollTop > 0) {
      setShowDropShadow(true)
    } else {
      setShowDropShadow(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow)
    return () => window.removeEventListener('scroll', setDropShadow)
  }, [])

  return (
    <Navbar
      className={'navbar-glass fs--1 navbar-top sticky-kit'}
      expand={'lg'}
    >
      <Navbar.Toggle
        className={
          'toggle-icon-wrapper me-md-3 me-2 d-xl-none navbar-toggler collapsed'
        }
        as={'div'}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>
      <Logo at={'navbar-top'} />
      <Nav navbar className={`align-items-center d-none d-lg-block`} as="ul">
        <Nav.Item as="li">
          <SearchBox />
        </Nav.Item>
      </Nav>
      <TopNavRightSideNavItem />
    </Navbar>
  )
}

export default NavbarTop
