import React from 'react'
import AddAssetModal from '../../features/asset/components/list/AddAssetModal'
import Spinner from '../../components/spinner/Spinner'
import ArkModal from '../../components/modal/ArkModal'
import useTenantCustomerContext from '../context/tenant-customer.context'
import { useNavigate } from 'react-router-dom'
import { AssetQuery } from '../../store/kopiliot-api/query-type'
import { useForm } from 'react-hook-form'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import { Pagination } from '../../shared/models/others/pagination'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { EntityType } from '../../shared/models/entity-type.models'
import { useLazyGetCustomerUsersQuery } from '../../store/kopiliot-api/customer'
import { useLazyGetTenantUsersQuery } from '../../store/kopiliot-api/tenant'
import User from '../../shared/models/user'
import {
  useLazyGetUserQuery,
  useLazyGetUsersQuery,
} from '../../store/kopiliot-api/user'
import {
  defaultUserActionsRow,
  getColumnsForTableUsers,
} from '../../features/users/list/columns'
import AddUserModal from '../../features/users/modal/AddUserModal'

const UserList = () => {
  // Get the context of the current tenant and customer
  const { tenantIDParam, customerIDParam } = useTenantCustomerContext()
  const navigate = useNavigate()
  // State
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  const [selectedAsset, setSelectedAsset] = React.useState<string | undefined>()
  const searchForm = useForm<AssetQuery>()
  const {
    watch,
    formState: { errors },
  } = searchForm
  const { textSearch, assetProfileID, tenantID, customerID } = watch()
  // Query
  const [getUsers, resultGetUsers] = useLazyGetUsersQuery()
  const [getUsersByTenant, resultGetUsersByTenant] =
    useLazyGetTenantUsersQuery()
  const [getUsersByCustomer, resultGetUsersByCustomer] =
    useLazyGetCustomerUsersQuery()

  const [fetchUser, resultFetchUser] = useLazyGetUserQuery()

  // Pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    AssetQuery,
    PaginationActions
  >(reducerPage, {
    textSearch: '',
    assetProfileID: undefined,
    tenantID: undefined,
    customerID: undefined,
    limit: 20,
    offset: 0,
  })
  React.useEffect(() => {
    fetchAssets()
  }, [
    paginationState.offset,
    paginationState.limit,
    textSearch,
    assetProfileID,
    tenantID,
    customerID,
    tenantIDParam,
    customerIDParam,
  ])

  // Utility functions
  const onDeleteUser = (assetId: string) => {
    // Delete the asset
  }
  const onEditUser = (userID: string) => {
    navigate(`/users/all/${userID}`)
  }
  const getData = (): Pagination<User> | undefined => {
    if (tenantIDParam) {
      if (customerIDParam) {
        return resultGetUsersByCustomer.data
      }
      return resultGetUsersByTenant.data
    } else {
      return resultGetUsers.data
    }
  }
  const isLoadingData = resultGetUsers.isLoading || resultFetchUser.isLoading

  function fetchAssets() {
    if (tenantIDParam) {
      if (customerIDParam) {
        getUsersByCustomer({
          limit: paginationState.limit,
          offset: paginationState.offset,
          textSearch,
          customerID: { id: customerIDParam, entityType: EntityType.CUSTOMER },
        }) // Fetch assets for a specific customer
      }
      // Fetch assets for a specific tenant
      getUsersByTenant({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch,
        customerID,
        tenantID: { id: tenantIDParam, entityType: EntityType.TENANT },
      })
    } else {
      // Fetch assets for all tenants
      getUsers({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch,
        customerID,
        tenantID,
      })
    }
  }

  return (
    <>
      <KopiliotList
        title={'Utilisateurs'}
        icon={'users'}
        isLoading={isLoadingData}
        actionsProps={{
          onRefresh: () => {
            fetchAssets()
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableUsers(
            defaultUserActionsRow(onDeleteUser, onEditUser)
          ),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: User) => item.id!.id,
          titleAccessor: (item: User) => item.firstname,
          descriptionAccessor: (item: User) => item.email,
          linkAccessor: (item: User) => `${item.id!.id}`,
          actionsRow: defaultUserActionsRow(onDeleteUser, onEditUser),
        }}
      />
      <ArkModal
        title={'Supprimer un User'}
        handleClose={() => setShowModalDelete(false)}
        isOpen={showModalDelete}
        validButton={{
          children: 'Supprimer',
          onClick: () => {
            if (selectedAsset) {
              onDeleteUser(selectedAsset)
            }
            setShowModalDelete(false)
          },
          variant: 'danger',
        }}
      >
        {resultFetchUser.data && resultFetchUser.isLoading && <Spinner />}
        <p>
          {`Voulez-vous vraiement supprimer le user ${resultFetchUser.data?.email}`}{' '}
          ?
        </p>
      </ArkModal>
      <AddUserModal isOpen={showModalCreate} setIsOpen={setShowModalCreate} />
    </>
  )
}

export default UserList
