import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { TenantRouteParam } from '../context/tenant.context'
import { TenantCustomerRouteParam } from '../context/tenant-customer.context'

const AlarmHome = () => {
  const { tenantID, customerID } = useParams<TenantCustomerRouteParam>()
  return (
    <>
      <Outlet
        context={{
          tenantIDParam: tenantID,
          customerIDParam: customerID,
        }}
      />
    </>
  )
}

export default AlarmHome
