import { Outlet, useParams } from 'react-router-dom'
import ListGroupNav from '../../components/navbar/internal/ListGroupNav'
import React from 'react'
import { TenantRouteParam } from '../context/tenant.context'

const RuleChainHome = () => {
  const { tenantID } = useParams<TenantRouteParam>()
  return (
    <>
      <Outlet
        context={{
          tenantIDParam: tenantID,
        }}
      />
    </>
  )
}

export default RuleChainHome
