import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'
import { Node } from 'reactflow'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'
import Divider from '../../../../../../components/common/Divider'

export interface CheckFieldExistConfiguration {
  allKeysRequired: boolean
  metadataFieldName: string[]
  messageFieldName: string[]
}

interface CheckFieldExistFormProps {
  onSubmit: (
    data: Node<
      BaseNodeData<CheckFieldExistConfiguration>,
      NodeTypes.FILTER_CHECK_FIELD_EXIST
    >
  ) => void
  defaultValues: Node<
    BaseNodeData<CheckFieldExistConfiguration>,
    NodeTypes.FILTER_CHECK_FIELD_EXIST
  >
}

const CheckFieldExistForm = ({
  onSubmit,
  defaultValues,
}: CheckFieldExistFormProps) => {
  const form = useForm({ defaultValues })
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = form

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={'align-items-center'}>
          <Form.Group as={Col} lg={6} md={12} className="mb-3">
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={errors?.data?.name}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Check
              type="switch"
              label="Debug Mode ?"
              {...register('data.isDebugMode')}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12} className="mb-3">
            <Form.Label>Metadata Fields names</Form.Label>
            <Controller
              name={'data.configuration.metadataKeyNames'}
              render={({ field: { onChange, value: values } }) => {
                return (
                  <CreatableSelect
                    isClearable
                    isMulti
                    value={values?.map((value: string) => ({
                      value,
                      label: value,
                    }))}
                    onChange={(values, actionMeta) => {
                      const strValues = _.chain(values)
                        .map('value')
                        .uniq()
                        .value()
                      onChange(strValues)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12} className="mb-3">
            <Form.Label>Message key names</Form.Label>
            <Controller
              name={'data.configuration.messageKeyNames'}
              render={({ field: { onChange, value: values } }) => {
                return (
                  <CreatableSelect
                    isClearable
                    isMulti
                    value={values?.map((value: string) => ({
                      value,
                      label: value,
                    }))}
                    onChange={(values, actionMeta) => {
                      const strValues = _.chain(values)
                        .map('value')
                        .uniq()
                        .value()
                      onChange(strValues)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Check
              type="switch"
              label="All keys have to be filled ?"
              {...register('data.configuration.allKeysRequired')}
            />
          </Form.Group>
        </Row>
        <Divider />
        <Row>
          <Form.Group as={Col} lg={12}>
            <Input
              register={form.register('data.description', {
                shouldUnregister: true,
              })}
              label={'Description'}
              type={'textarea'}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default CheckFieldExistForm
