import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import Customer from '../../shared/models/customer'
import { AssetQuery, CustomerQuery, DeviceQuery, UserQuery } from './query-type'
import { EntityType } from '../../shared/models/entity-type.models'
import User from '../../shared/models/user'
import Asset from '../../shared/models/asset'

const customer = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<Pagination<Customer>, CustomerQuery>({
      query: (args) => ({
        url: 'customers',
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
          tenantID: args.tenantID?.id,
        },
      }),
      providesTags: (result = new Pagination<Customer>(), error, arg) => [
        ...result.rows.map((item: Customer) => {
          return {
            type: 'Customer' as const,
            id: item.id?.id,
          }
        }),
        { type: 'Customer', id: 'LIST' },
      ],
    }),
    getCustomer: build.query<Customer, string>({
      query: (customerId) => `customers/${customerId}`,
      keepUnusedDataFor: 10,
      providesTags: (result, error, arg) => [{ type: 'Customer', id: arg }],
    }),
    getCustomerDevices: build.query<
      Pagination<Customer>,
      DeviceQuery & {
        customerID: { id: string; entityType: EntityType.CUSTOMER }
      }
    >({
      query: (args) => ({
        url: `customers/${args.customerID.id}/devices`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
          deviceProfileID: args.deviceProfileID,
        },
      }),
      providesTags: (result = new Pagination<Customer>(), error, arg) => [
        ...result.rows.map((item: Customer) => {
          return {
            type: 'Customer' as const,
            id: item.id?.id,
          }
        }),
        { type: 'Customer', id: 'LIST' },
      ],
    }),
    getCustomerAssets: build.query<
      Pagination<Asset>,
      AssetQuery & {
        customerID: { id: string; entityType: EntityType.CUSTOMER }
      }
    >({
      query: (args) => ({
        url: `customers/${args.customerID.id}/assets`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
          assetProfileID: args.assetProfileID,
        },
      }),
      providesTags: (result = new Pagination<Asset>(), error, arg) => [
        { type: 'Asset', id: 'LIST' },
        { type: 'AssetByCustomer', id: arg.customerID?.id },
        { type: 'AssetByTenant', id: arg.tenantID?.id },
      ],
    }),
    getCustomerUsers: build.query<
      Pagination<User>,
      UserQuery & {
        customerID: { id: string; entityType: EntityType.CUSTOMER }
      }
    >({
      query: (args) => ({
        url: `customer/${args.customerID.id}/users`,
        method: 'GET',
        params: {
          textSearch: args.textSearch,
          limit: args.limit,
          offset: args.offset,
        },
      }),
    }),
    addCustomer: build.mutation<Customer, Customer>({
      query: (customer) => ({
        url: 'customers',
        method: 'POST',
        body: customer,
      }),
      invalidatesTags: [
        { type: 'Customer', id: 'LIST' },
        { type: 'CustomerByTenant', id: 'LIST' },
      ],
    }),
    updateCustomer: build.mutation<Customer, Customer>({
      query: (customer) => ({
        url: `customers/${customer.id?.id}`,
        method: 'PUT',
        body: customer,
      }),
      invalidatesTags: [{ type: 'Customer', id: 'LIST' }],
    }),
    deleteCustomer: build.mutation<void, string>({
      query: (customerId) => ({
        url: `customers/${customerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Customer', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useLazyGetCustomerAssetsQuery,
  useLazyGetCustomerUsersQuery,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} = customer
