import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../../components/button/ButtonLoading'
import Asset from '../../../../shared/models/asset'
import { useAddNewAssetMutation } from '../../../../store/kopiliot-api/asset'
import AssetForm from '../forms/AssetForm'
import { toast } from 'react-toastify'

interface AddAssetModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  defaultValues?: Partial<Asset>
  hideFields?: Array<keyof Asset>
}

const AddAssetModal = ({
  isOpen,
  setIsOpen,
  defaultValues,
  hideFields,
}: AddAssetModalProps) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const form = useForm<Asset>({ defaultValues: defaultValues })
  const [addAsset, { isLoading, error }] = useAddNewAssetMutation()
  const onSubmit = async (data: Asset) => {
    try {
      await addAsset(data).unwrap()
      form.reset()
      handleClose()
      toast.success('Asset ajouté avec succès !')
    } catch (e) {
      console.log(e)
    }
  }
  React.useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'xl'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Créer un Asset</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <AssetForm form={form} onSubmit={onSubmit} hideFields={hideFields} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          variant={'outline-primary'}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddAssetModal
