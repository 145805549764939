import { EntityId } from './entity-id'
import { EntityType } from '../entity-type.models'

/*
 * RuleNodeId is a class that implements EntityId.
 */
export class RuleNodeId implements EntityId {
  entityType = EntityType.RULE_NODE
  id: string
  constructor(id: string) {
    this.id = id
  }
}
