import React from 'react'
import { Node } from 'reactflow'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import _ from 'lodash'
import Select from 'react-select'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'

export enum MessageType {
  POST_TELEMETRY = 'POST TELEMETRY',
  POST_ATTRIBUTE = 'POST ATTRIBUTE',
}

export interface MessageTypeConfiguration {
  messageTypeFilter: MessageType[]
}

interface MessageTypeFormProps {
  onSubmit: (
    data: Node<
      BaseNodeData<MessageTypeConfiguration>,
      NodeTypes.FILTER_MESSAGE_TYPE
    >
  ) => void
  defaultValues?: Node<
    BaseNodeData<MessageTypeConfiguration>,
    NodeTypes.FILTER_MESSAGE_TYPE
  >
}

const MessageTypeForm = ({ onSubmit, defaultValues }: MessageTypeFormProps) => {
  const form = useForm<
    Node<BaseNodeData<MessageTypeConfiguration>, NodeTypes.FILTER_MESSAGE_TYPE>
  >({
    defaultValues,
  })
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = form
  const filterOptions = Object.values(MessageType).map((value) => {
    return {
      label: value,
      value,
    }
  })

  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={errors?.data?.name}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <Form.Label>Select Keys</Form.Label>
            <Controller
              name={'data.configuration.messageTypeFilter'}
              render={({ field: { onChange } }) => {
                return (
                  <Select
                    isClearable
                    isMulti
                    options={filterOptions}
                    defaultValue={_.filter(filterOptions, (obj) => {
                      return _.includes(
                        defaultValues?.data?.configuration?.messageTypeFilter,
                        obj.value
                      )
                    })}
                    onChange={(values, actionMeta) => {
                      const updValues = values.map((obj) => obj.value)
                      onChange(updValues)
                    }}
                    {..._.omit(
                      register('data.configuration.messageTypeFilter'),
                      ['onChange']
                    )}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default MessageTypeForm
