import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../common/Flex'
import SubtleBadge from '../../common/SubtleBadge'
import { MenuItem } from '../../../routes/routes'

interface NavbarVerticalMenuItemProps {
  route: MenuItem
}

const NavbarVerticalMenuItem = ({ route }: NavbarVerticalMenuItemProps) => {
  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text ps-1">{route.name}</span>
      {route.badge && (
        <SubtleBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SubtleBadge>
      )}
    </Flex>
  )
}

export default React.memo(NavbarVerticalMenuItem)
