import { EntityType } from '../../shared/models/entity-type.models'
import { baseArkhaleAPI } from './base-api'
import { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  AggregatedTimeseriesData,
  LatestTimeseriesData,
  TimeseriesData,
} from '../../shared/models/telemetry/telemetry.model'
import { Aggregation, AggregationType } from '../../shared/models/time.model'

export interface TimeSeriesQuery {
  queryID: number // TODO: verify if it works
  entityType: EntityType
  entityID: string
  keys: string[]
  startTs?: number
  endTs?: number
  aggregation?: Aggregation
}

export interface AggKey {
  key: string
  aggType: AggregationType
  previousStartTs?: number
  previousEndTs?: number
  previousValueOnly?: boolean
}

export interface AggTimeseriesCmd {
  entityType: EntityType
  entityID: string
  aggKeys: AggKey[]
  startTs: number
  timeWindow: number
}

const timeSeriesAPI = baseArkhaleAPI.injectEndpoints({
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    getTimeSeries: build.query<TimeseriesData, TimeSeriesQuery>({
      query: (args: TimeSeriesQuery): string | FetchArgs => ({
        url: `telemetries/${args.entityType}/${args.entityID}/values/timeseries`,
        method: 'GET',
        params: {
          keys: args.keys,
          startTs: args.startTs,
          endTs: args.endTs,
          aggregationType: args.aggregation?.type,
          aggregationInterval: args.aggregation?.interval,
          limit: args.aggregation?.limit,
        },
      }),
    }),
    getLatestTimeSeries: build.query<LatestTimeseriesData, TimeSeriesQuery>({
      query: (args: TimeSeriesQuery): string | FetchArgs => ({
        url: `telemetries/${args.entityType}/${args.entityID}/values/timeseries/latest`,
        method: 'GET',
        params: {
          keys: args.keys,
        },
      }),
    }),
    getAggTimeSeries: build.mutation<
      AggregatedTimeseriesData,
      AggTimeseriesCmd
    >({
      query: (args: AggTimeseriesCmd): string | FetchArgs => ({
        url: `telemetries/${args.entityType}/${args.entityID}/values/timeseries/aggregate`,
        method: 'POST',
        body: {
          keys: args.aggKeys,
          startTs: args.startTs,
          timeWindow: args.timeWindow,
        },
      }),
    }),
  }),
})

export const {
  useLazyGetTimeSeriesQuery,
  useLazyGetLatestTimeSeriesQuery,
  useGetAggTimeSeriesMutation,
} = timeSeriesAPI
