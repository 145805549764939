import React from 'react'
import { useFormContext } from 'react-hook-form'
import { DataSource, PanelType } from '../../../../shared/models/panel'
import Input from '../../../../components/form/Input'
import { Button, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SpecificDataKeySettings from './SpecificDataKeySettings'

interface DataKeyFormProps {
  dataKeyIndex: number
  dataSourcesIndex: number
  remove: (index?: number | number[] | undefined) => void
  panelType: PanelType
  type: 'dataKeys' | 'latestDataKeys'
}

const DataKeyForm = ({
  dataKeyIndex,
  dataSourcesIndex,
  remove,
  panelType,
  type,
}: DataKeyFormProps) => {
  const form = useFormContext<{ dataSources: Partial<DataSource>[] }>()
  const [isSpecificDataKeySettingsOpen, setIsSpecificDataKeySettingsOpen] =
    React.useState(false)
  const isInitialRender = React.useRef(true)

  return (
    <>
      <td>
        <Input
          type={'text'}
          error={
            form.formState.errors.dataSources?.[dataSourcesIndex]?.[type]?.[
              dataKeyIndex
            ]?.key
          }
          register={form.register(
            `dataSources.${dataSourcesIndex}.${type}.${dataKeyIndex}.key`,
            {
              required: 'La clé est requise',
            }
          )}
        />
      </td>
      <td>
        <Input
          type={'text'}
          error={
            form.formState.errors.dataSources?.[dataSourcesIndex]?.dataKeys?.[
              dataKeyIndex
            ]?.label
          }
          register={form.register(
            `dataSources.${dataSourcesIndex}.${type}.${dataKeyIndex}.label`,
            {
              required: 'Label est requis',
            }
          )}
        />
      </td>
      <td>
        <Input
          type={'color'}
          errors={form.formState.errors}
          register={form.register(
            `dataSources.${dataSourcesIndex}.${type}.${dataKeyIndex}.color`
          )}
        />
      </td>
      <td>
        <Input
          type={'text'}
          errors={form.formState.errors}
          register={form.register(
            `dataSources.${dataSourcesIndex}.${type}.${dataKeyIndex}.units`
          )}
        />
      </td>
      <td>
        <Input
          type={'numeric'}
          errors={form.formState.errors}
          register={form.register(
            `dataSources.${dataSourcesIndex}.${type}.${dataKeyIndex}.decimal`
          )}
        />
      </td>
      <td>
        <ButtonGroup>
          <Button
            //className={''}
            variant={'outline-primary'}
            type={'button'}
            onClick={() => setIsSpecificDataKeySettingsOpen(true)}
          >
            <FontAwesomeIcon icon={'gear'} />
          </Button>
          <Button
            variant={'outline-danger'}
            type={'button'}
            onClick={() => {
              form.trigger().then(() => {
                remove(dataKeyIndex)
              })
            }}
          >
            <FontAwesomeIcon icon={'trash'} />
          </Button>
        </ButtonGroup>
        <SpecificDataKeySettings
          isOpen={isSpecificDataKeySettingsOpen}
          onClosed={() => setIsSpecificDataKeySettingsOpen(false)}
          initialValues={form.getValues(
            `dataSources.${dataSourcesIndex}.${type}.${dataKeyIndex}.specificProps`
          )}
          onSubmitted={(d) => {
            form.setValue(
              `dataSources.${dataSourcesIndex}.${type}.${dataKeyIndex}.specificProps`,
              d
            )
            setIsSpecificDataKeySettingsOpen(false)
          }}
          panelType={panelType}
        />
      </td>
    </>
  )
}

export default DataKeyForm
