import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import classNames from 'classnames'
import { FormProvider, useForm } from 'react-hook-form'
import { ActivateAccountOrResetPasswordRequest } from '../../shared/models/others/activate-account'
import { useNavigate } from 'react-router-dom'
import bgImg from '../../assets/img/generic/20.jpg'
import Input from '../../components/form/Input'
import SplitPageLayout from '../_layout/SplitPageLayout'
import {
  useActivateAccountMutation,
  useResetPasswordMutation,
} from '../../store/kopiliot-api/auth'

interface PasswordResetFormProps {
  token: string | null
  title: string
  action: 'reset' | 'activate'
}

const PasswordResetForm = ({
  token,
  title,
  action,
}: PasswordResetFormProps) => {
  const navigate = useNavigate()
  const [activateAccount, { data: activateAccountData, isLoading }] =
    useActivateAccountMutation()
  const [resetPassword, { data: resetPasswordData }] =
    useResetPasswordMutation()

  const form = useForm<ActivateAccountOrResetPasswordRequest>()
  useEffect(() => {
    if (!token) {
      toast.error('Invalid token')
      navigate('/auth/login')
    }
    form.setValue('token', token)
  }, [])
  const {
    formState: { errors },
  } = form
  const onSubmit = async (data: ActivateAccountOrResetPasswordRequest) => {
    try {
      if (action === 'reset') {
        await resetPassword(data).unwrap()
        toast.success('Password reset successful')
        navigate('/auth/login')
      } else {
        await activateAccount(data).unwrap()
        toast.success('Account activation successful')
        navigate('/auth/login')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <SplitPageLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <h3>{title}</h3>
      </div>
      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Input register={form.register('token')} type="hidden" />
          <Input
            register={form.register('password', {
              required: 'Password is required',
            })}
            label="Password"
            type="password"
            error={errors.password}
          />
          <Input
            register={form.register('passwordConfirm', {
              required: 'Confirm Password is required',
            })}
            label="Confirm Password"
            type="password"
            error={errors.passwordConfirm}
          />
          <Button type="submit" className="btn btn-primary btn-block">
            Submit
          </Button>
        </Form>
      </FormProvider>
    </SplitPageLayout>
  )
}

export default PasswordResetForm
