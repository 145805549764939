import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Attribute } from '../../shared/models/attribute'
import { toast } from 'react-toastify'
import React from 'react'
import dayjs from 'dayjs'
import IconButton from '../../components/common/IconButton'

const attributeColumns = (onEdit: (data: Attribute) => void) => {
  const columnHelper = createColumnHelper<Attribute>()
  try {
    const columns: ColumnDef<Attribute, string>[] = [
      columnHelper.display({
        id: 'name',
        header: () => 'Nom',
        enableSorting: true,
        meta: {
          className: 'align-middle',
        },
        cell: (props) => {
          const { lastUpdateDate } = props.row.original

          return lastUpdateDate
            ? dayjs(lastUpdateDate).format('DD/MM/YYYY HH:mm')
            : ''
        },
      }),
      columnHelper.accessor((row) => row.key, {
        id: 'key',
        cell: (info) => info.getValue(),
        header: () => 'Key',
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.value, {
        id: 'value',
        cell: (info) => {
          const value = info.getValue()
          const type = typeof value
          switch (type) {
            case 'object':
              return JSON.stringify(value)
            case 'boolean':
              return value ? 'true' : 'false'
            default:
              return value
          }
        },
        header: () => 'Value',
        enableSorting: true,
      }),
      columnHelper.display({
        id: 'actions',
        header: () => 'Actions',
        cell: (props) => {
          return (
            <div className={'d-flex justify-content-center align-items-center'}>
              <div className={'me-2'}>
                <IconButton
                  iconClassName={'edit'}
                  size={'sm'}
                  variant={'outline-primary'}
                  onClick={(e) => {
                    onEdit(props.row.original)
                  }}
                />
              </div>
              <div>
                <IconButton
                  iconClassName={'trash'}
                  size={'sm'}
                  variant={'outline-danger'}
                />
              </div>
            </div>
          )
        },
      }),
    ]
    return columns
  } catch (error) {
    toast.error('Error while creating columns')
  }
  return []
}

export default attributeColumns
