import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Flex from '../../components/common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NotificationMenu = () => {
  return (
    <Nav
      variant={'underline'}
      defaultActiveKey={'home'}
      className={'list-group-nav fw-semi-bold'}
    >
      <Nav.Item>
        <NavLink to={'inbox'} className={'nav-link px-4 py-3'}>
          <Flex alignItems="center" justifyContent={'center'}>
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={'message'} />
            </span>
            <span className="nav-link-text ps-1">Boite de réception</span>
          </Flex>
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className={`nav-link px-4 py-3`} to={'sent'}>
          <Flex alignItems="center" justifyContent={'center'}>
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={'paper-plane'} />
            </span>
            <span className="nav-link-text ps-1">Envoyées</span>
          </Flex>
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className={`nav-link px-4 py-3`} to={'recipients'}>
          <Flex alignItems="center" justifyContent={'center'}>
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={'users-viewfinder'} />
            </span>
            <span className="nav-link-text ps-1">Destinataires</span>
          </Flex>
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className={`nav-link px-4 py-3`} to={'templates'}>
          <Flex alignItems="center" justifyContent={'center'}>
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={'file-invoice'} />
            </span>
            <span className="nav-link-text ps-1">Modèles</span>
          </Flex>
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className={`nav-link px-4 py-3`} to={'rules'}>
          <Flex alignItems="center" justifyContent={'center'}>
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={'gears'} />
            </span>
            <span className="nav-link-text ps-1">Règles</span>
          </Flex>
        </NavLink>
      </Nav.Item>
    </Nav>
  )
}

export default NotificationMenu
