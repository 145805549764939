import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface TooltipBadgeProps extends React.PropsWithChildren {
  placement?: Placement
  tooltip: string
  icon?: IconProp
  color?: string
}

const TooltipBadge = ({
  placement = 'top',
  tooltip,
  icon,
  color = 'primary',
  children,
}: TooltipBadgeProps) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <span>
        {children && children}
        {!children && icon && (
          <FontAwesomeIcon
            icon={icon}
            transform="shrink-2"
            className={`text-${color} ms-1`}
          />
        )}
      </span>
    </OverlayTrigger>
  )
}

TooltipBadge.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
}

export default TooltipBadge
