import React from 'react'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  changePageSize,
  changeTextSearch,
  PaginationActions,
  PaginationActionType,
} from '../../hooks/pagination-reducer'
import AddCustomerModal from '../../features/customers/modal/AddCustomerModal'
import DeleteCustomerModal from '../../features/customers/modal/DeleteCustomerModal'
import useTenantContext from '../context/tenant.context'
import { useNavigate } from 'react-router-dom'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import Customer from '../../shared/models/customer'
import {
  defaultCustomerActionsRow,
  getColumnsForTableCustomer,
} from '../../features/customers/list/utils'
import { CustomerQuery } from '../../store/kopiliot-api/query-type'
import { useForm } from 'react-hook-form'
import CustomerSearchForm from '../../features/customers/form/CustomerSearchForm'
import { EntityType } from '../../shared/models/entity-type.models'
import { createEntityQueryReducer } from '../../hooks/create-entity-pagination-reducer'
import {
  ActionUpdateTenantID,
  CommonActionType,
} from '../../hooks/common-actions'
import { useFetchCustomers } from './customer.utils'

const CustomerList = () => {
  const { tenantIDParam } = useTenantContext()
  const { fetchCustomers, getData, isLoading } =
    useFetchCustomers(tenantIDParam)
  const reducer = React.useMemo(() => {
    return createEntityQueryReducer<
      CustomerQuery,
      PaginationActions | ActionUpdateTenantID
    >({
      handleSpecificActions: {
        [CommonActionType.UPDATE_TENANT_ID]: (state, action) => {
          return { ...state, tenantID: action.payload.tenantID }
        },
      },
    })
  }, [])
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducer,
    CustomerQuery,
    PaginationActions | ActionUpdateTenantID
  >(reducer, {
    limit: 20,
    offset: 0,
    textSearch: '',
    tenantID: undefined,
  })

  const navigate = useNavigate()
  const searchForm = useForm<Omit<CustomerQuery, 'offset' | 'limit'>>()
  const { watch } = searchForm
  const { textSearch, tenantID } = watch()

  // Modal Delete
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [selectedCustomer, setSelectedCustomer] = React.useState<
    string | undefined
  >(undefined)
  // Modal Edit Or Create
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  React.useEffect(() => {
    const search = setTimeout(() => {
      changeTextSearch(dispatchPaginationAction, textSearch)
    }, 1000)
    return () => clearTimeout(search)
  }, [textSearch])
  // Get data from the server
  React.useEffect(() => {
    const currentTenantID = tenantIDParam
      ? { id: tenantIDParam, entityType: EntityType.TENANT }
      : tenantID
    dispatchPaginationAction({
      type: CommonActionType.UPDATE_TENANT_ID,
      payload: { tenantID: currentTenantID },
    })
  }, [tenantIDParam, tenantID])
  React.useEffect(() => {
    fetchCustomers(paginationState, true)
  }, [paginationState])

  const getDisabledField = (): Array<
    keyof Omit<CustomerQuery, 'offset' | 'limit'>
  > => {
    return tenantIDParam ? ['tenantID'] : []
  }
  const onDeleteItem = (id: string) => {
    setSelectedCustomer(id)
    setShowModalDelete(true)
  }
  const onEditItem = (id: string) => {
    navigate(`customers/${id}`)
  }
  const actionsRow = defaultCustomerActionsRow(onDeleteItem, onEditItem)

  return (
    <>
      <KopiliotList
        title={'Clients'}
        icon={'microchip'}
        isLoading={isLoading}
        actionsProps={{
          filterForm: (
            <CustomerSearchForm
              form={searchForm}
              onSubmit={() => {}}
              disabledFields={getDisabledField()}
            />
          ),
          onRefresh: () => {
            fetchCustomers(paginationState, false)
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            changePageSize(dispatchPaginationAction, size)
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableCustomer(actionsRow),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: Customer) => item.id!.id,
          titleAccessor: (item: Customer) => item.name,
          descriptionAccessor: (item: Customer) => item.description,
          linkAccessor: (item: Customer) => `${item.id!.id}`,
          actionsRow: actionsRow,
        }}
      />
      <AddCustomerModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
      />
      <DeleteCustomerModal
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        customerID={selectedCustomer}
      />
    </>
  )
}

export default CustomerList
