import React from 'react'
import {
  useGetDeviceInfoByDeviceIdQuery,
  useGetDeviceQuery,
} from '../../../store/kopiliot-api/device'
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubtleBadge from '../../../components/common/SubtleBadge'
import { DeviceStatus } from '../../../shared/models/device-info'
import dayjs from 'dayjs'
import {
  useGetDeviceProfileQuery,
  useLazyGetDeviceProfileQuery,
} from '../../../store/kopiliot-api/device-profile'

interface Props {
  deviceID: string
}

const IsConnected = ({ deviceID }: Props) => {
  const { data, isLoading, isFetching, error } =
    useGetDeviceInfoByDeviceIdQuery(deviceID)
  if (error) {
    return <div>Error, cannot retrieve this information</div>
  }
  const loading = isLoading || isFetching
  let bg: 'success' | 'danger' | 'warning' | 'dark' = 'dark'
  let text = 'UNKNOWN'
  if (data) {
    if (data.isConnected) {
      bg = 'success'
      text = 'CONNECTED'
    } else {
      bg = 'danger'
      text = 'DISCONNECTED'
    }
  }
  return loading || !data ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <SubtleBadge pill className={'d-block'} bg={bg}>
      {text}
      <FontAwesomeIcon
        icon={data?.status ? 'check' : 'times'}
        transform="shrink-2"
        className={'ms-1'}
      />
    </SubtleBadge>
  )
}

const LastActivityAt = ({ deviceID }: Props) => {
  const { data, isLoading, isFetching, error } =
    useGetDeviceInfoByDeviceIdQuery(deviceID)
  if (error) {
    return <div>Error, cannot retrieve this information</div>
  }
  const loading = isLoading || isFetching
  return loading || !data ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <span>{dayjs(data.lastActivityAt).format('DD/MM/YYYY HH:mm:ss')}</span>
  )
}

const Status = ({ deviceID }: Props) => {
  const { data, isLoading, isFetching, error } =
    useGetDeviceInfoByDeviceIdQuery(deviceID)
  if (error) {
    return <div>Error, cannot retrieve this information</div>
  }
  const loading = isLoading || isFetching
  let bg: 'success' | 'danger' | 'warning' | 'dark' = 'dark'
  switch (data?.status) {
    case DeviceStatus.ENABLED:
      bg = 'success'
      break
    case DeviceStatus.ERROR:
    case DeviceStatus.DISABLED:
      bg = 'danger'
      break
  }
  return loading || !data ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <SubtleBadge pill className={'d-block'} bg={bg}>
      {data.status.toUpperCase()}
      <FontAwesomeIcon
        icon={data?.status ? 'check' : 'times'}
        transform="shrink-2"
        className={'ms-1'}
      />
    </SubtleBadge>
  )
}

const DeviceProfile = ({ deviceID }: Props) => {
  const { data, isLoading, isFetching, error } = useGetDeviceQuery(deviceID)
  const [
    getDeviceProfile,
    {
      data: deviceProfile,
      isLoading: isLoadingDeviceProfile,
      isFetching: isFetchingDeviceProfile,
      error: errorDeviceProfile,
    },
  ] = useLazyGetDeviceProfileQuery()
  React.useEffect(() => {
    if (data) {
      getDeviceProfile(data.deviceProfileID.id, true)
    }
  }, [data])
  if (error || errorDeviceProfile) {
    return <div>Error, cannot retrieve this information</div>
  }
  const loading =
    isLoading || isFetching || isLoadingDeviceProfile || isFetchingDeviceProfile

  return loading || !data || !deviceProfile ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <span>{deviceProfile?.name}</span>
  )
}

const DeviceInfoTools = {
  IsConnected,
  LastActivityAt,
  Status,
  DeviceProfile,
}

export default DeviceInfoTools
