import React from 'react'
import User, { UserCreate } from '../../../shared/models/user'
import { useForm } from 'react-hook-form'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../components/button/ButtonLoading'
import UserForm from '../form/UserForm'
import { useAddUserMutation } from '../../../store/kopiliot-api/user'

interface AddUserModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
}

const AddUserModal = ({ isOpen, setIsOpen }: AddUserModalProps) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const [addUser, { isLoading }] = useAddUserMutation()
  const form = useForm<UserCreate>()
  const onSubmit = async (data: UserCreate) => {
    try {
      await addUser(data).unwrap()
      form.reset()
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'lg'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Ajouter un utilisateur</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <UserForm
          form={form}
          onSubmit={onSubmit}
          isLoading={isLoading}
          disabledField={[]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} variant={'secondary'} onClick={handleClose}>
          Annuler
        </Button>
        <ButtonLoading
          isLoading={false} // TODO
          disabled={false} // TODO
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddUserModal
