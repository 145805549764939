import { BaseData } from './base-data'
import { RuleNodeId } from './id/rule-node-id'
import _ from 'lodash'

export interface RuleNode extends BaseData<RuleNodeId> {
  name: string
  nodeType: string
  positionX: number
  positionY: number
  configuration: any
}

export enum NodeTypes {
  INPUT_NODE = 'inputNode',
  // Filters
  FILTER_CHECK_FIELD_EXIST = 'filterCheckFieldExistNode',
  FILTER_ASSET_PROFILE_SWITCH = 'filterAssetProfileSwitchNode',
  FILTER_ENTITY_TYPE = 'filterEntityTypeNode',
  FILTER_ENTITY_TYPE_SWITCH = 'filterEntityTypeSwitchNode',
  FILTER_MESSAGE_TYPE = 'filterMessageTypeNode',
  FILTER_MESSAGE_TYPE_SWITCH = 'filterMessageTypeSwitchNode',
  FILTER_CHECK_ALARM_STATUS = 'filterCheckAlarmStatusNode',
  FILTER_SCRIPT = 'filterScriptNode',
  // Actions
  ACTION_SAVE_TELEMETRY = 'actionSaveTelemetryNode',
  ACTION_CREATE_ALARM = 'actionCreateAlarmNode',
  ACTION_CLEAR_ALARM = 'actionClearAlarmNode',
  // Transform
  TRANSFORM_RENAME_KEYS = 'transformRenameKeysNode',
  TRANSFORM_SCRIPT = 'transformScriptNode',
  TRANSFORM_TO_EMAIL = 'transformRenameKeysNode',
  // Enrichment
  ENRICHMENT_ORIGINATOR_ATTRIBUTES = 'enrichmentOriginatorAttributesNode',
  ENRICHMENT_CUSTOMER_ATTRIBUTES = 'enrichmentCustomerAttributesNode',
}

export interface RuleNodeFmt extends BaseData<RuleNodeId> {
  added: boolean
  name: string
  type: NodeTypes
  debugMode: boolean
  positionX: number
  positionY: number
  configuration: any
}

type ConditionForNode = {
  value: string
  label: string
  sourceNodes: NodeTypes[] | 'all'
}

export const conditionForNode: ConditionForNode[] = [
  {
    value: 'SUCCESS',
    label: 'Success',
    sourceNodes: [
      NodeTypes.FILTER_CHECK_FIELD_EXIST,
      NodeTypes.FILTER_ASSET_PROFILE_SWITCH,
      NodeTypes.FILTER_ENTITY_TYPE,
      NodeTypes.FILTER_ENTITY_TYPE_SWITCH,
      NodeTypes.FILTER_MESSAGE_TYPE,
      NodeTypes.FILTER_MESSAGE_TYPE_SWITCH,
      NodeTypes.ACTION_SAVE_TELEMETRY,
      NodeTypes.ENRICHMENT_CUSTOMER_ATTRIBUTES,
      NodeTypes.ENRICHMENT_ORIGINATOR_ATTRIBUTES,
      NodeTypes.TRANSFORM_SCRIPT,
    ],
  },
  {
    value: 'ALARM_CREATED',
    label: 'Alarm Created',
    sourceNodes: [NodeTypes.ACTION_CREATE_ALARM],
  },
  {
    value: 'ALARM_UPDATED',
    label: 'Alarm Created',
    sourceNodes: [NodeTypes.ACTION_CREATE_ALARM],
  },
  {
    value: 'FAILURE',
    label: 'Failure',
    sourceNodes: 'all',
  },
  {
    value: 'TRUE',
    label: 'True',
    sourceNodes: [NodeTypes.FILTER_SCRIPT],
  },
  {
    value: 'FALSE',
    label: 'False',
    sourceNodes: [NodeTypes.FILTER_SCRIPT],
  },
]

export const getConditionForNode = (value: NodeTypes) => {
  return _.chain(conditionForNode)
    .filter((item) => {
      return item.sourceNodes.includes(value) || item.sourceNodes === 'all'
    })
    .map((item) => {
      return {
        value: item.value,
        label: item.label,
      }
    })
    .value()
}
