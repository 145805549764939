import React from 'react'
import { TimeWindowProps } from './types'
import Flex from '../../../../components/common/Flex'
import { Form } from 'react-bootstrap'
import Input from '../../../../components/form/Input'
import {
  HistoryWindowType,
  TimeWindow,
} from '../../../../shared/models/time.model'
import LastSelector from './LastSelector'
import Divider from '../../../../components/common/Divider'
import QuickIntervalSelector from './QuickIntervalSelector'
import AggregationSelector from './AggregationSelector'
import GroupingIntervalSelector from './GroupingIntervalSelector'
import { useFormContext } from 'react-hook-form'
import FixedTimeWindowSelector from './FixedTimeWindowSelector'
import LabelHelp from '../../../../components/form/LabelHelp'

const TimeWindowHistory = ({ isEditing }: TimeWindowProps) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext<TimeWindow>()
  const { history } = watch()
  return (
    <div>
      <Flex direction={'row'} justifyContent={'start'}>
        {isEditing && (
          <div>
            <Form.Label htmlFor={'hideInterval'} className={'px-2 text-body'}>
              Caché
            </Form.Label>
            <Input
              className={'ms-2'}
              type={'switch'}
              register={register('hideInterval', { shouldUnregister: true })}
            />
          </div>
        )}

        <Flex direction={'column'} className={'flex-grow-1'}>
          <Flex direction={'row'}>
            <Flex
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              className={'px-3'}
            >
              <Input
                register={register('history.historyType')}
                value={HistoryWindowType.LAST}
                type={'radio'}
              />
            </Flex>

            <Flex
              direction={'row'}
              justifyContent={'start'}
              alignContent={'flex-start'}
              alignItems={'start'}
              className={'flex-grow-1'}
            >
              <div className={'px-2 flex-grow-1'}>
                <LabelHelp
                  label={'Dernières'}
                  help={
                    'Affiche les dernières valeurs selon un interval défini'
                  }
                  className={'text-body'}
                />
                {history?.historyType === HistoryWindowType.LAST && (
                  <LastSelector name={'history.timeWindowsMs'} />
                )}
              </div>
            </Flex>
          </Flex>
          <Divider />
          <Flex direction={'row'}>
            <Flex direction={'row'} alignItems={'center'} className={'px-3'}>
              <Input
                register={register('history.historyType')}
                value={HistoryWindowType.FIXED}
                type={'radio'}
              />
            </Flex>
            <Flex
              direction={'row'}
              className={'flex-grow-1'}
              justifyContent={'start'}
              alignContent={'flex-start'}
              alignItems={'start'}
            >
              <div className={'px-2 flex-grow-1'}>
                <div>
                  <LabelHelp
                    label={'Période de temps fixe'}
                    help={
                      'Affiche les dernières valeurs selon un interval défini'
                    }
                    className={'text-body'}
                  />
                </div>

                {history?.historyType === HistoryWindowType.FIXED && (
                  <FixedTimeWindowSelector />
                )}
              </div>
            </Flex>
          </Flex>
          <Divider />
          <Flex direction={'row'}>
            <Flex direction={'row'} alignItems={'center'} className={'px-3'}>
              <Input
                register={register('history.historyType')}
                value={HistoryWindowType.INTERVAL}
                type={'radio'}
              />
            </Flex>
            <Flex
              direction={'row'}
              justifyContent={'start'}
              alignContent={'flex-start'}
              alignItems={'start'}
              className={'flex-grow-1'}
            >
              <div className={'px-2 flex-grow-1'}>
                <LabelHelp
                  label={'Intervale Rapide'}
                  help={'Sélectionnez un interval rapide'}
                  className={'text-body'}
                />
                {history?.historyType === HistoryWindowType.INTERVAL && (
                  <QuickIntervalSelector name={'realtime.quickInterval'} />
                )}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Divider />
      <Flex direction={'row'} justifyContent={'start'}>
        {isEditing && (
          <div>
            <Form.Label
              htmlFor={'hideAggregation'}
              className={'px-2 text-light'}
            >
              Caché
            </Form.Label>
            <Input
              className={'ms-2'}
              type={'switch'}
              register={register('hideAggregation', { shouldUnregister: true })}
            />
          </div>
        )}
        <div className={'px-2 flex-grow-1'}>
          <AggregationSelector />
        </div>
      </Flex>
      <Divider />
      <Flex direction={'row'} justifyContent={'start'}>
        {isEditing && (
          <div>
            <Form.Label
              htmlFor={'hideAggInterval'}
              className={'px-2 text-light'}
            >
              Caché
            </Form.Label>
            <Input
              className={'ms-2'}
              type={'switch'}
              register={register('hideAggInterval', { shouldUnregister: true })}
            />
          </div>
        )}

        <div className={'px-2 flex-grow-1'}>
          <GroupingIntervalSelector />
        </div>
      </Flex>
    </div>
  )
}

export default TimeWindowHistory
