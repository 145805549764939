import React from 'react'
import { Attribute, DataType } from '../../shared/models/attribute'
import Input from '../../components/form/Input'
import { Controller, useFormContext } from 'react-hook-form'
import AceEditor from 'react-ace'

interface Props {
  value?: string | number | boolean | object
  dataType: DataType
}

const AttributeValue = ({ dataType, value }: Props) => {
  const form = useFormContext<Attribute>()
  const renderStringInput = () => {
    return (
      <Input
        register={form.register('value', {
          shouldUnregister: true,
        })}
      />
    )
  }
  const renderNumericInput = () => {
    return (
      <Input
        register={form.register('value', {
          shouldUnregister: true,
          valueAsNumber: true,
        })}
        type={'numeric'}
      />
    )
  }
  const renderBooleanInput = () => {
    return (
      <Input
        register={form.register('value', { shouldUnregister: true })}
        type={'switch'}
      />
    )
  }

  const renderJsonInput = () => {
    return (
      <Controller
        name={'value'}
        control={form.control}
        rules={{
          required: 'Script is required',
          validate: {
            json: (value) => {
              try {
                if (typeof value !== 'string') {
                  return 'Invalid JSON'
                }
                return true
              } catch (e) {
                return 'Invalid JSON'
              }
            },
          },
        }}
        render={({ field }) => {
          return (
            <AceEditor
              width={'100%'}
              height={'300px'}
              placeholder="Write some code"
              value={
                typeof field.value === 'string'
                  ? field.value
                  : JSON.stringify(field.value, null, 2)
              }
              mode="golang"
              theme="monokai"
              fontSize={14}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              onChange={(value) => {
                field.onChange(value)
              }}
            />
          )
        }}
      />
    )
  }
  const renderInput = () => {
    switch (dataType) {
      case DataType.STRING:
        return renderStringInput()
      case DataType.LONG:
      case DataType.DOUBLE:
        return renderNumericInput()
      case DataType.BOOLEAN:
        return renderBooleanInput()
      case DataType.JSON:
        return renderJsonInput()
      default:
        return <>Error</>
    }
  }
  return <>{renderInput()}</>
}

export default AttributeValue
