import React from 'react'
import { BaseNodeProps } from '../../base/types'
import { CreateAlarmConfiguration } from '../../action/alarm/CreateAlarmForm'
import { Handle, Position } from 'reactflow'
import NodeTemplate from '../../base/NodeTemplate'

const GetOriginatorAttributes = React.memo(
  ({ data, isConnectable }: BaseNodeProps<CreateAlarmConfiguration>) => {
    const title = 'Attributs du client'
    return (
      <>
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
        />
        <NodeTemplate icon={'puzzle-piece'} title={title} name={data.name} />
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
      </>
    )
  }
)

export default GetOriginatorAttributes
