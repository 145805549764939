import React from 'react'
import { flexRender, HeaderGroup } from '@tanstack/react-table'
import { TimeSeriesTableType } from './columns'
import classNames from 'classnames'

interface TimeSeriesTableHeaderProps {
  columns: HeaderGroup<TimeSeriesTableType>[]
}

const TimeSeriesTableHeader = ({ columns }: TimeSeriesTableHeaderProps) => {
  return (
    <thead>
      {columns.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id}>
              <div
                {...{
                  className: classNames(
                    'ps-2 text-800 user-select-none',
                    header.column.columnDef.meta?.className,
                    header.column.getCanSort() && 'cursor-pointer'
                  ),
                  onClick: header.column.getToggleSortingHandler(),
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                {(header.column.getCanSort() &&
                  {
                    asc: <span className="sort desc" />,
                    desc: <span className="sort asc" />,
                  }[header.column.getIsSorted() as string]) ?? (
                  <span className="sort" />
                )}
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  )
}

export default TimeSeriesTableHeader
