import { baseArkhaleAPI } from './base-api'
import Device from '../../shared/models/device.model'
import { Pagination } from '../../shared/models/others/pagination'
import { DeviceInfo } from '../../shared/models/device-info'
import { CustomerId } from '../../shared/models/id/customer-id'
import TenantId from '../../shared/models/id/tenant-id'
import { DeviceProfileId } from '../../shared/models/id/device-profile-id'
import { DeviceId } from '../../shared/models/id/device-id'
import { DeviceQuery, formatSortOrder } from './query-type'
import { TimeseriesData } from '../../shared/models/telemetry/telemetry.model'

export interface CreateDeviceCommand {
  name: string
  description: string
  isGateway: boolean
  deviceProfileID: DeviceProfileId
  customerID?: CustomerId
  tenantID: TenantId
}

export interface UpdateDeviceCommand {
  id: DeviceId
  description: string
  isGateway: boolean
  deviceProfileID: DeviceProfileId
  customerID?: CustomerId
}

export interface UpdateDeviceResult extends Device {}

export interface Certificate {
  crt: string
  key: string
}

export interface CreateDeviceResult {
  device: Device
  certificate: Certificate
}

const deviceApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getDevices: build.query<Pagination<Device>, DeviceQuery>({
      query: ({
        offset,
        limit,
        textSearch,
        deviceProfileID,
        customerID,
        tenantID,
        sortOrder,
      }) => ({
        url: 'devices',
        params: {
          offset,
          limit,
          textSearch,
          deviceProfileID: deviceProfileID?.id,
          customerID: customerID?.id,
          tenantID: tenantID?.id,
          ...formatSortOrder(sortOrder),
        },
      }),
    }),
    getDevice: build.query<Device, string>({
      query: (id) => `devices/${id}`,
    }),
    getDeviceInfoByDeviceId: build.query<DeviceInfo, string>({
      query: (id) => `devices/${id}/device-infos`,
    }),
    getDeviceMetricsByDeviceId: build.query<
      TimeseriesData,
      { deviceID: string; startDate: string }
    >({
      query: ({ deviceID, startDate }) => ({
        url: `devices/${deviceID}/device-metrics`,
        params: {
          start_date: startDate,
        },
      }),
    }),
    addDevice: build.mutation<CreateDeviceResult, CreateDeviceCommand>({
      query: (device) => ({
        url: 'devices',
        method: 'POST',
        body: device,
      }),
    }),
    updateDevice: build.mutation<UpdateDeviceResult, UpdateDeviceCommand>({
      query: (device) => ({
        url: `devices/${device.id?.id}`,
        method: 'PUT',
        body: device,
      }),
    }),
    deleteDevice: build.mutation<void, string>({
      query: (id) => ({
        url: `devices/${id}`,
        method: 'DELETE',
      }),
    }),
    enableDevice: build.mutation<DeviceInfo, { id: DeviceId; enable: boolean }>(
      {
        query: ({ id, enable }) => ({
          url: `devices/${id.id}/enable`,
          method: 'PATCH',
          body: {
            enable,
          },
        }),
      }
    ),
    renewDeviceCertificate: build.mutation<
      CreateDeviceResult,
      { id: DeviceId }
    >({
      query: ({ id }) => ({
        url: `devices/${id.id}/renew-certificate`,
        method: 'PATCH',
        body: {
          deviceID: id.id,
        },
      }),
    }),
    changeCustomerDevice: build.mutation<
      CreateDeviceResult,
      { id: DeviceId; customerID?: CustomerId }
    >({
      query: ({ id, customerID }) => ({
        url: `devices/${id.id}/change-customer`,
        method: 'PATCH',
        body: {
          deviceID: id.id,
          customerID: customerID?.id,
        },
      }),
    }),
  }),
})

export const {
  useGetDevicesQuery,
  useLazyGetDevicesQuery,
  useGetDeviceQuery,
  useLazyGetDeviceQuery,
  useGetDeviceInfoByDeviceIdQuery,
  useRenewDeviceCertificateMutation,
  useChangeCustomerDeviceMutation,
  useLazyGetDeviceInfoByDeviceIdQuery,
  useEnableDeviceMutation,
  useGetDeviceMetricsByDeviceIdQuery,
  useLazyGetDeviceMetricsByDeviceIdQuery,
  useAddDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
} = deviceApi
