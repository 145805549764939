import React from 'react'
import CheckFieldExistForm from '../nodes/filter/check-field-exist/CheckFieldExistForm'
import SaveTimeseriesForm from '../nodes/action/timeseries/SaveTimeseriesForm'
import MessageTypeForm from '../nodes/filter/message-type/MessageTypeForm'
import RenameKeysNodeForm from '../nodes/transform/RenameKeysNodeForm'
import EntityTypeForm from '../nodes/filter/entity-type/EntityTypeForm'
import { NodeTypes } from '../../../../shared/models/rule-node'
import ScriptForm from '../nodes/filter/script/ScriptForm'
import CreateAlarmForm from '../nodes/action/alarm/CreateAlarmForm'
import ClearAlarmForm from '../nodes/action/alarm/ClearAlarmForm'
import GetOriginatorAttributesForm from '../nodes/enrichment/origin-attributes/GetOriginatorAttributesForm'
import TransformScriptForm from '../nodes/action/script/TransformScriptForm'
import GetCustomerAttributesForm from '../nodes/enrichment/origin-attributes/GetCustomerAttributesForm'

interface ModalNodeContentProps {
  onSubmit: (data: any) => void
  defaultValues?: any
}

const ModalNodeContent = ({
  onSubmit,
  defaultValues,
}: ModalNodeContentProps) => {
  if (!defaultValues) return <p>'Cannot retrieve the module type'</p>
  switch (defaultValues.type) {
    case NodeTypes.FILTER_CHECK_FIELD_EXIST:
      return (
        <CheckFieldExistForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      )
    case NodeTypes.ACTION_SAVE_TELEMETRY:
      return (
        <SaveTimeseriesForm onSubmit={onSubmit} defaultValues={defaultValues} />
      )
    case NodeTypes.ACTION_CREATE_ALARM:
      return (
        <CreateAlarmForm onSubmit={onSubmit} defaultValues={defaultValues} />
      )
    case NodeTypes.ACTION_CLEAR_ALARM:
      return (
        <ClearAlarmForm onSubmit={onSubmit} defaultValues={defaultValues} />
      )
    case NodeTypes.FILTER_MESSAGE_TYPE:
      return (
        <MessageTypeForm onSubmit={onSubmit} defaultValues={defaultValues} />
      )
    case NodeTypes.TRANSFORM_RENAME_KEYS:
      return (
        <RenameKeysNodeForm onSubmit={onSubmit} defaultValues={defaultValues} />
      )
    case NodeTypes.TRANSFORM_SCRIPT:
      return (
        <TransformScriptForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      )
    case NodeTypes.FILTER_ENTITY_TYPE:
      return (
        <EntityTypeForm onSubmit={onSubmit} defaultValues={defaultValues} />
      )
    case NodeTypes.FILTER_SCRIPT:
      return <ScriptForm onSubmit={onSubmit} defaultValues={defaultValues} />
    case NodeTypes.ENRICHMENT_CUSTOMER_ATTRIBUTES:
      return (
        <GetCustomerAttributesForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      )
    case NodeTypes.ENRICHMENT_ORIGINATOR_ATTRIBUTES:
      return (
        <GetOriginatorAttributesForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      )
    default:
      return <p>'Cannot retrieve the module type'</p>
  }
}

export default ModalNodeContent
