import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import ModalNodeContent from './ModalNodeContent'
import { useReactFlow } from 'reactflow'

interface ModalNodeProps {
  show?: boolean
  onHide?: () => void
  currentNodeEdit: any
  setNodeModalShow: () => void
}

const ModalNode = ({
  onHide,
  show,
  currentNodeEdit,
  setNodeModalShow,
}: ModalNodeProps) => {
  const instance = useReactFlow()
  const updateNodes = (node: any) => {
    instance.setNodes((nodes) => {
      const nodeIndex = nodes.findIndex((n) => n.id === node.id)
      if (nodeIndex !== -1) {
        nodes[nodeIndex] = node
      } else {
        nodes = nodes.concat(node)
      }
      return nodes
    })
    setNodeModalShow()
  }
  return (
    <Modal size={'xl'} show={show} onHide={onHide} centered scrollable>
      <Modal.Header closeButton>
        <h3>Editer le node</h3>
      </Modal.Header>
      <Modal.Body>
        <ModalNodeContent
          onSubmit={updateNodes}
          defaultValues={currentNodeEdit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} onClick={onHide}>
          Cancel
        </Button>
        <Button type={'submit'} form={'form_rule_node'}>
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalNode
