import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from './custom-fetch-base'
import User from '../../shared/models/user'

// Define a service using a base URL and expected endpoints
export const baseArkhaleAPI = createApi({
  reducerPath: 'arkhaleApi',
  baseQuery: customFetchBase,
  tagTypes: [
    'TenantProfile',
    'Tenant',
    'Customer',
    'CustomerByTenant',
    'DeviceProfile',
    'AssetProfile',
    'Asset',
    'AssetByTenant',
    'AssetByCustomer',
    'Device',
    'DeviceByTenant',
    'DeviceByCustomer',
    'User',
    'UserByTenant',
    'UserByCustomer',
    'Role',
    'Dashboard',
    'DashboardByTenant',
    'RuleChain',
    'RuleChainByTenant',
    'Alias',
    'EntityGroups',
    'ApiUsage',
    'Alarm',
    'Attribute',
  ],
  endpoints: (build) => ({
    me: build.query<User, null>({
      query: () => 'me',
    }),
  }),
})

export const { useMeQuery } = baseArkhaleAPI
