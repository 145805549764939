export interface FieldError {
  field: string
  message: string
}

export interface ApiError {
  code: number
  message: string
  fields?: FieldError[]
}

export interface ApiErrorResponse {
  status: number
  data: ApiError
}

export function isApiErrorResponse(error: unknown): error is ApiErrorResponse {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    typeof (error as any).status === 'number'
  )
}
