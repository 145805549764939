import { BaseData } from '../base-data'

export class Pagination<T> {
  rows: T[]
  totalRows: number
  offset: number
  limit: number

  constructor() {
    this.rows = new Array<T>()
    this.totalRows = 0
    this.offset = 0
    this.limit = 0
  }
}
