import React from 'react'
import { Button } from 'react-bootstrap'
import Flex from '../../../components/common/Flex'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { setCurrentAction } from '../../../store/slices/dashboardSlice'

interface Props {
  onClickSubmit?: () => void
}

const PanelFormHeader = ({ onClickSubmit }: Props) => {
  const currentDashboard = useAppSelector((state) => state.dashboard)
  const dispatch = useAppDispatch()
  return (
    <>
      <Flex direction={'row'}>
        <div className={'ms-auto'}>
          <Button
            variant="outline-danger"
            type={'button'}
            onClick={() => dispatch(setCurrentAction({ action: undefined }))}
          >
            Discard
          </Button>
          <Button
            type={'button'}
            variant="outline-primary"
            className={'ms-1'}
            onClick={onClickSubmit}
          >
            Apply
          </Button>
        </div>
      </Flex>
    </>
  )
}

export default PanelFormHeader
