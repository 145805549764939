import React, { useState } from 'react'
import Flex from '../common/Flex'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

export interface KopiliotListPaginationProps {
  totalRecords: number
  pageLimit?: number
  pageNeighbours?: number
  onPageChanged: (page: {
    currentPage: number
    totalPages: number
    pageLimit: number
  }) => void
}

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

export const KopiliotListPagination = ({
  totalRecords,
  pageLimit = 30,
  pageNeighbours = 2,
  onPageChanged,
}: KopiliotListPaginationProps) => {
  const totalPages = Math.ceil(totalRecords / pageLimit)
  const [currentPage, setCurrentPage] = useState(1)
  //
  const goToPage = (page: number) => {
    const currentPage = Math.max(0, Math.min(page, totalPages))
    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: pageLimit,
      totalRecords: totalRecords,
    }
    setCurrentPage(page)
    onPageChanged(paginationData)
  }
  const handleRightClick = () => {
    goToPage(currentPage + pageNeighbours * 2 - 1)
  }
  const handleLeftClick = () => {
    goToPage(currentPage - pageNeighbours * 2 - 1)
  }
  const handleClick = (page: number) => {
    goToPage(page)
  }
  const fetchPageNumber = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2
    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
      let pages: (string | number)[] = _.range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)
      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = _.range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = _.range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }
      return [1, ...pages, totalPages]
    }

    return _.range(1, totalPages + 1)
  }
  const pages = fetchPageNumber()
  return (
    <div className={'mt-3'}>
      <Flex alignItems="center" justifyContent="center">
        <ul className={'pagination mb-0 mx-2'}>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <li key={index} className={'page-item'}>
                  <Button
                    className={'page-link'}
                    onClick={() => handleLeftClick()}
                  >
                    <FontAwesomeIcon icon={'angles-left'} />
                  </Button>
                </li>
              )
            }
            if (page === RIGHT_PAGE) {
              return (
                <li key={index} className={'page-item'}>
                  <Button
                    className={'page-link'}
                    onClick={() => handleRightClick()}
                  >
                    <FontAwesomeIcon icon={'angles-right'} />
                  </Button>
                </li>
              )
            }
            return (
              <li
                key={index}
                className={`page-item ${currentPage == page ? ' active' : ''}`}
              >
                <Button
                  size={'sm'}
                  variant={'falcon-default'}
                  className={classNames('page', 'me-2')}
                  onClick={() =>
                    handleClick(typeof page === 'number' ? page : 1)
                  }
                >
                  {page}
                </Button>
              </li>
            )
          })}
        </ul>
      </Flex>
    </div>
  )
}

export default KopiliotListPagination
