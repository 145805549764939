import React, { useEffect } from 'react'
import {
  Field,
  FieldPath,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form'
import Customer from '../../../shared/models/customer'
import Input from '../../../components/form/Input'
import { Col, Form, Row } from 'react-bootstrap'
import ContactBasedForm from '../../../pages/common/form/ContactBasedForm'
import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../../store/kopiliot-api/tenant'
import TenantId from '../../../shared/models/id/tenant-id'
import GenericSelect from '../../../components/page-components/selects-entities/GenericSelect'

interface CustomerFormProps {
  form: UseFormReturn<Customer>
  isLoading?: boolean
  onSubmit: SubmitHandler<Customer>
  initialValues?: Partial<Customer>
  isEditing?: boolean
  hideFields?: Array<keyof Customer>
}

const CustomerForm = ({
  onSubmit,
  form,
  isLoading,
  isEditing,
  initialValues,
  hideFields = [],
}: CustomerFormProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form
  useEffect(() => {
    reset()
    reset(initialValues)
  }, [initialValues?.id])
  const hideIt = (field: keyof Customer) => {
    return !hideFields.includes(field)
  }
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={6}>
            <Input
              register={register('name', {
                required: 'Name field is required',
              })}
              label={'Nom'}
              error={errors.name}
            />
          </Col>
          <Col md={6}>
            <Input
              register={register('description', {
                required: 'Description field is required',
              })}
              label={'Description'}
              error={errors.name}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={6}>
            {hideIt('tenantId') && (
              <GenericSelect
                register={register('tenantId', {
                  required: 'The tenant is required',
                })}
                isDisabled={!hideIt('tenantId') || isLoading}
                error={errors.tenantId ? errors.tenantId.id : undefined}
                getQuery={useLazyGetTenantQuery}
                listQuery={useLazyGetTenantsQuery}
                label={'Tenant'}
                valueConstructor={(value) => new TenantId(value)}
              />
            )}
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center mt-3'}>
          <Col md={12}>
            <h3>Adresse et contact</h3>
          </Col>
        </Row>
        <ContactBasedForm errors={errors} isLoading />
      </Form>
    </FormProvider>
  )
}

export default CustomerForm
