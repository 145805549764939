import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import ListGroupNav from '../../components/navbar/internal/ListGroupNav'
import { TenantCustomerRouteParam } from '../context/tenant-customer.context'

const UserHome = () => {
  const { tenantID, customerID } = useParams<TenantCustomerRouteParam>()
  return (
    <>
      <div className={'mb-2'}>
        <ListGroupNav />
      </div>
      <Outlet
        context={{
          tenantIDParam: tenantID,
          customerIDParam: customerID,
        }}
      />
    </>
  )
}

export default UserHome
