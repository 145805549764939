import React from 'react'
import { useAppDispatch } from '../../../store/store'
import { Button, Offcanvas } from 'react-bootstrap'
import { setShowAliasList } from '../../../store/slices/dashboardSlice'
import ContentBlock from '../../../components/common/component-card/ContentBlock'
import AdvanceTableHeader from '../../../components/advance-table/AdvanceTableHeader'
import AdvanceTable from '../../../components/advance-table/AdvanceTable'
import { useGenericReducer } from '../../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
} from '../../../hooks/pagination-reducer'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { Alias } from '../../../shared/models/alias.model'
import AliasModal from '../modal/AliasModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { camelCaseToWords } from '../../../utils/utils'
import {
  AliasListQuery,
  useGetAliasesQuery,
  useGetAliasQuery,
  useLazyGetAliasQuery,
} from '../../../store/kopiliot-api/dashboard'
import AdvanceTablePagination from '../../../components/advance-table/AdvanceTablePagination'
import { createEntityQueryReducer } from '../../../hooks/create-entity-pagination-reducer'
import {
  ActionUpdateDashboardID,
  CommonActionType,
} from '../../../hooks/common-actions'
import DeleteAliasModal from '../modal/DeleteAliasModal'
import { AliasID } from '../../../shared/models/id/alias-id'
import { EntityType } from '../../../shared/models/entity-type.models'
import _ from 'lodash'

const getColumnsForAlias = (
  onEditItem: (id: Alias) => void,
  onDeleteItem: (alias: Alias) => void
) => {
  const columnHelper = createColumnHelper<Alias>()
  const columns: ColumnDef<Alias, string>[] = [
    columnHelper.accessor((row) => row.id?.id, {
      id: 'id',
      cell: (info) => info.getValue(),
      header: () => 'Id',
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      cell: (info) => info.getValue(),
      header: () => 'Nom',
    }),
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      cell: (info) => info.getValue(),
      header: () => 'Description',
    }),
    columnHelper.display({
      id: 'filterType',
      header: 'Type de filtre',
      cell: (props) => {
        return (
          <>
            {props.row.original.config && (
              <div
                className={'d-flex align-items-center justify-content-center'}
              >
                <span className={'badge badge-soft-success rounded-pill'}>
                  {camelCaseToWords(props.row.original.config.type)}
                </span>
              </div>
            )}
          </>
        )
      },
    }),
    columnHelper.display({
      id: 'resolveMultiple',
      header: 'Résoudre plusieurs',
      cell: (props) => {
        return (
          <>
            <div className={'d-flex align-items-center justify-content-center'}>
              <span className={'badge badge-soft-success rounded-pill'}>
                {props.row.original.config?.resolveMultiple ? 'Oui' : 'Non'}
              </span>
            </div>
          </>
        )
      },
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        return (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                variant={'outline-primary'}
                className={'rounded-circle mr-3'}
                size={'sm'}
                onClick={() => {
                  if (props.row.original.id) onEditItem(props.row.original)
                }}
              >
                <FontAwesomeIcon icon={['fas', 'edit']} />
              </Button>
              <Button
                variant={'outline-danger'}
                className={'rounded-circle mr-3'}
                size={'sm'}
                onClick={() => {
                  if (props.row.original.id) onDeleteItem(props.row.original)
                }}
              >
                <FontAwesomeIcon icon={['fas', 'trash']} />
              </Button>
            </div>
          </>
        )
      },
    }),
  ]
  return columns
}

interface Props {
  open: boolean
  dashboardID: string
}

const CanvasAliasList = ({ open, dashboardID }: Props) => {
  const [showModalDelete, setShowModalDelete] = React.useState<boolean>(false)
  const reducer = createEntityQueryReducer<
    AliasListQuery,
    PaginationActions | ActionUpdateDashboardID
  >({
    handleSpecificActions: {
      [CommonActionType.UPDATE_DASHBOARD_ID]: (state, action) => {
        return { ...state, dashboardID: action.payload.dashboardID }
      },
    },
  })
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducer,
    AliasListQuery,
    PaginationActions | ActionUpdateDashboardID
  >(reducer, {
    limit: 20,
    offset: 0,
    textSearch: '',
    dashboardID: dashboardID,
  })

  // Get data from the server
  const { data, isFetching, isLoading, error } =
    useGetAliasesQuery(paginationState)
  // Modal Create or Edit
  const [showModalCreateOrEdit, setShowModalCreateOrEdit] =
    React.useState<boolean>(false)
  const [selectedAlias, setSelectedAlias] = React.useState<Alias | undefined>()

  // App dispatcher
  const dispatch = useAppDispatch()
  // Get the dashboard data from the store (slice)
  const onEditItem = (alias: Alias) => {
    setSelectedAlias(_.cloneDeep(alias))
    setShowModalCreateOrEdit(true)
  }
  const onDeleteItem = (alias: Alias) => {
    setShowModalDelete(true)
    setSelectedAlias(alias)
  }
  const columns = getColumnsForAlias(onEditItem, onDeleteItem)

  return (
    <>
      <Offcanvas
        show={open}
        placement={'end'}
        className={'canvas-wide'}
        onHide={() => {
          dispatch(setShowAliasList())
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h4 className={'text-primary'}>Liste des alias</h4>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={''}>
          <ContentBlock>
            <ContentBlock.Header>
              <AdvanceTableHeader
                paginationSettings={paginationState}
                filterFunc={(textSearch) => {
                  dispatchPaginationAction({
                    type: PaginationActionType.PAGE_FILTER,
                    payload: { filter: textSearch },
                  })
                }}
                onPageSizeChange={(pageSize) => {
                  dispatchPaginationAction({
                    type: PaginationActionType.PAGE_SIZE,
                    payload: { pageSize: pageSize },
                  })
                }}
                addEntityButton={{
                  onClick: () => {
                    setSelectedAlias(undefined)
                    setShowModalCreateOrEdit(true)
                  },
                }}
              />
            </ContentBlock.Header>
            <ContentBlock.Body noLight>
              <AdvanceTable
                hiddenColumns={['id']}
                columns={columns}
                data={data?.rows ? data.rows : []}
                tableProps={{
                  size: 'sm',
                  className: 'fs--1 mb-0 overflow-hidden',
                  striped: true,
                }}
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                headerClassName="bg-200 text-900 text-nowrap align-middle"
              />
            </ContentBlock.Body>
            <ContentBlock.Footer>
              <AdvanceTablePagination
                totalRecords={data?.totalRows ? data.totalRows : 0}
                pageLimit={paginationState.limit}
                onPageChanged={(page) => {
                  dispatchPaginationAction({
                    type: PaginationActionType.PAGE_CHANGE,
                    payload: { ...page },
                  })
                }}
              />
            </ContentBlock.Footer>
          </ContentBlock>
        </Offcanvas.Body>
      </Offcanvas>
      <AliasModal
        isOpen={showModalCreateOrEdit}
        setIsOpen={(value) => {
          setShowModalCreateOrEdit(value)
        }}
        alias={selectedAlias}
        dashboardID={dashboardID}
      />
      <DeleteAliasModal
        isOpen={showModalDelete}
        alias={selectedAlias}
        onHidden={() => {
          setShowModalDelete(false)
          setSelectedAlias(undefined)
        }}
      />
    </>
  )
}

export default CanvasAliasList
