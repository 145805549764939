import React, { useEffect } from 'react'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip as ChartJSTooltip,
} from 'chart.js'
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'
import {
  DataSource,
  Panel,
  PanelType,
  SingleValueDataKeySpecificProps,
} from '../../../../shared/models/panel'
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Flex from '../../../../components/common/Flex'
import PanelMenu from '../PanelMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../store/store'
import {
  AggTimeseriesCmd,
  TimeSeriesQuery,
  useGetAggTimeSeriesMutation,
  useLazyGetLatestTimeSeriesQuery,
  useLazyGetTimeSeriesQuery,
} from '../../../../store/kopiliot-api/time-series'
import _ from 'lodash'
import { toast } from 'react-toastify'
import GaugeList from '../chart-helpers/gauge/GaugeList'
import {
  AggregationType,
  getFromTsAndToTs,
} from '../../../../shared/models/time.model'
import {
  AggregatedTimeseriesData,
  LatestTimeseriesData,
  TimeseriesData,
} from '../../../../shared/models/telemetry/telemetry.model'
import TimeSeriesLineChart from '../chart-helpers/line/TimeSeriesLineChart'
import Spinner from '../../../../components/spinner/Spinner'
import SingleValueList from '../chart-helpers/single-value/SingleValueList'
import TimeSeriesTable from '../chart-helpers/table/time-series-table/TimeSeriesTable'
import JsonTable from '../chart-helpers/table/json-table/JsonTable'
import TimeSeriesBarChart from '../chart-helpers/bar/TimeSeriesBarChart'
import TimeSeriesPieChart from '../chart-helpers/pie/TimeSeriesPieChart'
import TimeSeriesPolarChart from '../chart-helpers/polar/TimeSeriesPolarChart'
import dayjs from 'dayjs'
import SimpleIndicatorList from '../chart-helpers/simple-indicator/SimpleIndicatorList'
import { websocketSendQuery } from '../../../../store/slices/websocket'
import { v4 as uuidV4 } from 'uuid'

interface Props {
  panel?: Panel
  isMouseIn?: boolean
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ChartJSTooltip,
  Legend,
  TimeSeriesScale,
  TimeScale,
  RadialLinearScale
)

export const PanelBody = ({ panel, isMouseIn }: Props) => {
  const { isConnected } = useAppSelector((state) => state.websocket)
  const [queryId] = React.useState<string>(uuidV4())
  const { config, aliases = {} } = useSelector(
    (state: RootState) => state.dashboard
  )

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isConnected && queryId) {
      dispatch(
        websocketSendQuery({
          queryID: queryId,
          query: 'your_query_here',
        })
      )
    }
  }, [dispatch, isConnected])

  const [timeSeriesData, setTimeSeriesData] = React.useState<{
    [aliasID: string]: { datasource: DataSource; data: TimeseriesData }
  }>({})
  const [latestData, setLatestData] = React.useState<{
    [aliasID: string]: { datasource: DataSource; data: LatestTimeseriesData }
  }>({})
  const [aggregatedData, setAggregatedData] = React.useState<{
    [aliasID: string]: {
      datasource: DataSource
      data: AggregatedTimeseriesData
    }
  }>({})
  // Getting timeseries data
  const [getTelemetries, { isLoading, isFetching }] =
    useLazyGetTimeSeriesQuery()
  const [
    getLatestTelemetries,
    { isLoading: isLatestLoading, isFetching: isLatestFetching },
  ] = useLazyGetLatestTimeSeriesQuery()
  const [getAggTimeSeries, { isLoading: isAggLoading, data: aggData }] =
    useGetAggTimeSeriesMutation()
  const getDataSources = (): DataSource[] => {
    return panel?.config.dataSources ? panel?.config.dataSources : []
  }

  const buildLatestQuery = (
    dataSource: DataSource,
    from: number,
    to: number
  ) => {
    const { entityAliasID } = dataSource
    if (!entityAliasID) {
      return
    }
    const {
      config: { singleEntity },
    } = aliases[entityAliasID.id]
    if (!singleEntity || !singleEntity.entityType || !singleEntity.id) {
      return
    }
    const queryParam: TimeSeriesQuery = {
      queryID: 1,
      entityType: singleEntity.entityType,
      entityID: singleEntity.id,
      keys: _.map(dataSource.latestDataKeys, (item) => item.key),
      startTs: from,
      endTs: to,
      aggregation: {
        type: AggregationType.NONE,
        limit: 100,
      },
    }
    return queryParam
  }

  const buildAggregateQuery = (
    dataSource: DataSource<SingleValueDataKeySpecificProps>,
    from: number,
    to: number
  ) => {
    const { entityAliasID } = dataSource
    if (!entityAliasID) {
      return
    }
    const {
      config: { singleEntity },
    } = aliases[entityAliasID.id]
    if (!singleEntity || !singleEntity.entityType || !singleEntity.id) {
      return
    }
    const queryParam: AggTimeseriesCmd = {
      entityType: singleEntity.entityType,
      entityID: singleEntity.id,
      aggKeys: _.map(dataSource.dataKeys, (k) => {
        return {
          key: k.key,
          aggType: k.specificProps?.strategy
            ? k.specificProps.strategy
            : AggregationType.NONE,
          previousStartTs: from - (to - from),
          previousEndTs: to,
        }
      }),
      startTs: from,
      // get the time window between from and now in milliseconds
      timeWindow: to - from,
    }

    return queryParam
  }

  React.useEffect(() => {
    // Get the datasources
    const dataSources = getDataSources()
    // Extract time window

    const fetchData = async () => {
      const { from, to } = getFromTsAndToTs(
        panel?.config.useCustomTimeWindow && panel.config.timeWindow
          ? panel.config.timeWindow
          : config.timeWindow
      )
      let queryIDCounter = 0
      await Promise.all(
        _.map(dataSources, async (d) => {
          if (d.entityAliasID) {
            const {
              config: { singleEntity },
            } = aliases[d.entityAliasID.id]
            if (singleEntity && singleEntity.entityType && singleEntity.id) {
              const queryParam: TimeSeriesQuery = {
                queryID: queryIDCounter,
                startTs: from,
                endTs: to,
                entityType: singleEntity.entityType,
                entityID: singleEntity.id,
                keys: _.map(d.dataKeys, (k) => k.key),
                aggregation:
                  panel?.config.useCustomTimeWindow && panel.config.timeWindow
                    ? panel.config.timeWindow.aggregation
                    : config.timeWindow?.aggregation,
              }
              queryParam.keys = [
                ...queryParam.keys,
                ..._.map(d.latestDataKeys, (k) => k.key),
              ]
              if (panel?.type === PanelType.JSON_TABLE) {
                queryParam.aggregation = {
                  type: AggregationType.NONE,
                  limit: 100,
                }
              }
              try {
                if (
                  !isLoading ||
                  !isFetching ||
                  !isLatestLoading ||
                  !isLatestFetching ||
                  !isAggLoading
                ) {
                  if (
                    panel?.type === PanelType.GAUGE ||
                    panel?.type === PanelType.SIMPLE_INDICATOR
                  ) {
                    const result = await getLatestTelemetries(
                      queryParam
                    ).unwrap()
                    setLatestData((prev) => {
                      if (d.entityAliasID) {
                        return {
                          ...prev,
                          [d.entityAliasID.id]: {
                            datasource: d,
                            data: result,
                          },
                        }
                      } else {
                        return prev
                      }
                    })
                  } else if (panel?.type === PanelType.SINGLE_VALUE) {
                    const aggregateQuery = buildAggregateQuery(d, from, to)
                    if (aggregateQuery) {
                      const result = await getAggTimeSeries(
                        aggregateQuery
                      ).unwrap()
                      setAggregatedData((prev) => {
                        if (d.entityAliasID) {
                          return {
                            ...prev,
                            [d.entityAliasID.id]: {
                              datasource: d,
                              data: result,
                            },
                          }
                        } else {
                          return prev
                        }
                      })
                    }
                    const latestQuery = buildLatestQuery(d, from, to)
                    if (latestQuery && latestQuery.keys.length > 0) {
                      const result = await getLatestTelemetries(
                        latestQuery
                      ).unwrap()
                      setLatestData((prev) => {
                        if (d.entityAliasID) {
                          return {
                            ...prev,
                            [d.entityAliasID.id]: {
                              datasource: d,
                              data: result,
                            },
                          }
                        } else {
                          return prev
                        }
                      })
                    }
                  } else {
                    const result = await getTelemetries(queryParam).unwrap()
                    setTimeSeriesData((prev) => {
                      if (!d.entityAliasID) {
                        return prev
                      }
                      return {
                        ...prev,
                        [d.entityAliasID.id]: { datasource: d, data: result },
                      }
                    })
                  }
                }
              } catch (error) {
                console.log('error', error)
              }
              queryIDCounter++
            }
          }
        })
      )
    }
    fetchData().catch((e) => {
      console.log(e)
      toast.error('error')
    })
  }, [panel?.config.dataSources, config.timeWindow, panel?.config.timeWindow]) // deleted panel for not refreshing the data
  const renderPanelBody = () => {
    switch (panel?.type) {
      case PanelType.TIME_SERIES_LINE:
        return (
          <TimeSeriesLineChart
            options={panel.config.settings}
            timeSeriesData={timeSeriesData}
            timeWindow={config.timeWindow}
            aliases={aliases}
          />
        )
      case PanelType.TIME_SERIES_BAR:
        return (
          <TimeSeriesBarChart
            options={panel.config.settings}
            timeSeriesData={timeSeriesData}
            timeWindow={config.timeWindow}
            aliases={aliases}
          />
        )
      case PanelType.TIME_SERIES_PIE:
        return (
          <TimeSeriesPieChart
            options={panel.config.settings}
            timeSeriesData={timeSeriesData}
            timeWindow={config.timeWindow}
            aliases={aliases}
          />
        )
      case PanelType.GAUGE:
        return (
          <GaugeList
            aliases={aliases}
            timeWindow={config.timeWindow}
            latestTimeSeriesData={latestData}
            options={panel.config.settings}
          />
        )
      case PanelType.SINGLE_VALUE:
        return (
          <SingleValueList
            options={panel.config.settings}
            aliases={aliases}
            latestTimeSeriesData={latestData}
            aggregateTimeSeriesData={aggregatedData}
          />
        )
      case PanelType.SIMPLE_INDICATOR:
        return (
          <SimpleIndicatorList
            options={panel.config.settings}
            aliases={aliases}
            latestTimeSeriesData={latestData}
            aggregateTimeSeriesData={aggregatedData}
          />
        )
      case PanelType.TIMESERIES_TABLE:
        return <TimeSeriesTable timeSeriesData={timeSeriesData} />
      case PanelType.JSON_TABLE:
        return <JsonTable timeSeriesData={timeSeriesData} />
      case PanelType.TIME_SERIES_RADAR:
        return (
          <TimeSeriesPolarChart
            options={panel.config.settings}
            timeSeriesData={timeSeriesData}
            timeWindow={config.timeWindow}
            aliases={aliases}
          />
        )
      default:
        return <span>Panel type is not valid</span>
    }
  }
  if (!panel || !panel.config) {
    return null
  }
  return (
    <Card className={'h-100'}>
      <Card.Header className={'p-0 react-grid-dragHandler cursor-pointer'}>
        <Flex direction={'row'} justifyContent={'between'}>
          <div className={'pt-1 ps-2'}>
            {panel.title && (
              <h5 className={'me-1 d-inline text-secondary'}>{panel.title}</h5>
            )}
            {panel.description && (
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: 'fixed' }}
                    id="overlay-trigger-example"
                  >
                    {panel.description}
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={'circle-question'} />
              </OverlayTrigger>
            )}
          </div>
          <div className={''} hidden={!isMouseIn}>
            {isMouseIn}
            {panel.id && <PanelMenu panelID={panel.id} />}
          </div>
        </Flex>
      </Card.Header>
      <Card.Body className={'p-0 h-100 overflow-auto'}>
        {isLoading || isFetching || isLatestLoading ? <Spinner /> : null}
        {renderPanelBody()}
      </Card.Body>
    </Card>
  )
}

export default PanelBody
