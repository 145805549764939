import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ImageProps } from 'react-bootstrap'

export interface BackgroundProps {
  image: string
  overlay?: boolean | string
  className?: string
  position?: string | { x: string; y: string }
  style?: React.CSSProperties
}

const Background: React.FC<BackgroundProps> = ({
  image,
  overlay,
  className,
  position,
  style = {},
}) => {
  const bgStyle: React.CSSProperties = {
    backgroundImage: `url(${image})`,
    ...style,
  }
  if (typeof position === 'string') {
    bgStyle.backgroundPosition = position
  } else if (typeof position === 'object') {
    position.x && (bgStyle.backgroundPositionX = position.x)
    position.y && (bgStyle.backgroundPositionY = position.y)
  }
  return (
    <div
      className={classNames(
        'bg-holder',
        {
          overlay: overlay,
          [`overlay-${overlay}`]: typeof overlay === 'string',
        },
        className
      )}
      style={bgStyle}
    ></div>
  )
}

export default Background
