import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import GenericSelect from '../../../components/page-components/selects-entities/GenericSelect'
import {
  useLazyGetTenantsQuery,
  useLazyGetTenantQuery,
} from '../../../store/kopiliot-api/tenant'
import TenantId from '../../../shared/models/id/tenant-id'
import {
  useLazyGetDeviceProfileQuery,
  useLazyGetDeviceProfilesQuery,
} from '../../../store/kopiliot-api/device-profile'
import { CreateDeviceCommand } from '../../../store/kopiliot-api/device'
import { Controller, useFormContext } from 'react-hook-form'
import { DeviceFormStepProps } from './DeviceForm'
import {
  useDeviceProfileSearch,
  useTenantSearch,
} from '../../../components/page-components/selects-entities/generic-select/hooks'
import SelectBase from '../../../components/page-components/selects-entities/generic-select/SelectBase'
import { SelectableValue } from '../../../components/page-components/selects-entities/generic-select/selectable-value'
import { DeviceProfileId } from '../../../shared/models/id/device-profile-id'

const DeviceFormDetails = ({ disabledField }: DeviceFormStepProps) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<CreateDeviceCommand>()
  const { tenantID, deviceProfileID } = watch()
  const {
    tenantOptions,
    setSearch,
    isLoading: isLoadingTenants,
  } = useTenantSearch(tenantID)
  const {
    deviceProfileOptions,
    setSearch: setSearchDeviceProfile,
    isLoading: isLoadingDeviceProfiles,
  } = useDeviceProfileSearch(deviceProfileID)
  const hideIt = (field: keyof CreateDeviceCommand) => {
    return disabledField.includes(field)
  }
  return (
    <Container fluid>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Col md={8}>
          <Input
            isDisabled={false}
            register={register('name', {
              required: {
                value: true,
                message: 'Le nom du device est obligatoire',
              },
              pattern: {
                value: /^[a-zA-Z0-9-_.]*$/,
                message:
                  "Le nom du device ne doit pas contenir d'espaces et les seuls caractères spéciaux autorisés sont - _ .",
              },
              maxLength: {
                value: 30,
                message: 'Le nom du device ne doit pas dépasser 30 caractères',
              },
            })}
            label={'Nom'}
            error={errors.name}
          />
        </Col>
        <Col md={4}>
          <Input
            type={'switch'}
            label={'Gateway'}
            register={register('isGateway')}
          />
        </Col>
      </Row>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Form.Group as={Col} md={12}>
          <Input
            register={register('description', {
              shouldUnregister: true,
            })}
            label={'Description'}
            type={'textarea'}
          />
        </Form.Group>
      </Row>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Form.Group as={Col} lg={6} md={12}>
          <Form.Label htmlFor={'deviceProfileID'}>Profil de device</Form.Label>
          <Controller
            control={control}
            name={'deviceProfileID'}
            rules={{ required: 'Séléctionnez un profil de device' }}
            render={({ field }) => {
              return (
                <SelectBase
                  isLoading={isLoadingTenants}
                  placeholder={'Rechercher...'}
                  options={deviceProfileOptions}
                  onInputChange={(value) => {
                    setSearchDeviceProfile(value)
                  }}
                  isClearable={true}
                  debounceDelay={1000}
                  minCharsForSearch={2}
                  value={field.value ? { value: field.value } : undefined}
                  onChange={(value: SelectableValue<DeviceProfileId>) => {
                    if (value) {
                      field.onChange(value?.value)
                    }
                  }}
                  valueComparator={(a, b) => {
                    return a?.value?.id === b?.value?.id
                  }}
                  errorMessage={errors.deviceProfileID?.message}
                />
              )
            }}
          />
        </Form.Group>
        {!hideIt('tenantID') && (
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Label htmlFor={'tenantID'}>Tenant</Form.Label>
            <Controller
              control={control}
              name={'tenantID'}
              rules={{ required: 'Please select a tenant' }}
              render={({ field }) => {
                return (
                  <SelectBase
                    isLoading={isLoadingTenants}
                    placeholder={'Rechercher...'}
                    options={tenantOptions}
                    onInputChange={(value) => {
                      setSearch(value)
                    }}
                    isClearable={true}
                    debounceDelay={1000}
                    minCharsForSearch={2}
                    value={field.value ? { value: field.value } : undefined}
                    onChange={(value: SelectableValue<TenantId>) => {
                      if (value) {
                        field.onChange(value?.value)
                      }
                    }}
                    valueComparator={(a, b) => {
                      return a?.value?.id === b?.value?.id
                    }}
                    errorMessage={errors.tenantID?.message}
                  />
                )
              }}
            />
          </Form.Group>
        )}
      </Row>
    </Container>
  )
}

export default DeviceFormDetails
