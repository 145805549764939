import React, { useState } from 'react'
import {
  useEnableDeviceMutation,
  useGetDeviceInfoByDeviceIdQuery,
  useGetDeviceQuery,
} from '../../store/kopiliot-api/device'
import { useParams } from 'react-router-dom'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import { Form, Tab, Tabs } from 'react-bootstrap'
import DeviceInfos from '../../features/device/DeviceInfos'
import DeviceUpdateForm from '../../features/device/form/DeviceUpdateForm'
import { EntityType } from '../../shared/models/entity-type.models'
import RelationListing from '../../features/relation/RelationListing'
import AuditLogHome from '../audit-log/AuditLogHome'
import LatestTelemetries from '../../features/lastest-telemetries/LatestTelemetries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../components/common/Flex'
import { DeviceStatus } from '../../shared/models/device-info'
import AttributesList from '../../features/attributes/AttributesList'
import classNames from 'classnames'

type IdRouteParam = {
  deviceID: string
}

const DeviceEdit = () => {
  let { deviceID } = useParams<IdRouteParam>() as IdRouteParam
  // Manage the current tab (default to 'main')
  const [key, setKey] = useState('main')
  const {
    data,
    isLoading: isLoadingQuery,
    isFetching: isFetchingQuery,
    error,
  } = useGetDeviceQuery(deviceID)
  const {
    data: dataDeviceInfo,
    isLoading: isLoadingDeviceInfo,
    isFetching: isFetchingDeviceInfo,
    error: errorDeviceInfo,
  } = useGetDeviceInfoByDeviceIdQuery(deviceID)
  const [enable, { isLoading: isEnabling }] = useEnableDeviceMutation()
  return (
    <>
      <ContentBlock>
        <ContentBlock.Header>
          <Flex alignContent={'space-between'} alignItems={'center'}>
            <Flex alignItems={'center'}>
              <FontAwesomeIcon
                icon={'microchip'}
                className="fs-0 pe-1 text-primary"
              />
              <h4 className="mb-0 text-center text-md-start me-2 text-primary">
                Device {data?.name}
              </h4>
            </Flex>
            <div className={'ms-auto'}>
              <Form.Check
                type="switch"
                style={{ transform: 'scale(1.5)' }}
                id="active-switch"
              >
                <Form.Check.Input
                  type={'checkbox'}
                  className={classNames({
                    'bg-danger-subtle border-danger':
                      dataDeviceInfo?.status === DeviceStatus.DISABLED,
                    'bg-success-subtle border-success':
                      dataDeviceInfo?.status === DeviceStatus.ENABLED,
                  })}
                  onChange={() => {
                    if (dataDeviceInfo) {
                      enable({
                        id: dataDeviceInfo.id,
                        enable: dataDeviceInfo.status !== DeviceStatus.ENABLED,
                      })
                    }
                  }}
                  checked={
                    dataDeviceInfo
                      ? dataDeviceInfo.status === DeviceStatus.ENABLED
                      : false
                  }
                />
              </Form.Check>
            </div>
          </Flex>
        </ContentBlock.Header>
        <ContentBlock.Body noLight={false} className={'pe-0 ps-0'}>
          <Tabs
            transition
            unmountOnExit
            fill={true}
            activeKey={key}
            onSelect={(k) => setKey(k ? k : 'main')}
          >
            <Tab eventKey="main" title="Main" className="p-3">
              {data?.id && <DeviceUpdateForm device={data} />}
            </Tab>
            <Tab eventKey="device_info" title="Device Infos" className="p-3">
              {data?.id && <DeviceInfos deviceId={data.id.id} />}
            </Tab>
            <Tab
              eventKey="attribute"
              title="Attributes"
              className="p-3 pe-0 ps-0"
            >
              {data?.id && <AttributesList entityID={data.id} />}
            </Tab>
            <Tab
              eventKey="relations"
              title="Relations"
              className="p-3 pe-0 ps-0"
            >
              <RelationListing
                entityID={deviceID}
                entityType={EntityType.DEVICE}
              />
            </Tab>
            <Tab
              eventKey="audit_logs"
              title="Audit Logs"
              className="p-3 pe-0 ps-0"
            >
              {data?.id && <AuditLogHome entityID={data.id} />}
            </Tab>
            <Tab
              eventKey="latest_telemetry"
              title="Dernières données"
              className="p-3 pe-0 ps-0"
            >
              {data?.id && <LatestTelemetries entityID={data.id} />}
            </Tab>
          </Tabs>
        </ContentBlock.Body>
      </ContentBlock>
    </>
  )
}

export default DeviceEdit
