import { Controller, useFormContext } from 'react-hook-form'
import { TimeWindow } from '../../../../shared/models/time.model'
import LabelHelp from '../../../../components/form/LabelHelp'
import Select from 'react-select'
import React from 'react'
import { NameBasedProps } from './types'

const LastSelector = ({ name }: NameBasedProps) => {
  const { control, watch } = useFormContext<TimeWindow>()
  const [options, setOptions] = React.useState<
    { value: number; label: string }[]
  >([
    { value: 1000, label: '1 seconde' },
    { value: 5000, label: '5 secondes' },
    { value: 10000, label: '10 secondes' },
    { value: 15000, label: '15 secondes' },
    { value: 30000, label: '30 secondes' },
    { value: 60000, label: '1 minute' },
    { value: 300000, label: '5 minutes' },
    { value: 600000, label: '10 minutes' },
    { value: 900000, label: '15 minutes' },
    { value: 1800000, label: '30 minutes' },
    { value: 3600000, label: '1 heure' },
    { value: 7200000, label: '2 heures' },
    { value: 10800000, label: '3 heures' },
    { value: 21600000, label: '6 heures' },
    { value: 43200000, label: '12 heures' },
    { value: 86400000, label: '1 jour' },
    { value: 172800000, label: '2 jours' },
    { value: 604800000, label: '1 semaine' },
    { value: 1209600000, label: '2 semaines' },
    { value: 2592000000, label: '30 jours' },
  ])
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Select
              className={'w-100'}
              classNamePrefix={'react-select'}
              value={options.find((o) => o.value === field.value)}
              isSearchable={false}
              options={options}
              onChange={(e) => {
                if (e) {
                  field.onChange(e.value)
                }
              }}
            />
          )
        }}
      />
    </>
  )
}

export default LastSelector
