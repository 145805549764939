import React, { useEffect } from 'react'
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import Tenant from '../../../shared/models/tenant'
import ContactBasedForm from '../../../pages/common/form/ContactBasedForm'
import GenericSelect from '../../../components/page-components/selects-entities/GenericSelect'
import TenantProfileId from '../../../shared/models/id/tenant-profile-id'
import {
  useLazyGetTenantProfileQuery,
  useLazyGetTenantProfilesQuery,
} from '../../../store/kopiliot-api/tenant-profile'
import Input from '../../../components/form/Input'

interface TenantFormProps {
  form: UseFormReturn<Tenant>
  onSubmit: SubmitHandler<Tenant>
  initialValues?: Partial<Tenant>
  isLoading?: boolean
}

const TenantForm = ({
  form,
  onSubmit,
  initialValues,
  isLoading,
}: TenantFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = form

  // Init value if needed
  useEffect(() => {
    reset(initialValues)
  }, [initialValues])
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'12'}>
            <Form.Label htmlFor="name">Name</Form.Label>
            <Form.Control
              className="mb-2"
              disabled={isLoading}
              type="text"
              placeholder="Enter the name"
              isInvalid={!!errors.name}
              {...register('name', { required: 'Name field is required' })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name && errors.name.message}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} md={12}>
            <Input
              register={form.register('description', {
                shouldUnregister: true,
              })}
              label={'Description'}
              type={'textarea'}
            />
          </Form.Group>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <GenericSelect
              register={register('tenantProfileId', {
                required: 'The tenant profile is mandatory',
              })}
              error={errors.tenantProfileId}
              isDisabled={isLoading}
              getQuery={useLazyGetTenantProfileQuery}
              listQuery={useLazyGetTenantProfilesQuery}
              label={'Tenant profile'}
              valueConstructor={(value) => new TenantProfileId(value)}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center mt-3'}>
          <Col md={12}>
            <h3>Adresse et contact</h3>
          </Col>
        </Row>
        <ContactBasedForm errors={errors} />
      </Form>
    </FormProvider>
  )
}

export default TenantForm
