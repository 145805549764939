import React, { useEffect, useState } from 'react'
import { EntityType } from '../../shared/models/entity-type.models'
import {
  useLazyGetEntityGroupsByTypeAndOwnerQuery,
  useLazyGetEntityGroupsByTypeQuery,
} from '../../store/kopiliot-api/entity-groups'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import EntityGroupAddModal from '../../features/entity-group/EntityGroupAddModal'
import TenantId from '../../shared/models/id/tenant-id'
import { CustomerId } from '../../shared/models/id/customer-id'
import useTenantCustomerContext from '../context/tenant-customer.context'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { EntityGroup } from '../../shared/models/entity-group.model'
import {
  defaultEntityGroupActionsRow,
  getColumnsForTableEntityGroup,
} from '../../features/entity-group/columns'
import { EntityGroupQuery } from '../../store/kopiliot-api/query-type'
import { useNavigate } from 'react-router-dom'

interface Props {
  entityType: EntityType
}

type PaginationActionWithEntityType =
  | PaginationActions
  | {
      type: 'CHANGE_ENTITY_TYPE'
      payload: { entityType: EntityType; ownerID?: TenantId | CustomerId }
    }

const reducerWithEntityType = (
  state: EntityGroupQuery,
  action: PaginationActionWithEntityType
): EntityGroupQuery => {
  if (
    action.type === PaginationActionType.PAGE_FILTER ||
    action.type === PaginationActionType.PAGE_CHANGE ||
    action.type === PaginationActionType.PAGE_SIZE ||
    action.type === PaginationActionType.PAGE_SORT
  ) {
    return { ...reducerPage(state, action), entityType: state.entityType }
  } else {
    return { ...state, entityType: action.payload.entityType }
  }
}

const EntityGroupList = ({ entityType }: Props) => {
  const navigate = useNavigate()
  const { tenantIDParam, customerIDParam } = useTenantCustomerContext()
  const [ownerID, setOwnerID] = React.useState<
    TenantId | CustomerId | undefined
  >(undefined)
  React.useEffect(() => {
    if (customerIDParam) {
      setOwnerID(new CustomerId(customerIDParam))
    } else if (tenantIDParam) {
      setOwnerID(new TenantId(tenantIDParam))
    } else {
      setOwnerID(undefined)
    }
  }, [tenantIDParam, customerIDParam])
  // Modal
  const [showModalCreate, setShowModalCreate] = React.useState(false)
  // Pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerWithEntityType,
    EntityGroupQuery,
    PaginationActionWithEntityType
  >(reducerWithEntityType, {
    limit: 20,
    offset: 0,
    textSearch: '',
    entityType: entityType,
  })
  useEffect(() => {
    dispatchPaginationAction({
      type: 'CHANGE_ENTITY_TYPE',
      payload: { entityType, ownerID },
    })
  }, [entityType])
  React.useEffect(() => {
    fetchEntityGroups()
  }, [paginationState])
  const [getEntityGroupByType, resultEntityGroupByType] =
    useLazyGetEntityGroupsByTypeQuery()
  const [getEntityGroupByTypeAndOwner, resultEntityGroupByTypeAndOwner] =
    useLazyGetEntityGroupsByTypeAndOwnerQuery()

  // Utility function
  const fetchEntityGroups = () => {
    if (
      ownerID &&
      (ownerID.entityType === EntityType.CUSTOMER ||
        ownerID.entityType === EntityType.TENANT)
    ) {
      getEntityGroupByTypeAndOwner({
        ...paginationState,
        textSearch: paginationState.textSearch,
        ownerID: ownerID.id,
        ownerType: ownerID.entityType,
        entityType: entityType,
      })
    } else {
      getEntityGroupByType({
        ...paginationState,
        textSearch: paginationState.textSearch,
        entityType: entityType,
      })
    }
  }
  const getData = () => {
    if (ownerID) {
      return resultEntityGroupByTypeAndOwner.data
    } else {
      return resultEntityGroupByType.data
    }
  }
  const isLoadingData =
    resultEntityGroupByType.isLoading ||
    resultEntityGroupByTypeAndOwner.isLoading
  const onDelete = (id: string) => {
    alert(id)
  }
  const onEdit = (id: string) => {
    alert(id)
  }
  const actionsRow = defaultEntityGroupActionsRow(onDelete, onEdit)
  return (
    <>
      <KopiliotList
        title="Liste des groupes d'entités"
        icon={'object-ungroup'}
        isLoading={isLoadingData}
        actionsProps={{
          onAdd: () => {
            setShowModalCreate(true)
          },
          onRefresh: () => {
            fetchEntityGroups()
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableEntityGroup(actionsRow),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: EntityGroup) => item.id!.id,
          titleAccessor: (item: EntityGroup) => item.name,
          descriptionAccessor: (item: EntityGroup) => item.description,
          linkAccessor: (item: EntityGroup) => `${item.id!.id}`,
          actionsRow: actionsRow,
        }}
      />
      <EntityGroupAddModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        defaultValues={{
          ownerID: ownerID,
          entityType: paginationState.entityType,
        }}
      />
    </>
  )
}

export default EntityGroupList
