import React from 'react'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import { useLazyGetDashboardsQuery } from '../../store/kopiliot-api/dashboard'
import AddDashboardModal from '../../features/dahsboards/modal/AddDashboardModal'
import useTenantContext from '../context/tenant.context'
import {
  DashboardQuery,
  SortOrderDirection,
} from '../../store/kopiliot-api/query-type'
import { useForm } from 'react-hook-form'
import { EntityType } from '../../shared/models/entity-type.models'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import {
  defaultDashboardActionsRow,
  getColumnsForTableDashboard,
} from '../../features/dahsboards/utils'
import { Dashboard } from '../../shared/models/dashboard.model'
import DashboardSearchForm from '../../features/dahsboards/form/DashboardSearchForm'
import { RootState, useAppSelector } from '../../store/store'
import { Authority } from '../../shared/models/user'
import DeleteDashboardModal from '../../features/dahsboards/modal/DeleteDashboardModal'
import { DashboardId } from '../../shared/models/id/dashboard-id'
import { useNavigate } from 'react-router-dom'

interface DashboardListProps {
  linkTo?: string
}

const DashboardList = ({ linkTo }: DashboardListProps) => {
  const navigate = useNavigate()
  // Get the current user
  const user = useAppSelector((state: RootState) => state.currentUser)
  // Get the context of the current tenant and customer
  const { tenantIDParam } = useTenantContext()
  // Modal Delete
  const [showModalDelete, setShowModalDelete] = React.useState(false)
  const [selectedDashboard, setSelectedDashboard] =
    React.useState<DashboardId>()
  // Pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    DashboardQuery,
    PaginationActions
  >(reducerPage, {
    limit: 20,
    offset: 0,
    textSearch: '',
    tenantID: undefined,
  })

  const searchForm = useForm<DashboardQuery>()
  const {
    watch,
    formState: { errors },
  } = searchForm
  const { textSearch, tenantID } = watch()
  React.useEffect(() => {
    if (tenantIDParam) {
      dispatchPaginationAction({
        type: PaginationActionType.PAGE_FILTER,
        payload: { filter: textSearch },
      })
    } else {
      dispatchPaginationAction({
        type: PaginationActionType.PAGE_FILTER,
        payload: { filter: textSearch },
      })
    }
  }, [tenantIDParam, tenantID, textSearch])
  React.useEffect(() => {
    fetchDashboards()
  }, [paginationState])
  const [getDashboards, resultGetDashboards] = useLazyGetDashboardsQuery()
  //const [getDashboardsByTenants, resultGetDashboardsByTenants] = useLazyGetTenantDashboards()
  // Modal Create
  const [showModalCreate, setShowModalCreate] = React.useState(false)

  // utility function
  const fetchDashboards = () => {
    if (tenantIDParam) {
      getDashboards({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch: paginationState.textSearch,
        sortOrder: paginationState.sortOrder,
        tenantID: { id: tenantIDParam, entityType: EntityType.TENANT },
      })
    } else {
      getDashboards({
        limit: paginationState.limit,
        offset: paginationState.offset,
        textSearch: paginationState.textSearch,
        sortOrder: paginationState.sortOrder,
        tenantID: tenantID,
      })
    }
  }
  const isLoadingData = resultGetDashboards.isLoading
  const getDisabledField = (): Array<keyof DashboardQuery> => {
    return tenantIDParam ? ['tenantID'] : []
  }
  const getData = () => {
    if (tenantIDParam) {
      return resultGetDashboards.data
    } else {
      return resultGetDashboards.data
    }
  }
  const onDeleteItem = (id: string) => {
    setShowModalDelete(true)
    setSelectedDashboard({ id: id, entityType: EntityType.DASHBOARD })
  }
  const onEditItem = (id: string) => {
    setShowModalCreate(true)
  }

  const actionsRow = defaultDashboardActionsRow(onDeleteItem, onEditItem)
  const getHiddenColumns = (): Array<keyof Dashboard> => {
    if (user.authority === Authority.TENANT_ADMIN || tenantIDParam) {
      return ['tenantID']
    } else if (user.authority === Authority.CUSTOMER_USER) {
      return ['tenantID']
    } else {
      return []
    }
  }
  return (
    <>
      <KopiliotList
        title={'Dashboard'}
        icon={'chart-line'}
        isLoading={isLoadingData}
        actionsProps={{
          filterForm: (
            <DashboardSearchForm
              form={searchForm}
              onSubmit={() => {}}
              disabledFields={getDisabledField()}
            />
          ),
          onRefresh: () => {
            fetchDashboards()
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id', ...getHiddenColumns()],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableDashboard(actionsRow),
          sortOrder: paginationState.sortOrder,
          setSortOrder: (sorting) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SORT,
              payload: { sort: sorting },
            })
          },
          onClickRow: (row) => {
            navigate(`${linkTo ? linkTo : ''}${row.original.id?.id}`)
          },
        }}
        cardProps={{
          idAccessor: (item: Dashboard) => item.id!.id,
          titleAccessor: (item: Dashboard) => item.name,
          descriptionAccessor: (item: Dashboard) => item.description,
          linkAccessor: (item: Dashboard) =>
            `${linkTo ? linkTo : ''}${item.id!.id}`,
          actionsRow: actionsRow,
        }}
      />
      <AddDashboardModal
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
      />
      <DeleteDashboardModal
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        dashboardID={selectedDashboard}
      />
    </>
  )
}

export default DashboardList
