import { Controller, useFormContext } from 'react-hook-form'
import {
  AggregationType,
  TimeWindow,
} from '../../../../shared/models/time.model'
import LabelHelp from '../../../../components/form/LabelHelp'
import Select from 'react-select'
import React from 'react'

const AggregationSelector = () => {
  const { control, watch } = useFormContext<TimeWindow>()
  const { realtime } = watch()
  const [options, setOptions] = React.useState<
    { value: AggregationType; label: string }[]
  >([
    { value: AggregationType.MIN, label: 'Min' },
    { value: AggregationType.MAX, label: 'Max' },
    { value: AggregationType.AVG, label: 'Moyenne' },
    { value: AggregationType.SUM, label: 'Somme' },
    { value: AggregationType.COUNT, label: 'Compte' },
    { value: AggregationType.NONE, label: 'Aucun' },
  ])
  return (
    <>
      <LabelHelp
        label={'Aggregation'}
        help={'help text'}
        className={'text-body'}
      />
      <Controller
        name={'aggregation.type'}
        control={control}
        render={({ field }) => {
          return (
            <Select
              className={'w-100'}
              classNamePrefix={'react-select'}
              value={options.find((o) => o.value === field.value)}
              options={options}
              onChange={(e) => {
                if (e) {
                  field.onChange(e.value)
                }
              }}
            />
          )
        }}
      />
    </>
  )
}

export default AggregationSelector
