import React, { useState } from 'react'
import TenantProfileId from '../../../shared/models/id/tenant-profile-id'
import {
  Controller,
  FieldError,
  useFormContext,
  UseFormRegisterReturn,
} from 'react-hook-form'
import {
  useGetTenantProfileQuery,
  useGetTenantProfilesQuery,
} from '../../../store/kopiliot-api/tenant-profile'
import _ from 'lodash'
import Select from 'react-select'
import { Form } from 'react-bootstrap'

interface TenantProfileSelectProps {
  register: UseFormRegisterReturn<any>
  error?: FieldError
}

const TenantProfileSelect = ({ register, error }: TenantProfileSelectProps) => {
  const form = useFormContext()
  const [searchTerm, setSearchTerm] = useState('')
  const {
    data: tenantProfiles,
    isFetching: isFetchingTenantProfiles,
    isLoading: isLoadingTenantProfiles,
    error: errorTenantProfiles,
  } = useGetTenantProfilesQuery(
    {
      textSearch: searchTerm,
      offset: 0,
      limit: 40,
    },
    { skip: !searchTerm || searchTerm.length < 3 }
  )

  const {
    data: tenantProfile,
    isFetching: isFetchingTenantProfile,
    isLoading: isLoadingTenantProfile,
    error: errorTenantProfile,
  } = useGetTenantProfileQuery(
    form.getValues(register.name)?.id ? form.getValues(register.name).id : '',
    {
      skip: form.getValues(register.name) === undefined,
    }
  )

  const getOptions = () => {
    const res = _.chain(tenantProfiles?.rows)
      .map((tenantProfile) => {
        if (tenantProfile.id) {
          return { value: tenantProfile.id.id, label: tenantProfile.name }
        }
      })
      .compact()
      .value()
    if (tenantProfile?.id && !_.find(res, { value: tenantProfile.id.id })) {
      res.unshift({ value: tenantProfile.id.id, label: tenantProfile.name })
    }
    return res
  }

  const isLoading =
    isLoadingTenantProfiles ||
    isFetchingTenantProfiles ||
    isLoadingTenantProfile ||
    isFetchingTenantProfile

  return (
    <>
      <Controller
        name={'tenantProfileId'}
        render={({
          field: { onChange, onBlur, value, ref },
          formState,
          fieldState,
        }) => {
          return (
            <>
              <Select
                onChange={(value) => {
                  if (value) {
                    onChange(new TenantProfileId(value.value))
                  } else {
                    onChange(undefined)
                  }
                }}
                isClearable={true}
                isLoading={isLoading}
                //isDisabled={isLoading}
                options={getOptions()}
                {..._.omit(register, ['onChange'])}
                onInputChange={(value) => setSearchTerm(value)}
                value={
                  tenantProfile?.id
                    ? { value: tenantProfile.id.id, label: tenantProfile.name }
                    : undefined
                }
              />
              <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </>
          )
        }}
      />
    </>
  )
}

export default TenantProfileSelect
