import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { Outlet } from 'react-router-dom'
import Error401 from '../errors/Error401'

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { authority } = useSelector((state: RootState) => state.currentUser)
  return allowedRoles.includes(authority) ? <Outlet /> : <Error401 />
}

export default RequireUser
