import { baseArkhaleAPI } from './base-api'
import { Pagination } from '../../shared/models/others/pagination'
import { Relation } from '../../shared/models/entity-relation'
import { EntityType } from '../../shared/models/entity-type.models'
import { PaginationWithSearchTerm } from './query-type'

export interface ToIDQuery extends PaginationWithSearchTerm {
  direction: 'from' | 'to'
  entityID: string
  entityType: EntityType
}

const entityRelationApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getToByEntityID: build.query<Pagination<Relation>, ToIDQuery>({
      query: ({
        direction,
        entityID,
        entityType,
        limit,
        textSearch,
        offset,
      }) => ({
        url: `relations/${direction}`,
        params:
          direction === 'from'
            ? {
                fromID: entityID,
                fromType: entityType,
                offset,
                limit,
                textSearch,
              }
            : { toID: entityID, toType: entityType, offset, limit, textSearch },
      }),
    }),
    addRelation: build.mutation<Relation, Relation>({
      query: (relation) => ({
        url: 'relations',
        method: 'POST',
        body: relation,
      }),
    }),
  }),
})

export const {
  useGetToByEntityIDQuery,
  useLazyGetToByEntityIDQuery,
  useAddRelationMutation,
} = entityRelationApi
