import React from 'react'
import { useLocation } from 'react-router'
import { Outlet, useNavigate } from 'react-router-dom'
import NotificationMenu from './NotificationMenu'

const NotificationHome = () => {
  const location = useLocation()
  const navigate = useNavigate()
  React.useEffect(() => {
    const arrayLocation = location.pathname
      .split('/')
      .filter((item) => item != '')
    if (arrayLocation.slice(-1)[0] == 'notification-center') {
      navigate('inbox')
    }
  }, [location])
  return (
    <>
      <NotificationMenu />
      <Outlet />
    </>
  )
}

export default NotificationHome
