/*##################################
########### ContentBlockHeader ########
##################################*/
import React, { PropsWithChildren } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import Flex from '../Flex'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KopiliotListRowPerPage from '../../kopiliot-list/KopiliotListRowPerPage'

interface ContentBlockHeaderProps extends PropsWithChildren {
  light?: boolean
  className?: string
  title?: string
  icon?: IconProp
}
const ContentBlockHeader = ({
  light,
  className,
  title,
  children,
  icon,
}: ContentBlockHeaderProps) => {
  const renderTitle = () => {
    return (
      <div className="d-flex align-items-center">
        {icon && (
          <FontAwesomeIcon icon={icon} className="fs-0 pe-1 text-primary" />
        )}
        <h4 className="mb-0 text-center text-md-start me-2 text-primary">
          {title}
        </h4>
      </div>
    )
  }
  return (
    <Card.Header className={classNames({ 'bg-light': light }, className)}>
      <Flex>
        <div>{renderTitle()}</div>
        <div className={'flex-grow-1'}>{children}</div>
      </Flex>
    </Card.Header>
  )
}

export default ContentBlockHeader
