import React from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import Asset from '../../../../shared/models/asset'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../components/form/Input'
import GenericSelect from '../../../../components/page-components/selects-entities/GenericSelect'
import {
  useLazyGetAssetProfileQuery,
  useLazyGetAssetProfilesQuery,
} from '../../../../store/kopiliot-api/asset-profile'
import { AssetProfileId } from '../../../../shared/models/id/asset-profile-id'
import SelectBase from '../../../../components/page-components/selects-entities/generic-select/SelectBase'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
  useCustomerSearch,
  useTenantSearch,
} from '../../../../components/page-components/selects-entities/generic-select/hooks'
import { BaseFormProps } from '../../../utils/common'
import { Select } from '../../../../components/page-components/selects-entities/generic-select/Select'
import { SelectableValue } from '../../../../components/page-components/selects-entities/generic-select/selectable-value'
import { CustomerId } from '../../../../shared/models/id/customer-id'
import TenantId from '../../../../shared/models/id/tenant-id'

const AssetForm = ({
  form,
  isLoading,
  isEditing,
  onSubmit,
  hideFields = [],
}: BaseFormProps<Asset>) => {
  const user = useSelector((state: RootState) => state.currentUser)
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = form
  const watchTenantID = form.watch('tenantID')
  const {
    tenantOptions,
    setSearch,
    isLoading: isLoadingTenants,
  } = useTenantSearch(watchTenantID)
  const watchCustomerID = form.watch('customerID')
  const {
    customerOptions,
    setSearch: setCustomerSearch,
    isLoading: isLoadingCustomers,
  } = useCustomerSearch(watchCustomerID)

  const hideIt = (field: keyof Asset) => {
    return !hideFields.includes(field)
  }
  const isLoadingForm = isLoading || isEditing
  return (
    <>
      <FormProvider {...form}>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Col md={6}>
              <Input
                register={register('name', {
                  required: 'Name field is required',
                })}
                isDisabled={isLoadingForm}
                label={'Nom'}
                error={errors.name}
              />
            </Col>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group as={Col} md={12}>
              <Input
                register={register('description', {
                  required: 'Description field is required',
                })}
                type={'textarea'}
                textAreaRows={3}
                isDisabled={isLoadingForm}
                label={'Description'}
                error={errors.description}
              />
            </Form.Group>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Col md={6}>
              <GenericSelect
                register={register('assetProfileID', {
                  required: 'The asset profile is required',
                })}
                isDisabled={isLoadingForm}
                error={
                  errors.assetProfileID ? errors.assetProfileID.id : undefined
                }
                getQuery={useLazyGetAssetProfileQuery}
                listQuery={useLazyGetAssetProfilesQuery}
                label={'Profile'}
                valueConstructor={(value) => new AssetProfileId(value)}
              />
            </Col>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            {hideIt('customerID') && (
              <Form.Group as={Col} lg={6} md={12}>
                <Form.Label htmlFor={'customerID'}>Customer</Form.Label>
                <Controller
                  control={control}
                  name={'customerID'}
                  render={({ field }) => {
                    return (
                      <Select
                        isLoading={isLoadingCustomers}
                        placeholder={'Rechercher...'}
                        options={customerOptions}
                        onInputChange={(value) => {
                          setCustomerSearch(value)
                        }}
                        isClearable={true}
                        debounceDelay={1000}
                        minCharsForSearch={2}
                        value={field.value ? { value: field.value } : undefined}
                        onChange={(value: SelectableValue<CustomerId>) => {
                          if (value) {
                            field.onChange(value?.value)
                          }
                        }}
                        valueComparator={(a, b) => {
                          return a?.value?.id === b?.value?.id
                        }}
                        errorMessage={errors.customerID?.message}
                      />
                    )
                  }}
                />
              </Form.Group>
            )}
            {hideIt('tenantID') && (
              <Form.Group as={Col} lg={6} md={12}>
                <Form.Label htmlFor={'tenantID'}>Tenant</Form.Label>
                <Controller
                  control={control}
                  name={'tenantID'}
                  rules={{ required: 'Please select a tenant' }}
                  render={({ field }) => {
                    return (
                      <SelectBase
                        isLoading={isLoadingTenants}
                        placeholder={'Rechercher...'}
                        options={tenantOptions}
                        onInputChange={(value) => {
                          setSearch(value)
                        }}
                        isClearable={true}
                        debounceDelay={1000}
                        minCharsForSearch={2}
                        value={field.value ? { value: field.value } : undefined}
                        onChange={(value: SelectableValue<TenantId>) => {
                          if (value) {
                            field.onChange(value?.value)
                          }
                        }}
                        valueComparator={(a, b) => {
                          return a?.value?.id === b?.value?.id
                        }}
                        errorMessage={errors.tenantID?.message}
                      />
                    )
                  }}
                />
              </Form.Group>
            )}
          </Row>
        </Form>
      </FormProvider>
    </>
  )
}

export default AssetForm
