import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import TenantProfile from '../../shared/models/tenant-profile'
import TenantProfileForm from './TenantProfileForm'
import ButtonLoading from '../../components/button/ButtonLoading'

interface AddTenantProfileModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  onSubmit: SubmitHandler<TenantProfile>
  form: UseFormReturn<TenantProfile>
  isLoading?: boolean
}

const AddTenantProfileModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  form,
  isLoading,
}: AddTenantProfileModalProps) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'lg'}
      centered
      scrollable
      contentClassName="border"
    >
      <Modal.Header
        closeButton
        //closeVariant={isDark ? 'white' : undefined}
        className="px-x1 border-bottom-0"
      >
        <Modal.Title as="h5">Créer un tenant profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-x1">
        <TenantProfileForm
          onSubmit={onSubmit}
          form={form}
          isLoading={isLoading}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} variant={'secondary'} onClick={handleClose}>
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddTenantProfileModal
