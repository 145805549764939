import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Nav } from 'react-bootstrap'
import ProfileDropdown from './ProfileDropdown'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../store/store'
import { switchTheme } from '../../../store/slices/settings'
import KopiliotNotificationCenter from './KopiliotNotificationCenter'

const TopNavRightSideNavItem = () => {
  const { theme } = useSelector((state: RootState) => state.settings)

  const dispatch = useAppDispatch()
  const handleClickOnThemeButton = () => {
    dispatch(switchTheme())
  }
  const handleExpandFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then((r) => console.log(r))
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then((r) => console.log(r))
      }
    }
  }
  const handleTestNotification = () => {
    //trigger({ message: 'test', data: 'test' })
  }
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={handleTestNotification}
        >
          <div className="theme-control-toggle-label">
            <FontAwesomeIcon icon={'arrows'} className="fs-0" />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={handleExpandFullScreen}
        >
          <div className="theme-control-toggle-label">
            <FontAwesomeIcon
              icon={document.fullscreenElement == null ? 'expand' : 'compress'}
              className="fs-0"
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={handleClickOnThemeButton}
        >
          <div className="theme-control-toggle-label">
            <FontAwesomeIcon
              icon={theme === 'dark' ? 'moon' : 'sun'}
              className="fs-0"
            />
          </div>
        </Nav.Link>
      </Nav.Item>
      <KopiliotNotificationCenter />
      <ProfileDropdown />
    </Nav>
  )
}

export default TopNavRightSideNavItem
