import React from 'react'
import { useParams } from 'react-router-dom'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import DashboardView from '../../features/dahsboards/DashboardView'

type IdRouteParam = {
  dashboardID: string
}

const DashboardViewMain = () => {
  // Get the id from the route
  let { dashboardID } = useParams<IdRouteParam>() as IdRouteParam
  return (
    <>
      <DashboardView dashboardID={dashboardID} />
    </>
  )
}

export default DashboardViewMain
