import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Panel } from '../../../../shared/models/panel'
import Input from '../../../../components/form/Input'

const LineForm = () => {
  const { register, watch, setValue } = useFormContext<Panel>()

  return (
    <>
      <Input
        register={register('config.settings.elements.line.borderJoinStyle', {
          shouldUnregister: true,
        })}
        type={'select'}
        label={'Border join style'}
        options={[
          { value: 'round', text: 'Round' },
          { value: 'bevel', text: 'Bevel' },
          { value: 'miter', text: 'Miter' },
        ]}
      />
      <Input
        register={register('config.settings.elements.line.borderWidth', {
          shouldUnregister: true,
        })}
        type={'numeric'}
        label={'Border width'}
      />
    </>
  )
}

export default LineForm
