import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { isApiErrorResponse } from '../kopiliot-api/errors'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLoggerMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      toast.error(action.payload.data?.message)
      if (
        isApiErrorResponse(action.payload) &&
        action.payload.data &&
        action.payload.data.fields
      ) {
        for (const field of action.payload.data.fields) {
          toast.error(`${field.field}: ${field.message}`)
        }
      }
    }

    return next(action)
  }
