import { EntityId } from './id/entity-id'
import { EntityType } from './entity-type.models'

export enum RelationType {
  EDGE = 'EDGE',
  CONTAIN = 'CONTAIN',
  MANAGE = 'MANAGE',
}

export enum RelationTypeGroup {
  COMMON = 'COMMON',
}

export const EntityTypeAllowedRelation = [
  EntityType.ASSET,
  EntityType.TENANT,
  EntityType.CUSTOMER,
  EntityType.DEVICE,
  EntityType.USER,
  EntityType.DASHBOARD,
]

export interface Relation {
  from: EntityId
  to: EntityId
  relationType: RelationType
  relationTypeGroup: RelationTypeGroup
}
