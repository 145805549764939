import React from 'react'
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Offcanvas,
  Row,
} from 'react-bootstrap'
import './canvasAlarmDetails.css'
import { Alarm, AlarmStatus } from '../../shared/models/alarm.model'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import SubtleBadge from '../../components/common/SubtleBadge'
import {
  getColorForAcknowledge,
  getColorForActive,
  getColorForAlarmSeverity,
} from './tools'
import AssignUserToAlarm from './AssignUserToAlarm'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import Flex from '../../components/common/Flex'
import CanvasAlarmHeader from './CanvasAlarmHeader'
import { AlarmID } from '../../shared/models/id/alarm-id'
import { useLazyGetAlarmQuery } from '../../store/kopiliot-api/alarm'

dayjs.extend(duration)
dayjs.extend(relativeTime)

interface Props {
  open: boolean
  onHide: () => void
  alarmID?: AlarmID
}

const CanvasAlarmDetails = ({ open, onHide, alarmID }: Props) => {
  const [getAlarm, { data, isLoading, error }] = useLazyGetAlarmQuery()
  React.useEffect(() => {
    if (alarmID?.id) {
      getAlarm(alarmID)
    }
  }, [alarmID?.id])
  const getDuration = (startTs: number, endTs: number): string => {
    const diff = endTs
      ? dayjs(endTs).diff(dayjs(startTs), 'second')
      : dayjs().diff(dayjs(startTs), 'second')
    let format = 's [secondes]'
    if (diff > 60) {
      format = `m [minutes] ${format}`
    }
    if (diff > 3600) {
      format = `H [heures] M [minutes]`
    }
    if (diff > 86400) {
      format = `D [jours] H [heures]`
    }
    if (diff > 2592000) {
      format = `M [mois] D [jours]`
    }
    if (diff > 31536000) {
      format = `Y [années] M [mois]`
    }
    return dayjs.duration(diff, 'seconds').format(format)
  }
  const getIsView = (alarm: Alarm) => {
    switch (alarm.status) {
      case AlarmStatus.ACTIVE_UNACK:
      case AlarmStatus.CLEARED_UNACK:
        return false
      case AlarmStatus.ACTIVE_ACK:
      case AlarmStatus.CLEARED_ACK:
        return true
      default:
        return false
    }
  }
  const getIsActive = (alarm: Alarm) => {
    switch (alarm.status) {
      case AlarmStatus.ACTIVE_UNACK:
      case AlarmStatus.ACTIVE_ACK:
        return true
      case AlarmStatus.CLEARED_UNACK:
      case AlarmStatus.CLEARED_ACK:
        return false
      default:
        return false
    }
  }
  return (
    <Offcanvas
      show={open}
      placement={'end'}
      className={'canvas-alarm-details'}
      onHide={onHide}
    >
      <CanvasAlarmHeader alarm={data} />
      <Offcanvas.Body className={''}>
        {data && data.id && (
          <>
            <Container fluid className={'fs-8'}>
              <Row>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Type: </h5>
                      {data.type}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Sévérité: </h5>
                      <SubtleBadge
                        bg={getColorForAlarmSeverity(data.severity)}
                        className={'fs-9'}
                      >
                        {data.severity}
                      </SubtleBadge>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Début: </h5>
                      {dayjs(data.startTs).format('DD/MM/YYYY HH:mm:ss')}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Durée: </h5>
                      {getDuration(data.startTs, data.endTs)}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Pris en compte: </h5>
                      <SubtleBadge
                        bg={getColorForAcknowledge(data.status)}
                        className={'fs-9'}
                      >
                        {getIsView(data) ? 'Oui' : 'Non'}
                      </SubtleBadge>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Prise en compte à : </h5>
                      {data.ackTs != 0 &&
                        dayjs(data.ackTs).format('DD/MM/YYYY HH:mm:ss')}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Active : </h5>
                      <SubtleBadge
                        bg={getColorForActive(data.status)}
                        className={'fs-9'}
                      >
                        {getIsActive(data) ? 'Oui' : 'Non'}
                      </SubtleBadge>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <h5>Retour à la normale à : </h5>
                      {data.clearTs != 0 &&
                        dayjs(data.clearTs).format('DD/MM/YYYY HH:mm:ss')}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className={'mb-2'}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5>Détails</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <AceEditor
                        width={'100%'}
                        height={'300px'}
                        placeholder="Pas de détails pour cette alarme"
                        value={JSON.stringify(data.details, null, 2)}
                        mode="json"
                        theme="monokai"
                        fontSize={14}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        readOnly={true}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Row>
              {/*<Row>
                <Col lg={12} md={12} className={'mb-2'}>
                  <Card className={'h-100'}>
                    <Card.Body>
                      <AssignUserToAlarm alarmID={data.id} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>*/}
            </Container>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default CanvasAlarmDetails
