import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { baseArkhaleAPI } from '../kopiliot-api/base-api'
import User from '../../shared/models/user'

export interface SettingsState {
  theme: 'light' | 'dark'
  isNavbarVerticalCollapsed: boolean
}

const initialState: SettingsState = {
  theme: 'light',
  isNavbarVerticalCollapsed: false,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    switchTheme: (state) => {
      state.theme = state.theme === 'light' ? 'dark' : 'light'
    },
    collapseNavbarVertical: (state) => {
      state.isNavbarVerticalCollapsed = !state.isNavbarVerticalCollapsed
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      baseArkhaleAPI.endpoints.me.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        state.theme = action.payload.userMetadata.theme
          ? action.payload.userMetadata.theme
          : 'light'
        state.isNavbarVerticalCollapsed =
          action.payload.userMetadata.isNavbarVerticalCollapsed
      }
    )
  },
})

// Action creators are generated for each case reducer function
export const { switchTheme, collapseNavbarVertical } = settingsSlice.actions

export default settingsSlice.reducer
