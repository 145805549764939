import React from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import { useAppSelector } from '../../../store/store'
import Divider from '../../../components/common/Divider'
import { PaginationWithSearchTerm } from '../../../store/kopiliot-api/query-type'

interface Props {
  form: UseFormReturn<PaginationWithSearchTerm, any>
  onSubmit: (data: any) => void
}

const TenantSearchForm = ({ form, onSubmit }: Props) => {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = form
  const { authority } = useAppSelector((state) => state.currentUser)

  return (
    <FormProvider {...form}>
      <Form className={'px-4 py-3'} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className={''}>
          <Input
            size={'sm'}
            label={'Search text'}
            register={register('textSearch')}
            className={'w-auto'}
          />
        </Form.Group>
        <Divider />
        <Button
          variant={'outline-primary'}
          type={'button'}
          size={'sm'}
          onClick={() => reset()}
        >
          Reset
        </Button>
      </Form>
    </FormProvider>
  )
}

export default TenantSearchForm
