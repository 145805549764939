import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from './Flex'
import { isIterableArray } from '../../helpers/utls'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface AvatarProps {
  size?: 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'
  rounded?: 'circle'
  src?: string | string[]
  emoji?: '😊'
  isExact?: boolean
  name?: string
  className?: string
  mediaClass?: string
  icon?: IconProp
}

const Avatar = ({
  size = 'xl',
  rounded = 'circle',
  src,
  name,
  emoji = '😊',
  className,
  mediaClass,
  isExact = false,
  icon,
}: AvatarProps) => {
  const classNames = ['avatar', `avatar-${size}`, className].join(' ')
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass,
  ].join(' ')

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img src={src[0]} alt="" />
            </div>
            <div className="w-50 d-flex flex-column">
              <img src={src[1]} alt="" className="h-50 border-bottom" />
              <img src={src[2]} alt="" className="h-50" />
            </div>
          </div>
        )
      } else {
        return <img className={mediaClasses} src={src} alt="" />
      }
    }

    if (name) {
      // TODO Case where name regex does not work
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g)?.join('')}</span>
        </div>
      )
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      )
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    )
  }

  return <div className={classNames}>{getAvatar()}</div>
}

export default Avatar
