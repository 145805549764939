import React from 'react'
import { Outlet } from 'react-router-dom'
import useTenantCustomerContext from '../context/tenant-customer.context'

const EntityGroup = () => {
  const context = useTenantCustomerContext()
  return (
    <>
      <Outlet context={context} />
    </>
  )
}

export default EntityGroup
