import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import AuditLog, { ActionStatus } from '../../../shared/models/audit-log'
import SubtleBadge from '../../../components/common/SubtleBadge'
import { getColorForActionType } from '../tools'

const getColumnsForTableAuditLogs = () => {
  const columnHelper = createColumnHelper<AuditLog>()
  const columns: ColumnDef<AuditLog, string>[] = [
    columnHelper.accessor((row) => row.id?.id, {
      id: 'id',
      cell: (info) => info.getValue(),
      header: () => 'Id',
    }),
    columnHelper.accessor((row) => row.createdAt?.toLocaleString(), {
      id: 'createdAt',
      cell: (info) => {
        try {
          return moment(info.getValue()).format('DD/MM/YYYY HH:mm:ss.SSS')
        } catch (error) {
          return 'Impossible de formater la date'
        }
      },
      header: () => 'Date',
    }),
    columnHelper.accessor((row) => row.entityId?.entityType.toString(), {
      id: 'entityType',
      cell: (info) => info.getValue(),
      header: () => 'Entity Type',
    }),
    columnHelper.accessor((row) => row.entityId?.id, {
      id: 'entityId',
      cell: (info) => info.getValue(),
      header: () => 'Entity Id',
    }),
    columnHelper.accessor((row) => row.entityName, {
      id: 'entityName',
      cell: (info) => info.getValue(),
      header: () => 'Entity Name',
    }),
    columnHelper.accessor((row) => row.userID?.id, {
      id: 'userID',
      cell: (info) => info.getValue(),
      header: () => 'User ID',
    }),
    columnHelper.accessor((row) => row.username, {
      id: 'username',
      cell: (info) => info.getValue(),
      header: () => 'Username',
    }),
    columnHelper.display({
      id: 'actionType',
      cell: (props) => {
        const { actionType } = props.row.original
        return (
          <>
            <SubtleBadge bg={getColorForActionType(actionType)} pill>
              {actionType.toUpperCase()}
            </SubtleBadge>
          </>
        )
      },
      header: () => 'Action',
    }),
    columnHelper.display({
      id: 'status',
      cell: (props) => {
        const { actionStatus } = props.row.original
        return (
          <>
            <SubtleBadge
              bg={actionStatus === ActionStatus.SUCCESS ? 'success' : 'danger'}
              pill
            >
              {actionStatus}
              <FontAwesomeIcon
                icon={actionStatus === ActionStatus.SUCCESS ? 'check' : 'times'}
                transform="shrink-2"
                className={'ms-1'}
              />
            </SubtleBadge>
          </>
        )
      },
      header: () => 'Status',
    }),

    columnHelper.display({
      id: 'actionFailureDetails',
      cell: (props) => {
        const { actionFailureDetails } = props.row.original
        return (
          <span>
            <pre>{JSON.stringify(actionFailureDetails, null, 2)}</pre>
          </span>
        )
      },
      header: () => 'Action failure details',
    }),
  ]
  return columns
}

export { getColumnsForTableAuditLogs }
