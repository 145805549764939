import React from 'react'
import { useForm } from 'react-hook-form'
import { Dashboard } from '../../../shared/models/dashboard.model'
import { Button, Modal } from 'react-bootstrap'
import DashboardForm from '../DashboardForm'
import ButtonLoading from '../../../components/button/ButtonLoading'
import { useAppDispatch } from '../../../store/store'
import { updateBasicInfo } from '../../../store/slices/dashboardSlice'

interface EditDashboardModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  initialDashboard: Pick<
    Dashboard,
    'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
  >
}

const EditDashboardModal = ({
  isOpen,
  setIsOpen,
  initialDashboard,
}: EditDashboardModalProps) => {
  const dispatch = useAppDispatch()
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const form =
    useForm<
      Pick<
        Dashboard,
        | 'id'
        | 'name'
        | 'description'
        | 'tenantID'
        | 'image'
        | 'assignedCustomers'
      >
    >()

  React.useEffect(() => {
    form.reset(initialDashboard)
  }, [initialDashboard])

  const onSubmit = async (
    data: Pick<
      Dashboard,
      'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
    >
  ) => {
    try {
      dispatch(updateBasicInfo(data))
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal
      show={isOpen}
      centered
      onHide={handleClose}
      size={'lg'}
      className={'border'}
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Modifier {initialDashboard.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <DashboardForm
          form={form}
          disabledFields={['tenantID']}
          onSubmit={onSubmit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          type={'submit'}
          text={'Valider'}
          variant={'outline-primary'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default EditDashboardModal
