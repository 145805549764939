import React from 'react'
import {
  useLazyGetAuditLogsByEntityIdQuery,
  useLazyGetAuditLogsQuery,
} from '../../store/kopiliot-api/audit-log'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import AuditLog from '../../shared/models/audit-log'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { getColumnsForTableAuditLogs } from '../../features/audit-log/list/columns'
import { EntityType } from '../../shared/models/entity-type.models'
import { useForm } from 'react-hook-form'
import {
  AuditLogsQuery,
  PaginationWithSearchTerm,
} from '../../store/kopiliot-api/query-type'
import AuditLogSearchForm from '../../features/audit-log/form/AuditLogSearchForm'
import AuditLogDetailModal from './AuditLogDetailModal'

interface Props {
  entityID?: { id: string; entityType: EntityType }
  hiddenColumns?: Array<keyof AuditLog>
}

const AuditLogHome = ({ entityID, hiddenColumns }: Props) => {
  const [auditLogSelected, setAuditLogSelected] = React.useState<AuditLog>()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const [getAuditLogs, resultGetAuditLogs] = useLazyGetAuditLogsQuery()
  const [getAuditLogsByEntityID, resultGetAuditLogsByEntityID] =
    useLazyGetAuditLogsByEntityIdQuery()
  const searchForm = useForm<AuditLogsQuery>()

  // Pagination
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    PaginationWithSearchTerm,
    PaginationActions
  >(reducerPage, {
    limit: 20,
    offset: 0,
    textSearch: '',
  })
  // Load on mount
  React.useEffect(() => {
    fetchAuditLogs()
  }, [paginationState, entityID])

  // Utility Functions
  const fetchAuditLogs = () => {
    if (entityID) {
      getAuditLogsByEntityID({
        ...paginationState,
        entityId: {
          id: entityID.id,
          entityType: entityID.entityType,
        },
      })
    } else {
      getAuditLogs(paginationState)
    }
  }
  const getData = () => {
    if (entityID) {
      return resultGetAuditLogsByEntityID.data
    } else {
      return resultGetAuditLogs.data
    }
  }
  const isLoadingData =
    resultGetAuditLogs.isLoading || resultGetAuditLogsByEntityID.isLoading

  function getDisabledField() {
    return undefined
  }

  const onGetDetails = (id: string) => {
    if (entityID) {
      const auditLogSelected = resultGetAuditLogsByEntityID.data?.rows?.find(
        (item) => {
          return item.id?.id === id
        }
      )
      setAuditLogSelected(auditLogSelected)
      setIsModalOpen(true)
    } else {
      const auditLogSelected = resultGetAuditLogs.data?.rows?.find((item) => {
        return item.id?.id === id
      })
      setAuditLogSelected(auditLogSelected)
      setIsModalOpen(true)
    }
  }

  return (
    <>
      <KopiliotList
        title={'Audit logs'}
        icon={'terminal'}
        isLoading={isLoadingData}
        actionsProps={{
          filterForm: (
            <AuditLogSearchForm
              form={searchForm}
              onSubmit={() => {}}
              disabledFields={getDisabledField()}
            />
          ),
          onRefresh: () => {
            fetchAuditLogs()
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id', 'entityId', 'actionFailureDetails', 'userID'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableAuditLogs(),
          onClickRow: (row) => {
            onGetDetails(row.original.id?.id!)
          },
        }}
        cardProps={{
          idAccessor: (item: AuditLog) => item.id!.id,
          titleAccessor: (item: AuditLog) => item.tenantId.entityType,
          descriptionAccessor: (item: AuditLog) => item.entityName,
          linkAccessor: (item: AuditLog) => `${item.id!.id}`,
          actionsRow: [
            {
              title: 'Details',
              icon: 'info-circle',
              color: 'primary',
              onClick: (id) => {
                onGetDetails(id)
              },
            },
          ],
        }}
      />
      <AuditLogDetailModal
        isOpen={isModalOpen}
        setIsOpen={() => {
          setIsModalOpen(!isModalOpen)
          setAuditLogSelected(undefined)
        }}
        details={auditLogSelected}
      />
    </>
  )
}

export default AuditLogHome
