import React, { PropsWithChildren } from 'react'
import { Card, CardProps, Col, Row, Tab } from 'react-bootstrap'
import classNames from 'classnames'
import ContentBlockHeader from './ContentBlockHeader'
import ContentBlockBody from './ContentBlockBody'
import ContentBlocFooter from './ContentBlocFooter'

interface ContentBlockProps extends PropsWithChildren {
  multiSections?: boolean
  noGuttersBottom?: boolean
}

const ContentBlock = ({
  multiSections,
  noGuttersBottom,
  children,
  ...rest
}: ContentBlockProps & CardProps) => {
  return (
    <Card className={classNames({ 'mb-3': !noGuttersBottom })} {...rest}>
      {multiSections ? (
        <>{children}</>
      ) : (
        <Tab.Container defaultActiveKey="preview">{children}</Tab.Container>
      )}
    </Card>
  )
}

ContentBlock.Header = ContentBlockHeader
ContentBlock.Body = ContentBlockBody
ContentBlock.Footer = ContentBlocFooter

export default ContentBlock
