import { EntityType } from '../../shared/models/entity-type.models'
import {
  useLazyGetTenantCustomersQuery,
  useLazyGetTenantDevicesQuery,
} from '../../store/kopiliot-api/tenant'
import { useLazyGetCustomersQuery } from '../../store/kopiliot-api/customer'
import { CustomerQuery, DeviceQuery } from '../../store/kopiliot-api/query-type'
import { useLazyGetDevicesQuery } from '../../store/kopiliot-api/device'

export const useFetchDevices = (
  tenantIDParam?: string,
  customerIDParams?: string
) => {
  const [getDevices, resultGetDevices] = useLazyGetDevicesQuery()
  const [getTenantDevices, resultGetTenantDevices] =
    useLazyGetTenantDevicesQuery()
  //const [getCustomerDevices, resultGetCustomerDevices] = useLazyGetCustomerDevicesQuery()

  const fetchDevices = (query: DeviceQuery, useCache?: boolean) => {
    if (tenantIDParam) {
      getTenantDevices(
        {
          ...query,
          tenantID: { id: tenantIDParam, entityType: EntityType.TENANT },
        },
        useCache
      )
    } else {
      getDevices(
        {
          ...query,
          tenantID: tenantIDParam
            ? { id: tenantIDParam, entityType: EntityType.TENANT }
            : query.tenantID,
        },
        useCache
      )
    }
  }
  const getData = () => {
    if (tenantIDParam) {
      return resultGetTenantDevices.data
    } else {
      return resultGetDevices.data
    }
  }
  const isLoading = tenantIDParam
    ? resultGetTenantDevices.isLoading
    : resultGetDevices.isLoading

  return {
    fetchDevices,
    getData,
    isLoading,
  }
}
