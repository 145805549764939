import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Flex from '../Flex'
import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SectionTitleProps {
  title: string
  subTitle?: string
  icon: IconProp
  transform?: string
  className?: string
}

const SectionTitle = ({
  title,
  subTitle,
  icon,
  transform,
  className,
}: SectionTitleProps) => {
  return (
    <Flex className={className}>
      <span className="fa-stack ms-n1 me-2">
        <FontAwesomeIcon icon="circle" className="text-300 fa-stack-2x" />
        <FontAwesomeIcon
          icon={icon}
          transform={transform}
          className="text-primary fa-stack-1x"
          inverse
        />
      </span>
      <Col>
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
        </h5>
        <p className="mb-0">{subTitle}</p>
      </Col>
    </Flex>
  )
}

export default SectionTitle
