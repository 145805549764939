import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../../components/button/ButtonLoading'
import { useForm } from 'react-hook-form'
import RuleChain from '../../../../shared/models/rule-chain.model'
import { useAddRuleChainMutation } from '../../../../store/kopiliot-api/rule-chain'
import RuleChainForm from './RuleChainForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

interface AddRuleChainModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  defaultValues?: Partial<RuleChain>
  disabledField?: Array<keyof RuleChain>
}

const AddRuleChainModal = ({
  isOpen,
  setIsOpen,
  defaultValues,
  disabledField = [],
}: AddRuleChainModalProps) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const form = useForm<RuleChain>({
    defaultValues,
  })
  const [addRuleChain, { isLoading, error }] = useAddRuleChainMutation()
  const onSubmit = async (data: RuleChain) => {
    try {
      await addRuleChain(data).unwrap()
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'xl'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Créer une Rule Chain</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <RuleChainForm
          form={form}
          onSubmit={onSubmit}
          isLoading={isLoading}
          disabledField={disabledField} // Check if we need to disable some fields depending of rights
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} variant={'secondary'} onClick={handleClose}>
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddRuleChainModal
