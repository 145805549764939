import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
  useLazyGetDeviceQuery,
  useDeleteDeviceMutation,
} from '../../../store/kopiliot-api/device'
import {
  useDeleteDeviceProfileMutation,
  useLazyGetDeviceProfileQuery,
} from '../../../store/kopiliot-api/device-profile'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  deviceProfileID?: string
}

const DeleteDeviceProfileModal = ({
  isOpen,
  setIsOpen,
  deviceProfileID,
}: Props) => {
  const [triggerGetDeviceProfile, resultGetDeviceProfile] =
    useLazyGetDeviceProfileQuery()
  useEffect(() => {
    if (deviceProfileID) {
      triggerGetDeviceProfile(deviceProfileID, true)
    }
  }, [deviceProfileID])
  const [deleteDeviceProfile, { isLoading: isDeleting }] =
    useDeleteDeviceProfileMutation()
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Modal
        show={isOpen && deviceProfileID !== undefined}
        onHide={handleClose}
        size={'xl'}
        className={'border'}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title as="h5">
            Supprimer le client {`${resultGetDeviceProfile?.data?.name}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'p-2'}>
          <p>
            {`Êtes - vous sûr de vouloir supprimer le device profile ${resultGetDeviceProfile?.data?.name} ?`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={'button'}
            variant={'secondary'}
            onClick={handleClose}
            disabled={isDeleting}
          >
            Annuler
          </Button>
          <Button
            children={'Supprimer'}
            variant={'danger'}
            disabled={isDeleting}
            onClick={() => {
              if (deviceProfileID) {
                deleteDeviceProfile(deviceProfileID)
              }
              handleClose()
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteDeviceProfileModal
