export const calculateGridDimensions = (
  parentWidth: number,
  parentHeight: number,
  itemSpacing: number,
  numberOfChildren: number
) => {
  const vertical = calculateSizeOfChild(
    parentWidth,
    parentHeight,
    numberOfChildren
  )
  const horizontal = calculateSizeOfChild(
    parentHeight,
    parentWidth,
    numberOfChildren
  )
  const square = Math.max(vertical, horizontal)
  let xCount = Math.floor(parentWidth / square)
  let yCount = Math.ceil(numberOfChildren / xCount)

  // after yCount update xCount to make split between rows more even
  xCount = Math.ceil(numberOfChildren / yCount)

  const itemsOnLastRow = xCount - (xCount * yCount - numberOfChildren)
  const widthOnLastRow =
    parentWidth / itemsOnLastRow - itemSpacing + itemSpacing / itemsOnLastRow

  return {
    width: parentWidth / xCount - itemSpacing + itemSpacing / xCount,
    height: parentHeight / yCount - itemSpacing + itemSpacing / yCount,
    widthOnLastRow,
    xCount,
    yCount,
  }
}

function calculateSizeOfChild(
  parentWidth: number,
  parentHeight: number,
  numberOfChildren: number
): number {
  const parts = Math.ceil(
    Math.sqrt((numberOfChildren * parentWidth) / parentHeight)
  )

  if (
    Math.floor((parts * parentHeight) / parentWidth) * parts <
    numberOfChildren
  ) {
    return parentHeight / Math.ceil((parts * parentHeight) / parentWidth)
  }

  return parentWidth / parts
}

interface GaugeAutoProps {
  titleFontSize: number
  gaugeHeight: number
  gaugeWidth: number
  showLabel: boolean
}

export function calculateGaugeAutoProps(
  width: number,
  height: number,
  type?: 'semicircle' | 'radial' | 'grafana',
  title?: string
): GaugeAutoProps {
  const showLabel = title !== null && title !== undefined
  const titleFontSize = Math.min((width * 0.15) / 1.5, 20) // 20% of height * line-height, max 40px
  const titleHeight = titleFontSize * 1.5
  const availableHeight = showLabel ? height - titleHeight : height
  const gaugeHeight = Math.min(availableHeight, width)
  // replaced for test
  let gaugeWidth = gaugeHeight
  // const gaugeWidth = width
  switch (type) {
    case 'semicircle':
      if (gaugeWidth < width) {
        gaugeWidth = width
      }
      break
    case 'radial':
      if (gaugeWidth < width && gaugeWidth < height) {
        gaugeWidth = width
      }
      break
    case 'grafana':
      gaugeWidth = width
      break
  }

  return {
    showLabel,
    gaugeHeight,
    titleFontSize,
    gaugeWidth,
  }
}
