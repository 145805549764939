import React from 'react'
import PasswordResetForm from './PasswordResetForm'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMeQuery } from '../../store/kopiliot-api/base-api'
import { toast } from 'react-toastify'
import PasswordResetRequest from './PasswordResetRequest'

const PasswordReset = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { data: me } = useMeQuery(null)

  if (me) {
    toast('You are already logged in')
    navigate('/')
  }

  if (searchParams.get('reset_token')) {
    return (
      <PasswordResetForm
        token={searchParams.get('reset_token')}
        title={'Réinitialisez votre mot de passe'}
        action={'reset'}
      />
    )
  }

  return <PasswordResetRequest />
}

export default PasswordReset
