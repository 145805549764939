import React from 'react'
import SubtleBadge from '../../../../components/common/SubtleBadge'

const DebugMode = ({ isDebugMode }: { isDebugMode?: boolean }) => {
  return (
    <SubtleBadge
      pill
      className={'me-2 '}
      bg={isDebugMode ? 'warning' : 'success'}
    >
      {isDebugMode ? 'Oui' : 'Non'}
    </SubtleBadge>
  )
}

export default DebugMode
