import React from 'react'
import { AssetProfile } from '../../../shared/models/asset-profile'
import { useForm } from 'react-hook-form'
import { useAddAssetProfileMutation } from '../../../store/kopiliot-api/asset-profile'
import { toast } from 'react-toastify'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../components/button/ButtonLoading'
import AssetProfileForm from '../form/AssetProfileForm'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  defaultValues?: Partial<AssetProfile>
  hideFields?: Array<keyof AssetProfile>
}

const AddAssetProfileModal = ({
  isOpen,
  setIsOpen,
  defaultValues,
  hideFields,
}: Props) => {
  const form = useForm<AssetProfile>({ defaultValues: defaultValues })
  const [addAssetProfile, { isLoading, error }] = useAddAssetProfileMutation()
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const onSubmit = async (data: AssetProfile) => {
    try {
      await addAssetProfile(data).unwrap()
      form.reset()
      handleClose()
      toast.success('Asset profile ajouté avec succès !')
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'xl'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Créer un Asset Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <AssetProfileForm
          form={form}
          onSubmit={onSubmit}
          hideFields={hideFields}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          variant={'outline-primary'}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddAssetProfileModal
