import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Spinner from '../../components/spinner/Spinner'
import ButtonLoading from '../../components/button/ButtonLoading'

interface ModalDeleteProps {
  show: boolean
  onClose: () => void
  onDelete: () => void
  name?: string
  text?: string
  isLoading?: boolean
  isLoadingDelete?: boolean
  isQuestion?: boolean
}

const ModalDelete = ({
  show,
  onClose,
  onDelete,
  text = "Êtes-vous sûr de vouloir supprimer l'élément",
  name,
  isLoading,
  isLoadingDelete,
  isQuestion = true,
}: ModalDeleteProps) => {
  return (
    <Modal show={show} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Supprimer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Spinner />}
        <p>{`${text} ${name ? name : ''} ${isQuestion ? '?' : ''}`}</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonLoading
          variant="outline-secondary"
          onClick={onClose}
          disabled={isLoading}
          text={'Annuler'}
        />
        <ButtonLoading
          isLoading={isLoadingDelete}
          variant="outline-danger"
          onClick={onDelete}
          disabled={isLoading || isLoadingDelete}
          text={isLoadingDelete ? 'Suppression...' : 'Supprimer'}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalDelete
