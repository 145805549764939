import React from 'react'
import { DataSource } from '../../../../../../shared/models/panel'
import { TimeseriesData } from '../../../../../../shared/models/telemetry/telemetry.model'
import { TimeWindow } from '../../../../../../shared/models/time.model'
import { Nav, Tab, Tabs } from 'react-bootstrap'
import _ from 'lodash'
import { useAppSelector } from '../../../../../../store/store'
import { useLazyGetDeviceQuery } from '../../../../../../store/kopiliot-api/device'
import TimeSeriesTableWrapper from './TimeSeriesTableWrapper'

interface TimeSeriesTableProps {
  timeSeriesData?: {
    [key: string]: { datasource: DataSource; data: TimeseriesData }
  }
  timeWindow?: TimeWindow
  options?: {}
}

const TimeSeriesTable = ({
  timeSeriesData,
  timeWindow,
  options,
}: TimeSeriesTableProps) => {
  const dash = useAppSelector((state) => state.dashboard)
  const [tabs, setTabs] = React.useState<{ [key: string]: string }>({})
  React.useEffect(() => {
    if (timeSeriesData && dash.aliases) {
      const newTabs: { [key: string]: string } = {}
      _.map(timeSeriesData, (data, key) => {
        if (data.datasource.entityAliasID?.id) {
          const alias = dash.aliases?.[data.datasource.entityAliasID?.id]
          if (alias) {
            newTabs[key] = alias.name
          }
          return
        }
      })
      setTabs(newTabs)
    }
  }, [dash.aliases, timeSeriesData])
  return (
    <>
      <Tabs>
        {_.map(tabs, (tab, key) => {
          return (
            <Tab eventKey={key} title={tab} key={key}>
              <TimeSeriesTableWrapper
                timeSeriesData={timeSeriesData?.[key]}
                timeWindow={timeWindow}
                options={options}
              />
            </Tab>
          )
        })}
      </Tabs>
    </>
  )
}

export default TimeSeriesTable
