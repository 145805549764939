import React from 'react'
import Flex from '../../components/common/Flex'
import { useLazyGetHubMetricsByTenantIDQuery } from '../../store/kopiliot-api/tenant'
import dayjs from 'dayjs'
import { TsValue } from '../../shared/models/telemetry/telemetry.model'
import MetricRangeSelect, {
  MetricRange,
  MetricSelectType,
  MetricType,
} from './MetricRangeSelect'
import MetricsChart from './MetricsChart'
import {
  useGetDeviceMetricsByDeviceIdQuery,
  useLazyGetDeviceMetricsByDeviceIdQuery,
} from '../../store/kopiliot-api/device'

interface DeviceMetricsProps {
  deviceID: string
}

const DeviceMetrics = ({ deviceID }: DeviceMetricsProps) => {
  const [fetchMetrics, { data, isLoading, error }] =
    useLazyGetDeviceMetricsByDeviceIdQuery()
  const [metricType, setMetricType] = React.useState<MetricType>(
    MetricType.MessageCount
  )
  const [metricRange, setMetricRange] = React.useState<MetricRange>(
    MetricRange.LastDay
  )
  const [startDate, setStartDate] = React.useState<Date>()
  const selectMetricType: MetricSelectType[] = [
    { value: MetricType.MessageCount, label: 'Messages' },
    { value: MetricType.DeviceConnected, label: 'Devices Connectés' },
  ]
  const getMetric = (name: string): Array<TsValue> | null => {
    return data && data[name] ? data[name] : null
  }
  React.useEffect(() => {
    try {
      switch (metricRange) {
        case MetricRange.LastHour:
          setStartDate(dayjs().subtract(1, 'hour').toDate())
          break
        case MetricRange.LastDay:
          setStartDate(dayjs().subtract(1, 'day').toDate())
          break
        case MetricRange.LastWeek:
          setStartDate(dayjs().subtract(1, 'week').toDate())
          break
        case MetricRange.LastMonth:
          setStartDate(dayjs().subtract(1, 'month').toDate())
          break
        case MetricRange.LastYear:
          setStartDate(dayjs().subtract(1, 'year').toDate())
          break
        default:
          break
      }
    } catch (error) {
      console.log(error)
    }
  }, [metricRange])

  React.useEffect(() => {
    try {
      if (startDate) {
        fetchMetrics({
          deviceID: deviceID,
          startDate: startDate.toISOString(),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [startDate])

  const handleMetricTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMetricType(e.target.value as MetricType)
  }
  return (
    <>
      <Flex direction={'row'} justifyContent={'between'}>
        <div>
          <select onChange={handleMetricTypeChange}>
            {selectMetricType.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <MetricRangeSelect
            metricRange={metricRange}
            setMetricRange={setMetricRange}
          />
        </div>
      </Flex>
      <>
        {metricType === MetricType.DeviceConnected && (
          <MetricsChart
            label={'Connecté'}
            metric={getMetric(MetricType.DeviceConnected)}
          />
        )}
        {metricType === MetricType.MessageCount ? (
          <MetricsChart
            label={'Messages'}
            metric={getMetric(MetricType.MessageCount)}
          />
        ) : null}
      </>
    </>
  )
}

export default DeviceMetrics
