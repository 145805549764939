import React from 'react'
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../../components/form/Input'
import IconButton from '../../../../../components/common/IconButton'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import {
  GaugeDataKeySpecificProps,
  JsonTableDataKeySpecificProps,
} from '../../../../../shared/models/panel'

interface JsonTableDataKeySpecificFormProps {
  initialValues?: JsonTableDataKeySpecificProps
  onSubmitted?: (data: any) => void
}

const JsonTableDataKeySpecificForm = ({
  initialValues,
  onSubmitted,
}: JsonTableDataKeySpecificFormProps) => {
  const form = useFormContext<JsonTableDataKeySpecificProps>()
  const {
    reset,
    watch,
    register,
    trigger,
    formState: { errors },
  } = form
  const { fields, append, remove, swap } =
    useFieldArray<JsonTableDataKeySpecificProps>({
      control: form.control,
      name: `columns`,
    })
  React.useEffect(() => {
    if (initialValues) {
      form.reset(initialValues)
    }
  }, [initialValues])

  const onSubmit = async () => {
    const result = await trigger()
    if (onSubmitted && result) {
      await form.handleSubmit(onSubmitted)()
    }
  }
  return (
    <Form onSubmit={onSubmitted ? form.handleSubmit(onSubmitted) : undefined}>
      <Form.Group>
        <Form.Label>Colonnes</Form.Label>
        {fields.map((field, index) => {
          return (
            <Row key={field.id}>
              <Form.Group as={Col} md={4}>
                <Form.Label>Clé</Form.Label>
                <Input
                  type={'text'}
                  error={errors?.columns?.[index]?.key}
                  register={register(`columns.${index}.key`, {
                    required: 'La clé est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label>Label</Form.Label>
                <Input
                  type={'text'}
                  error={errors?.columns?.[index]?.label}
                  register={register(`columns.${index}.label`, {
                    required: 'Le label est requis',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={2}>
                <IconButton
                  iconClassName={'trash'}
                  variant={'outline-danger'}
                  onClick={() => remove(index)}
                />
              </Form.Group>
            </Row>
          )
        })}
        <div>
          <Button
            type="button"
            variant={'outline-primary'}
            onClick={() =>
              append({
                key: '',
                label: '',
              })
            }
          >
            Ajouter
          </Button>
        </div>
      </Form.Group>
    </Form>
  )
}

export default JsonTableDataKeySpecificForm
