import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState, useAppDispatch, useAppSelector } from '../../../store/store'
import {
  removePanel,
  setCurrentAction,
} from '../../../store/slices/dashboardSlice'
import { PanelID } from '../../../shared/models/id/panel-id'

interface Props {
  panelID: PanelID
}

const PanelMenu = ({ panelID }: Props) => {
  // Get the dashboard data from the store (slice)
  const currentDashboard = useAppSelector((state: RootState) => state.dashboard)
  const dispatch = useAppDispatch()
  const onEditPanel = () => {
    dispatch(setCurrentAction({ action: 'edit', panelID: panelID }))
  }
  return (
    <>
      <div
        className={'overflow-hidden'}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size={'sm'}
            variant={'light'}
            id="dropdown-panel-actions"
            className={'border-0 bg-transparent'}
          >
            <FontAwesomeIcon icon={'ellipsis-vertical'} />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align={'end'}
            popperConfig={{ strategy: 'fixed' }}
            renderOnMount={true}
          >
            <Dropdown.Header>Actions</Dropdown.Header>
            <Dropdown.Item onClick={(event) => {}}>
              <FontAwesomeIcon icon={'eye'} className={'me-2'} />
              View
            </Dropdown.Item>
            <Dropdown.Item onClick={(event) => {}}>
              <FontAwesomeIcon icon={'download'} className={'me-2'} />
              Exporter
            </Dropdown.Item>
            {currentDashboard.isEditing && (
              <>
                <Dropdown.Item onClick={() => onEditPanel()}>
                  <FontAwesomeIcon icon={'pencil'} className={'me-2'} />
                  Edit
                </Dropdown.Item>
                <Dropdown.Header>Manage</Dropdown.Header>
                <Dropdown.Item>
                  <FontAwesomeIcon icon={'copy'} className={'me-2'} />
                  Duplicate
                </Dropdown.Item>
                <Dropdown.Item>
                  <FontAwesomeIcon icon={'copy'} className={'me-2'} />
                  Copy
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item
                  className={'text-danger'}
                  onClick={() => {
                    dispatch(removePanel({ id: panelID.id }))
                  }}
                >
                  <FontAwesomeIcon icon={'trash'} className={'me-2'} />
                  Remove
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}

export default PanelMenu
