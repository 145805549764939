import { baseArkhaleAPI } from './base-api'
import { LoginInput } from '../../shared/models/others/login-input'
import { toast } from 'react-toastify'
import { ActivateAccountOrResetPasswordRequest } from '../../shared/models/others/activate-account'

export const auth = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<{ accessToken: string; status: string }, LoginInput>({
      query(data) {
        return {
          url: 'auth/login',
          method: 'POST',
          body: data,
          credentials: 'include', // Required for refresh cookie
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled
          localStorage.setItem('access_token', data.data.accessToken)
          await dispatch(baseArkhaleAPI.endpoints.me.initiate(null))
          toast.success('Login successful')
        } catch (error) {
          console.error(error)
        }
      },
    }),
    logout: build.mutation<void, void>({
      query() {
        return {
          url: 'auth/logout',
          method: 'POST',
          credentials: 'include', // Required for refresh cookie
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          localStorage.removeItem('access_token')
          await dispatch(
            baseArkhaleAPI.endpoints.me.initiate(null, {
              forceRefetch: true,
            })
          )
        } catch (error) {}
      },
    }),
    activateAccount: build.mutation<
      { message: string },
      ActivateAccountOrResetPasswordRequest
    >({
      query(data) {
        return {
          url: 'auth/activate-account',
          method: 'POST',
          body: data,
        }
      },
    }),
    requestPasswordReset: build.mutation<{ email: string }, { email: string }>({
      query(data) {
        return {
          url: 'auth/reset-password',
          method: 'POST',
          body: data,
        }
      },
    }),
    resetPassword: build.mutation<
      { message: string },
      ActivateAccountOrResetPasswordRequest
    >({
      query(data) {
        return {
          url: 'auth/reset-password',
          method: 'PUT',
          body: data,
        }
      },
    }),
    refreshToken: build.mutation<{ accessToken: string; status: string }, null>(
      {
        query() {
          return {
            url: 'auth/refresh-token',
            method: 'POST',
            credentials: 'include', // require for refresh cookie
          }
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            // Wait for
            const data = await queryFulfilled
            localStorage.setItem('access_token', data.data.accessToken)
            await dispatch(baseArkhaleAPI.endpoints.me.initiate(null))
          } catch (error) {
            console.error(error)
          }
        },
      }
    ),
  }),
  overrideExisting: false,
})

export const {
  useLoginMutation,
  useActivateAccountMutation,
  useRefreshTokenMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useLogoutMutation,
} = auth

export const authApi = auth
