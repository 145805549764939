import React from 'react'
import { DeviceId } from '../../../shared/models/id/device-id'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import {
  useLazyGetDeviceQuery,
  useRenewDeviceCertificateMutation,
} from '../../../store/kopiliot-api/device'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from '../../../components/common/Flex'

interface RenewDeviceCertificateModalProps {
  isOpen: boolean
  onClose: () => void
  deviceID: DeviceId
}

const RenewDeviceCertificateModal = ({
  isOpen,
  deviceID,
  onClose,
}: RenewDeviceCertificateModalProps) => {
  const [getDevice, deviceData] = useLazyGetDeviceQuery()
  const [renew, dataFromRenew] = useRenewDeviceCertificateMutation()
  React.useEffect(() => {
    if (isOpen) {
      getDevice(deviceID.id)
    }
  }, [deviceID.id, isOpen])
  const onRenew = () => {
    renew({ id: deviceID })
  }
  // Utilitaires
  const createBlob = (content: string, mimeType: string) => {
    return new Blob([content], { type: mimeType })
  }
  const downloadFile = (content: string, filename: string) => {
    const blob = createBlob(content, 'text/plain')
    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download = filename
    document.body.appendChild(element)
    element.click()
  }
  return (
    <Modal show={isOpen} onHide={onClose} centered size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title className={'text-danger'}>
          Renouveler le certificat
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex alignItems={'center'}>
          <Col md={4} className={'d-flex justify-content-center'}>
            <FontAwesomeIcon
              icon={
                dataFromRenew.data &&
                !dataFromRenew.isLoading &&
                !dataFromRenew.error
                  ? 'check-circle'
                  : 'warning'
              }
              size={'8x'}
              color={
                dataFromRenew.data &&
                !dataFromRenew.isLoading &&
                !dataFromRenew.error
                  ? '#28a745'
                  : '#dc3545'
              }
            />
          </Col>
          <Col md={8}>
            {!dataFromRenew.data ? (
              <>
                <p>
                  Êtes-vous sûr de vouloir renouveler le certificat du device{' '}
                  {deviceData.data?.name} ?
                </p>
                <p className={'text-danger'}>
                  <b>Attention:</b> Cette action est irréversible et entraînera
                  la perte de connexion du device.
                </p>
              </>
            ) : (
              <>
                <p>
                  Vos certificats sont prêts. Cliquez sur les liens ci-dessous
                  pour les télécharger.
                </p>
                <Flex
                  justifyContent={'evenly'}
                  direction={'column'}
                  alignItems={'center'}
                >
                  <div>
                    {dataFromRenew.data && (
                      <Button
                        className="px-5 my-3"
                        role="button"
                        variant="outline-primary"
                        onClick={() => {
                          if (dataFromRenew.data?.certificate.crt) {
                            downloadFile(
                              dataFromRenew.data.certificate.crt,
                              `Certificate_${dataFromRenew.data?.device.name}.crt`
                            )
                          }
                        }}
                      >
                        Télécharger le certificat
                      </Button>
                    )}
                  </div>
                  <div>
                    {dataFromRenew.data && (
                      <Button
                        className="px-5 my-3"
                        role="button"
                        variant={'outline-primary'}
                        onClick={() => {
                          if (dataFromRenew.data?.certificate.key) {
                            downloadFile(
                              dataFromRenew.data.certificate.key,
                              `Private_key_${dataFromRenew.data?.device.name}.crt`
                            )
                          }
                        }}
                      >
                        Télécharger la clé privée
                      </Button>
                    )}
                  </div>
                </Flex>
              </>
            )}
          </Col>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'outline-secondary'} onClick={onClose}>
          {dataFromRenew.data ? 'Fermer' : 'Annuler'}
        </Button>
        {!dataFromRenew.data && (
          <Button
            variant={'outline-danger'}
            disabled={dataFromRenew.isLoading}
            onClick={onRenew}
          >
            Renouveler
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default RenewDeviceCertificateModal
