import React from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'
import Flex from '../common/Flex'

export interface KopiliotListRowPerPageProps {
  onChange: (size: number) => void
  rowsPerPageOptions?: number[]
  pageSize: number
  className?: string
}

const KopiliotListRowPerPage = ({
  onChange,
  pageSize,
  rowsPerPageOptions = [20, 30, 40, 50],
  className,
}: KopiliotListRowPerPageProps) => {
  const setPageSize = (value: string) => {
    onChange(parseInt(value))
  }
  return (
    <Flex direction={'row'} className={'fs--1'} alignItems={'center'}>
      <Form.Select
        size="sm"
        className="w-auto"
        onChange={(e) => setPageSize(e.target.value)}
        defaultValue={pageSize}
      >
        {rowsPerPageOptions.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </Form.Select>
    </Flex>
  )
}

export default KopiliotListRowPerPage
