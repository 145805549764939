import { Dashboard } from '../../../shared/models/dashboard.model'
import { useForm } from 'react-hook-form'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../components/button/ButtonLoading'
import DashboardForm from '../DashboardForm'
import { useAddDashboardMutation } from '../../../store/kopiliot-api/dashboard'

interface AddDashboardModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
}

const AddDashboardModal = ({ isOpen, setIsOpen }: AddDashboardModalProps) => {
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const form =
    useForm<
      Pick<
        Dashboard,
        | 'id'
        | 'name'
        | 'description'
        | 'tenantID'
        | 'image'
        | 'assignedCustomers'
      >
    >()
  const [addDashboard, { isLoading, error }] = useAddDashboardMutation()
  const onSubmit = async (
    data: Pick<
      Dashboard,
      'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
    >
  ) => {
    try {
      await addDashboard(data).unwrap()
      handleClose()
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      centered
      onHide={handleClose}
      size={'lg'}
      className={'border'}
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Créer un Dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <DashboardForm
          form={form}
          disabledFields={['assignedCustomers']}
          onSubmit={onSubmit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          type={'submit'}
          text={'Valider'}
          variant={'outline-primary'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddDashboardModal
