import React, { useRef } from 'react'
import VisualRepeater, {
  VisualRepeaterRenderValueProps,
  VizOrientation,
} from '../VisualRepeater'
import { useResizeObserver } from 'usehooks-ts'
import Gauge, { CustomGaugeComponentProps, GaugeProps } from './Gauge'
import {
  DataSource,
  GaugeDataKeySpecificProps,
} from '../../../../../shared/models/panel'
import { LatestTimeseriesData } from '../../../../../shared/models/telemetry/telemetry.model'
import { TimeWindow } from '../../../../../shared/models/time.model'
import { Aliases } from '../../../../../shared/models/alias.model'
import _ from 'lodash'
import { GaugeComponentProps } from 'react-gauge-component/dist/lib/GaugeComponent/types/GaugeComponentProps'

interface GaugeListProps {
  latestTimeSeriesData?: {
    [key: string]: {
      datasource: DataSource<GaugeDataKeySpecificProps>
      data: LatestTimeseriesData
    }
  }
  timeWindow?: TimeWindow
  aliases: Aliases
  options?: any
}

const GaugeList = ({
  latestTimeSeriesData,
  timeWindow,
  aliases,
  options,
}: GaugeListProps) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({
    ref: elementRef,
  })
  const getLength = (): number => {
    return _.chain(latestTimeSeriesData)
      .map((t) => t.data)
      .map((d) => _.size(d))
      .sum()
      .value()
  }
  const renderValue = (
    props: VisualRepeaterRenderValueProps<CustomGaugeComponentProps>
  ): JSX.Element => {
    if (!props.value) {
      return <></>
    }
    return (
      <Gauge
        key={`${props.width}-${props.height}`}
        width={props.width}
        height={props.height}
        value={props.value}
        //type={props.value.datasource.type}
      />
    )
  }

  function getValues() {
    const values: CustomGaugeComponentProps[] = []
    _.forEach(latestTimeSeriesData, (t) => {
      _.forEach(t.data, (v, k) => {
        const ds = t.datasource.dataKeys.find((d) => d.key === k)
        const value = _.get(v, 'value')
        const gaugeValue: CustomGaugeComponentProps = {
          value,
          minValue: ds?.specificProps?.min ?? 0,
          maxValue: ds?.specificProps?.max ?? 100,
          type: ds?.specificProps?.type,
          arc: ds?.specificProps?.arc,
          title: ds?.label,
          units: ds?.units,
        }
        values.push(gaugeValue)
      })
    })
    return values
  }

  return (
    <div className={'h-100'} ref={elementRef}>
      <VisualRepeater
        width={width}
        height={height}
        renderValue={renderValue}
        length={getLength()}
        getValues={getValues}
      />
    </div>
  )
}

export default GaugeList
