import { EntityType } from '../../shared/models/entity-type.models'
import TenantId from '../../shared/models/id/tenant-id'
import { ActionStatus, ActionType } from '../../shared/models/audit-log'

export enum SortOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortOrder {
  property: string
  direction: SortOrderDirection
}

export const formatSortOrder = (sortOrder?: SortOrder) => {
  if (sortOrder) {
    return {
      property: sortOrder.property,
      direction: sortOrder.direction,
    }
  }
  return {}
}

export interface PaginationWithSearchTerm {
  textSearch: string
  limit: number
  offset: number
  sortOrder?: SortOrder
}

export interface PaginationByTenantID extends PaginationWithSearchTerm {
  tenantID: string
}

export interface PaginationByCustomerID extends PaginationWithSearchTerm {
  customerID?: {
    id: string
    entityType: EntityType.CUSTOMER
  }
}

export interface CustomerQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}

export interface DeviceQuery extends PaginationByCustomerID {
  deviceProfileID?: {
    id: string
    entityType: EntityType.DEVICE_PROFILE
  }
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}

export interface DeviceProfileQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}

export interface AssetQuery extends PaginationWithSearchTerm {
  assetProfileID?: {
    id: string
    entityType: EntityType.ASSET_PROFILE
  }
  customerID?: {
    id: string
    entityType: EntityType.CUSTOMER
  }
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}

export interface AssetProfileQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}

export interface UserQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
  customerID?: {
    id: string
    entityType: EntityType.CUSTOMER
  }
}

export interface AuditLogsQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
  customerID?: {
    id: string
    entityType: EntityType.CUSTOMER
  }
  entityId?: {
    id: string
    entityType: EntityType
  }
  actionStatus?: ActionStatus
  actionType?: ActionType
}

export interface RuleChainQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}

export interface EntityGroupQuery extends PaginationWithSearchTerm {
  entityType: EntityType
  ownerID?: string
  ownerType?: EntityType.TENANT | EntityType.CUSTOMER
}

export interface EntityGroupQueryWithOwner extends EntityGroupQuery {
  ownerID: string
  ownerType: EntityType.TENANT | EntityType.CUSTOMER
}

export interface DashboardQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}

export interface NotificationTargetQuery extends PaginationWithSearchTerm {
  tenantID?: {
    id: string
    entityType: EntityType.TENANT
  }
}
