import { DeviceInfoID } from './id/device-info-id'

export enum DeviceStatus {
  UNKNOWN = 'unknown',
  ERROR = 'error',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum FilterPolicy {
  UNKNOWN = 'unknown',
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export interface MessageFilterPolicy {
  policy: FilterPolicy
  topics: string[]
}

export interface MessageFilter {
  publish: MessageFilterPolicy
  subscribe: MessageFilterPolicy
}

export interface DeviceInfo {
  id: DeviceInfoID
  name: string
  description?: string
  hubId: string
  status: DeviceStatus
  lastActivityAt: string
  isConnected: boolean
  allowInsecure: boolean
  allowMultipleConnections: boolean
  messageFilters: MessageFilter
  has_custom_certificate: boolean
  created_at: Date
  updated_at: Date
}
