import React, { useState } from 'react'

import { Form, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArkhaleCloseButton from '../../../common/ArkhaleCloseButton'
import RecentSearch from './RecentSearch'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../store/store'
import { addRecentSearchTerm } from '../../../../store/slices/recent-search'

export interface GeneralSearchForm {
  searchInputValue: string
}

const SearchBox = () => {
  const appDispatch = useAppDispatch()
  // Declare form
  const form = useForm<GeneralSearchForm>()
  const watchSearchInputValue = form.watch('searchInputValue')
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const onSubmitSearch = (data: GeneralSearchForm) => {
    if (data.searchInputValue && data.searchInputValue.length > 3) {
      appDispatch(addRecentSearchTerm(data.searchInputValue))
    }
  }

  return (
    <Dropdown className="search-box" show={dropdownOpen}>
      <FormProvider {...form}>
        <Form
          className="position-relative"
          noValidate
          onSubmit={form.handleSubmit(onSubmitSearch)}
        >
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            {...form.register('searchInputValue', {
              required: true,
              onBlur: (event) => {
                setDropdownOpen(false)
              },
            })}
            onFocus={() => setDropdownOpen(true)}
          />
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {(dropdownOpen || watchSearchInputValue) && (
            <div className="search-box-close-btn-container">
              <ArkhaleCloseButton
                size="sm"
                noOutline
                onClick={() => {
                  form.setValue('searchInputValue', '')
                  setDropdownOpen(false)
                }}
              />
            </div>
          )}
        </Form>
        <Dropdown.Menu>
          <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
            <RecentSearch />
          </div>
        </Dropdown.Menu>
      </FormProvider>
    </Dropdown>
  )
}

export default SearchBox
