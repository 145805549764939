import React from 'react'
import ContentBlock from '../../../../components/common/component-card/ContentBlock'
import Flex from '../../../../components/common/Flex'
import IconButton from '../../../../components/common/IconButton'
import { useNavigate } from 'react-router-dom'

interface RuleChainHeaderProps {
  title?: string
  onSave: () => void
}

const RuleChainHeader = ({ title, onSave }: RuleChainHeaderProps) => {
  const navigate = useNavigate()
  const navigateBack = () => {
    navigate(-1)
  }
  return (
    <ContentBlock>
      <Flex
        direction={'row'}
        justifyContent={'between'}
        alignContent={'flex-end'}
        breakpoint={'sm'}
        inline={true}
        className={'m-2'}
      >
        <div className={'px-2'}>
          <h4 className={'my-0 text-primary'}>{title}</h4>
        </div>
        <Flex justifyContent={'center'}>
          <IconButton
            iconClassName={'cancel'}
            variant={'outline-danger'}
            title={'Cancel'}
            iconAlign="left"
            className={'me-2'}
            size={'sm'}
            onClick={navigateBack}
          >
            <span className={'d-none d-lg-inline'}>Annuler</span>
          </IconButton>
          <IconButton
            iconClassName={'save'}
            variant={'outline-primary'}
            title={'Sauvegarder'}
            iconAlign="left"
            onClick={onSave}
            size={'sm'}
          >
            <span className={'d-none d-lg-inline'}>Sauvegarder</span>
          </IconButton>
        </Flex>
      </Flex>
    </ContentBlock>
  )
}

export default RuleChainHeader
