import React from 'react'
import {
  FormProvider,
  useFormContext,
  UseFormReturn,
  useWatch,
} from 'react-hook-form'
import {
  NotificationTarget,
  NotificationTargetConfigType,
  NotificationTargetType,
} from '../../../shared/models/notification.model'
import { Form } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import Divider from '../../../components/common/Divider'

interface Props {
  form: UseFormReturn<NotificationTarget>
}

const NotificationTargetForm = ({ form }: Props) => {
  const { control, register } = form
  const configurationType = useWatch({
    control,
    name: 'configuration.type',
    defaultValue: NotificationTargetType.USERS,
  })
  const getSpecificForm = () => {
    switch (configurationType) {
      case NotificationTargetType.USERS:
        return <NotificationTargetUserForm />
    }
  }
  return (
    <FormProvider {...form}>
      <Form>
        <div>
          <Form.Label>Type de destinataire :</Form.Label>
          <Form.Group>
            <Input
              register={register('configuration.type')}
              inline={true}
              type={'radio'}
              label={'Utilisateurs de la plateforme'}
              value={NotificationTargetType.USERS}
            />
            <Input
              register={register('configuration.type')}
              type={'radio'}
              inline={true}
              value={NotificationTargetType.SLACK}
              isDisabled={true}
              label={'Slack'}
            />
            <Input
              register={register('configuration.type')}
              type={'radio'}
              inline={true}
              isDisabled={true}
              label={'Microsoft Teams'}
              value={NotificationTargetType.MICROSOFT_TEAMS}
            />
          </Form.Group>
        </div>
        <Divider />
        <Form.Group>{getSpecificForm()}</Form.Group>
      </Form>
    </FormProvider>
  )
}

const NotificationTargetUserForm = () => {
  const form = useFormContext<NotificationTarget>()
  const userFilterType = useWatch({
    control: form.control,
    name: 'configuration.usersFilter.type',
    defaultValue: NotificationTargetConfigType.ALL_USERS,
  })
  return (
    <>
      <Form.Group>
        <Input
          register={form.register('configuration.usersFilter.type')}
          type={'select'}
          options={[
            {
              value: NotificationTargetConfigType.ALL_USERS,
              text: 'Tous les utilisateurs',
            },
            {
              value: NotificationTargetConfigType.AFFECTED_USER,
              text: 'Utilisateurs affectés',
            },
            {
              value: NotificationTargetConfigType.TENANT_ADMINISTRATORS,
              text: 'Administrateurs des tenants',
            },
            {
              value: NotificationTargetConfigType.ORIGINATOR_ENTITY_OWNER_USERS,
              text: "Utilisateur du propriétaire de l'entité",
            },
            {
              value:
                NotificationTargetConfigType.AFFECTED_TENANT_ADMINISTRATORS,
              text: 'Administrateurs des tenants affectés',
            },
            {
              value: NotificationTargetConfigType.CUSTOMER_USERS,
              text: "Utilisateurs d'un client",
            },
            {
              value: NotificationTargetConfigType.USER_LIST,
              text: "Liste d'utilisateurs",
            },
            {
              value: NotificationTargetConfigType.USER_GROUP_LIST,
              text: "Liste de groupe d'utilisateur",
            },
            {
              value: NotificationTargetConfigType.SYSTEM_ADMINISTRATORS,
              text: 'Administrateurs système',
            },
          ]}
        />
        {userFilterType === NotificationTargetConfigType.USER_LIST && (
          <UserListInput />
        )}
        {userFilterType === NotificationTargetConfigType.CUSTOMER_USERS && (
          <CustomerUserInput />
        )}
      </Form.Group>
    </>
  )
}

const UserListInput = () => {
  const form = useFormContext<NotificationTarget>()
  return <>USER_LIST</>
}

const CustomerUserInput = () => {
  const form = useFormContext<NotificationTarget>()
  return <>CUSTOMER USER INPUT</>
}

export default NotificationTargetForm
