import React from 'react'
import KopiliotListing from '../../components/kopiliot-listing/KopiliotListing'
import { KopiliotListingProvider } from '../../components/kopiliot-listing/context'

const NotificationInbox = () => {
  return (
    <KopiliotListingProvider>
      {/*<KopiliotListing header={{ title: <h4>Test</h4> }} />*/}
    </KopiliotListingProvider>
  )
}

export default NotificationInbox
