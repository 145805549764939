import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Placement } from 'react-bootstrap/types'

interface VerifiedBadgeProps {
  placement?: Placement
}

const VerifiedBadge = ({ placement = 'top' }: VerifiedBadgeProps) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>Activé</Tooltip>}>
      <span>
        <FontAwesomeIcon
          icon="check-circle"
          transform="shrink-4 down-2"
          className="text-primary me-1"
        />
      </span>
    </OverlayTrigger>
  )
}

export default VerifiedBadge
