import { ActionRowProps } from '../../components/kopiliot-list/type'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TsValue } from '../../shared/models/telemetry/telemetry.model'
import dayjs from 'dayjs'

const getColumnsForTableLatestTelemetries = (actionRows: ActionRowProps[]) => {
  const columnHelper = createColumnHelper<{ key: string } & TsValue>()
  const columns: ColumnDef<{ key: string } & TsValue, string>[] = [
    columnHelper.accessor((row) => row.key, {
      id: 'key',
      cell: (info) => info.getValue(),
      header: () => 'Clé',
    }),
    columnHelper.display({
      id: 'ts',
      header: () => 'Date',
      enableSorting: true,
      cell: (props) => {
        const { ts } = props.row.original

        return <span>{dayjs(ts).format('DD/MM/YYYY HH:mm:ss.SSS')}</span>
      },
    }),
    columnHelper.display({
      id: 'value',
      header: () => 'Valeur',
      enableSorting: true,
      cell: (props) => {
        let value = props.row.original.value
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        return <span>{value.toString()}</span>
      },
    }),
  ]
  return columns
}

export { getColumnsForTableLatestTelemetries }
