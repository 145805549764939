import React from 'react'
import { useAppSelector } from '../../store/store'
import { Outlet } from 'react-router-dom'

const HomeWrapper = () => {
  const currentUser = useAppSelector((state) => state.currentUser)

  return (
    <Outlet
      context={{
        tenantIDParam: currentUser.tenantID,
        customerIDParam: currentUser.customerID,
      }}
    />
  )
}

export default HomeWrapper
