import React, { PropsWithChildren } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Background, { BackgroundProps } from '../../components/common/Background'
import logoKopiliotWithText from '../../assets/img/illustrations/logo-kopiliot-with-text-light.png'

interface SplitPageLayoutProps extends PropsWithChildren {
  bgProps: BackgroundProps
}
const SplitPageLayout = ({ children, bgProps }: SplitPageLayoutProps) => {
  return (
    <Row className="min-vh-100 bg-100">
      <Col xs={6} className="d-none d-lg-block position-relative">
        {bgProps && <Background {...bgProps} />}
      </Col>
      <Col sm={10} md={6} className="px-sm-0 align-self-center mx-auto py-5">
        <Row className="g-0 justify-content-center">
          <Col lg={9} xl={8} className="col-xxl-6">
            <Card className={'shadow-none bg-light'}>
              <Card.Header className="text-center p-2">
                <img src={logoKopiliotWithText} alt={''} className={'mw-100'} />
              </Card.Header>
              <Card.Body className="p-4">{children}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SplitPageLayout
