import React from 'react'
import { FieldError, FieldErrors, useFormContext } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import { ContactBased } from '../../../shared/models/contact-based'
import Input from '../../../components/form/Input'

interface ContactBasedFormProps {
  isLoading?: boolean
  errors: FieldErrors<ContactBased<any>>
}

const ContactBasedForm = ({ isLoading, errors }: ContactBasedFormProps) => {
  const { register } = useFormContext<ContactBased<any>>()
  return (
    <>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Form.Group as={Col} md={'12'}>
          <Input
            label={'Adresse'}
            register={register('address', {
              required: 'Address field is required',
            })}
            error={errors.address}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={'12'}>
          <Input
            label={'Adresse complément'}
            register={register('additionalAddressDetails')}
            error={errors.additionalAddressDetails}
          />
        </Form.Group>
      </Row>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Col md={'6'}>
          <Input
            label={'Code postal'}
            register={register('zip', { required: 'Zip field is required' })}
            error={errors.zip}
          />
        </Col>
        <Col md={'6'}>
          <Input
            label={'Ville'}
            register={register('city', { required: 'City field is required' })}
            error={errors.city}
          />
        </Col>
      </Row>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Col md={'6'}>
          <Input
            label={'Région'}
            register={register('state', {
              required: 'State field is required',
            })}
            error={errors.state}
          />
        </Col>
        <Col md={'6'}>
          <Input
            label={'Pays'}
            register={register('country', {
              required: 'Country field is required',
            })}
            error={errors.country}
          />
        </Col>
      </Row>
      <Row className={'gx-3 gy-2 align-items-center'}>
        <Col md={'6'}>
          <Input
            label={'Téléphone'}
            register={register('phone', {
              required: 'Phone field is required',
            })}
            error={errors.phone}
          />
        </Col>
        <Col md={'6'}>
          <Input
            type={'email'}
            label={'Email'}
            register={register('email', {
              required: 'Email field is required',
            })}
            error={errors.email}
          />
        </Col>
      </Row>
    </>
  )
}

export default ContactBasedForm
