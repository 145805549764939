import React from 'react'
import LabelHelp from '../../../../components/form/LabelHelp'
import { Controller, useFormContext } from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import { TimeWindow } from '../../../../shared/models/time.model'
import dayjs from 'dayjs'

registerLocale('fr', fr)

const days = ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di']
const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

const FixedTimeWindowSelector = () => {
  const { control, watch, getValues, setValue } = useFormContext<TimeWindow>()
  const { history } = watch()
  React.useEffect(() => {
    if (
      history?.fixedTimeWindow?.startTimeMs != undefined &&
      history?.fixedTimeWindow?.endTimeMs != undefined
    ) {
      const timeWindowMs =
        history?.fixedTimeWindow?.endTimeMs -
        history?.fixedTimeWindow?.startTimeMs
      if (timeWindowMs > 0) {
        setValue('history.timeWindowsMs', timeWindowMs)
      }
    }
  }, [
    history?.fixedTimeWindow?.startTimeMs,
    history?.fixedTimeWindow?.endTimeMs,
  ])
  return (
    <>
      <LabelHelp label={'De'} help={'Date de début'} className={'text-body'} />
      <Controller
        control={control}
        name={'history.fixedTimeWindow.startTimeMs'}
        render={({ field }) => {
          return (
            <DatePicker
              selected={
                history?.fixedTimeWindow?.startTimeMs
                  ? dayjs(history.fixedTimeWindow.startTimeMs).toDate()
                  : dayjs().subtract(1, 'day').toDate()
              }
              onChange={(date) => {
                if (date) {
                  let ts = date.setMilliseconds(0)
                  ts = date.setSeconds(0)
                  field.onChange(ts)
                }
              }}
              //formatWeekDay={(day) => day.slice(0, 3)}
              className="form-control"
              placeholderText="Sélectionner la date et l'heure de début"
              timeIntervals={5}
              locale={'fr'}
              dateFormat="dd/MM/yyyy hh:mm"
              showTimeSelect
              fixedHeight
            />
          )
        }}
      />
      <LabelHelp label={'A'} help={'Date de fin'} className={'text-light'} />
      <Controller
        control={control}
        name={'history.fixedTimeWindow.endTimeMs'}
        render={({ field }) => {
          return (
            <DatePicker
              selected={
                history?.fixedTimeWindow?.endTimeMs
                  ? dayjs(history.fixedTimeWindow.endTimeMs).toDate()
                  : dayjs().toDate()
              }
              onChange={(date) => {
                if (date) {
                  let ts = date.setSeconds(0)
                  field.onChange(ts)
                }
              }}
              //formatWeekDay={(day) => day.slice(0, 3)}
              className="form-control"
              placeholderText="Sélectionner la date et l'heure de fin"
              timeIntervals={5}
              dateFormat="dd/MM/yyyy hh:mm"
              showTimeSelect
              fixedHeight
            />
          )
        }}
      />
    </>
  )
}

export default FixedTimeWindowSelector
