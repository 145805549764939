import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export interface AddEntityButtonProps {
  onClick?: () => void
  link?: string
}

const AddEntityButton = ({ onClick, link }: AddEntityButtonProps) => {
  if (!onClick && !link) {
    return null
  } else if (link) {
    return (
      <Link to={link}>
        <Button variant={'outline-primary'} size={'sm'} className={'me-2'}>
          <FontAwesomeIcon icon={'plus'} className="fs-0" />
        </Button>
      </Link>
    )
  }
  return (
    <Button
      variant={'outline-primary'}
      size={'sm'}
      className={'me-2'}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={'plus'} className="fs-0" />
    </Button>
  )
}

export default AddEntityButton
