import React from 'react'
import { Button, ButtonGroup, Col, Form } from 'react-bootstrap'
import Input from '../../../../../components/form/Input'
import IconButton from '../../../../../components/common/IconButton'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { GaugeDataKeySpecificProps } from '../../../../../shared/models/panel'

interface GaugeDataKeySpecificFormProps {
  initialValues?: GaugeDataKeySpecificProps
  onSubmitted?: (data: any) => void
}

const GaugeDataKeySpecificForm = ({
  initialValues,
  onSubmitted,
}: GaugeDataKeySpecificFormProps) => {
  const form = useFormContext<GaugeDataKeySpecificProps>()
  const {
    reset,
    watch,
    register,
    trigger,
    formState: { errors },
  } = form
  const { fields, append, remove, swap } =
    useFieldArray<GaugeDataKeySpecificProps>({
      control: form.control,
      name: `arc.subArcs`,
    })
  React.useEffect(() => {
    if (initialValues) {
      form.reset(initialValues)
    }
  }, [initialValues])

  const { type, min, max, arc } = watch()
  const onSubmit = async () => {
    const result = await trigger()
    if (onSubmitted && result) {
      await form.handleSubmit(onSubmitted)()
    }
  }
  return (
    <Form onSubmit={onSubmitted ? form.handleSubmit(onSubmitted) : undefined}>
      <div className="form-group">
        <label>Min</label>
        <Input
          type="numeric"
          className="form-control"
          error={errors?.min}
          register={register('min', {
            required: 'Le minimum est requis',
            valueAsNumber: true,
            validate: {
              lowerMax: (value) => {
                if (value != undefined && max != undefined && value > max) {
                  return 'Le minimum doit être inférieur au maximum'
                }
                return true
              },
            },
          })}
        />
      </div>
      <div className="form-group">
        <label>Max</label>
        <Input
          type="numeric"
          className="form-control"
          error={errors?.max}
          register={register('max', {
            required: 'Le maximum est requis',
            valueAsNumber: true,
            validate: {
              upperMin: (value) => {
                if (value != undefined && min != undefined && value < min) {
                  return 'Le maximum doit être supérieur au minimum'
                }
                return true
              },
            },
          })}
        />
      </div>
      <div className="form-group">
        <label>Unit</label>
        <Form.Select {...form.register('type')}>
          <option value="radial">Radiale</option>
          <option value="grafana">Advancée</option>
          <option value="semicircle">Demi-cercle</option>
        </Form.Select>
      </div>
      <Form.Group>
        <Form.Label>Sub Arcs</Form.Label>

        {fields.map((field, index) => {
          return (
            <div key={field.id} className="input-group mb-3">
              <Form.Group as={Col} md={2}>
                <Form.Label>Color</Form.Label>
                <Input
                  type={'color'}
                  register={form.register(`arc.subArcs.${index}.color`, {
                    required: 'La couleur est requise',
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label>Limit</Form.Label>
                <Input
                  type={'numeric'}
                  error={form.formState.errors?.arc?.subArcs?.[index]?.limit}
                  register={form.register(`arc.subArcs.${index}.limit`, {
                    required: 'La limite est requise',
                    valueAsNumber: true,
                    validate: {
                      upperMin: (value) => {
                        if (
                          value != undefined &&
                          min != undefined &&
                          value >= min
                        ) {
                          return true
                        }
                        return 'La limite doit être supérieure au minimum'
                      },
                      lowerMax: (value) => {
                        if (
                          value != undefined &&
                          max != undefined &&
                          value <= max
                        ) {
                          return true
                        }
                        return 'La limite doit être inférieure au maximum'
                      },
                    },
                  })}
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label>Label</Form.Label>
                <div>
                  <ButtonGroup>
                    <IconButton
                      variant={'outline-secondary'}
                      iconClassName="chevron-up"
                      type="button"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0) {
                          swap(index - 1, index)
                        }
                      }}
                    />
                    <IconButton
                      variant={'outline-secondary'}
                      iconClassName="chevron-down"
                      type="button"
                      disabled={index === fields.length - 1}
                      onClick={() => {
                        if (index < fields.length - 1) {
                          swap(index, index + 1)
                        }
                      }}
                    />
                    <Button
                      variant={'outline-danger'}
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Remove
                    </Button>
                  </ButtonGroup>
                </div>
              </Form.Group>
            </div>
          )
        })}
        <div>
          <Button
            type="button"
            variant={'outline-primary'}
            onClick={() =>
              append({
                color: '',
                limit: 0,
              })
            }
          >
            Ajouter
          </Button>
        </div>
      </Form.Group>
    </Form>
  )
}

export default GaugeDataKeySpecificForm
