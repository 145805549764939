import React from 'react'
import { Chart as ChartJS, ArcElement, Plugin, ChartDataset } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import _, { values } from 'lodash'
import { formatValue } from '../../../../../utils/utils'

ChartJS.register(ArcElement)

interface CustomChartDataset extends ChartDataset<'doughnut'> {
  data: number[]
  needleValue?: number
  maxValue?: number
  minValue?: number
  valueAsPercent?: boolean
  units?: string
  label?: string
}

export interface CustomGaugeComponentProps {
  value?: number
  minValue?: number
  maxValue?: number
  type?: 'semicircle' | 'radial' | 'grafana' | undefined
  arc?: { subArcs: { limit?: number; color?: string }[] }
  units?: string
  title?: string
}

export interface GaugeProps {
  width: number
  height: number
  value?: CustomGaugeComponentProps
}

const Gauge = ({ width, height, value }: GaugeProps): JSX.Element | null => {
  const [gaugeColor, setGaugeColor] = React.useState('black')

  React.useMemo(() => {
    const concreteValue = value?.value ?? 0
    if (concreteValue) {
      const finalColor = _.chain(value?.arc?.subArcs)
        .filter((c) => c.limit !== undefined && c.color !== undefined)
        .orderBy('limit', 'asc')
        .filter((c) => c.limit! >= concreteValue)
        .head()
        .get('color')
        .value()

      setGaugeColor(finalColor ?? 'black')
    }
  }, [value?.value, value?.arc?.subArcs])

  const gaugeValue: Plugin<'doughnut', CustomChartDataset> = {
    id: 'gaugeValue',
    afterDatasetDraw(chart, args, options) {
      const { ctx, chartArea } = chart
      const dataset = chart.data.datasets![0] as CustomChartDataset

      ctx.save()

      const needleValue = dataset.needleValue
      const element = chart.getDatasetMeta(1).data[0] as ArcElement

      if (element) {
        const xCenter = element.x
        const yCenter = element.y

        const circumference =
          (element.circumference / Math.PI / dataset.data[0]) * needleValue!
        let value = null
        if (dataset.valueAsPercent) {
          value = circumference * 100 + '%'
        } else {
          value = `${formatValue(needleValue, 2, dataset.units, false)}`
        }
        ctx.font = 'bold 15px sans-serif'
        ctx.fillStyle = gaugeColor
        ctx.textAlign = 'center'
        ctx.fillText(`${value}`, xCenter, yCenter)
      }
    },
  }
  const gaugeLabel: Plugin<'doughnut', CustomChartDataset> = {
    id: 'gaugeLabel',
    afterDatasetDraw(chart, args, options) {
      const { ctx, chartArea } = chart
      const dataset = chart.data.datasets![0] as CustomChartDataset

      ctx.save()
      const element = chart.getDatasetMeta(1).data[0] as ArcElement

      if (element) {
        const xCenter = element.x
        const yCenter = element.y + 45

        ctx.font = 'bold 15px sans-serif'
        ctx.fillStyle = 'grey'
        ctx.textAlign = 'center'
        ctx.fillText(dataset.label ?? '', xCenter, yCenter)
      }
    },
  }

  const data: CustomChartDataset = {
    data: _.map(value?.arc?.subArcs, (curr, index, array) => {
      const prev = index > 0 ? array[index - 1].limit : 0
      return curr.limit! - prev!
    }),
    backgroundColor: _.map(value?.arc?.subArcs, (v) => {
      return v.color?.toString()
    }),
    borderColor: _.map(value?.arc?.subArcs, (v) => {
      //return v.color?.toString()
      return 'transparent'
    }),
    borderRadius: 0,
    weight: 0.2,
    spacing: 0,

    needleValue: value?.value,
    // Removed for graphana style
    /*circumference: 180,
    rotation: 270,*/
    circumference: 240,
    rotation: 240,
    maxValue: 100,
    units: value?.units,
    label: value?.title,
  }
  if (value) {
    if (value.value && value.maxValue && value.value > value.maxValue) {
      return <>Out of range</>
    }
    if (value.value && value.minValue && value.value < value.minValue) {
      return <>Out of range</>
    }
    if (value.arc?.subArcs && value.arc?.subArcs.length <= 0) {
      return <>No arc defined</>
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        alignItems: 'center',
      }}
      className={''}
    >
      <Doughnut
        data={{
          datasets: [
            data,
            {
              data: [value?.maxValue],
              borderColor: ['red', 'transparent'],
              circumference: 240,
              borderWidth: 0,
              weight: 0.1,
              rotation: 240,
              spacing: 0,
            },
            {
              data: [
                value?.value ?? 0,
                (value?.maxValue ?? 0) - (value?.value ?? 0),
              ],
              backgroundColor: [gaugeColor, 'transparent'],
              borderColor: ['red', 'transparent'],
              circumference: 240,
              borderWidth: 0,
              rotation: 240,
              spacing: 0,
            },
          ],
        }}
        options={{
          layout: {
            padding: {
              bottom: 50,
            },
          },
          borderColor: 'transparent',
          responsive: true, // Added
          maintainAspectRatio: false, // Added
          //aspectRatio: 1, // Removed
          //cutout: '80%',
          animation: true,
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
        plugins={[gaugeValue, gaugeLabel]}
      />
    </div>
  )
}

export default Gauge
