import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonGroup, DropdownButton } from 'react-bootstrap'
import { TimeWindow } from '../../../../shared/models/time.model'
import TimeWindowForm from './TimeWindowForm'
import './TimeWindowForm.css'
import classNames from 'classnames'

interface Props {
  isEditing?: boolean
  defaultTimeWindow: TimeWindow
  onSubmitTimeWindow: (data: TimeWindow) => void
  className?: string
}

const TimeWindowDropdown = ({
  isEditing,
  defaultTimeWindow,
  className,
  onSubmitTimeWindow,
}: Props) => {
  const [openPicker, setOpenPicker] = React.useState(false)
  const handleToggle = (isOpen: boolean, metadata: { source?: string }) => {
    if (metadata.source === 'select') {
      setOpenPicker(true)
    } else {
      setOpenPicker(isOpen)
    }
  }
  const onSubmit = (data: TimeWindow) => {
    onSubmitTimeWindow(data)
    setOpenPicker(false)
  }
  return (
    <DropdownButton
      className={classNames('d-inline-block', className)}
      title={<FontAwesomeIcon icon={['far', 'clock']} />}
      size={'sm'}
      as={ButtonGroup}
      show={openPicker}
      autoClose={'outside'}
      onToggle={handleToggle}
      variant="outline-primary"
    >
      <TimeWindowForm
        onSubmit={onSubmit}
        className={'time-window-form'}
        isEditing={isEditing}
        defaultTimeWindow={defaultTimeWindow}
      />
    </DropdownButton>
  )
}

export default TimeWindowDropdown
