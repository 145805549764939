import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import ButtonLoading from '../../components/button/ButtonLoading'
import {
  useAcknowledgeAlarmMutation,
  useClearAlarmMutation,
} from '../../store/kopiliot-api/alarm'
import { Alarm, AlarmStatus } from '../../shared/models/alarm.model'
import { toast } from 'react-toastify'

interface Props {
  alarm?: Alarm
}

const CanvasAlarmHeader = ({ alarm }: Props) => {
  const [clearAlarm, dataClearAlarm] = useClearAlarmMutation()
  const [acknowledgeAlarm, dataAcknowledgeAlarm] = useAcknowledgeAlarmMutation()
  const clearAlarmHandler = async () => {
    if (alarm && alarm.id) {
      try {
        await clearAlarm(alarm.id).unwrap()
        toast.success('Alarme effacée')
      } catch (e) {
        toast.error("Impossible d'effacer l'alarme")
      }
    } else {
      toast.error("Impossible de prendre en compte l'alarme")
    }
  }
  const acknowledgeAlarmHandler = async () => {
    if (alarm && alarm.id) {
      try {
        await acknowledgeAlarm(alarm.id).unwrap()
        toast.success('Alarme prise en compte')
      } catch (e) {
        toast.error("Impossible de prendre en compte l'alarme")
      }
    } else {
      toast.error("Impossible de prendre en compte l'alarme")
    }
  }
  return (
    <Offcanvas.Header closeButton>
      <Offcanvas.Title className={'w-100'}>
        <div
          className={
            'd-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center'
          }
        >
          <h4 className={'text-primary'}>Détails de l'alarme</h4>
          <div>
            <ButtonLoading
              variant="outline-warning"
              size="sm"
              className={'mx-2'}
              isLoading={dataAcknowledgeAlarm.isLoading}
              disabled={
                dataAcknowledgeAlarm.isLoading ||
                alarm?.status === AlarmStatus.CLEARED_ACK ||
                alarm?.status === AlarmStatus.ACTIVE_ACK
              }
              text={'Prise en compte'}
              onClick={acknowledgeAlarmHandler}
            />
            <ButtonLoading
              variant="outline-success"
              size="sm"
              className={'mx-2'}
              isLoading={dataClearAlarm.isLoading}
              text={'Effacer'}
              disabled={
                dataClearAlarm.isLoading ||
                alarm?.status === AlarmStatus.CLEARED_ACK ||
                alarm?.status === AlarmStatus.CLEARED_UNACK
              }
              onClick={clearAlarmHandler}
            />
          </div>
        </div>
      </Offcanvas.Title>
    </Offcanvas.Header>
  )
}

export default CanvasAlarmHeader
