import React, { PropsWithChildren } from 'react'
import { Card } from 'react-bootstrap'

interface ContentBlocFooterProps extends PropsWithChildren {
  className?: string
}

const ContentBlocFooter = ({ children, className }: ContentBlocFooterProps) => {
  return <Card.Footer className={className}>{children}</Card.Footer>
}

export default ContentBlocFooter
