import React from 'react'
import { TimeseriesData } from '../../../../../../shared/models/telemetry/telemetry.model'
import { DataSource } from '../../../../../../shared/models/panel'
import { TimeWindow } from '../../../../../../shared/models/time.model'
import { Button, ButtonGroup, Table } from 'react-bootstrap'
import TimeSeriesTableHeader from './TimeSeriesTableHeader'
import getColumns, { TimeSeriesTableType } from './columns'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import Flex from '../../../../../../components/common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TimeSeriesTableWrapperProps {
  timeSeriesData?: { datasource: DataSource; data: TimeseriesData }
  timeWindow?: TimeWindow
  options: any
}

const TimeSeriesTableWrapper = ({
  timeSeriesData,
  timeWindow,
  options,
}: TimeSeriesTableWrapperProps) => {
  const columns = React.useMemo(() => {
    return getColumns(timeSeriesData?.datasource.dataKeys)
  }, [timeSeriesData])
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const data = React.useMemo(() => {
    const d: TimeSeriesTableType[] = []
    if (timeSeriesData) {
      return _.chain(timeSeriesData.data)
        .flatMap((row, key) => {
          return _.map(row, (value, ts) => {
            return { ts: value.ts, value: value.value, key }
          })
        })
        .groupBy('ts')
        .map((values, ts) => {
          return {
            ts: parseInt(ts),
            ..._.mapValues(_.keyBy(values, 'key'), 'value'),
          }
        })
        .value()
    }
    return d
  }, [timeSeriesData?.data])
  const table = useReactTable<TimeSeriesTableType>({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  })
  return (
    <div>
      <Table striped={true} hover>
        <TimeSeriesTableHeader columns={table.getHeaderGroups()} />
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={'text-800'}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={'text-800'}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Flex direction={'row'} justifyContent={'between'}>
        <Flex direction={'row'} alignItems={'center'}>
          <div>Page</div>
          {table.getState().pagination.pageIndex + 1} sur{' '}
          {table.getPageCount().toLocaleString()}
        </Flex>
        <Flex direction={'row'}>
          <ButtonGroup className="me-2">
            <Button
              variant="outline-primary"
              onClick={() => {
                setPagination({ ...pagination, pageIndex: 0 })
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon={'angle-double-left'} />
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                table.previousPage()
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <FontAwesomeIcon icon={'angle-left'} />
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                table.nextPage()
              }}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon={'angle-right'} />
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                //table.lastPage()
              }}
              disabled={!table.getCanNextPage()}
            >
              <FontAwesomeIcon icon={'angle-double-right'} />
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </div>
  )
}

export default TimeSeriesTableWrapper
