import React, { PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, ButtonProps } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
interface IconButtonProps extends PropsWithChildren {
  icon?: IconProp
  iconAlign?: string
  iconClassName: IconProp
  transform?: string
}

const IconButton = ({
  icon,
  iconAlign = 'left',
  iconClassName,
  transform,
  children,
  ...rest
}: IconButtonProps & ButtonProps) => (
  <Button {...rest}>
    {iconAlign === 'right' && children}
    <FontAwesomeIcon
      icon={iconClassName}
      className={classNames({
        'me-1': children && iconAlign === 'left',
        'ms-1': children && iconAlign === 'right',
      })}
      transform={transform}
    />
    {iconAlign === 'left' || iconAlign === 'middle' ? children : false}
  </Button>
)

export default IconButton
