import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Button, Modal } from 'react-bootstrap'
import ButtonLoading from '../../../components/button/ButtonLoading'
import { DeviceProfile } from '../../../shared/models/device-profile'
import { useAddDeviceProfileMutation } from '../../../store/kopiliot-api/device-profile'
import DeviceProfileForm from '../form/DeviceProfileForm'

interface Props {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
  defaultValues?: Partial<DeviceProfile>
  hideFields?: Array<keyof DeviceProfile>
}

const AddDeviceProfileModal = ({
  isOpen,
  setIsOpen,
  defaultValues,
  hideFields,
}: Props) => {
  const form = useForm<DeviceProfile>({ defaultValues: defaultValues })
  const [addDeviceProfile, { isLoading, error }] = useAddDeviceProfileMutation()
  const handleClose = () => {
    setIsOpen(!isOpen)
  }
  const onSubmit = async (data: DeviceProfile) => {
    try {
      await addDeviceProfile(data).unwrap()
      form.reset()
      handleClose()
      toast.success('Device profile ajouté avec succès !')
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size={'xl'}
      className={'border'}
      centered
    >
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title as="h5">Créer un Device Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'p-2'}>
        <DeviceProfileForm
          form={form}
          onSubmit={onSubmit}
          hideFields={hideFields}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type={'button'}
          variant={'outline-secondary'}
          onClick={handleClose}
        >
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          variant={'outline-primary'}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddDeviceProfileModal
