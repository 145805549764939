import { EntityType } from '../../shared/models/entity-type.models'
import { useLazyGetTenantCustomersQuery } from '../../store/kopiliot-api/tenant'
import { useLazyGetCustomersQuery } from '../../store/kopiliot-api/customer'
import { CustomerQuery } from '../../store/kopiliot-api/query-type'

export const useFetchCustomers = (tenantIDParam?: string) => {
  const [getCustomers, resultGetCustomer] = useLazyGetCustomersQuery()
  const [getTenantCustomers, resultGetTenantCustomer] =
    useLazyGetTenantCustomersQuery()

  const fetchCustomers = (query: CustomerQuery, useCache?: boolean) => {
    if (tenantIDParam) {
      getTenantCustomers(
        {
          ...query,
          tenantID: { id: tenantIDParam, entityType: EntityType.TENANT },
        },
        useCache
      )
    } else {
      getCustomers(
        {
          ...query,
          tenantID: tenantIDParam
            ? { id: tenantIDParam, entityType: EntityType.TENANT }
            : query.tenantID,
        },
        useCache
      )
    }
  }
  const getData = () => {
    if (tenantIDParam) {
      return resultGetTenantCustomer.data
    } else {
      return resultGetCustomer.data
    }
  }
  const isLoading = tenantIDParam
    ? resultGetTenantCustomer.isLoading
    : resultGetCustomer.isLoading

  return {
    fetchCustomers,
    getData,
    isLoading,
  }
}
