import React from 'react'
import TimeWindowDropdown from '../../form/timeWindow/TimeWindowDropdown'
import {
  defaultTimeWindow,
  TimeWindow,
} from '../../../../shared/models/time.model'

interface Props {
  onSubmit: (data: TimeWindow) => void
  timeWindow: TimeWindow
}

const PanelTimeWindowForm = ({ onSubmit, timeWindow }: Props) => {
  return (
    <div>
      <TimeWindowDropdown
        defaultTimeWindow={timeWindow}
        onSubmitTimeWindow={onSubmit}
      />
    </div>
  )
}

export default PanelTimeWindowForm
