import React from 'react'
import { Link } from 'react-router-dom'
import Flex from '../common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav } from 'react-bootstrap'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'

interface Props {
  link?: string
  icon?: IconProp
  text?: string
}

const TileLink = ({ link = '#', icon = 'user', text }: Props) => {
  const [mouseOver, setMouseOver] = React.useState(false)
  return (
    <Nav.Item>
      <Link
        to={link}
        className={'nav-link'}
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      >
        <Flex
          className={classNames('bg-primary text-light p-3 h-100', {
            'bg-secondary': mouseOver,
          })}
          justifyContent={'center'}
          direction={'column'}
          alignItems={'center'}
        >
          <FontAwesomeIcon icon={icon} size={'4x'} />
          <p className={'mb-0'}>{text}</p>
        </Flex>
      </Link>
    </Nav.Item>
  )
}

export default TileLink
