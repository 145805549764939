import { createSlice } from '@reduxjs/toolkit'

export interface BreadcrumbSegment {
  text: string
  path: string
}
export interface BreadcrumbState {
  breadcrumb: BreadcrumbSegment[]
}

const initialState: BreadcrumbState = {
  breadcrumb: [],
}

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumb: (state, action) => {
      state.breadcrumb = action.payload
    },
  },
})

export const { setBreadcrumb } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer
