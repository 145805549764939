import React, { useEffect } from 'react'
import { Card, Dropdown, ListGroup } from 'react-bootstrap'
import { v4 as uuidV4 } from 'uuid'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isIterableArray } from '../../../helpers/utls'
import CardHeader from '../../common/card/CardHeader'
import { useSubscribeNotificationsQuery } from '../../../store/kopiliot-api/notification'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { websocketSendQuery } from '../../../store/slices/websocket'
import { useSelector } from 'react-redux'

const KopiliotNotificationCenter = (): JSX.Element => {
  const [notificationQueryId] = React.useState<string>(uuidV4())
  const { isConnected } = useAppSelector((state) => state.websocket)
  // get the number of unread notifications
  const totalUnreadCount = 0
  const [isOpen, setIsOpen] = React.useState(false)
  const [isAllRead, setIsAllRead] = React.useState(false)

  const { data, isLoading, isFetching, error } =
    useSubscribeNotificationsQuery()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isConnected) {
      dispatch(
        websocketSendQuery({
          queryID: notificationQueryId,
          query: 'your_query_here',
        })
      )
    }
  }, [dispatch, isConnected])
  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false)
    })
  }, [])

  const markAsRead = (e: any) => {
    e.preventDefault()

    const updatedNewNotifications = data?.map((notification) =>
      Object.prototype.hasOwnProperty.call(notification, 'unread')
        ? { ...notification, unread: false }
        : notification
    )

    setIsAllRead(true)
  }
  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary':
            totalUnreadCount > 0,
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <CardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Tout marquer comme lu
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            <div className="list-group-title">Nouvelles</div>{' '}
            {isIterableArray(data) &&
              data?.map((notification) => (
                <ListGroup.Item
                  key={notification.id?.id}
                  onClick={handleToggle}
                >
                  {/*<Notification {...notification} flush />*/}
                </ListGroup.Item>
              ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/notification-center/inbox">
              Voir toutes les notifications
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default KopiliotNotificationCenter
