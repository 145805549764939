import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetAssetQuery,
  useUpdateAssetMutation,
} from '../../store/kopiliot-api/asset'
import { useForm } from 'react-hook-form'
import Asset from '../../shared/models/asset'
import Spinner from '../../components/spinner/Spinner'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import AssetForm from '../../features/asset/components/forms/AssetForm'
import { toast } from 'react-toastify'
import { AssetId } from '../../shared/models/id/asset-id'
import RelationListing from '../../features/relation/RelationListing'
import { EntityType } from '../../shared/models/entity-type.models'
import HandleError from '../../utils/HandleError'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import AuditLogHome from '../audit-log/AuditLogHome'

type IdRouteParam = {
  assetID: string
}

const AssetEdit = () => {
  const user = useSelector((state: RootState) => state.currentUser)
  const [key, setKey] = React.useState('main')
  let { assetID } = useParams<IdRouteParam>() as IdRouteParam
  // Fetch Asset data
  const {
    data,
    error,
    isLoading: isLoadingAsset,
    isFetching: isFetchingAsset,
  } = useGetAssetQuery(assetID)
  // Update form with the asset
  React.useEffect(() => {
    if (data) {
      assetForm.reset(data)
    }
  }, [data])
  const [updateAsset, { isLoading: isLoadingUpdate }] = useUpdateAssetMutation()
  const onSubmit = async (data: Asset) => {
    try {
      const res = await updateAsset(data).unwrap()
      toast.success(`${res.name} updated successfully`)
    } catch (error) {
      console.log(error)
    }
  }
  const getHideFields = () => {
    const hideFields: (keyof Asset)[] = ['tenantID']
    if (user.authority === 'CUSTOMER_USER') {
      hideFields.push('customerID')
    }
    return hideFields
  }
  // Form
  const assetForm = useForm<Asset>()
  if (error) {
    return <HandleError error={error} />
  }
  return (
    <>
      {(isFetchingAsset || isLoadingAsset) && <Spinner />}
      <ContentBlock>
        <ContentBlock.Header title={data?.name} icon={'box-open'} />
        <ContentBlock.Body className={'p-0'} noLight={false}>
          <Tabs
            transition={true}
            unmountOnExit={true}
            fill={true}
            activeKey={key}
            onSelect={(k) => setKey(k ? k : 'main')}
          >
            <Tab eventKey="main" title="Main" className="p-3">
              <AssetForm
                isLoading={isLoadingAsset || isFetchingAsset}
                initialValues={data}
                hideFields={getHideFields()}
                form={assetForm}
                onSubmit={onSubmit}
              />
              <Row>
                <Col md={{ span: 2, offset: 10 }}>
                  <Button
                    onClick={assetForm.handleSubmit(onSubmit)}
                    disabled={isLoadingUpdate}
                    type={'submit'}
                  >
                    Valider
                  </Button>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="attributes" title="Attributes" className="p-3"></Tab>
            <Tab
              eventKey="relations"
              title="Relations"
              className="p-3 pe-0 ps-0"
            >
              <RelationListing
                entityID={assetID}
                entityType={EntityType.ASSET}
              />
            </Tab>
            <Tab
              eventKey="audit_logs"
              title="Audit Logs"
              className="p-3 pe-0 ps-0"
            >
              <AuditLogHome entityID={new AssetId(assetID)} />
            </Tab>
          </Tabs>
        </ContentBlock.Body>
      </ContentBlock>
    </>
  )
}

export default AssetEdit
