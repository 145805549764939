import React from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import ListGroupNav from '../../components/navbar/internal/ListGroupNav'
import { TenantCustomerRouteParam } from '../context/tenant-customer.context'
import { useLocation } from 'react-router'
import { useLazyGetCustomerQuery } from '../../store/kopiliot-api/customer'

const AssetHome = () => {
  const { tenantID, customerID } = useParams<TenantCustomerRouteParam>()
  const [getCustomer, resultGetCustomer] = useLazyGetCustomerQuery()
  // Ajoutez un state pour tenantID
  const [currentTenantID, setCurrentTenantID] = React.useState(tenantID)
  React.useEffect(() => {
    if (customerID) {
      getCustomer(customerID)
    }
  }, [customerID])
  React.useEffect(() => {
    if (resultGetCustomer.isSuccess) {
      const customer = resultGetCustomer.data
      if (customer) {
        setCurrentTenantID(customer.tenantId.id)
      }
    }
  }, [resultGetCustomer])
  const location = useLocation()
  const navigate = useNavigate()
  React.useEffect(() => {
    const arrayLocation = location.pathname.split('/').filter((x) => x !== '')
    if (arrayLocation.slice(-1)[0] === 'assets') {
      navigate(`all`)
    }
  }, [location])
  return (
    <>
      <div className={'mb-2'}>
        <ListGroupNav />
      </div>
      {!resultGetCustomer.isLoading && (
        <Outlet
          context={{
            tenantIDParam: currentTenantID ?? tenantID,
            customerIDParam: customerID,
          }}
        />
      )}
    </>
  )
}

export default AssetHome
