import React, { DragEvent } from 'react'
import NodeTemplate from '../nodes/base/NodeTemplate'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { NodeTypes } from '../../../../shared/models/rule-node'

interface SideBarItemProps {
  title: string
  icon?: IconProp
  nodeType: NodeTypes
}

const SideBarItem = ({ icon, nodeType, title }: SideBarItemProps) => {
  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }
  return (
    <div
      className={`dndnode react-flow__node-${nodeType} mt-2 mb-2`}
      onDragStart={(event) => onDragStart(event, nodeType)}
      draggable
    >
      <NodeTemplate title={title} icon={icon} />
    </div>
  )
}

export default SideBarItem
