import { Controller, useFormContext } from 'react-hook-form'
import {
  getIntervals,
  HistoryWindowType,
  maxIntervalLimit,
  minIntervalLimit,
  quickIntervalToTimeWindowMs,
  RealTimeWindowType,
  TimeInterval,
  TimeWindow,
  TimeWindowType,
} from '../../../../shared/models/time.model'
import React from 'react'
import { SelectableValue } from '../../../../components/page-components/selects-entities/generic-select/selectable-value'
import LabelHelp from '../../../../components/form/LabelHelp'
import SelectBase from '../../../../components/page-components/selects-entities/generic-select/SelectBase'

interface Props {
  options: SelectableValue<number>[]
  onChange: (value: number | null) => void
  value?: number | null
}

const GroupingIntervalSelect = ({ options, onChange, value }: Props) => {
  const [selectedValue, setSelectedValue] =
    React.useState<SelectableValue<number> | null>(null)
  React.useEffect(() => {
    setSelectedValue(
      options.find((o) => o.value === value) ?? options[0] ?? null
    )
    onChange(selectedValue?.value ?? null)
  }, [options, value])
  React.useMemo(() => {
    setSelectedValue(
      options.find((o) => o.value === value) ?? options[0] ?? null
    )
  }, [value])
  // test
  return (
    <SelectBase
      classNamePrefix={'react-select'}
      options={options}
      value={selectedValue}
      valueComparator={(a, b) => a.value === b.value}
      onChange={(e: SelectableValue<number>) => {
        onChange(e.value ? e.value : null)
      }}
    />
  )
}

const GroupingIntervalSelector = () => {
  const { control, watch, setValue } = useFormContext<TimeWindow>()
  const [options, setOptions] = React.useState<SelectableValue<number>[]>([])
  const { realtime, timeWindowType, history } = watch()

  React.useEffect(() => {
    let intervals: TimeInterval[] = []
    // Realtime
    if (timeWindowType === TimeWindowType.REAL_TIME) {
      // Last
      if (
        realtime?.realtimeType === RealTimeWindowType.LAST &&
        realtime?.timeWindowsMs
      ) {
        intervals = getIntervals(
          minIntervalLimit(realtime.timeWindowsMs),
          maxIntervalLimit(realtime.timeWindowsMs)
        )
      }
      // Interval
      else if (
        realtime?.realtimeType === RealTimeWindowType.INTERVAL &&
        realtime?.quickInterval
      ) {
        intervals = getIntervals(
          minIntervalLimit(quickIntervalToTimeWindowMs(realtime.quickInterval)),
          maxIntervalLimit(quickIntervalToTimeWindowMs(realtime.quickInterval))
        )
      }
    } else if (timeWindowType === TimeWindowType.HISTORY) {
      // Last
      if (
        history?.historyType === HistoryWindowType.LAST &&
        history?.timeWindowsMs
      ) {
        intervals = getIntervals(
          minIntervalLimit(history.timeWindowsMs),
          maxIntervalLimit(history.timeWindowsMs)
        )
      } else if (history?.historyType === HistoryWindowType.FIXED) {
        intervals = getIntervals(
          minIntervalLimit(history.timeWindowsMs),
          maxIntervalLimit(history.timeWindowsMs)
        )
        console.log(intervals)
      }
    }
    if (intervals.length > 0) {
      setOptions(intervals.map((i) => ({ value: i.value, label: i.name })))
      setValue('aggregation.interval', intervals[0].value)
    }
  }, [
    realtime?.quickInterval,
    realtime?.realtimeType,
    realtime?.timeWindowsMs,
    history?.historyType,
    history?.fixedTimeWindow?.startTimeMs,
    history?.fixedTimeWindow?.endTimeMs,
    history?.timeWindowsMs,
    history?.quickInterval,
    timeWindowType,
  ])

  return (
    <>
      <LabelHelp
        label={'Groupement'}
        help={"Définir l'interval de regroupement"}
        labelFor={'aggregation.interval'}
        className={'text-body'}
      />
      <Controller
        name={'aggregation.interval'}
        control={control}
        render={({ field }) => {
          return (
            <GroupingIntervalSelect
              options={options}
              onChange={(value) => {
                field.onChange(value)
              }}
              value={field.value}
            />
          )
        }}
      />
    </>
  )
}

export default GroupingIntervalSelector
