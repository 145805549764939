import { baseArkhaleAPI } from './base-api'
import Asset from '../../shared/models/asset'
import { Pagination } from '../../shared/models/others/pagination'
import { AssetQuery } from './query-type'
import { DeleteResponse } from './response'

const assetApi = baseArkhaleAPI.injectEndpoints({
  endpoints: (build) => ({
    getAssets: build.query<Pagination<Asset>, AssetQuery>({
      query: (args) => ({
        url: 'assets',
        method: 'GET',
        params: {
          offset: args.offset,
          limit: args.limit,
          textSearch: args.textSearch,
        },
      }),
      providesTags: (result = new Pagination<Asset>(), error, args) => [
        { type: 'Asset', id: 'LIST' },
      ],
    }),
    getAsset: build.query<Asset, string>({
      query: (id) => `assets/${id}`,
    }),
    addNewAsset: build.mutation<Asset, Asset>({
      query: (asset) => ({
        url: 'assets',
        method: 'POST',
        body: asset,
      }),
      invalidatesTags: (result, error, asset) => [
        { type: 'Asset', id: 'LIST' },
        { type: 'AssetByTenant', id: asset.tenantID?.id },
        { type: 'AssetByCustomer', id: asset.customerID?.id },
      ],
    }),
    updateAsset: build.mutation<Asset, Asset>({
      query: (asset) => ({
        url: `assets/${asset.id?.id}`,
        method: 'PUT',
        body: asset,
      }),
      invalidatesTags: (result, error, asset) => [
        { type: 'Asset', id: 'LIST' },
        { type: 'AssetByTenant', id: asset.tenantID?.id },
        { type: 'AssetByCustomer', id: asset.customerID?.id },
      ],
    }),
    deleteAsset: build.mutation<DeleteResponse, { id: string; asset: Asset }>({
      query: ({ id, asset }) => {
        // Obtenez l'actif avant de le supprimer
        return {
          url: `assets/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, { asset }) => [
        { type: 'Asset', id: 'LIST' },
        { type: 'AssetByTenant', id: asset.tenantID?.id },
        { type: 'AssetByCustomer', id: asset.customerID?.id },
      ],
    }),
  }),
})

export const {
  useLazyGetAssetsQuery,
  useGetAssetQuery,
  useLazyGetAssetQuery,
  useAddNewAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
} = assetApi
