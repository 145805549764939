import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import ListGroupNav from '../../components/navbar/internal/ListGroupNav'
import { TenantRouteParam } from '../context/tenant.context'

const CustomerHome = () => {
  const { tenantID } = useParams<TenantRouteParam>()

  return (
    <>
      <div className={'mb-2'}>
        <ListGroupNav />
      </div>
      <Outlet
        context={{
          tenantIDParam: tenantID,
        }}
      />
    </>
  )
}

export default CustomerHome
