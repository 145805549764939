import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useGetTenantProfileQuery } from '../../store/kopiliot-api/tenant-profile'
import { useGetCustomerQuery } from '../../store/kopiliot-api/customer'

interface CustomerNameProps {
  customerId: string
}

const CustomerName = ({ customerId }: CustomerNameProps) => {
  const { data, isLoading, isFetching, error } = useGetCustomerQuery(customerId)
  if (error) {
    return <span>Error while getting the customer name</span>
  }
  return isLoading && isFetching ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <span>{data?.name}</span>
  )
}

export default CustomerName
