import React, { PropsWithChildren } from 'react'
import { Card } from 'react-bootstrap'
import classNames from 'classnames'

interface ContentBlockBodyProps extends PropsWithChildren {
  noLight?: boolean
  className?: string
}

const ContentBlockBody = ({
  noLight,
  className = '',
  children,
}: ContentBlockBodyProps) => {
  return (
    <Card.Body className={classNames({ [className]: className })}>
      {children}
    </Card.Body>
  )
}

export default ContentBlockBody
