import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  ButtonGroup,
  Col,
  Form,
  FormLabel,
  Row,
  ToggleButton,
} from 'react-bootstrap'
import Input from '../../../../../../components/form/Input'
import { Node } from 'reactflow'
import { BaseNodeData } from '../../base/types'
import { NodeTypes } from '../../../../../../shared/models/rule-node'
import { AlarmSeverity } from '../../../../../../shared/models/alarm.model'
import AceEditor from 'react-ace'
import { Select } from '../../../../../../components/page-components/selects-entities/generic-select/Select'
import _ from 'lodash'
import { SelectableValue } from '../../../../../../components/page-components/selects-entities/generic-select/selectable-value'
import CreatableSelect from 'react-select/creatable'
import Flex from '../../../../../../components/common/Flex'

export interface GetOriginatorAttributesConfiguration {
  clientAttributeNames: string[]
  sharedAttributeNames: string[]
  serverAttributeNames: string[]
  latestTsKeyNames: string[]
  addTo: 'data' | 'metadata'
  failIfNotFound: boolean
  isDebugMode: boolean
  name: string
}

interface GetOriginatorAttributesFormProps {
  onSubmit: (
    data: Node<
      BaseNodeData<GetOriginatorAttributesConfiguration>,
      NodeTypes.ENRICHMENT_ORIGINATOR_ATTRIBUTES
    >
  ) => void
  defaultValues: Node<
    BaseNodeData<GetOriginatorAttributesConfiguration>,
    NodeTypes.ENRICHMENT_ORIGINATOR_ATTRIBUTES
  >
}

const GetOriginatorAttributesForm = ({
  onSubmit,
  defaultValues,
}: GetOriginatorAttributesFormProps) => {
  const form = useForm<
    Node<
      BaseNodeData<GetOriginatorAttributesConfiguration>,
      NodeTypes.ENRICHMENT_ORIGINATOR_ATTRIBUTES
    >
  >({
    defaultValues,
  })
  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = form
  const { configuration } = watch('data')
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} id={'form_rule_node'}>
        <Row className={''}>
          <Form.Group as={Col} lg={6} md={12}>
            <Input
              register={register('data.name', {
                required: 'Le nom est obligatoire',
              })}
              label={'Nom'}
              error={errors.data?.name ? errors.data?.name : undefined}
            />
          </Form.Group>
          <Form.Group as={Col} lg={6} md={12}>
            <Form.Check
              type="switch"
              label="Debug Mode ?"
              {...register('data.isDebugMode')}
            />
          </Form.Group>
        </Row>
        <Row></Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <FormLabel>Client side attributes</FormLabel>
            <Controller
              name={'data.configuration.clientAttributeNames'}
              render={({ field: { onChange, value: values } }) => {
                return (
                  <CreatableSelect
                    classNamePrefix={'react-select'}
                    isClearable
                    isMulti
                    value={values?.map((value: string) => ({
                      value,
                      label: value,
                    }))}
                    onChange={(values, actionMeta) => {
                      const strValues = _.chain(values)
                        .map('value')
                        .uniq()
                        .value()
                      onChange(strValues)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <FormLabel>Attributs partagés</FormLabel>
            <Controller
              name={'data.configuration.sharedAttributeNames'}
              render={({ field: { onChange, value: values } }) => {
                return (
                  <CreatableSelect
                    classNamePrefix={'react-select'}
                    isClearable
                    isMulti
                    value={values?.map((value: string) => ({
                      value,
                      label: value,
                    }))}
                    onChange={(values, actionMeta) => {
                      const strValues = _.chain(values)
                        .map('value')
                        .uniq()
                        .value()
                      onChange(strValues)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <FormLabel>Attributs serveurs</FormLabel>
            <Controller
              name={'data.configuration.serverAttributeNames'}
              render={({ field: { onChange, value: values } }) => {
                return (
                  <CreatableSelect
                    classNamePrefix={'react-select'}
                    isClearable
                    isMulti
                    value={values?.map((value: string) => ({
                      value,
                      label: value,
                    }))}
                    onChange={(values, actionMeta) => {
                      const strValues = _.chain(values)
                        .map('value')
                        .uniq()
                        .value()
                      onChange(strValues)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <FormLabel>Dernière télémétries</FormLabel>
            <Controller
              name={'data.configuration.latestTsKeyNames'}
              render={({ field: { onChange, value: values } }) => {
                return (
                  <CreatableSelect
                    classNamePrefix={'react-select'}
                    isClearable
                    isMulti
                    value={values?.map((value: string) => ({
                      value,
                      label: value,
                    }))}
                    onChange={(values, actionMeta) => {
                      const strValues = _.chain(values)
                        .map('value')
                        .uniq()
                        .value()
                      onChange(strValues)
                    }}
                  />
                )
              }}
            />
          </Form.Group>
        </Row>
        <Row className={'mt-3'}>
          <Form.Group as={Col} lg={12}>
            <Flex
              direction={'row'}
              alignItems={'center'}
              justifyContent={'between'}
            >
              <FormLabel>Importer les valeurs dans :</FormLabel>
              <ButtonGroup>
                <ToggleButton
                  value={'data'}
                  type="radio"
                  variant={'outline-primary'}
                  onClick={() => {
                    form.setValue('data.configuration.addTo', 'data')
                  }}
                  checked={configuration?.addTo === 'data'}
                  {...register('data.configuration.addTo')}
                >
                  Data
                </ToggleButton>
                <ToggleButton
                  value={'metadata'}
                  type="radio"
                  variant={'outline-primary'}
                  onClick={() => {
                    form.setValue('data.configuration.addTo', 'metadata')
                  }}
                  checked={configuration?.addTo === 'metadata'}
                  {...register('data.configuration.addTo')}
                >
                  Metadata
                </ToggleButton>
              </ButtonGroup>
            </Flex>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={12}>
            <Form.Check
              type="switch"
              label="Mettre le node en echec si une valeur n'est pas trouvée ?"
              {...register('data.configuration.failIfNotFound')}
            />
          </Form.Group>
        </Row>
      </Form>
    </FormProvider>
  )
}

export default GetOriginatorAttributesForm
