import React, { useEffect } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'
import RuleChain from '../../../../shared/models/rule-chain.model'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../../../components/form/Input'
import SelectBase from '../../../../components/page-components/selects-entities/generic-select/SelectBase'
import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../../../store/kopiliot-api/tenant'
import { SelectableValue } from '../../../../components/page-components/selects-entities/generic-select/selectable-value'
import TenantId from '../../../../shared/models/id/tenant-id'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'

interface RuleChainFormProps {
  form: UseFormReturn<RuleChain>
  onSubmit: SubmitHandler<RuleChain>
  initialValues?: Partial<RuleChain>
  isLoading?: boolean
  disabledField: Array<keyof RuleChain>
}

const RuleChainForm = ({
  form,
  onSubmit,
  initialValues,
  isLoading,
  disabledField,
}: RuleChainFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = form
  const watchTenantID = form.watch('tenantId')
  const hideIt = (field: keyof RuleChain) => {
    return disabledField.includes(field)
  }
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [getTenant, tenantResult] = useLazyGetTenantQuery()
  const [tenantOptions, setTenantOptions] = React.useState<
    SelectableValue<TenantId>[]
  >([])
  React.useEffect(() => {
    const res = _.map(tenantsResult.data?.rows, (tenant) => {
      return {
        label: tenant.name,
        value: tenant.id!,
      }
    })
    setTenantOptions(res)
  }, [tenantsResult.data])
  React.useEffect(() => {
    if (watchTenantID && !hideIt('tenantId')) {
      const fetchData = async () => {
        try {
          const t = await getTenant(watchTenantID.id).unwrap()
          if (t) {
            setTenantOptions([{ label: t.name, value: watchTenantID }])
          }
        } catch (err) {
          //toast.error(err.message)
        }
      }
      fetchData()
    }
  }, [watchTenantID])
  // Init value if needed
  useEffect(() => {
    reset(initialValues)
  }, [initialValues])
  return (
    <FormProvider {...form}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              register={register('name')}
              label={'Nom'}
              error={errors.name}
              isDisabled={isLoading}
            />
          </Col>
          <Col md={'6'}>
            <Input
              register={register('description')}
              label={'Description'}
              error={errors.description}
              isDisabled={isLoading}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'6'}>
            <Input
              type={'switch'}
              label={'Debug Mode'}
              register={register('debugMode')}
            />
          </Col>
          {hideIt('tenantId') ? null : (
            <Col md={6}>
              <Form.Label htmlFor={'tenantId'}>Tenant</Form.Label>
              <Controller
                name={'tenantId'}
                control={form.control}
                render={({ field }) => {
                  return (
                    <SelectBase
                      onChange={(value: SelectableValue<TenantId>) => {
                        if (value) {
                          field.onChange(value?.value)
                        }
                      }}
                      isLoading={
                        tenantsResult.isLoading || tenantResult.isLoading
                      }
                      placeholder={'Rechercher...'}
                      options={tenantOptions}
                      onInputChange={(value) => {
                        getTenants({
                          textSearch: value,
                          offset: 0,
                          limit: 40,
                        }).unwrap()
                      }}
                      isClearable={true}
                      debounceDelay={1000}
                      minCharsForSearch={2}
                      value={field.value ? { value: field.value } : undefined}
                      valueComparator={(a, b) => {
                        return a?.value?.id === b?.value?.id
                      }}
                      errorMessage={errors.tenantId?.message}
                    />
                  )
                }}
              />
            </Col>
          )}
        </Row>
      </Form>
    </FormProvider>
  )
}

export default RuleChainForm
