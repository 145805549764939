import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Spinner from '../../../components/spinner/Spinner'
import Flex from '../../../components/common/Flex'
import { CreateDeviceResult } from '../../../store/kopiliot-api/device'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface DeviceFormSuccessProps {
  isLoading?: boolean
  closeModal?: () => void
  result?: CreateDeviceResult
}

const DeviceFormSuccess = ({
  isLoading,
  closeModal,
  result,
}: DeviceFormSuccessProps) => {
  const createBlob = (content: string, mimeType: string) => {
    return new Blob([content], { type: mimeType })
  }
  const downloadFile = (content: string, filename: string) => {
    const blob = createBlob(content, 'text/plain')
    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download = filename
    document.body.appendChild(element)
    element.click()
  }
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Row>
          <Col className="text-center">
            <div className="mb-3">
              <FontAwesomeIcon
                icon={{ prefix: 'fas', iconName: 'check-circle' }}
                size={'6x'}
                className={'text-success'}
              />
            </div>
            <h4 className="mb-1">Votre device est paramétré !</h4>
            <p className="fs-0">
              Vous pouvez le connecter et envoyer des données. Notez bien les
              informations ci-dessous, elles n'apparaîtront plus.
            </p>
            <Flex justifyContent={'evenly'}>
              <div>
                {result && (
                  <Button
                    className="px-5 my-3"
                    role="button"
                    color="outline-primary"
                    onClick={() =>
                      downloadFile(
                        result?.certificate.crt,
                        `Certificate_${result?.device.name}.crt`
                      )
                    }
                  >
                    Télécharger le certificat
                  </Button>
                )}
              </div>
              <div>
                {result && (
                  <Button
                    className="px-5 my-3"
                    role="button"
                    color="outline-primary"
                    onClick={() =>
                      downloadFile(
                        result?.certificate.key,
                        `Private_key_${result?.device.name}.crt`
                      )
                    }
                  >
                    Télécharger la clé privée
                  </Button>
                )}
              </div>
            </Flex>
            {closeModal && (
              <Button
                color="primary"
                className="px-5 my-3"
                onClick={closeModal}
              >
                Close
              </Button>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export default DeviceFormSuccess
