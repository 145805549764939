import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { NotificationTarget } from '../../../shared/models/notification.model'
import ButtonLoading from '../../../components/button/ButtonLoading'
import { useAddNotificationTargetMutation } from '../../../store/kopiliot-api/notification'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import NotificationTargetForm from '../forms/NotificationTargetForm'

interface Props {
  show?: boolean
  onHide: () => void
  entity?: Partial<NotificationTarget>
}

const AddOrEditNotificationTargetModal = ({ show, onHide, entity }: Props) => {
  const [addNotificationTarget, resultAddNotificationTarget] =
    useAddNotificationTargetMutation()

  const form = useForm<NotificationTarget>()
  React.useEffect(() => {
    form.reset(entity)
  }, [entity])
  const onSubmit = async (data: NotificationTarget) => {
    try {
      await addNotificationTarget(data).unwrap()
      toast.success('Destinataire ajouté avec succès')
      onHide()
    } catch (e) {
      console.log(e)
    }
  }

  const isLoading = resultAddNotificationTarget.isLoading

  return (
    <Modal show={show} onHide={onHide} size={'lg'} centered>
      <Modal.Header>
        <Modal.Title>{`${
          entity?.id ? 'Editer' : 'Ajouter'
        } un destinataire`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NotificationTargetForm form={form} />
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} variant={'outline-secondary'} onClick={onHide}>
          Annuler
        </Button>
        <ButtonLoading
          isLoading={isLoading}
          disabled={isLoading}
          variant={'outline-primary'}
          type={'submit'}
          text={'Valider'}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default AddOrEditNotificationTargetModal
