import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  UpdateDeviceCommand,
  useUpdateDeviceMutation,
} from '../../../store/kopiliot-api/device'
import Device from '../../../shared/models/device.model'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Input from '../../../components/form/Input'
import {
  useLazyGetDeviceProfileQuery,
  useLazyGetDeviceProfilesQuery,
} from '../../../store/kopiliot-api/device-profile'
import GenericSelect from '../../../components/page-components/selects-entities/GenericSelect'
import { DeviceId } from '../../../shared/models/id/device-id'
import {
  useCustomerSearch,
  useDeviceProfileSearch,
} from '../../../components/page-components/selects-entities/generic-select/hooks'
import SelectBase from '../../../components/page-components/selects-entities/generic-select/SelectBase'
import ButtonLoading from '../../../components/button/ButtonLoading'
import { SelectableValue } from '../../../components/page-components/selects-entities/generic-select/selectable-value'
import { DeviceProfileId } from '../../../shared/models/id/device-profile-id'
import { CustomerId } from '../../../shared/models/id/customer-id'
import { toast } from 'react-toastify'

interface Props {
  device: Device
}

const DeviceUpdateForm = ({ device }: Props) => {
  const form = useForm<UpdateDeviceCommand>({
    defaultValues: {
      id: device.id,
      description: device.description,
      deviceProfileID: device.deviceProfileID,
      customerID: device.customerID,
    },
  })
  const [updateDevice, { isLoading, error }] = useUpdateDeviceMutation()
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = form
  const { deviceProfileID } = watch()

  const {
    deviceProfileOptions,
    setSearch: setSearchDeviceProfiles,
    isLoading: isLoadingDeviceProfiles,
  } = useDeviceProfileSearch(deviceProfileID)
  const onSubmit = async (data: UpdateDeviceCommand) => {
    try {
      await updateDevice(data).unwrap()
      toast('Device updated', { type: 'success' })
    } catch (error) {
      console.error('Failed to update device', error)
    }
  }
  return (
    <>
      <FormProvider {...form}>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group className="mb-3" as={Col} md={12}>
              <Input
                label={'Description'}
                type={'textarea'}
                register={register('description')}
                error={errors.description}
              />
            </Form.Group>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Form.Group as={Col} lg={6} md={12}>
              <Form.Label htmlFor={'deviceProfileID'}>
                Profil de device
              </Form.Label>
              <Controller
                control={control}
                name={'deviceProfileID'}
                rules={{ required: 'Séléctionnez un profil de device' }}
                render={({ field }) => {
                  return (
                    <SelectBase
                      isLoading={isLoadingDeviceProfiles}
                      placeholder={'Rechercher...'}
                      options={deviceProfileOptions}
                      onInputChange={(value) => {
                        setSearchDeviceProfiles(value)
                      }}
                      isClearable={true}
                      debounceDelay={1000}
                      minCharsForSearch={2}
                      value={field.value ? { value: field.value } : undefined}
                      onChange={(value: SelectableValue<DeviceProfileId>) => {
                        if (value) {
                          field.onChange(value?.value)
                        }
                      }}
                      valueComparator={(a, b) => {
                        return a?.value?.id === b?.value?.id
                      }}
                      errorMessage={errors.deviceProfileID?.message}
                    />
                  )
                }}
              />
            </Form.Group>
          </Row>
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Col md={{ span: 2, offset: 10 }}>
              <ButtonLoading
                variant={'outline-primary'}
                type={'submit'}
                className={'w-100'}
                disabled={isLoading}
                isLoading={isLoading}
                text={'Enregistrer'}
                onClick={form.handleSubmit(onSubmit)}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  )
}

export default DeviceUpdateForm
