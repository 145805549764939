import { ActionType } from '../../shared/models/audit-log'

const getColorForActionType = (type: ActionType) => {
  switch (type) {
    // Read case
    // Add case
    case ActionType.ADDED:
      return 'success'
    // Update case
    case ActionType.UPDATED:
      return 'warning'
    // Delete case
    case ActionType.DELETED:
      return 'danger'
    case ActionType.RULE_CHAIN_EXECUTIONS:
    case ActionType.LOGIN:
      return 'info'
    default:
      return 'dark'
  }
}

export { getColorForActionType }
