import React, { useState } from 'react'
import {
  useAddNewTenantProfileMutation,
  useDeleteTenantProfileMutation,
  useLazyGetTenantProfileQuery,
  useLazyGetTenantProfilesQuery,
} from '../../store/kopiliot-api/tenant-profile'
import TenantProfile from '../../shared/models/tenant-profile'
import Spinner from '../../components/spinner/Spinner'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import {
  defaultTenantProfileActionsRow,
  getColumnsForTableTenantProfile,
} from './utils'
import AddTenantProfileModal from './AddTenantProfileModal'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { useNavigate } from 'react-router-dom'
import TenantProfileId from '../../shared/models/id/tenant-profile-id'
import { EntityType } from '../../shared/models/entity-type.models'
import ModalDelete from '../../features/modals/ModalDelete'
import { SortingState } from '@tanstack/react-table'
import { PaginationWithSearchTerm } from '../../store/kopiliot-api/query-type'

const TenantProfileList = () => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    PaginationWithSearchTerm,
    PaginationActions
  >(reducerPage, {
    limit: 20,
    offset: 0,
    textSearch: '',
  })

  React.useEffect(() => {
    fetchTenantProfiles(paginationState, false)
  }, [paginationState])
  React.useEffect(() => {}, [sorting])

  const navigate = useNavigate()
  const [showModalCreate, setShowModalCreate] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [selectedItem, setSelectedItem] = useState<TenantProfileId | null>(null)
  const [fetchTenantProfiles, { data, isLoading, isFetching }] =
    useLazyGetTenantProfilesQuery()
  React.useEffect(() => {
    if (selectedItem) {
      if (selectedItem.entityType === EntityType.TENANT_PROFILE) {
        getTenantProfile(selectedItem.id)
      }
    }
  }, [showModalDelete])
  const onDeleteItem = (id: string) => {
    deleteTenantProfile(new TenantProfileId(id))
    setSelectedItem(null)
    setShowModalDelete(false)
  }
  // Form
  const formTenantProfile = useForm<TenantProfile>()
  const [addTenantProfile, { isLoading: isLoadingSubmit }] =
    useAddNewTenantProfileMutation()
  const [deleteTenantProfile, { isLoading: isLoadingDelete }] =
    useDeleteTenantProfileMutation()
  const [
    getTenantProfile,
    { data: dataTenantProfile, isLoading: isLoadingTenantProfile },
  ] = useLazyGetTenantProfileQuery()
  const onSubmitForm = async (data: TenantProfile) => {
    try {
      const res = await addTenantProfile(data).unwrap()
      setShowModalCreate(false)
      toast.success(`Tenant profile ${res.name} créé avec succès`)
      formTenantProfile.reset()
    } catch (e) {
      console.log(e)
    }
  }
  const onTenantProfileEdit = (id: string) => {
    navigate(`${id}`)
  }
  const onTenantProfileDelete = (id: string) => {
    setSelectedItem({ id: id, entityType: EntityType.TENANT_PROFILE })
    setShowModalDelete(true)
  }
  const actionsRow = defaultTenantProfileActionsRow(
    onTenantProfileDelete,
    onTenantProfileEdit
  )
  return (
    <div>
      {isLoading || (isFetching && <Spinner />)}
      <KopiliotList
        title={'Profils de tenant'}
        icon={'address-card'}
        isLoading={isLoading || isFetching}
        actionsProps={{
          onRefresh: () => {
            fetchTenantProfiles(paginationState, false)
          },
          onAdd: () => {
            setShowModalCreate(true)
          },
        }}
        data={data}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: data?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id'],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableTenantProfile(actionsRow),
          onClickRow: (row) => {
            navigate(`${row.original.id?.id}`)
          },
          sortOrder: paginationState.sortOrder,
          //setSorting,
        }}
        cardProps={{
          idAccessor: (item: TenantProfile) => item.id!.id,
          titleAccessor: (item: TenantProfile) => item.name,
          descriptionAccessor: (item: TenantProfile) => item.description || '',
          linkAccessor: (item: TenantProfile) => `${item.id!.id}`,
          actionsRow: actionsRow,
        }}
      />
      <AddTenantProfileModal
        onSubmit={onSubmitForm}
        form={formTenantProfile}
        isOpen={showModalCreate}
        setIsOpen={setShowModalCreate}
        isLoading={isLoadingSubmit}
      />
      <ModalDelete
        show={showModalDelete}
        name={dataTenantProfile?.name}
        onClose={() => setShowModalDelete(false)}
        onDelete={() => {
          if (dataTenantProfile?.id?.id) {
            onDeleteItem(dataTenantProfile.id.id)
          }
        }}
        isLoading={isLoadingTenantProfile}
      />
    </div>
  )
}

export default TenantProfileList
