import React from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'
import { Dashboard } from '../../shared/models/dashboard.model'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../components/form/Input'
import InputFileImageAsBase64 from '../../components/form/InputFileImageAsBase64'
import SelectBase from '../../components/page-components/selects-entities/generic-select/SelectBase'
import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../store/kopiliot-api/tenant'
import _ from 'lodash'
import TenantId from '../../shared/models/id/tenant-id'
import Tenant from '../../shared/models/tenant'
import { SelectableValue } from '../../components/page-components/selects-entities/generic-select/selectable-value'
import {
  useCustomerSearch,
  useCustomersSearch,
} from '../../components/page-components/selects-entities/generic-select/hooks'
import { CustomerId } from '../../shared/models/id/customer-id'
import { MultiSelect } from '../../components/page-components/selects-entities/generic-select/Select'
import { SelectValue } from '../../components/page-components/selects-entities/generic-select/types'

interface DashboardFormProps {
  form: UseFormReturn<
    Pick<
      Dashboard,
      'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
    >
  >
  onSubmit: SubmitHandler<
    Pick<
      Dashboard,
      'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
    >
  >
  initialValues?: Partial<
    Pick<
      Dashboard,
      'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
    >
  >
  isLoading?: boolean
  disabledFields: Array<
    keyof Pick<
      Dashboard,
      'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
    >
  >
}

const DashboardForm = ({
  onSubmit,
  isLoading,
  form,
  disabledFields,
}: DashboardFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = form
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [tenantOptions, setTenantOptions] = React.useState<
    SelectableValue<TenantId>[]
  >([])
  const customerIDs = watch('assignedCustomers')

  const {
    customerOptions,
    setSearch: setSearchCustomers,
    isLoading: isLoadingCustomers,
  } = useCustomersSearch(customerIDs)

  React.useEffect(() => {
    const res = _.map(tenantsResult.data?.rows, (tenant) => {
      return {
        label: tenant.name,
        value: tenant.id!,
      }
    })
    setTenantOptions(res)
  }, [tenantsResult.data])

  const isDisabled = (
    field: keyof Pick<
      Dashboard,
      'id' | 'name' | 'description' | 'tenantID' | 'image' | 'assignedCustomers'
    >
  ) => {
    return disabledFields.includes(field)
  }
  return (
    <FormProvider {...form}>
      <Form
        noValidate
        onSubmit={() => {
          handleSubmit((data) => onSubmit(data))
        }}
      >
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Col md={'12'}>
            <Input
              label={'Name'}
              register={register('name', { required: true })}
              error={errors.name}
            />
          </Col>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Form.Group as={Col} md={12}>
            <Input
              register={form.register('description', {
                shouldUnregister: true,
              })}
              label={'Description'}
              type={'textarea'}
              textAreaRows={5}
            />
          </Form.Group>
        </Row>
        <Row className={'gx-3 gy-2 align-items-center'}>
          <Form.Group as={Col} md={12}>
            <InputFileImageAsBase64
              label={'Image'}
              error={errors.image}
              setValue={setValue}
              setError={setError}
              register={register('image')}
            />
          </Form.Group>
        </Row>
        {!isDisabled('tenantID') && (
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Col md={'6'}>
              <Form.Label>Tenant</Form.Label>
              <Controller
                name={'tenantID'}
                render={({ field }) => {
                  return (
                    <SelectBase
                      isLoading={tenantsResult.isLoading}
                      placeholder={'Rechercher...'}
                      options={tenantOptions}
                      onInputChange={(value) => {
                        getTenants({
                          textSearch: value,
                          offset: 0,
                          limit: 40,
                        }).unwrap()
                      }}
                      isClearable={true}
                      debounceDelay={2000}
                      minCharsForSearch={2}
                      value={field.value ? { value: field.value } : undefined}
                      valueComparator={(a: SelectableValue<TenantId>, b) => {
                        return a?.value?.id === b?.value?.id
                      }}
                      errorMessage={errors.tenantID?.message}
                      onChange={(value: SelectableValue<TenantId>) => {
                        if (value) {
                          field.onChange(value?.value)
                        }
                      }}
                    />
                  )
                }}
              />
            </Col>
          </Row>
        )}
        {!isDisabled('assignedCustomers') && (
          <Row className={'gx-3 gy-2 align-items-center'}>
            <Col md={'6'}>
              <Form.Label>Customers</Form.Label>
              <Controller
                name={'assignedCustomers'}
                render={({ field }) => {
                  return (
                    <MultiSelect
                      isLoading={isLoadingCustomers}
                      placeholder={'Rechercher...'}
                      options={customerOptions}
                      onInputChange={(value) => {
                        setSearchCustomers(value)
                      }}
                      isClearable={true}
                      debounceDelay={2000}
                      minCharsForSearch={2}
                      value={field.value?.map((v: CustomerId) => {
                        return { value: v }
                      })}
                      valueComparator={(a, b) => {
                        return a?.value?.id === b?.value?.id
                      }}
                      errorMessage={errors.assignedCustomers?.message}
                      onChange={(value) => {
                        if (value) {
                          field.onChange(value?.map((v) => v.value))
                        }
                      }}
                    />
                  )
                }}
              />
            </Col>
          </Row>
        )}
      </Form>
    </FormProvider>
  )
}

export default DashboardForm
