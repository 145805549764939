import { EntityId } from './entity-id'
import { EntityType } from '../entity-type.models'

class TenantId implements EntityId {
  entityType = EntityType.TENANT
  id: string
  constructor(id: string) {
    this.id = id
  }
}

export default TenantId
