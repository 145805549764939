import { BaseData } from './base-data'
import { PanelID } from './id/panel-id'
import { EntityType } from './entity-type.models'
import { DashboardId } from './id/dashboard-id'
import { ChartOptions } from 'chart.js'
import { CustomGaugeComponentProps } from '../../features/dahsboards/panel/chart-helpers/gauge/Gauge'
import { AggregationType, ComparisonDuration, TimeWindow } from './time.model'

export interface GridPos {
  positionX: number
  positionY: number
  positionW: number
  positionH: number
  static?: boolean
}

export enum ComparisonResultType {
  PREVIOUS_VALUE = 'PREVIOUS_VALUE',
  DELTA_ABSOLUTE = 'DELTA_ABSOLUTE',
  DELTA_PERCENT = 'DELTA_PERCENT',
}

export interface DataSource<S = any> {
  type: 'entity'
  entityAliasID?: { id: string; entityType: EntityType }
  dataKeys: DataKey<S>[]
  latestDataKeys?: DataKey<S>[]
}

export interface DataKey<S = any> {
  key: string
  //type: 'timeseries' | attribute
  label: string
  color: string
  units: string
  decimal: number
  specificProps?: S
}

export interface JsonTableDataKeySpecificProps {
  columns: { key: string; label: string }[]
}

export const isJsonTableDataKeySpecificProps = (
  v: any
): v is JsonTableDataKeySpecificProps => {
  return (
    Array.isArray(v.columns) &&
    v.columns.every(
      (c: any) => typeof c.key === 'string' && typeof c.label === 'string'
    )
  )
}

export interface GaugeDataKeySpecificProps {
  min?: number
  max?: number
  type?: 'semicircle' | 'radial' | 'grafana'
  arc?: CustomGaugeComponentProps['arc']
}

export interface SingleValueDataKeySpecificProps {
  helperText?: string
  strategy?: AggregationType
  enableDelta?: boolean
  comparisonPeriod?: ComparisonDuration
  deltaCalculation?: ComparisonResultType
  deltaColor?: { limit: number; color: string }[]
  thresholds?: ThresholdWithColor[]
  showGraph?: boolean
}

export enum ThresholdOperator {
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  EQUAL = '==',
  NOT_EQUAL = '!=',
  IN = 'in',
  NOT_IN = 'not in',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not contains',
  MATCHES = 'matches',
  NOT_MATCHES = 'not matches',
}

export const processThresholdOperator = (
  operator: ThresholdOperator,
  value: any,
  reference: any
): boolean => {
  switch (operator) {
    case ThresholdOperator.GREATER_THAN:
    case ThresholdOperator.GREATER_THAN_OR_EQUAL:
    case ThresholdOperator.LESS_THAN:
    case ThresholdOperator.LESS_THAN_OR_EQUAL:
      return processComparisonOperator(operator, value, reference)
    case ThresholdOperator.EQUAL:
    case ThresholdOperator.NOT_EQUAL:
      return processEqualityOperator(operator, value, reference)
    default:
      return false
  }
}
/**
 * Process the comparison operation
 * @param operator
 * @param value
 * @param reference
 * @return boolean
 */
const processComparisonOperator = (
  operator: ThresholdOperator,
  value: any,
  reference: any
): boolean => {
  let aValue = 0
  let bValue = 0
  aValue = Number(value)
  bValue = Number(reference)
  if (isNaN(aValue) || isNaN(bValue)) {
    return false
  }

  switch (operator) {
    case ThresholdOperator.GREATER_THAN:
      return aValue > bValue
    case ThresholdOperator.GREATER_THAN_OR_EQUAL:
      return aValue >= bValue
    case ThresholdOperator.LESS_THAN:
      return aValue < bValue
    case ThresholdOperator.LESS_THAN_OR_EQUAL:
      return aValue <= bValue
    default:
      return false
  }
}

const processEqualityOperator = (
  operator: ThresholdOperator,
  value: any,
  reference: any
) => {
  // declare a variable for converted value
  let referenceValue: any = reference
  // if type of value is not equal to type of reference
  if (typeof value !== typeof reference) {
    const valueType = typeof value
    switch (valueType) {
      case 'string':
        if (reference.toString) {
          referenceValue = reference.toString()
        } else {
          return false
        }
        break
      case 'number':
        referenceValue = Number(reference)
        if (isNaN(referenceValue)) {
          return false
        }
        break
      case 'boolean':
        referenceValue = reference === 'true'
        break
      default:
        return false
    }
  }
  if (operator === ThresholdOperator.EQUAL) {
    return value === referenceValue
  } else if (operator === ThresholdOperator.NOT_EQUAL) {
    return value !== referenceValue
  }
  return false
}

export enum Icons {
  CIRCLE = 'circle',
  CIRCLE_XMARK = 'circle-xmark',
  CIRCLE_CHECKMARK = 'circle-check',
  CIRCLE_ARROW_UP = 'circle-arrow-up',
}

export enum IconSize {
  '2xs' = '2xs',
  xs = 'xs',
  sm = 'sm',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
  '1x' = '1x',
  '2x' = '2x',
  '3x' = '3x',
  '4x' = '4x',
  '5x' = '5x',
  '6x' = '6x',
  '7x' = '7x',
  '8x' = '8x',
  '9x' = '9x',
  '10x' = '10x',
}

export interface Threshold {
  reference: number | string
  operator: ThresholdOperator
}

export interface ThresholdWithColor extends Threshold {
  color: string
}

export interface ThresholdWithIcon extends ThresholdWithColor {
  icon: Icons
}

export interface SimpleIndicatorDataKeySpecificProps {
  thresholds?: ThresholdWithIcon[]
  align?: 'left' | 'right'
  showValue?: boolean
  showLabel?: boolean
  iconSize?: IconSize
}

export interface PieChartDataKeySpecificProps {
  hoverOffset?: number
}

export interface Annotation {
  type: 'line'
  key: string
  display?: boolean
  yMin?: number | string
  yMax?: number | string
  xMin?: number | string
  xMax?: number | string
  backgroundColor?: string
  borderColor?: string
}

export interface LineLabel {
  backgroundColor?: string
  color?: string
  content?: string
  display?: boolean
  opacity?: number
  position?: 'start' | 'center' | 'end'
}

export interface LineAnnotation extends Annotation {
  borderWidth?: number
  label?: LineLabel
}

export interface AnnotationAnimation {}

export interface TimeSeriesLineDataKeySpecificProps<T extends Annotation> {
  color?: string
  borderWidth?: string
  fill?: boolean
  backgroundColor?: string
  tension?: number
  borderDash?: {
    length?: number
    spacing?: number
  }
  annotation?: {
    animations?: AnnotationAnimation
    annotations?: T[]
  }
}

export enum PanelType {
  SINGLE_VALUE = 'single_value',
  SIMPLE_INDICATOR = 'simple_indicator',
  TIME_SERIES_LINE = 'time_series_line',
  TIME_SERIES_BAR = 'time_series_bar',
  TIME_SERIES_PIE = 'time_series_pie',
  TIME_SERIES_RADAR = 'time_series_radar',
  GAUGE = 'gauge',
  TIMESERIES_TABLE = 'table',
  JSON_TABLE = 'json_table',
}

export interface PanelConfig {
  dataSources: DataSource[]
  settings: ChartOptions | any // TODO CHECK FOR TYPE
  useCustomTimeWindow?: boolean
  allowChangingTimeWindow?: boolean
  timeWindow?: TimeWindow
}

export interface Panel<TOptions = any> extends BaseData<PanelID> {
  position: GridPos
  type: PanelType
  isNew: boolean
  title?: string
  description?: string
  config: PanelConfig
  dashboardID: DashboardId
}
