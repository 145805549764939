import React from 'react'
import { Link } from 'react-router-dom'
import logoDark from '../../assets/img/illustrations/logo-kopiliot-dark.png'
import logoLight from '../../assets/img/illustrations/logo-kopiliot-light.png'
import logoLightWithText from '../../assets/img/illustrations/logo-kopiliot-with-text-light.png'
import logoDarkWithText from '../../assets/img/illustrations/logo-kopiliot-with-text-dark.png'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

interface LogoProps {
  at: 'navbar-top' | 'navbar-vertical'
}

const Logo = ({ at }: LogoProps) => {
  const { theme } = useSelector((state: RootState) => state.settings)
  let isDark = theme === 'dark'
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
    >
      <div
        className={classNames('d-flex', {
          'align-items-center py-3': at === 'navbar-vertical',
          'align-items-center': at === 'navbar-top',
        })}
      >
        <img
          className="me-2 d-md-none"
          src={isDark ? logoDark : logoLight}
          alt="Logo"
          height={32.93}
        />
        <img
          className="me-2 d-none d-md-block"
          src={isDark ? logoDarkWithText : logoLightWithText}
          alt="Logo"
          width={180}
        />
      </div>
    </Link>
  )
}

export default Logo
