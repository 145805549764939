import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import Flex from '../../components/common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatisticCard from '../../components/common/widgets/StatisticCard'
import { useAppSelector } from '../../store/store'
import DashboardList from '../dashboard/DashboardList'
import DashboardHome from '../dashboard/DashboardHome'

const DefaultHomeCustomerDashboard = () => {
  const currentUser = useAppSelector((state) => state.currentUser)
  return (
    <Container fluid>
      <Row className={'mt-2'}>
        <Col sm={12} md={6} lg={4} className={'px-1 h-100'}>
          <ContentBlock>
            <ContentBlock.Header className={'border-bottom py-1'}>
              <Flex>
                <FontAwesomeIcon
                  icon={'triangle-exclamation'}
                  className={'me-1 mt-1 text-primary'}
                />
                <h5 className={'text-primary mb-0'}>Alertes</h5>
              </Flex>
            </ContentBlock.Header>
            <ContentBlock.Body noLight className={'pt-1'}>
              <Row>
                <Col className={'p-1'}>
                  <StatisticCard
                    title={'Assignées'}
                    value={3}
                    className={'shadow-none bg-success-subtle'}
                  />
                </Col>
                <Col className={'p-1'}>
                  <StatisticCard
                    value={2}
                    title={'Critiques'}
                    className={'shadow-none bg-danger-subtle'}
                  />
                </Col>
                <Col className={'p-1'}>
                  <StatisticCard
                    title={'Total'}
                    value={10}
                    className={'shadow-none bg-info-subtle'}
                  />
                </Col>
              </Row>
            </ContentBlock.Body>
          </ContentBlock>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DashboardList linkTo={'dashboards/all/'} />
        </Col>
      </Row>
    </Container>
  )
}

export default DefaultHomeCustomerDashboard
