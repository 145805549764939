import dayjs from 'dayjs'
import { isDefinedAndNotNull } from '../../utils/utils'

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY
export const YEAR = DAY * 365
const MIN_INTERVAL = SECOND
const MAX_INTERVAL = 365 * 20 * DAY

export type ComparisonDuration = 'previousInterval'

export enum TimeWindowType {
  REAL_TIME = 'REAL_TIME',
  HISTORY = 'HISTORY',
}

export enum RealTimeWindowType {
  LAST = 'LAST',
  INTERVAL = 'INTERVAL',
}

export enum HistoryWindowType {
  LAST = 'LAST',
  FIXED = 'FIXED',
  INTERVAL = 'INTERVAL',
  FOR_ALL_TIME = 'FOR_ALL_TIME',
}

export enum QuickTimeInterval {
  YESTERDAY = 'YESTERDAY',
  DAY_BEFORE_YESTERDAY = 'DAY_BEFORE_YESTERDAY',
  THIS_DAY_LAST_WEEK = 'THIS_DAY_LAST_WEEK',
  PREVIOUS_WEEK = 'PREVIOUS_WEEK',
  PREVIOUS_WEEK_ISO = 'PREVIOUS_WEEK_ISO',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  PREVIOUS_QUARTER = 'PREVIOUS_QUARTER',
  PREVIOUS_HALF_YEAR = 'PREVIOUS_HALF_YEAR',
  PREVIOUS_YEAR = 'PREVIOUS_YEAR',
  CURRENT_HOUR = 'CURRENT_HOUR',
  CURRENT_DAY = 'CURRENT_DAY',
  CURRENT_DAY_SO_FAR = 'CURRENT_DAY_SO_FAR',
  CURRENT_WEEK = 'CURRENT_WEEK',
  CURRENT_WEEK_ISO = 'CURRENT_WEEK_ISO',
  CURRENT_WEEK_SO_FAR = 'CURRENT_WEEK_SO_FAR',
  CURRENT_WEEK_ISO_SO_FAR = 'CURRENT_WEEK_ISO_SO_FAR',
  CURRENT_MONTH = 'CURRENT_MONTH',
  CURRENT_MONTH_SO_FAR = 'CURRENT_MONTH_SO_FAR',
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  CURRENT_QUARTER_SO_FAR = 'CURRENT_QUARTER_SO_FAR',
  CURRENT_HALF_YEAR = 'CURRENT_HALF_YEAR',
  CURRENT_HALF_YEAR_SO_FAR = 'CURRENT_HALF_YEAR_SO_FAR',
  CURRENT_YEAR = 'CURRENT_YEAR',
  CURRENT_YEAR_SO_FAR = 'CURRENT_YEAR_SO_FAR',
}

export interface IntervalWindow {
  //interval: number
  timeWindowsMs: number
  quickInterval?: QuickTimeInterval
}

export interface RealtimeWindow extends IntervalWindow {
  realtimeType?: RealTimeWindowType
}

export interface FixedWindow {
  startTimeMs: number
  endTimeMs: number
}

export interface HistoryWindow extends IntervalWindow {
  historyType?: HistoryWindowType
  fixedTimeWindow?: FixedWindow
}

export enum AggregationType {
  MIN = 'MIN',
  MAX = 'MAX',
  AVG = 'AVG',
  SUM = 'SUM',
  COUNT = 'COUNT',
  NONE = 'NONE',
}

export interface Aggregation {
  interval?: number
  type: AggregationType
  limit: number
}

export interface TimeWindow {
  displayValue?: string
  displayTimezoneAbbr?: string
  hideInterval?: boolean
  hideQuickInterval?: boolean
  hideLastInterval?: boolean
  hideAggregation?: boolean
  hideAggInterval?: boolean
  hideTimezone?: boolean
  timeWindowType?: TimeWindowType
  realtime?: RealtimeWindow
  history?: HistoryWindow
  aggregation?: Aggregation
  timezone?: string
}

export interface SubscriptionAggregation extends Aggregation {
  timeWindow?: number
  stateData?: boolean
}

export interface SubscriptionTimeWindow {
  startTs?: number
  quickInterval?: QuickTimeInterval
  timezone?: string
  tsOffset?: number
  realtimeWindowMs?: number
  fixedWindow?: FixedWindow
  aggregation?: SubscriptionAggregation
  //timeForComparison?: ComparisonDuration
}

export const defaultTimeWindow = (): TimeWindow => {
  const currentTime = dayjs()
  return {
    displayValue: 'Last 1 hour',
    hideInterval: false,
    hideQuickInterval: false,
    hideLastInterval: false,
    hideAggregation: false,
    hideAggInterval: false,
    hideTimezone: false,
    timeWindowType: TimeWindowType.REAL_TIME,
    realtime: {
      realtimeType: RealTimeWindowType.LAST,
      //interval: 3600000,
      timeWindowsMs: 3600000,
      quickInterval: QuickTimeInterval.CURRENT_HOUR,
    },
    history: {
      historyType: HistoryWindowType.LAST,
      //interval: 3600000,
      timeWindowsMs: 3600000,
      quickInterval: QuickTimeInterval.CURRENT_HOUR,
    },
    aggregation: {
      interval: 3600000,
      type: AggregationType.NONE,
      limit: 100,
    },
    timezone: currentTime.format('Z'),
  }
}

export interface TimeInterval {
  name: string
  value: number
}
// Utility functions
export const defaultTimeIntervals = new Array<TimeInterval>(
  {
    name: '1 seconde',
    value: SECOND,
  },
  {
    name: '5 secondes',
    value: 5 * SECOND,
  },
  {
    name: '10 secondes',
    value: 10 * SECOND,
  },
  {
    name: '15 secondes',
    value: 15 * SECOND,
  },
  {
    name: '30 secondes',
    value: 30 * SECOND,
  },
  {
    name: '1 minute',
    value: MINUTE,
  },
  {
    name: '2 minutes',
    value: 2 * MINUTE,
  },
  {
    name: '5 minutes',
    value: 5 * MINUTE,
  },
  {
    name: '10 minutes',
    value: 10 * MINUTE,
  },
  {
    name: '15 minutes',
    value: 15 * MINUTE,
  },
  {
    name: '30 minutes',
    value: 30 * MINUTE,
  },
  {
    name: '1 heure',
    value: HOUR,
  },
  {
    name: '2 heures',
    value: 2 * HOUR,
  },
  {
    name: '5 heures',
    value: 5 * HOUR,
  },
  {
    name: '10 heures',
    value: 10 * HOUR,
  },
  {
    name: '12 heures',
    value: 12 * HOUR,
  },
  {
    name: '1 jour',
    value: DAY,
  },
  {
    name: '2 jours',
    value: 7 * DAY,
  },
  {
    name: '1 mois',
    value: 30 * DAY,
  }
)
export const getIntervals = (min: number, max: number): Array<TimeInterval> => {
  min = boundMinInterval(min)
  max = boundMaxInterval(max)
  return defaultTimeIntervals.filter(
    (interval) => interval.value >= min && interval.value <= max
  )
}

const boundMinInterval = (min: number): number => {
  if (isDefinedAndNotNull(min)) {
    min = Math.ceil(min / 1000) * 1000
  }
  return toBound(min, MIN_INTERVAL, MAX_INTERVAL, MIN_INTERVAL)
}

const boundMaxInterval = (max: number): number => {
  if (isDefinedAndNotNull(max)) {
    max = Math.floor(max / 1000) * 1000
  }
  return toBound(max, MIN_INTERVAL, MAX_INTERVAL, MAX_INTERVAL)
}

const toBound = (
  value: number,
  min: number,
  max: number,
  defaultValue: number
): number => {
  if (isDefinedAndNotNull(value)) {
    value = Math.max(value, min)
    value = Math.min(value, max)
    return value
  }
  return defaultValue
}

export const minIntervalLimit = (timeWindowMs: number): number => {
  const min = timeWindowMs / 500
  return boundMinInterval(min)
}

export const maxIntervalLimit = (timeWindowMs: number): number => {
  const max = timeWindowMs / 7
  return boundMaxInterval(max)
}

const avgInterval = (timeWindowMs: number): number => {
  const avg = timeWindowMs / 100
  return boundMinInterval(avg)
}

const boundIntervalToTimeWindow = (
  timeWindow: number,
  intervalMs: number,
  aggType: AggregationType
): number => {
  if (aggType === AggregationType.NONE) {
    return SECOND
  } else {
    const min = minIntervalLimit(timeWindow)
    const max = maxIntervalLimit(timeWindow)
    if (intervalMs) {
      return toBound(intervalMs, min, max, intervalMs)
    } else {
      return boundToPredefinedInterval(min, max, avgInterval(timeWindow))
    }
  }
}

const boundToPredefinedInterval = (
  min: number,
  max: number,
  intervalMs: number
): number => {
  const intervals = getIntervals(min, max)
  let minDelta = MAX_INTERVAL
  const boundedInterval = intervalMs || min
  if (!intervals.length) {
    return boundedInterval
  }
  let matchedInterval: TimeInterval = intervals[0]
  intervals.forEach((interval) => {
    const delta = Math.abs(interval.value - boundedInterval)
    if (delta < minDelta) {
      matchedInterval = interval
      minDelta = delta
    }
  })
  return matchedInterval.value
}

export const quickIntervalToTimeWindowMs = (qi: QuickTimeInterval): number => {
  switch (qi) {
    case QuickTimeInterval.CURRENT_HOUR:
      return HOUR
    case QuickTimeInterval.CURRENT_DAY:
    case QuickTimeInterval.CURRENT_DAY_SO_FAR:
    case QuickTimeInterval.YESTERDAY:
    case QuickTimeInterval.DAY_BEFORE_YESTERDAY:
    case QuickTimeInterval.THIS_DAY_LAST_WEEK:
      return DAY
    case QuickTimeInterval.CURRENT_WEEK:
    case QuickTimeInterval.CURRENT_WEEK_ISO:
    case QuickTimeInterval.CURRENT_WEEK_SO_FAR:
    case QuickTimeInterval.CURRENT_WEEK_ISO_SO_FAR:
    case QuickTimeInterval.PREVIOUS_WEEK:
    case QuickTimeInterval.PREVIOUS_WEEK_ISO:
      return WEEK
    case QuickTimeInterval.CURRENT_MONTH:
    case QuickTimeInterval.CURRENT_MONTH_SO_FAR:
    case QuickTimeInterval.PREVIOUS_MONTH:
      return 30 * DAY
    case QuickTimeInterval.CURRENT_QUARTER:
    case QuickTimeInterval.CURRENT_QUARTER_SO_FAR:
    case QuickTimeInterval.PREVIOUS_QUARTER:
      return 90 * DAY
    case QuickTimeInterval.CURRENT_HALF_YEAR:
    case QuickTimeInterval.CURRENT_HALF_YEAR_SO_FAR:
      return 180 * DAY
    case QuickTimeInterval.CURRENT_YEAR:
    case QuickTimeInterval.CURRENT_YEAR_SO_FAR:
    case QuickTimeInterval.PREVIOUS_YEAR:
      return YEAR
    default:
      return HOUR
  }
}

export const getFromTsAndToTs = (
  timeWindow?: TimeWindow
): { from: number; to: number } => {
  if (!timeWindow) {
    timeWindow = defaultTimeWindow()
  }
  const currentTime = dayjs()
  if (timeWindow.timeWindowType === TimeWindowType.REAL_TIME) {
    const { realtime } = timeWindow
    if (realtime?.realtimeType === RealTimeWindowType.LAST) {
      const { timeWindowsMs } = realtime
      return {
        from: currentTime.subtract(timeWindowsMs, 'millisecond').valueOf(),
        to: currentTime.valueOf(),
      }
    } else if (
      realtime?.realtimeType === RealTimeWindowType.INTERVAL &&
      realtime?.quickInterval
    ) {
      const { quickInterval } = realtime
      const timeWindowMs = quickIntervalToTimeWindowMs(quickInterval)
      return {
        from: currentTime.subtract(timeWindowMs, 'millisecond').valueOf(),
        to: currentTime.valueOf(),
      }
    } else {
      throw new Error('Invalid realtime type')
    }
  } else {
    const { history } = timeWindow
    if (history?.historyType === HistoryWindowType.LAST) {
      const { timeWindowsMs } = history
      return {
        from: currentTime.subtract(timeWindowsMs, 'millisecond').valueOf(),
        to: currentTime.valueOf(),
      }
    } else if (
      history?.historyType === HistoryWindowType.INTERVAL &&
      history?.quickInterval
    ) {
      const { quickInterval } = history
      const timeWindowMs = quickIntervalToTimeWindowMs(quickInterval)
      return {
        from: currentTime.subtract(timeWindowMs, 'millisecond').valueOf(),
        to: currentTime.valueOf(),
      }
    } else if (
      history?.historyType === HistoryWindowType.FIXED &&
      history.fixedTimeWindow?.startTimeMs &&
      history.fixedTimeWindow?.endTimeMs
    ) {
      return {
        from: history.fixedTimeWindow.startTimeMs,
        to: history.fixedTimeWindow.endTimeMs,
      }
    } else {
      console.log('error Invalid realtime type')
      throw new Error('Invalid history type')
    }
  }
}
