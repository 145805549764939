import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import useTenantCustomerContext from '../context/tenant-customer.context'
import { useForm } from 'react-hook-form'
import { Alarm, AlarmQuery } from '../../shared/models/alarm.model'
import { useLazyGetAlarmsQuery } from '../../store/kopiliot-api/alarm'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
  reducerPage,
} from '../../hooks/pagination-reducer'
import Asset from '../../shared/models/asset'
import { Authority } from '../../shared/models/user'
import KopiliotList from '../../components/kopiliot-list/KopiliotList'
import { Pagination } from '../../shared/models/others/pagination'
import { defaultAlarmActionRow, getColumnsForTableAlarm } from './columns'
import CanvasAlarmDetails from '../../features/alarm/CanvasAlarmDetails'

const AlarmList = () => {
  // Current user
  const user = useSelector((state: RootState) => state.currentUser)
  // If a parameter is present in the URL, it can be accessed via useParams()
  const { tenantIDParam, customerIDParam } = useTenantCustomerContext()
  const searchForm = useForm<AlarmQuery>()
  const [showCanvasDetails, setShowCanvasDetails] =
    React.useState<boolean>(false)
  const [selectedAlarm, setSelectedAlarm] = React.useState<Alarm | undefined>()
  const [getAlarms, { data, isLoading, error }] = useLazyGetAlarmsQuery()
  const {
    watch,
    formState: { errors },
  } = searchForm
  const { textSearch, tenantID, customerID } = watch()
  // Pagination
  // useGenericReducer is a custom hook that returns a state and a dispatch function
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducerPage,
    AlarmQuery,
    PaginationActions
  >(reducerPage, {
    textSearch: '',
    tenantID: undefined,
    customerID: undefined,
    limit: 10,
    offset: 0,
  })
  React.useEffect(() => {
    fetchAlarms()
  }, [
    paginationState.offset,
    paginationState.limit,
    textSearch,
    tenantID,
    customerID,
    tenantIDParam,
    customerIDParam,
  ])
  const fetchAlarms = () => {
    getAlarms({
      limit: paginationState.limit,
      offset: paginationState.offset,
      severity: paginationState.severity,
      status: paginationState.status,
      textSearch,
      customerID,
      tenantID,
    })
  }
  const getHiddenFields = () => {
    const hiddenFields: Array<keyof Asset> = []
    if (tenantIDParam) {
      hiddenFields.push('tenantID')
    }

    if (customerIDParam) {
      hiddenFields.push('customerID')
    }
    return hiddenFields
  }
  const getData = (): Pagination<Alarm> | undefined => {
    return data
  }
  const getHiddenColumns = (): Array<keyof Alarm> => {
    if (user.authority === Authority.TENANT_ADMIN || tenantIDParam) {
      return ['tenantID']
    } else if (user.authority === Authority.CUSTOMER_USER || customerIDParam) {
      return ['tenantID', 'customerID']
    } else {
      return []
    }
  }
  const onEditItem = (id: string) => {
    const alarm = getData()?.rows.find((item) => item.id?.id === id)
    setSelectedAlarm(alarm)
    setShowCanvasDetails(true)
  }
  return (
    <>
      <KopiliotList
        title={'Alarmes'}
        icon={'bell'}
        isLoading={isLoading}
        actionsProps={{
          onRefresh: () => {
            fetchAlarms()
          },
        }}
        data={getData()}
        paginationProps={{
          pageLimit: paginationState.limit,
          totalRecords: getData()?.totalRows || 0,
          onPageChanged: (page: {
            currentPage: number
            totalPages: number
            pageLimit: number
          }) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_CHANGE,
              payload: { ...page },
            })
          },
          onChangePageSize: (size: number) => {
            dispatchPaginationAction({
              type: PaginationActionType.PAGE_SIZE,
              payload: { pageSize: size },
            })
          },
        }}
        tableProps={{
          tableProps: {
            size: 'sm',
            className: 'mb-0 overflow-hidden table-hover cursor-pointer',
          },
          rowClassName: 'default__table-row align-middle fs-10',
          hiddenColumns: ['id', ...getHiddenColumns()],
          headerClassName: 'bg-200 text-900 text-nowrap align-middle',
          cellClassName: 'py-2 pe-4',
          columns: getColumnsForTableAlarm(defaultAlarmActionRow(onEditItem)),
          onClickRow: (row) => {
            row.original?.id?.id && onEditItem(row.original?.id?.id)
          },
        }}
        cardProps={{
          idAccessor: (item: Alarm) => item.id!.id,
          titleAccessor: (item: Alarm) => item.type,
          descriptionAccessor: (item: Alarm) => item.severity,
          linkAccessor: (item: Alarm) => `${item.id!.id}`,
          actionsRow: defaultAlarmActionRow(onEditItem),
        }}
      />
      <CanvasAlarmDetails
        open={showCanvasDetails}
        onHide={() => {
          setShowCanvasDetails(false)
          setSelectedAlarm(undefined)
        }}
        alarmID={selectedAlarm?.id}
      />
    </>
  )
}

export default AlarmList
