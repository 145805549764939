import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubtleBadge from '../../../components/common/SubtleBadge'
import { useGetUserCredentialsInfoQuery } from '../../../store/kopiliot-api/user'
import { Spinner } from 'react-bootstrap'

const UserActivated = ({ userID }: { userID: string }) => {
  let bg: 'success' | 'danger' | 'warning' | 'dark' = 'danger'
  const { data, isLoading, isFetching, error } =
    useGetUserCredentialsInfoQuery(userID)
  if (error) {
    return <span>Error while getting the user status</span>
  }

  const getColor = () => {
    if (error) {
      return 'danger'
    }
    if (data?.enabled && !error) {
      return 'success'
    }
    return 'warning'
  }
  const loadingCheck = isLoading || isFetching

  return loadingCheck || !data ? (
    <Spinner animation={'border'} size={'sm'} />
  ) : (
    <SubtleBadge pill className={'d-block'} bg={getColor()}>
      {data?.enabled ? 'ENABLED' : 'NOT ENABLED'}
      <FontAwesomeIcon
        icon={data?.enabled ? 'check' : 'times'}
        transform="shrink-2"
        className={'ms-1'}
      />
    </SubtleBadge>
  )
}

const UserModule = {
  UserActivated,
}

export default UserModule
