import React from 'react'
import { Card, CardProps } from 'react-bootstrap'
import classNames from 'classnames'
import SubtleBadge from '../SubtleBadge'
import CountUp from 'react-countup'

interface StatisticCardProps extends React.PropsWithChildren {
  title: string
  badgeText?: string
  badgeBg?: React.ComponentProps<typeof SubtleBadge>['bg']
  headerClassName?: string
  className?: string
  valueClassName?: string
  value?: number
  valuePrefix?: string
  valueSuffix?: string
  valueFormatter?: (value: number) => string
}

const StatisticCard = ({
  badgeText,
  badgeBg,
  headerClassName,
  className,
  title,
  valueClassName,
  value = 0,
  valuePrefix,
  valueSuffix,
  valueFormatter,
  ...rest
}: StatisticCardProps & CardProps) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')} {...rest}>
      <Card.Header className={classNames(headerClassName, 'pb-0')}>
        <h6>
          {title}
          {badgeText && (
            <SubtleBadge bg={badgeBg} pill className="ms-2">
              {badgeText}
            </SubtleBadge>
          )}
        </h6>
      </Card.Header>
      <Card.Body className="position-relative">
        <div
          className={classNames(
            valueClassName,
            'display-4 fs-4 mb-2 fw-normal font-sans-serif'
          )}
        >
          <CountUp end={value} duration={2} suffix={valueSuffix} />
        </div>
      </Card.Body>
    </Card>
  )
}

export default StatisticCard
