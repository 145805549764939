import React from 'react'
import { EntityId } from '../../shared/models/id/entity-id'
import {
  AttributePaginationForEntity,
  useLazyGetAttributesByEntityIdQuery,
} from '../../store/kopiliot-api/attribute'
import { Attribute, AttributeScope } from '../../shared/models/attribute'
import ContentBlock from '../../components/common/component-card/ContentBlock'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KopiliotListRowPerPage from '../../components/kopiliot-list/KopiliotListRowPerPage'
import { useGenericReducer } from '../../hooks/generic-hook'
import {
  PaginationActions,
  PaginationActionType,
} from '../../hooks/pagination-reducer'
import { createEntityQueryReducer } from '../../hooks/create-entity-pagination-reducer'
import { Select } from '../../components/page-components/selects-entities/generic-select/Select'
import { SelectableValue } from '../../components/page-components/selects-entities/generic-select/selectable-value'
import KopiliotListTypeTable from '../../components/kopiliot-list/KopiliotListTypeTable'
import attributeColumns from './columns'
import AddOrEditAttributeModal from './AddOrEditAttributeModal'
import IconButton from '../../components/common/IconButton'
import _ from 'lodash'

interface Props {
  entityID: EntityId
}

type ChangeScope = {
  type: 'UPDATE_SCOPE'
  payload: { scope: AttributeScope }
}

const AttributesList = ({ entityID }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedAttribute, setSelectedAttribute] = React.useState<Attribute>()
  const reducer = React.useMemo(() => {
    return createEntityQueryReducer<
      AttributePaginationForEntity,
      PaginationActions | ChangeScope
    >({
      handleSpecificActions: {
        ['UPDATE_SCOPE']: (state, action) => {
          return { ...state, scope: action.payload.scope }
        },
      },
    })
  }, [])
  const [paginationState, dispatchPaginationAction] = useGenericReducer<
    typeof reducer,
    AttributePaginationForEntity,
    PaginationActions | ChangeScope
  >(reducer, {
    limit: 20,
    offset: 0,
    textSearch: '',
    scope: AttributeScope.SERVER_SCOPE,
    entityId: entityID.id,
    entityType: entityID.entityType,
  })
  const [getAttributes, { data, isLoading, isFetching, error }] =
    useLazyGetAttributesByEntityIdQuery()
  React.useEffect(() => {
    getAttributes({
      entityId: entityID.id,
      entityType: entityID.entityType,
      scope: paginationState.scope,
      textSearch: '',
      limit: paginationState.limit,
      offset: 0,
    })
  }, [
    entityID,
    paginationState.scope,
    paginationState.limit,
    paginationState.offset,
  ])
  // Utility function
  const onChangePageSize = (size: number) => {
    dispatchPaginationAction({
      type: PaginationActionType.PAGE_SIZE,
      payload: { pageSize: size },
    })
  }
  const onModalClose = () => {
    setIsModalOpen(false)
    setSelectedAttribute(undefined)
  }

  const onEdit = (data: Attribute) => {
    // deep copy of the object
    const copy = _.cloneDeep(data)
    setIsModalOpen(true)
    copy.scope = paginationState.scope
    setSelectedAttribute(copy)
  }
  return (
    <>
      <ContentBlock>
        <ContentBlock.Header>
          <Row>
            <Col md={3}>
              <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                <FontAwesomeIcon
                  icon={'list'}
                  className="fs-0 pe-1 text-primary"
                />
                <h4 className="mb-0 text-center text-md-start me-2 text-primary">
                  Attributs
                </h4>
                <KopiliotListRowPerPage
                  onChange={onChangePageSize}
                  pageSize={paginationState.limit}
                />
              </div>
            </Col>
            <Col md={4} className={'mt-2 mt-md-0'}>
              <div className="d-flex justify-content-center justify-content-md-end">
                <Select
                  onChange={(e: SelectableValue<AttributeScope>) => {
                    e.value &&
                      dispatchPaginationAction({
                        type: 'UPDATE_SCOPE',
                        payload: { scope: e.value },
                      })
                  }}
                  value={paginationState.scope}
                  options={[
                    { value: AttributeScope.SERVER_SCOPE, label: 'Serveur' },
                    { value: AttributeScope.SHARED_SCOPE, label: 'Partagé' },
                    { value: AttributeScope.CLIENT_SCOPE, label: 'Client' },
                  ]}
                />
                <IconButton
                  onClick={() => {
                    setIsModalOpen(true)
                    setSelectedAttribute({
                      scope: paginationState.scope,
                      entityId: entityID.id,
                      entityType: entityID.entityType,
                      key: '',
                    } as Attribute)
                  }}
                  className="btn-sm"
                  variant={'outline-primary'}
                  iconClassName={'plus'}
                />
              </div>
            </Col>
          </Row>
        </ContentBlock.Header>
        <KopiliotListTypeTable
          cellClassName={'py-2 pe-4'}
          tableProps={{
            size: 'sm',
            className: 'fs-10 mb-0 overflow-hidden',
          }}
          headerClassName={'bg-200 text-900 text-nowrap align-middle'}
          columns={attributeColumns(onEdit)}
          data={data}
        />
      </ContentBlock>
      <AddOrEditAttributeModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        entityID={entityID}
        defaultValues={selectedAttribute}
      />
    </>
  )
}

export default AttributesList
