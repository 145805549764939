import {
  useLazyGetTenantQuery,
  useLazyGetTenantsQuery,
} from '../../../../store/kopiliot-api/tenant'
import React from 'react'
import { SelectableValue } from './selectable-value'
import TenantId from '../../../../shared/models/id/tenant-id'
import _ from 'lodash'
import { CustomerId } from '../../../../shared/models/id/customer-id'
import {
  useLazyGetCustomerQuery,
  useLazyGetCustomersQuery,
} from '../../../../store/kopiliot-api/customer'
import { AssetProfileId } from '../../../../shared/models/id/asset-profile-id'
import {
  useLazyGetDeviceProfileQuery,
  useLazyGetDeviceProfilesQuery,
} from '../../../../store/kopiliot-api/device-profile'
import { DeviceProfileId } from '../../../../shared/models/id/device-profile-id'

const useTenantSearch = (
  watchTenantID: TenantId,
  loadOnInit: boolean = false
) => {
  const [search, setSearch] = React.useState<string>('')
  const [getTenants, tenantsResult] = useLazyGetTenantsQuery()
  const [getTenant, tenantResult] = useLazyGetTenantQuery()
  const [tenantOptions, setTenantOptions] = React.useState<
    SelectableValue<TenantId>[]
  >([])
  React.useEffect(() => {
    getTenants({ textSearch: search, offset: 0, limit: 10 })
  }, [search])
  React.useEffect(() => {
    const res = _.map(tenantsResult.data?.rows, (tenant) => {
      return {
        label: tenant.name,
        value: tenant.id!,
      }
    })
    setTenantOptions(res)
  }, [tenantsResult.data])
  React.useEffect(() => {
    // We update the list only if the user is a main tenant
    if (watchTenantID && loadOnInit) {
      const fetchData = async () => {
        try {
          const t = await getTenant(watchTenantID.id).unwrap()
          if (t) {
            setTenantOptions([{ label: t.name, value: watchTenantID }])
          }
        } catch (err) {
          //toast.error(err.message)
        }
      }
      fetchData()
    }
  }, [watchTenantID])
  return {
    tenantOptions,
    setSearch,
    isLoading: tenantsResult.isLoading || tenantResult.isLoading,
  }
}

const useCustomerSearch = (watchCustomerID?: CustomerId, tenantID?: string) => {
  const [search, setSearch] = React.useState<string>('')
  const [getCustomers, customersResult] = useLazyGetCustomersQuery()
  const [getCustomer, customerResult] = useLazyGetCustomerQuery()
  const [customerOptions, setCustomerOptions] = React.useState<
    SelectableValue<CustomerId>[]
  >([])
  React.useEffect(() => {
    getCustomers({ textSearch: search, offset: 0, limit: 10 })
  }, [search])
  React.useEffect(() => {
    const res = _.map(customersResult.data?.rows, (tenant) => {
      return {
        label: tenant.name,
        value: tenant.id!,
      }
    })
    setCustomerOptions(res)
  }, [customersResult.data])
  React.useEffect(() => {
    // We update the list only if the user is a main tenant
    if (watchCustomerID) {
      const fetchData = async () => {
        try {
          const t = await getCustomer(watchCustomerID.id).unwrap()
          if (t) {
            setCustomerOptions([{ label: t.name, value: watchCustomerID }])
          }
        } catch (err) {
          //toast.error(err.message)
        }
      }
      fetchData()
    }
  }, [watchCustomerID])
  return {
    customerOptions,
    setSearch,
    isLoading: customersResult.isLoading || customerResult.isLoading,
  }
}

const useCustomersSearch = (
  selectedCustomerIDs?: CustomerId[],
  tenantID?: string
) => {
  const [search, setSearch] = React.useState<string>('')
  const [getCustomers, customersResult] = useLazyGetCustomersQuery()
  const [getCustomer, customerResult] = useLazyGetCustomerQuery()
  const [customerOptions, setCustomerOptions] = React.useState<
    SelectableValue<CustomerId>[]
  >([])
  // Fetch selected customers
  React.useEffect(() => {
    if (selectedCustomerIDs) {
      selectedCustomerIDs.forEach(async (id) => {
        const customer = await getCustomer(id.id, true).unwrap()
        if (customer) {
          setCustomerOptions((prevOptions) => [
            ...prevOptions,
            { label: customer.name, value: customer.id },
          ])
        }
      })
    }
  }, [selectedCustomerIDs?.length])
  React.useEffect(() => {
    getCustomers({ textSearch: search, offset: 0, limit: 10 })
  }, [search])
  React.useEffect(() => {
    const res = _.chain(customersResult.data?.rows)
      .map((c) => {
        return {
          label: c.name,
          value: c.id,
        }
      })
      .value()

    setCustomerOptions(res)
  }, [customersResult.data])
  return {
    customerOptions,
    setSearch,
    isLoading: customersResult.isLoading || customerResult.isLoading,
  }
}

const useAssetProfileSearch = (
  watchAssetProfileID?: AssetProfileId,
  tenantID?: string
) => {}

const useDeviceProfileSearch = (
  watchDeviceProfileID?: DeviceProfileId,
  tenantID?: string
) => {
  const [search, setSearch] = React.useState<string>('')
  const [getDeviceProfiles, deviceProfilesResult] =
    useLazyGetDeviceProfilesQuery()
  const [getDeviceProfile, deviceProfileResult] = useLazyGetDeviceProfileQuery()
  const [deviceProfileOptions, setDeviceProfileOptions] = React.useState<
    SelectableValue<DeviceProfileId>[]
  >([])
  React.useEffect(() => {
    getDeviceProfiles({ textSearch: search, offset: 0, limit: 10 })
  }, [search])
  React.useEffect(() => {
    const res = _.map(deviceProfilesResult.data?.rows, (tenant) => {
      return {
        label: tenant.name,
        value: tenant.id!,
      }
    })
    setDeviceProfileOptions(res)
  }, [deviceProfilesResult.data])
  React.useEffect(() => {
    // We update the list only if the user is a main tenant
    if (watchDeviceProfileID) {
      const fetchData = async () => {
        try {
          const t = await getDeviceProfile(watchDeviceProfileID.id).unwrap()
          if (t) {
            setDeviceProfileOptions([
              { label: t.name, value: watchDeviceProfileID },
            ])
          }
        } catch (err) {
          //toast.error(err.message)
        }
      }
      fetchData()
    }
  }, [watchDeviceProfileID])
  return {
    deviceProfileOptions,
    setSearch,
    isLoading: deviceProfilesResult.isLoading || deviceProfileResult.isLoading,
  }
}

export {
  useTenantSearch,
  useCustomerSearch,
  useCustomersSearch,
  useDeviceProfileSearch,
}
