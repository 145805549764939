import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidV4 } from 'uuid'
import { Dashboard, DashboardConfig } from '../../shared/models/dashboard.model'
import { RootState } from '../store'
import { GridPos, Panel } from '../../shared/models/panel'
import _ from 'lodash'
import { EntityType } from '../../shared/models/entity-type.models'
import TenantId from '../../shared/models/id/tenant-id'
import { Alias, Aliases } from '../../shared/models/alias.model'
import dashboardApi from '../kopiliot-api/dashboard'
import { defaultTimeWindow, TimeWindow } from '../../shared/models/time.model'
import { CustomerId } from '../../shared/models/id/customer-id'
import { PanelID } from '../../shared/models/id/panel-id'
import { Layout } from 'react-grid-layout'

interface CurrentDashboardSlice {
  id?: { id: string; entityType: EntityType }
  isEditing: boolean
  name: string
  description: string
  image?: string
  currentAction?: 'create' | 'edit'
  currentPanelEditID?: PanelID
  showAliasList: boolean
  config: DashboardConfig
  aliases?: Aliases
  panels: { [key: string]: Panel }
  assignedCustomers: CustomerId[]
  tenantID: TenantId
}
const initialState: CurrentDashboardSlice = {
  id: undefined,
  isEditing: false,
  name: '',
  description: '',
  currentAction: undefined,
  showAliasList: false,
  config: {
    version: 0,
    timeWindow: defaultTimeWindow(),
  },
  panels: {},
  assignedCustomers: [],
  tenantID: { id: '1', entityType: EntityType.TENANT },
}

// Dashboard Slice
const dashboardSlice = createSlice({
  initialState: initialState,
  name: 'dashboard',
  reducers: {
    setTime: (state, { payload }: PayloadAction<TimeWindow>) => {
      state.config.timeWindow = payload
    },
    setShowAliasList: (state) => {
      state.showAliasList = !state.showAliasList
    },
    setCurrentAction: (
      state,
      {
        payload,
      }: PayloadAction<{ action?: 'create' | 'edit'; panelID?: PanelID }>
    ) => {
      state.currentAction = payload.action
      state.currentPanelEditID = payload.panelID
    },
    setEditing: (state, { payload }: PayloadAction<{ isEditing: boolean }>) => {
      state.isEditing = payload.isEditing
    },
    addPanel: (state, { payload }: PayloadAction<{ panel: Panel }>) => {
      const { panel } = payload
      const id = uuidV4()
      panel.id = { id: id, entityType: EntityType.PANEL }
      panel.isNew = true
      panel.position = {
        positionX: (Object.keys(state.panels).length * 2) % 24,
        positionY: Infinity,
        positionW: 12,
        positionH: 8,
      }
      panel.dashboardID = state.id!
      //state.config.panels[id] = panel
      state.panels[id] = panel
    },
    updatePanel: (state, { payload }: PayloadAction<{ panel: Panel }>) => {
      const { panel } = payload
      if (panel.id) {
        state.panels[panel.id.id] = panel
      }
    },
    removePanel: (state, { payload }: PayloadAction<{ id: string }>) => {
      const { id } = payload
      console.log(state.panels)
      if (state.panels) {
        state.panels = _.omit(state.panels, id)
      }
    },
    updateGridPosByID: (
      state,
      { payload }: PayloadAction<{ id: string; gridPos: GridPos }>
    ) => {
      if (!state.panels) state.panels = {}
      const { id, gridPos } = payload
      if (state.panels[id]) {
        state.panels[id].position = {
          positionX: gridPos.positionX,
          positionY: gridPos.positionY,
          positionW: gridPos.positionW,
          positionH: isNaN(gridPos.positionH) ? 8 : gridPos.positionH,
        }
      }
    },
    updateLayout: (state, { payload }: PayloadAction<{ layout: Layout[] }>) => {
      payload.layout.forEach((item) => {
        if (state.panels[item.i]) {
          state.panels[item.i].position = {
            positionX: item.x,
            positionY: item.y,
            positionW: item.w,
            positionH: item.h,
          }
        }
      })
    },
    addAlias: (state, { payload }: PayloadAction<Alias>) => {
      if (!state.aliases) state.aliases = {}
      console.log(payload)
      if (!payload.id) {
        payload.id = { id: uuidV4(), entityType: EntityType.ALIAS }
      }
      state.aliases[payload.id.id] = { ...payload }
    },
    updateBasicInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string
        description: string
        assignedCustomers: CustomerId[]
      }>
    ) => {
      const { name, description } = payload
      state.name = name
      state.description = description
      state.assignedCustomers = payload.assignedCustomers
    },
    resetDashboard: (state) => {
      state.id = { id: '', entityType: EntityType.DASHBOARD }
      state.name = ''
      state.description = ''
      state.config = {
        version: 0,
        timeWindow: defaultTimeWindow(),
      }
      state.aliases = {}
      state.panels = {}
      state.assignedCustomers = []
      state.tenantID = { id: '1', entityType: EntityType.TENANT }
    },
  },
  // Copy the result of the API call to the slice https://stackoverflow.com/questions/75823034/rtk-query-extrareducers-uncaught-typeerror-cannot-read-properties-of-undefined
  extraReducers: (builder) => {
    builder.addMatcher<Dashboard>(
      dashboardApi.endpoints.getDashboard.matchFulfilled,
      (state, action) => {
        state.id = action.payload.id
        state.name = action.payload.name
        state.tenantID = action.payload.tenantId
        state.description = action.payload.description
        state.config = action.payload.config
        state.aliases = action.payload.aliases
        state.panels = action.payload.panels ? action.payload.panels : {}
        state.assignedCustomers = action.payload.assignedCustomers
      }
    )
  },
})

export const {
  setCurrentAction,
  addPanel,
  updatePanel,
  updateGridPosByID,
  updateLayout,
  setTime,
  removePanel,
  setShowAliasList,
  setEditing,
  addAlias,
  resetDashboard,
  updateBasicInfo,
} = dashboardSlice.actions

export default dashboardSlice.reducer

export const selectCurrentDashboard = (state: RootState) => state.dashboard

export const getCurrentLayout = (state: RootState) =>
  _.chain(state.dashboard.panels)
    .toArray()
    .filter((item) => !!item.id)
    .map((item) => {
      return {
        i: item.id!.id,
        x: item.position.positionX,
        y: item.position.positionY,
        w: item.position.positionW,
        h: item.position.positionH,
      }
    })
    .value()

//
export const getPanelByID = (state: RootState, id: string): Panel => {
  if (!state.dashboard.panels) {
    return {} as Panel
  }
  return state.dashboard.panels[id]
}
